import {
    Box,
    Button,
    Container,
    List,
    ListItem,
    ListItemText,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { PATH } from '../../constants/path'
import { ReactComponent as ChervonLeftIcon } from '../../assets/svgs/arrow-left.svg'
import { ReactComponent as NeedleMetaIcon } from '../../assets/svgs/needle-meta.svg'
const FacebookBusinessInstructionScreen = () => {
    const CopyButton = (props: { text: string }) => {
        const [isCopied, setIsCopied] = useState(false)
        return (
            <button
                onClick={() => {
                    navigator.clipboard.writeText(props.text)
                    setIsCopied(true)
                }}
                className="rounded-e-lg bg-black text-white h-45px px-4 font-bold"
            >
                {isCopied ? `Copied` : `Copy`}
            </button>
        )
    }
    return (
        <Container maxWidth={'md'}>
            <Stack>
                <Box position={'relative'} top={'32px'}>
                    <Link to={PATH.tools}>
                        <Box display="flex" gap={1} alignItems="center">
                            <ChervonLeftIcon />
                            <Typography fontSize={16} fontWeight={700}>
                                Back
                            </Typography>
                        </Box>
                    </Link>
                </Box>

                <Box display={'flex'} justifyContent={'center'}>
                    <NeedleMetaIcon />
                </Box>

                <Box
                    sx={{
                        mx: 3,
                        mt: 4,
                        mb: 8,
                        bgcolor: 'white',
                        p: 3,
                        borderRadius: '8px',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    }}
                >
                    <Box mt={1}>
                        <Typography variant="h1">
                            Needle is requesting Partner Access to your Meta
                            account
                        </Typography>
                        <Typography mt={1}>
                            Follow these simple instructions to grant access to
                            Needle:
                        </Typography>
                    </Box>
                    <List component="ol" sx={{ listTyle: 'decimal' }}>
                        <ListItem
                            sx={{
                                p: 0,
                            }}
                            component={'li'}
                            className={'liStyle'}
                        >
                            <Stack spacing={1}>
                                <ListItemText>
                                    1. Copy Needle's Business ID
                                </ListItemText>
                                <Box display={'flex'} alignItems={'center'}>
                                    <Box>
                                        <input
                                            type="text"
                                            value={817343246508546}
                                            className="py-3 px-4 border rounded-s-lg h-[45px] focus:ring-1 focus:ring-inset focus:ring-dark-blue w-[180px]"
                                        />
                                    </Box>
                                    <Box>
                                        <CopyButton text="817343246508546" />
                                    </Box>
                                </Box>
                            </Stack>
                        </ListItem>
                        <ListItem
                            sx={{
                                p: 0,
                                mt: 4,
                            }}
                            component={'li'}
                            className={'liStyle'}
                        >
                            <Stack spacing={1}>
                                <ListItemText>
                                    2. Open{' '}
                                    <strong>Meta Business Manager</strong>.
                                </ListItemText>

                                <Box>
                                    <a
                                        target="_blank"
                                        href="https://business.facebook.com/latest/settings"
                                    >
                                        <Button
                                            sx={{
                                                mt: 2,
                                                bgcolor: 'black !important',
                                                color: 'white !important',
                                            }}
                                        >
                                            OPEN BUSINESS MANAGER
                                        </Button>
                                    </a>
                                </Box>
                            </Stack>
                        </ListItem>

                        <ListItem
                            sx={{
                                p: 0,
                                mt: 4,
                            }}
                            component={'li'}
                            className={'liStyle'}
                        >
                            <Stack spacing={2}>
                                <ListItemText>
                                    3. Go to the{' '}
                                    <strong>“Users {'>'} Partners”</strong>{' '}
                                    page. Click{' '}
                                    <strong>
                                        “Add {'>'} Give a partner access to your
                                        assets”
                                    </strong>
                                    .
                                </ListItemText>
                                <img src="assets/imgs/add-partner.png" />
                            </Stack>
                        </ListItem>

                        <ListItem
                            sx={{
                                p: 0,
                                mt: 4,
                            }}
                            component={'li'}
                            className={'liStyle'}
                        >
                            <Stack spacing={2}>
                                <ListItemText>
                                    4. Paste in Needle’s{' '}
                                    <strong>Business Manager ID</strong> that
                                    you copied in Step 1.
                                </ListItemText>
                                <Box>
                                    <img src="/assets/imgs/paste-id.png" />
                                </Box>
                            </Stack>
                        </ListItem>

                        <ListItem
                            sx={{
                                p: 0,
                                mt: 4,
                            }}
                            component={'li'}
                            className={'liStyle'}
                        >
                            <Stack>
                                <ListItemText>
                                    5. Select "<strong>Ad Accounts</strong>" on
                                    the left.{' '}
                                    <strong>Choose your Ad Account(s)</strong>{' '}
                                    and <strong>switch on</strong> "Manage ad
                                    accounts".
                                </ListItemText>
                                <Box>
                                    <img src="/assets/imgs/AdAccount_admin.png" />
                                </Box>
                            </Stack>
                        </ListItem>
                        <ListItem
                            sx={{
                                p: 0,
                            }}
                            component={'li'}
                            className={'liStyle'}
                        >
                            <ListItemText>
                                6. Click <strong>Save Changes</strong>
                            </ListItemText>
                        </ListItem>
                    </List>

                    <Stack spacing={1} mt={1}>
                        <Box display={'flex'} justifyContent={'flex-end'}>
                            <Link to={PATH.tools}>
                                <Button
                                    sx={{
                                        bgcolor: 'black !important',
                                        color: 'white !important',
                                    }}
                                >
                                    {' '}
                                    I’ve done all the steps
                                </Button>
                            </Link>
                        </Box>
                    </Stack>
                </Box>
            </Stack>
        </Container>
    )
}

export default FacebookBusinessInstructionScreen
