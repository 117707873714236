import { Box, Button, CircularProgress, Stack } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import TextFieldDefault from '../../components/TextField'
import { AuthService } from '../../firebase/authentication'
import { showToast } from '../../redux/appReducer'
import './index.scss'
import NeedleTopLogo from '../../components/NeedleTopLogo'
function ForgotPasswordScreen() {
    const [email, setEmail] = useState<string>('')
    const [isSented, setIsSented] = useState<
        'idle' | 'loading' | 'success' | string
    >('idle')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleSendLinkClick = async () => {
        setIsSented('loading')
        const { success, message } = await AuthService.resetPassword(email)
        if (!success)
            dispatch(
                showToast({
                    type: 'error',
                    content: message,
                })
            )
        setIsSented(success ? 'success' : 'idle')
    }
    return (
        <Box className="forgot-password-wrapper">
            <NeedleTopLogo />
            <Box
                className={`background-img ${
                    isSented === 'success' ? 'sent' : ''
                } `}
            ></Box>
            {isSented === 'success' ? (
                <Box className="forgot-password-modal">
                    <Stack>
                        <Stack alignItems={'center'}>
                            <h1 className="page-title">Check your inbox</h1>
                            <p className="page-description">
                                All it takes is only 10 minutes to get your
                                first report.
                            </p>
                        </Stack>

                        <Stack direction={'row'} justifyContent={'center'}>
                            <img
                                height={242}
                                src="./assets/imgs/Check-Inbox.svg"
                                alt="your reset password link has been sent!"
                            />
                        </Stack>

                        <Button
                            className="sent-link-button"
                            onClick={() => navigate('/login')}
                            variant="contained"
                        >
                            Log in
                        </Button>
                        <Stack
                            className="other-option"
                            justifyContent={'center'}
                            direction={'row'}
                            spacing={1}
                        >
                            <span>Don't have account?</span>
                            <Link to={'/sign-up'}>Create account</Link>
                        </Stack>
                    </Stack>
                </Box>
            ) : (
                <Box className="forgot-password-modal">
                    <Stack>
                        <Stack alignItems={'center'}>
                            <h1 className="page-title">
                                Forgot your password?
                            </h1>
                            <p className="page-description">
                                No worries. We’ve all been there. Just tell us
                                your email address associated with your Needle
                                account and we’ll send you a reset link.
                            </p>
                        </Stack>
                        <TextFieldDefault
                            placeholder="youremail@address.com"
                            label="Email"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                            name="email"
                        />
                        <Button
                            startIcon={
                                isSented === 'loading' ? (
                                    <CircularProgress size={16} color="info" />
                                ) : undefined
                            }
                            sx={{ mt: 2 }}
                            onClick={handleSendLinkClick}
                            variant="contained"
                        >
                            Send Reset Link
                        </Button>
                        <Stack
                            className="other-option"
                            justifyContent={'center'}
                            direction={'row'}
                            spacing={1}
                        >
                            <span>Don't have account?</span>
                            <Link to={'/sign-up'}>Create account</Link>
                        </Stack>
                    </Stack>
                </Box>
            )}
        </Box>
    )
}

export default ForgotPasswordScreen
