import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { Services } from '../services'
import { Connector } from '../utils/enum'
import {
    currentEmailTools,
    currentMarket,
    currentShopAnalytics,
    getConnectedList,
} from '../views/ConnectToolsPage/component'

export interface ConnectorState {
    connectors?: Connector[]
    currentStep: number
    status: 'idle' | 'loading' | 'success' | 'error' | 'init'
    message: string
    emailConnected: string[]
    marketConnected: string[]
    shopAnalyticsConnected: string[]
}
const initialState = {
    connectors: [],
    currentStep: 0,
    status: 'init',
    message: '',
    emailConnected: [],
    marketConnected: [],
    shopAnalyticsConnected: [],
} as unknown as ConnectorState

export const moveToStep = createAction<number>('connector/moveToStep')
export const disconnectConnector = createAction<{
    serviceId: string
    message: string
}>('connector/disconnect')
export const setConnectorsForNewUser = createAction(
    'connector/setConnectorsForNewUser'
)

export const addNewConnectorToList = createAction<Connector>(
    'connector/addNewConnectorToList'
)
export const fetchUserConnector = createAsyncThunk(
    'connector/fetchList',
    async () => {
        const res = await Services.getConnectedConnector()
        return res.data?.items as Connector[]
    }
)
const connectorReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(moveToStep, (state, action) => {
            if (action.payload > state.currentStep)
                state.currentStep = action.payload
        })
        .addCase(disconnectConnector, (state, action) => {
            const id = action.payload.serviceId
            state.connectors = state.connectors?.filter(
                (item) => item.id !== id
            )
            const connectors = state.connectors ? state.connectors : []
            state.currentStep = determineStep(connectors)

            state.emailConnected = getConnectedList(
                connectors,
                currentEmailTools
            )
            state.marketConnected = getConnectedList(connectors, currentMarket)
            state.shopAnalyticsConnected = getConnectedList(
                connectors,
                currentShopAnalytics
            )
        })
        .addCase(setConnectorsForNewUser, (state, action) => {
            state.connectors = []
            state.currentStep = 1
            state.status = 'success'
        })
        .addCase(fetchUserConnector.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(fetchUserConnector.rejected, (state, action) => {
            const error: any = action.error
            state.currentStep = 1
            state.status = 'error'
            state.message = error.message
        })
        .addCase(fetchUserConnector.fulfilled, (state, action) => {
            const connectors = action.payload
            state.status = 'success'
            state.message = 'Get connectors succesfully!'
            state.connectors = connectors
            state.currentStep = determineStep(connectors)
            state.emailConnected = getConnectedList(
                connectors,
                currentEmailTools
            )
            state.marketConnected = getConnectedList(connectors, currentMarket)
            state.shopAnalyticsConnected = getConnectedList(
                connectors,
                currentShopAnalytics
            )
        })
        .addCase(addNewConnectorToList, (state, action) => {
            state.connectors = state.connectors?.concat(action.payload)
        })
})

const determineStep = (connectors: Connector[]): number => {
    const services = Array.from(
        new Set(
            connectors
                ?.filter(
                    (connector) =>
                        connector.status.setup_state === 'connected' ||
                        connector.service === 'shopify'
                )
                .map((item) => item.service)
        )
    )
    const hasShopify = services.includes('shopify')
    const hasWooCommerce = services.includes('woocommerce')
    const hasGA4 = services.includes('google_analytics_4')

    if (!hasShopify && !hasWooCommerce) {
        return 1
    } else if (hasGA4) {
        return 3
    } else {
        return 2
    }
}
export default connectorReducer
