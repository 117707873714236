import { Box, Container, Stack, Typography, Button } from '@mui/material'
import React from 'react'
import { ReactComponent as NeedleLogo } from '../../assets/svgs/needle-logo-yellow.svg'
import { ReactComponent as MobileIcon } from '../../assets/svgs/mobile.svg'
import { ReactComponent as DesktopOnlyImage } from '../../assets/svgs/desktop-only.svg'
import { useNavigate } from 'react-router-dom'

const NotSupportOnMobileScreen = () => {
    const navigate = useNavigate()

    return (
        <Container>
            <Stack
                justifyContent={'center'}
                sx={{
                    margin: '40px auto',
                }}
            >
                <Box onClick={() => navigate(`/result/growth-plan/`)}>
                    <NeedleLogo />
                </Box>
                <Typography mt={1} variant="body1">
                    Your personal AI marketer.
                </Typography>

                <Box>
                    <Box
                        mt={7}
                        sx={{
                            bgcolor: 'yellow.300',
                            borderRadius: '16px',
                            display: 'inline-flex',
                            gap: '4px',
                            justifyContent: 'start',
                            alignItems: 'center',
                            padding: '4px 12px',
                        }}
                    >
                        <MobileIcon />
                        <Typography
                            display={'inline'}
                            fontSize={14}
                            fontWeight={500}
                            lineHeight={'16.8px'}
                        >
                            Not supported on mobile
                        </Typography>
                    </Box>
                </Box>

                <Typography
                    sx={{
                        marginTop: '20px',
                        marginBottom: '28px',
                    }}
                    variant="h3-bold"
                >
                    For best experience, we recommend using a desktop device.
                </Typography>
                <DesktopOnlyImage width={'100%'} />

                <Button sx={{ marginTop: '20px' }} onClick={() => navigate(-1)}>
                    Back
                </Button>
            </Stack>
        </Container>
    )
}

export default NotSupportOnMobileScreen
