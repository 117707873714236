import { useMediaQuery } from '@mui/material'

export default function useMediaQueryHook() {
    const isMobile = useMediaQuery((theme: any) =>
        theme.breakpoints.between('0', 'mobile')
    )
    const isTablet = useMediaQuery((theme: any) =>
        theme.breakpoints.between('mobile', 'tablet')
    )
    const isLaptop = useMediaQuery((theme: any) =>
        theme.breakpoints.up('laptop')
    )

    return isLaptop ? 'laptop' : isTablet ? 'tablet' : isMobile ? 'mobile' : ''
}
