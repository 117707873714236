import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Modal,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import React, { memo, useEffect, useState } from 'react'
import { showToast } from '../../redux/appReducer'
import { disconnectConnector } from '../../redux/connectorReducer'
import { useAppDispatch } from '../../redux/store'
import { Services } from '../../services'
import { allTools as services } from '../../constants'
import './DisconnectToolModal.scss'
import useWorkspace from '../../hooks/useWorkspace'
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '467px',
    bgcolor: '#FFFFFF',
    padding: '32px 24px',
    borderRadius: '16px',
}

const messages = [
    'I want to change the account of my data source',
    'I have concerns about the privacy of my data',
    'I no longer have any use for Needle',
]

function DisconnectToolModal(props: {
    serviceId: string
    open: boolean
    handleClose: () => void
    connectorId: string
}) {
    const { handleClose } = props
    const [selection, setSelection] = useState<string>(messages[0])
    const [feedback, setFeedback] = useState<string>('')
    const [step, setStep] = useState<number>(0)
    const [status, setStatus] = useState<
        'idle' | 'loading' | 'error' | 'success'
    >('idle')
    const { data: workspace, mutate: mutateWorkspace } = useWorkspace(undefined)

    useEffect(() => {
        setStep(0)
        setFeedback('')
        setSelection(messages[0])
        setStatus('idle')
    }, [props.open])

    const dispatch = useAppDispatch()
    const handleDisconnect = async (message: string) => {
        try {
            setStatus('loading')
            await Services.deleteConnectorById({
                connectorId: props.connectorId,
                message,
            })
            setStatus('success')
            dispatch(
                disconnectConnector({
                    serviceId: props.connectorId ?? '',
                    message,
                })
            )
            setStep(2)
            mutateWorkspace()
        } catch (e) {
            dispatch(
                showToast({
                    type: 'error',
                    content:
                        'Cannot delete connector at this time, please try later!',
                })
            )
        }
    }
    const ConfirmDisconnectModal = memo(() => {
        return (
            <Stack
                className="modal-wrapper"
                alignItems={'center'}
                justifyContent={'center'}
                spacing={3}
            >
                <Typography className="modal-title" textAlign={'center'}>
                    Are you sure you want to disconnect?
                </Typography>
                <Stack
                    alignItems={'center'}
                    justifyContent={'center'}
                    spacing={1}
                >
                    {serviceData?.avatar}
                    <Typography className="service-name" textAlign={'center'}>
                        {serviceData?.service}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        sx={{ color: 'rgba(0, 0, 0, 0.5)', fontSize: '12px' }}
                        textAlign={'center'}
                    >
                        {serviceData?.id === 'shopify'
                            ? 'via Shopify'
                            : 'via Fivetran'}
                    </Typography>
                </Stack>
                {'shopify,google_analytics_4'.includes(props.serviceId) && (
                    <Typography
                        variant="subtitle2"
                        textAlign={'center'}
                        sx={{ color: 'color: rgba(0, 0, 0, 0.6)' }}
                    >
                        Needle won’t work if you disconnect key data sources
                        like Shopify or Google Analytics.{' '}
                    </Typography>
                )}
                <Stack
                    spacing={3}
                    width={'100%'}
                    direction={'row'}
                    justifyContent="space-between"
                >
                    <Button
                        disabled={status === 'loading'}
                        onClick={handleClose}
                        fullWidth
                        variant={'outlined'}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={status === 'loading'}
                        onClick={() => setStep(1)}
                        fullWidth
                        variant={'contained'}
                    >
                        {status === 'loading' ? (
                            <CircularProgress size={24} color="info" />
                        ) : (
                            'Disconnect'
                        )}
                    </Button>
                </Stack>
            </Stack>
        )
    })

    const SuccessModal = () => {
        return (
            <Stack
                className="modal-wrapper"
                alignItems={'center'}
                justifyContent={'center'}
                spacing={3}
            >
                <Typography className="modal-title" textAlign={'center'}>
                    Thanks for your feedback!
                </Typography>
                <Typography
                    variant="subtitle2"
                    textAlign={'center'}
                    sx={{ color: 'color: rgba(0, 0, 0, 0.6)' }}
                >
                    Your feedback helps us improve Needle.
                </Typography>

                <Box>
                    <img
                        className="success-img"
                        src="/assets/imgs/High-Five.svg"
                        alt="success"
                    />
                </Box>

                <Stack
                    spacing={3}
                    width={'100%'}
                    direction={'row'}
                    justifyContent="space-between"
                >
                    <Button
                        onClick={() => {
                            props.handleClose()
                            setStep(0)
                        }}
                        fullWidth
                        variant={'outlined'}
                    >
                        Close
                    </Button>
                </Stack>
            </Stack>
        )
    }

    const steps = [<ConfirmDisconnectModal />, <SuccessModal />]
    const serviceData = services.find((item) => item.id === props.serviceId)
    return (
        <Modal
            open={props.open}
            onClose={status === 'loading' ? () => {} : props.handleClose}
        >
            <Box sx={{ ...style, width: 400 }}>
                {step === 0 && steps[0]}
                {step === 1 && (
                    <Stack
                        className="modal-wrapper"
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={3}
                    >
                        <Typography
                            className="modal-title"
                            textAlign={'center'}
                        >
                            Are you sure you want to disconnect?
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            textAlign={'center'}
                            sx={{ color: 'color: rgba(0, 0, 0, 0.6)' }}
                        >
                            Needle won’t work if you disconnect key data sources
                            like Shopify or Google Analytics.{' '}
                        </Typography>

                        <FormControl
                            sx={{
                                width: '100%',
                            }}
                        >
                            <RadioGroup
                                value={selection}
                                onChange={(e) => {
                                    setFeedback('')
                                    setSelection(e.target.value)
                                }}
                            >
                                {messages.map((message) => {
                                    return (
                                        <FormControlLabel
                                            key={message}
                                            className="form-label"
                                            value={message}
                                            control={<Radio />}
                                            label={message}
                                        />
                                    )
                                })}

                                <FormControlLabel
                                    className="form-label"
                                    value="other"
                                    control={<Radio />}
                                    label="Other"
                                />
                            </RadioGroup>
                            {
                                <TextField
                                    value={feedback}
                                    disabled={
                                        selection !== 'other' ||
                                        status === 'loading'
                                    }
                                    onChange={(e) =>
                                        setFeedback(e.target.value)
                                    }
                                    sx={{
                                        marginTop: '24px',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderRadius: '16px',
                                        },
                                    }}
                                    rows={3}
                                    placeholder={'Extra details here'}
                                    multiline
                                    variant="outlined"
                                />
                            }
                        </FormControl>

                        <Stack
                            spacing={3}
                            width={'100%'}
                            direction={'row'}
                            justifyContent="space-between"
                        >
                            <Button
                                onClick={props.handleClose}
                                fullWidth
                                variant={'outlined'}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={selection === ''}
                                onClick={() =>
                                    handleDisconnect(
                                        selection === 'other'
                                            ? feedback
                                            : selection
                                    )
                                }
                                fullWidth
                                variant={'contained'}
                            >
                                {status === 'loading' ? (
                                    <CircularProgress size={24} color="info" />
                                ) : (
                                    'Send'
                                )}
                            </Button>
                        </Stack>
                    </Stack>
                )}
                {step === 2 && steps[1]}
            </Box>
        </Modal>
    )
}

export default DisconnectToolModal
