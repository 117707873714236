import {
    BrandCustomizationSection,
    BrandsCardType,
    Sections_Data,
} from '../BrandProfilePage/type'
const BASIC_ASSETS_SECTIONS: Sections_Data[] = [
    {
        name: 'Brand details',
        value: { Name: '', logo: null, guide: null, customFont: null },
        stateKey: 'aAndTBasicAssets',
        type: BrandsCardType.EIBasicAssets,
    },
]
export const ASSETS_AND_TEMPLATES_SECTIONS: BrandCustomizationSection = {
    name: 'Basic assets',
    detailSection: BASIC_ASSETS_SECTIONS,
}

export const customStylesDropDownAAndT = {
    control: (provided: any, state: any) => ({
        ...provided,
        minHeight: '45px', // Decrease the height of the dropdown control
        display: 'flex',
        height: '45px',
        alignItems: 'center',
        borderColor: 'bg-dark-blue',
        borderRadius: '8px',
        boxShadow: 'none',
        '&:hover': {
            border: 'bg-black',
        },
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
    }),
    singleValue: (styles: any) => ({
        ...styles,
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
        ...provided,
        paddingRight: '20px',
        color: 'black',
        svg: {
            height: '16px',
            width: '16px',
        },
    }),
    menuPortal: (provided: any) => ({
        ...provided,
        zIndex: 9999,
    }),
}
export const figmaTutorial = `
            <div>
                <p style="padding-bottom: 8px;">1. Go to your Figma file.</p>
                <p style="padding-bottom: 8px;">2. Click <strong>“Share”</strong> on the top right.</p>
                <p style="padding-bottom: 8px;">3. Add <strong>“access@askneedle.com”</strong> with role <strong>“can edit”</strong>, and click <strong>“Invite”</strong>.</p>
                <p>4. Click <strong>“Create & copy link”</strong> on the share menu, then click <strong>“Copy link”</strong> and paste the link below.</p>
            </div>`

export const canvaTutorial = `
            <div>
                <p style="padding-bottom: 8px;">1. Go to your Canva file.</p>
                <p style="padding-bottom: 8px;">2. Click <strong>“Share”</strong> on the top right.</p>
                <p style="padding-bottom: 8px;">3. Add <strong>“access@askneedle.com”</strong> with role <strong>“Edit”</strong>, and click <strong>“Share”</strong>.</p>
                <p>4. Click <strong>“Create & copy link”</strong> on the share menu, then click <strong>“Copy link”</strong> and paste the link below.</p>
            </div>`

export const klaviyoEI = `
            <div>
                <p style="padding-bottom: 8px;">1. Go to <strong>“Settings > Users”</strong>.</p>
                <p style="padding-bottom: 8px;">2. Click <strong>“Add new user”</strong>.</p>
                <p style="padding-bottom: 8px;">3. Add <strong>“access@askneedle.com”</strong> with the Role of <strong>“Content Creator”</strong>.</p>
            </div>`

export const getLinkGoogleDriveEI = `
            <div>
                <p style="padding-bottom: 8px;">1. Select the Google Drive folder you wish to share with Needle.</p>
                <p style="padding-bottom: 8px;">2. Click on <strong>“Share”</strong>.</p>
                <p style="padding-bottom: 8px;">3. Share with  <strong>“access@askneedle.com”</strong> with the Role of <strong>“Editor”</strong>.</p>
                <p>4. Click  <strong>“Copy link”</strong> on the share menu and paste the link below.</p>
            </div>`

export const getLinkDropboxEI = `
            <div>
                <p style="padding-bottom: 8px;">1. Select the Dropbox folder you wish to share with Needle.</p>
                <p style="padding-bottom: 8px;">2. Click on <strong>“Share selected”</strong>.</p>
                <p style="padding-bottom: 8px;">3. Share with <strong>“access@askneedle.com”</strong>, with the <strong>“can edit”</strong> access.</p>
                <p>4. Click <strong>“Create & copy link”</strong> on the share menu, then click <strong>“Copy link”</strong> and paste the link below.</p>
            </div>`
