import Lottie from 'react-lottie'
import { loadingIconAnimation } from './LottieAnimation'

interface NeedleButtonProps {
    onClickFunction?: React.MouseEventHandler<HTMLDivElement>
    buttonCharacter?: string
    children?: JSX.Element
    buttonType?: ButtonType
    isDisable?: boolean
    isFull?: boolean
    border?: string
    className?: string
    isLoading?: boolean
}
export enum ButtonType {
    Black = 'Black',
    White = 'White',
    Red = 'Red',
}
export const NeedleButton = ({
    onClickFunction = () => {},
    buttonCharacter,
    buttonType = ButtonType.Black,
    isDisable,
    children,
    border = 'p-3 px-4',
    isFull = false,
    className,
    isLoading = false,
}: NeedleButtonProps) => {
    const buttonShadow =
        buttonType === ButtonType.Black
            ? 'hover:shadow-button-primary cursor-pointer'
            : 'hover:shadow-button-secondary cursor-pointer'

    return (
        <div className={`flex font-button-text w-full ${className}`}>
            <div
                className={`${isFull && 'flex justify-center w-full'} border-black border-1px ${
                    buttonType === ButtonType.White
                        ? 'bg-white text-dark-blue'
                        : 'bg-black text-white'
                }
                rounded ${border} transition-all duration-300 ease-in-out
                ${isDisable ? 'opacity-50 disabled cursor-not-allowed' : buttonShadow}
                `}
                onClick={
                    isDisable || isLoading === true
                        ? undefined
                        : onClickFunction
                }
            >
                {isLoading ? (
                    <div className="h-4 aspect-square w-full flex justify-center">
                        <Lottie
                            options={loadingIconAnimation}
                            isClickToPauseDisabled={true}
                        />
                    </div>
                ) : (
                    <div>
                        {buttonCharacter}
                        {children}
                    </div>
                )}
            </div>
        </div>
    )
}
