import { create } from 'zustand'
import { ConceptType } from '../views/IdeasDetailPage/constants'
export enum DialogType {
    Archive = 'Archive',
    NoCredit = 'NoCredit',
    NeedleHelp = 'NeedleHelp',
    MarkAsDone = 'MarkAsDone',
    RestoreIdeas = 'RestoreIdeas',
    SomeThingWrong = 'SomeThingWrong',
}
export interface DialogStoreProps {
    isOpen: boolean
    dialogType: DialogType
    creditCost: number
    conceptsType: ConceptType
    conceptsId: string
    setIsOpen: (isOpen: boolean, dialogType?: DialogType) => void
    setOpenForNeedle: (
        isOpen: boolean,
        dialogType: DialogType,
        creditCost: number,
        conceptsType: ConceptType,
        conceptsId: string
    ) => void
}

const dialogStore = create<DialogStoreProps>((set, get) => ({
    isOpen: false,
    dialogType: DialogType.Archive,
    creditCost: 0,
    conceptsType: ConceptType.Email,
    conceptsId: '',
    setIsOpen: (isOpen: boolean, dialogType?: DialogType) =>
        set({
            isOpen: isOpen,
            dialogType: dialogType ? dialogType : get().dialogType,
        }),
    setOpenForNeedle: (
        isOpen: boolean,
        dialogType: DialogType,
        creditCost: number,
        conceptsType: ConceptType,
        conceptsId: string
    ) =>
        set({
            isOpen: isOpen,
            dialogType: dialogType,
            creditCost: creditCost,
            conceptsType: conceptsType,
            conceptsId: conceptsId,
        }),
}))

export default dialogStore
