import React, { useEffect, useState } from 'react'
import './TextSection.scss'
import ChatWithNeedleHelp from './ChatWithNeedleHelp'
import {
    AGE_DATA,
    FAMILY_DATA,
    GENDER_DATA,
} from '../../views/BrandProfilePage/constants'
import { OurCustomers } from '../../views/BrandProfilePage/type'
import CheckBoxNeedle from '../CheckBoxNeedle'
interface CardTypeCustomersProps {
    placeHolder?: string
    setCurrentCustomerData: (value: React.SetStateAction<any>) => void
    currentCustomerData: OurCustomers
    isDisplayNeedleHelp?: boolean
    setIsDisable: React.Dispatch<React.SetStateAction<boolean>>
}

function CardTypeCustomers({
    setCurrentCustomerData,
    currentCustomerData,
    setIsDisable,
    placeHolder,
    isDisplayNeedleHelp = false,
}: CardTypeCustomersProps) {
    const [describe, setDescribe] = useState<string>(
        currentCustomerData.Describe
    )
    const [gender, setGender] = useState<string[]>(currentCustomerData.Gender)
    const [ageGroup, setAgeGroup] = useState<string[]>(
        currentCustomerData.AgeGroup
    )
    const [family, setFamily] = useState<string[]>(currentCustomerData.Family)

    useEffect(() => {
        if (
            gender.length > 0 &&
            ageGroup.length > 0 &&
            family.length > 0 &&
            describe.trim() !== ''
        ) {
            const DataCustomer: OurCustomers = {
                Describe: describe,
                AgeGroup: ageGroup,
                Gender: gender,
                Family: family,
            }
            setCurrentCustomerData(DataCustomer)
            setIsDisable(false)
        } else {
            setIsDisable(true)
        }
    }, [gender, ageGroup, family, describe])

    return (
        <div className="mx-6 mt-6 mb-2">
            <div className="font-subtitle">
                Who are your customers?<span className="text-red-theme">*</span>
            </div>
            <div className="font-small-text py-2">
                Describe them, as if you’re talking to a friend.
            </div>
            <textarea
                rows={3}
                id="first_name"
                className="border border-beige-outline font-body-text
                    py-3 px-4 w-full rounded-lg focus:outline-dark-blue"
                placeholder={placeHolder}
                value={describe}
                onChange={(e) => {
                    setDescribe(e.target.value)
                }}
            />
            {isDisplayNeedleHelp && <ChatWithNeedleHelp />}
            <div className="">
                <div className="font-subtitle pt-2">
                    Age group:<span className="text-red-theme">*</span>
                </div>
                <div className="flex flex-col pt-2">
                    {AGE_DATA.map((ageType) => {
                        return (
                            <CheckBoxNeedle
                                key={ageType}
                                keyName={ageType}
                                selectedData={ageGroup}
                                setData={setAgeGroup}
                            />
                        )
                    })}
                </div>
                <div className="font-subtitle">
                    Gender:<span className="text-red-theme">*</span>
                </div>
                <div className="flex flex-row pt-2 flex-wrap">
                    {GENDER_DATA.map((genderType) => {
                        return (
                            <CheckBoxNeedle
                                key={genderType}
                                keyName={genderType}
                                selectedData={gender}
                                setData={setGender}
                            />
                        )
                    })}
                </div>
                <div className="font-subtitle pt-2">
                    Family status:<span className="text-red-theme">*</span>
                </div>
                <div className="flex flex-col pt-2">
                    {FAMILY_DATA.map((familyType) => {
                        return (
                            <CheckBoxNeedle
                                key={familyType}
                                keyName={familyType}
                                selectedData={family}
                                setData={setFamily}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default CardTypeCustomers
