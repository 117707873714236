import { useNavigate } from 'react-router-dom'
import { HtmlToReact } from '../../utils/utils'
import { ActionData, ListEvent } from '../../views/HomeScreen/constants'
import { SubItem } from './SubItem'
import { Product } from '../../utils/needleEnum'
import ListToolsConnect from '../../views/ListToolsConnect'
import { ButtonType, NeedleButton } from '../NeedleButton'
import { NeedleImage } from '../NeedleImage'
import { PATH } from '../../constants/path'
import { useEffect, useState } from 'react'
import { filterUniqueById } from './component'
interface IdeasDetailProps {
    ideasGroups: ListEvent
    action: ActionData
    productData?: Product[]
    isFullSize?: boolean
    isActionsPage?: boolean
    isSmallImage?: boolean
    isSubStyle?: boolean
    isHaveButton?: boolean
}

export const IdeasDetail = ({
    ideasGroups,
    action,
    isFullSize = false,
    isActionsPage = false,
    productData,
    isSmallImage = false,
    isSubStyle = true,
    isHaveButton = false,
}: IdeasDetailProps) => {
    const navigate = useNavigate()
    let limitLine = 'font-small-text'
    if (!isFullSize) {
        limitLine = 'font-body-text'
    }
    let imageSize = isSmallImage ? 'h-60px w-60px' : 'h-80px w-80px'
    const [filterProduct, setFilterProduct] = useState<Product[]>(
        productData ? filterUniqueById(productData) : []
    )

    useEffect(() => {
        setFilterProduct(productData ? filterUniqueById(productData) : [])
    }, [productData])

    return (
        <div
            className="p-6 relative"
            onClick={() =>
                ideasGroups.link !== '' &&
                navigate(PATH.ideasDetail.replace(':id', action.id))
            }
        >
            {isActionsPage && isSubStyle && (
                <div className="flex pb-2">
                    <SubItem
                        event={action.type}
                        metadata={action.metadata}
                        dateSet={action.dateEvent}
                    />
                </div>
            )}
            <div className="flex flex-row justify-between">
                <div className="flex items-center flex-1 space-x-3 ">
                    <div className={`font-header-3`}>
                        {HtmlToReact(action.name)}
                    </div>
                    {!isActionsPage && isSubStyle && (
                        <div className="h-full">
                            <SubItem
                                event={action.type}
                                metadata={action.metadata}
                                dateSet={action.dateEvent}
                            />
                        </div>
                    )}
                </div>

                {isHaveButton && (
                    <div className="flex flex-0 pl-3">
                        <div>
                            <NeedleButton
                                buttonCharacter="See idea details"
                                buttonType={ButtonType.White}
                                onClickFunction={() =>
                                    window.open(
                                        PATH.ideasDetail.replace(
                                            ':id',
                                            action.id
                                        ),
                                        '_blank'
                                    )
                                }
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="flex flex-row justify-between">
                <div>
                    <div
                        className={`${limitLine} pt-2`}
                        dangerouslySetInnerHTML={{ __html: action.description }}
                    ></div>
                    <div>
                        {productData && isActionsPage ? (
                            <div className="mt-3">
                                {action.assets.length > 0 ? (
                                    <NeedleImage
                                        alt={action.name}
                                        src={action.assets[0]}
                                        className={'h-32 w-32 rounded-md'}
                                    />
                                ) : null}
                            </div>
                        ) : (
                            <div className="flex flex-row mt-3">
                                {filterProduct.map((productItem, index) => {
                                    if (index < 4) {
                                        return (
                                            <NeedleImage
                                                key={productItem.id + index}
                                                src={productItem.image}
                                                alt={productItem.title}
                                                className={
                                                    imageSize +
                                                    ` ${index === 0 && 'rounded-s-md'} ${
                                                        index ===
                                                            filterProduct.length -
                                                                1 || index === 3
                                                            ? 'rounded-e-md'
                                                            : ''
                                                    }`
                                                }
                                            />
                                        )
                                    }
                                })}
                            </div>
                        )}
                    </div>
                </div>
                {!productData && <div className="flex flex-row h-9 mt-3" />}
                {/* <div className={`items-end flex justify-end`}>
                    <ListToolsConnect
                        listTools={action.metadata}
                        isVertical={true}
                    />
                </div> */}
            </div>
        </div>
    )
}
