import { createAction, createReducer } from '@reduxjs/toolkit'
export interface AppGlobalState {
    isDarkTheme: boolean
    toastStatus: {
        open: boolean
        type: 'success' | 'error' | 'warning' | 'info'
        content: string
        duration?: number
    }
}
const initialState = {
    isDarkTheme: true,
    toastStatus: {
        open: false,
        type: 'success',
        content: 'Successfully connected',
    },
} as AppGlobalState

export const toogleTheme = createAction('app/toogleTheme')
export const showToast = createAction<{
    type: 'success' | 'error' | 'warning' | 'info'
    content: string
    duration?: number
}>('app/showToast')
export const hideToast = createAction('app/hideToast')

const appReducer = createReducer(initialState, (builder) => {
    builder.addCase(toogleTheme, (state, action) => {
        state.isDarkTheme = !state.isDarkTheme
    })
    builder.addCase(showToast, (state, action) => {
        state.toastStatus = {
            open: true,
            type: action.payload.type,
            content: action.payload.content,
            duration: action.payload.duration,
        }
    })
    builder.addCase(hideToast, (state, action) => {
        state.toastStatus = {
            open: false,
            type: state.toastStatus.type,
            content: state.toastStatus.content,
            duration: undefined,
        }
    })
})

export default appReducer
