import React, { useEffect } from 'react'
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
    useLocation,
    useSearchParams,
} from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import './App.css'
import { resultRoutes, routes } from './routes'
import { auth } from './firebase/client'
import { Alert, Dialog, Snackbar } from '@mui/material'
import { useSelector } from 'react-redux'
import { AppState, useAppDispatch } from './redux/store'
import { AppGlobalState, hideToast } from './redux/appReducer'
import AuthGuard from './components/AuthGuard'
import { DISABLE_SEGMEMT_KEY } from './constants'
import { PATH } from './constants/path'
import GettingResultLayout from './layouts/result_layout/GettingResultLayout'
import MaintenancePage from './views/MaintenancePage/MaintenancePage'
import GlobalErrorDialog from './views/GlobalErrorDialog'

export const ScrollToTop = () => {
    const { pathname, search } = useLocation()
    const [searchParams] = useSearchParams()

    useEffect(() => {
        if (searchParams.get('staff') === 'true') {
            localStorage.setItem(DISABLE_SEGMEMT_KEY, 'true')
        } else if (searchParams.get('staff') === 'false') {
            localStorage.removeItem(DISABLE_SEGMEMT_KEY)
        }
    }, [searchParams])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname, search])

    return null
}

function App() {
    const [_user, loading] = useAuthState(auth)
    const dispatch = useAppDispatch()
    const appGlobalState = useSelector<AppState, AppGlobalState>(
        (state) => state.app
    )

    const handleCloseToast = () => {
        dispatch(hideToast())
    }

    if (loading) {
        return <div />
    }

    // if (
    //     window.location.host === 'app.askneedle.com' ||
    //     window.location.host === 'dev-needle.web.app'
    // ) {
    //     return <MaintenancePage />
    // }

    return (
        <div className="App-content">
            <Snackbar
                onClose={handleCloseToast}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                open={appGlobalState?.toastStatus.open}
                autoHideDuration={appGlobalState?.toastStatus.duration ?? 1000}
            >
                <Alert
                    severity={appGlobalState.toastStatus.type}
                    sx={{ width: '100%' }}
                >
                    {appGlobalState.toastStatus.content}
                </Alert>
            </Snackbar>
            <GlobalErrorDialog />
            <Router>
                <ScrollToTop />
                <Routes>
                    {resultRoutes.map((item) => (
                        <Route
                            key={item.path}
                            path={item.path}
                            element={
                                <AuthGuard>
                                    <GettingResultLayout>
                                        {item.element}
                                    </GettingResultLayout>
                                </AuthGuard>
                            }
                        />
                    ))}

                    {routes.map((item) => (
                        <Route
                            key={item.path}
                            path={item.path}
                            element={item.element}
                        />
                    ))}
                    <Route path="*" element={<Navigate to={PATH.notFound} />} />
                </Routes>
            </Router>
        </div>
    )
}

export default App
