import useSWRImmutable from 'swr/immutable'
import { IdeaDetailServices } from '../services/IdeaDetail/ideadetail.services'
import { CreativeIdea } from '../views/AddAnIdeaPage/type'

const useCreativeIdeas = () => {
    const { data, mutate, isLoading, ...rest } = useSWRImmutable(
        `/get-creative-ideas/`,
        async () => {
            const ideaDetails = await IdeaDetailServices.getCreativeType()
            return ideaDetails as CreativeIdea[]
        }
    )
    return { data, mutate, isLoading, ...rest }
}

export default useCreativeIdeas
