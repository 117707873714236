import Decimal from 'decimal.js'
import { USER_CURRENCY_KEY } from '../constants'
import useUserData from '../hooks/useUserData'
import { LOCAL_NEEDLE_ROLE } from '../hooks/useUserProfile'

export const titleCase = (s: string) =>
    s.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
        c ? c.toUpperCase() : ' ' + d.toUpperCase()
    )

export const displayValueDependOnFormat = (value: number, format?: string) => {
    if (value === 0) return `N/A`
    if (format?.trim() === '%') {
        return (
            new Decimal(value ?? 0).times(100).toDecimalPlaces(1).toString() +
            format
        )
    }
    if (format?.trim() === '$') {
        return displayMoneyFormat(value, 1)
    }
    return new Decimal(value ?? 0).toDecimalPlaces(1).toString() + format
}

export const displayMoneyFormat = (
    value: number,
    round_to: number = 100,
    currency?: string
) => {
    const char = round_to === 1 ? '' : '~'
    const money =
        char +
        new Intl.NumberFormat('us-US', {
            style: 'currency',
            currencyDisplay: 'narrowSymbol',
            currency:
                currency ?? localStorage.getItem(USER_CURRENCY_KEY) ?? 'USD',
            maximumFractionDigits: 0,
        }).format(Math.round(value / round_to) * round_to)

    return money
}

export const getLocalStorageItem = (key: string) => {
    return localStorage.getItem(key)
}

export const setLocalStorageItem = (key: string, value: string) => {
    localStorage.setItem(key, value)
}
export const capitalizeString = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const checkIfInAdminViewer = () => {
    return window.location.pathname.includes('admin-viewer')
}

export function getInnerTextFromHTML(htmlString?: string): string {
    if (!htmlString) return ''
    const tempElement = document.createElement('div')
    tempElement.innerHTML = htmlString
    const innerText = tempElement.textContent || tempElement.innerText
    tempElement.remove()
    return innerText.trim()
}

export function HtmlToReact(htmlString: string | string[] | undefined): string {
    if (!htmlString) return ''
    const element = document.createElement('div')
    element.innerHTML = String(htmlString)
    return element.innerText
}

export function pathToRegex(path?: string): RegExp {
    if (!path) return new RegExp('^$')
    const escapedPath = path.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // Escape special characters
    const regexString = escapedPath.replace(/\\\*/g, '[^/]+') // Replace '*' with regex to match any character except '/'
    return new RegExp(`^${regexString}$`)
}

export function isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
}

export function checkIfCurrentUserIsAdmin() {
    return localStorage.getItem(LOCAL_NEEDLE_ROLE) === 'admin'
}
