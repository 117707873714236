export const BASE_URL = String(process.env.REACT_APP_BASE_URL)
export const ASK_NEEDLE_API = process.env.REACT_APP_ASK_NEEDLE_API
const config = {
    USER: '/user',
    USERS: '/users',
    GENERATE_TEMPLATE: '/generate-template',
    CONNECTORS: '/connectors',
    SHOPIFY_CONNECTOR: '/shopify/connector',
    RESULT: '/results',
    TOOGLE_READY_RESULT: '/toogle_ready_result',
    CONNECTORS_CONFIG: '/settings/fivetran/connectors',
    CONNECTOR_CARD: '/card_url',
    SHOPIFY_OAUTH: '/shopify/auth',
    GET_SHOPIFY_OAUTH: '/shopify/auth/token',
    USER_ACTIONS: '/user_actions',
    SELECTED_REQUEST: '/selected_requests',
    FEEDBACK: '/feedback',
    TACTIC_COMMON_SETTINGS: '/tactic_common_settings',
    SPEAK_TO_ADVISOR: '/speak_to_adviser',
    UNLISTED_TOOLS_REQUEST: '/unlisted_tools_request',
    LINK_PREVIEW: '/link_preview',
    HAS_VIEW_RESULT: '/has_view_result',
    TACTICS: '/ideas',
    METRICS: '/metrics',
    OVERRIDEN_VALUES: '/user_input',
    TACTIC_FORM_OPTIONS: '/tactic_form_options',
    TACTIC_STATUS: '/tactic-status',
    CTA_TRIGGER: '/cta',
    FAKE_GENAI_CONFIG: '/fake-genai-config',
    SUGGESTED_RESULTS: '/suggested_results',
    WORKSPACE: '/workspaces',
    INVITATIONS: '/invitations',
    CAMPAIGNS: '/campaigns',
    CREDIT_PLANS: '/credit_plans',
    PURCHASES: '/purchases',
}

export const needdleConfig = {
    GROWTH_PLAN: '/growth-plan',
    GROWTH_PLAN_ID: '/growth-plan/ideas/',
    SKIP_ASSETS_BLOCKER: '/skip-additional-assets-blocker',
    UPDATE_ADDITIONAL_IDEA_ASSETS: '/update-idea-additional-assets',
    REPORT_SOMETHING_WRONG: '/report-something-wrong',
    UPDATE_STATUS: '/status',
    //UserReview page.
    FEED_BACK: '/feedback',
    //Enhance Your AI page.
    ASSETS_TEMPLATES: '/workspaces/assets-and-templates',
    BRAND_PROFILE: '/workspaces/profile',
    //User Data
    USER_PROFILE: '/users/profile',
    //Brand Products
    BRAND_PRODUCTS: '/workspaces/products',
    //Update Concepts:
    CONCEPTS: '/concepts/',
    //Creative type
    CREATIVE_TYPE: '/settings/creative-types',
    ADMIN_PREVIEW: '/admin-preview',
}

export default config
