import { create } from 'zustand'
import { EnhanceYourAIServices } from '../services/EnhanceYourAI/enhanceyourai.services'
export interface ProductsStoreProps {
    products: any
    isLoadedProducts: boolean
    setProductsData: (dataProduct: any) => void
}

const productsStore = create<ProductsStoreProps>((set) => ({
    products: [],
    isLoadedProducts: false,
    setProductsData: (newProductsData: any) =>
        set({ products: newProductsData, isLoadedProducts: true }),
}))
export async function getProductData() {
    if (!productsStore.getState().isLoadedProducts) {
        await fetchProductData()
    }
}
export async function fetchProductData() {
    try {
        const data = await EnhanceYourAIServices.getBrandProducts()
        productsStore.getState().setProductsData(data)
    } catch (error) {
        console.error('Failed to fetch data:', error)
    }
}
export default productsStore
