import { useNavigate } from 'react-router-dom'
import { ProgressBar } from './ProgressBar'
import { PATH } from '../constants/path'
export enum BoxCompleteType {
    ReviewPastIdeas = 'ReviewPastIdeas',
    ConnectTool = 'ConnectTool',
    BrandCustomization = 'BrandCustomization',
    ReviewIdeas = 'ReviewIdeas',
    BrandCustomizationHomePage = 'BrandCustomizationHomePage',
    AssetsTemplates = 'AssetsTemplates',
    AddOldIdea = 'AddOldIdea',
    BrandCustomizationOnBoarding = 'BrandCustomizationOnBoarding',
}
interface BoxCompleteProps {
    value: number
    max: number
    mode?: BoxCompleteType
    isOnboarding?: boolean
}

export const BoxComplete = ({
    value,
    max,
    mode = BoxCompleteType.BrandCustomizationHomePage,
    isOnboarding = false,
}: BoxCompleteProps) => {
    const navigate = useNavigate()
    let title = ''
    let description = ''
    let progressBarData = ''
    let imgSrc = ''
    let defaultHeight = '332px'
    let toolLink = PATH.enhanceYourAi
    let complete = ''
    let titleFont = 'font-header-2'
    switch (mode) {
        case BoxCompleteType.ReviewIdeas:
            title = 'Review ideas'
            description =
                'Get a new idea for each one you review and make Needle smarter!'
            progressBarData = 'Reviewed'
            imgSrc = '/assets/imgs/background-review-past-ideas.png'
            toolLink = PATH.reviewPastIdeasPage
            defaultHeight = '304px'
            complete = 'All reviewed'
            titleFont = 'font-header-3'
            break
        case BoxCompleteType.ReviewPastIdeas:
            title = 'Review Past Ideas'
            description =
                'Get a new idea for each one you review and make Needle smarter!'
            progressBarData = 'Reviewed'
            imgSrc = '/assets/imgs/background-review-past-ideas.png'
            toolLink = PATH.reviewPastIdeasPage
            defaultHeight = '304px'
            complete = 'All reviewed'
            break
        case BoxCompleteType.ConnectTool:
            title = 'Connect Tools'
            description =
                'Help Needle draw better insights and create better Ideas'
            progressBarData = 'Connected'
            imgSrc = '/assets/imgs/background-connection-tools.png'
            toolLink = PATH.tools
            complete = 'All Connected'
            break
        case BoxCompleteType.BrandCustomization:
            title = 'Brand Profile'
            description =
                'Tell Needle more about your brand for better creatives and ideas'
            progressBarData = 'Filled Out'
            toolLink = PATH.brandProfilePage
            imgSrc = '/assets/imgs/background-brand-customization.png'
            complete = 'All Filled Out'
            break
        case BoxCompleteType.BrandCustomizationHomePage:
            title = 'Fill up brand profile'
            description =
                'Tell Needle more about your brand for better creatives and ideas'
            progressBarData = 'Filled Out'
            toolLink = PATH.brandProfilePage
            imgSrc = '/assets/imgs/background-brand-customization.png'
            complete = 'All Filled Out'
            break
        case BoxCompleteType.BrandCustomizationOnBoarding:
            title = 'Fill up brand profile'
            description = 'Tell me more about your brand for better outputs'
            progressBarData = 'Filled Out'
            toolLink = PATH.brandProfilePage
            imgSrc = '/assets/imgs/background-brand-customization.png'
            complete = 'All Filled Out'
            break
        case BoxCompleteType.AssetsTemplates:
            title = 'Assets & Templates'
            description =
                'Images, videos, and templates to help Needle create new creatives'
            progressBarData = 'Provided'
            toolLink = PATH.assetsAndTemplates
            imgSrc = '/assets/imgs/background-asset-and-template.png'
            complete = 'All Provided'
            break
        case BoxCompleteType.AddOldIdea:
            title = 'Add your own idea'
            description = 'Fill in the details, and I’ll work on it!'
            toolLink = PATH.reviewPastIdeasPage
            imgSrc = '/assets/imgs/background-add-old-idea.png'
            break
    }

    return (
        <div className="flex w-full text-dark-blue h-full">
            <div
                className="w-full pt-4 h-full"
                onClick={() => {
                    if (!isOnboarding) {
                        navigate(toolLink)
                    }
                }}
            >
                <div
                    className={`rounded-lg 2xl:h-${defaultHeight} h-full bg-white font-sans ${
                        isOnboarding
                            ? 'shadow-un-click-card'
                            : `cursor-pointer shadow-click-card hover:shadow-click-card-hover hover:-translate-y-1 duration-300`
                    }`}
                >
                    <div className={`flex flex-col h-full`}>
                        <img
                            src={imgSrc}
                            alt="tools-logo"
                            className="h-152px w-full rounded-t-md object-cover"
                        />
                        <div className="p-6 justify-between flex flex-col flex-grow text-dark-blue">
                            <div>
                                <div className={`${titleFont}`}>{title}</div>
                                <div
                                    className={`pt-2 ${!isOnboarding && 'pb-4'} font-body-text`}
                                >
                                    {description}
                                </div>
                            </div>
                            {!isOnboarding && (
                                <div>
                                    <ProgressBar value={value} max={max} />
                                    <div className="font-small-text pt-2 text-dark-blue">
                                        {value === max
                                            ? complete
                                            : `${value}/${max} ${progressBarData}`}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
