import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import './font.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './redux/store'
import theme from './themes'
import { ThemeProvider } from '@mui/material'
import { ConfirmProvider } from 'material-ui-confirm'
import { SWRConfig } from 'swr'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <Provider store={store}>
        <SWRConfig
            value={{
                shouldRetryOnError: false,
            }}
        >
            <ThemeProvider theme={theme}>
                <ConfirmProvider>
                    <App />
                </ConfirmProvider>
            </ThemeProvider>
        </SWRConfig>
    </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
