import { Button } from '@mui/material'
import React, { ReactNode, useEffect, useRef, useState } from 'react'

interface ExpandableSectionProps {
    maxHeight?: number
    canHide?: boolean
    viewLabel?: string
    viewlabelAs?: 'link' | 'button'
    hideLabel?: string
    children: ReactNode
}

export const ExpandableSection = (props: ExpandableSectionProps) => {
    const { maxHeight, children, viewLabel, viewlabelAs, hideLabel } = props
    const [showAll, setShowAll] = useState(false)
    const [hide, setHide] = useState(props.canHide)
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (ref.current?.clientHeight! < maxHeight!) {
            setShowAll(true)
            setHide(false)
        }
    }, [maxHeight])

    const styles = !showAll ? `overflow-hidden relative` : ''
    return (
        <div>
            <div
                className={`transition-all ${styles}`}
                style={{
                    maxHeight: !showAll ? `${maxHeight}px` : undefined,
                }}
            >
                <div ref={ref}>{children}</div>
                {!showAll && (
                    <div
                        className="absolute w-full bottom-0 h-[88px]"
                        style={{
                            background:
                                'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)',
                        }}
                    />
                )}
            </div>
            {(!showAll || hide) && (
                <>
                    {viewlabelAs === 'button' ? (
                        <div className="mt-3">
                            <Button
                                onClick={() => setShowAll(!showAll)}
                                variant="outlined"
                                size="small"
                                sx={{
                                    height: '32px',
                                }}
                            >
                                {showAll ? hideLabel : viewLabel}
                            </Button>
                        </div>
                    ) : (
                        <span
                            onClick={() => {
                                setShowAll(!showAll)
                            }}
                            className="text-base text-[var(--link-color)] font-semibold px-6 cursor-pointer"
                        >
                            {showAll ? hideLabel : viewLabel}
                        </span>
                    )}
                </>
            )}
        </div>
    )
}

ExpandableSection.defaultProps = {
    maxHeight: 140,
    viewLabel: 'View All',
    viewlabelAs: 'button',
    hideLabel: 'Hide',
}
