import { axiosInstance, handleResponse, getOptions } from '../api'
import { needdleConfig } from '../api/config'

export const UserServices = {
    getUserProfile: async () => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(needdleConfig.USER_PROFILE, options)
        )
    },
    updateUserProfile: async (data: Partial<Needle.User>) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.patch(needdleConfig.USER_PROFILE, data, options)
        )
    },
}
