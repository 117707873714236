type ProgressBarProps = {
    value: number
    max: number
    backgroundColor?: string
    height?: string
    backgroundFill?: string
}
export const ProgressBar = ({
    value,
    max,
    backgroundColor,
    height = 'h-3',
    backgroundFill,
}: ProgressBarProps) => {
    const width = value > max ? max : (value / max) * 100
    const maxValue = max < value ? value : max
    const color =
        width <= 33.4
            ? 'bg-red-theme'
            : width <= 66.7
              ? 'bg-orange-theme'
              : 'bg-green-theme'
    return (
        <div
            className={`w-full ${backgroundColor ? backgroundColor : 'bg-gray-200'} border-beige-outline border ${height} rounded-full`}
        >
            <div
                className={`${backgroundFill ? backgroundFill : color} transition-all ease-linear duration-300 h-full rounded-s-full ${value === maxValue && 'rounded-e-full'}`}
                style={{ width: `${width}%` }}
                aria-valuenow={value}
                aria-valuemin={0}
                aria-valuemax={maxValue}
            ></div>
        </div>
    )
}
