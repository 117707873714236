import React, { useEffect, useRef, useState } from 'react'
import './TextSection.scss'
import UploadFile from './UploadFile'
import { EIBasicAssets } from '../../views/BrandProfilePage/type'
import { handleUpload, selectNewestFile, uploadClick } from './component'

interface CardTypeBrandAssetsProps {
    setCurrentData: (value: React.SetStateAction<any>) => void
    currentData: EIBasicAssets
    setIsDisable: React.Dispatch<React.SetStateAction<boolean>>
}

function CardTypeBrandAssets({
    setCurrentData,
    currentData,
    setIsDisable,
}: CardTypeBrandAssetsProps) {
    const [name, setName] = useState<string>('')
    const [logo, setLogo] = useState<Needle.metaDataAssets | null>(null)
    const [guide, setGuide] = useState<Needle.metaDataAssets | null>(null)
    const [font, setFont] = useState<Needle.metaDataAssets | null>(null)
    const [newLogo, setNewLogo] = useState<File | null | undefined>()
    const [newGuide, setNewGuide] = useState<File | null | undefined>()
    const [newFont, setNewFont] = useState<File | null | undefined>()
    const fileLogoRef = useRef<HTMLInputElement>(null)
    const fileGuideRef = useRef<HTMLInputElement>(null)
    const fileFontRef = useRef<HTMLInputElement>(null)

    const updateData = async () => {
        if (
            name &&
            name.trim() !== '' &&
            ((logo && newLogo === undefined) || newLogo)
        ) {
            const basicAssetsNewData = {
                Name: name,
                Logo: selectNewestFile(newLogo, logo),
                Guide: selectNewestFile(newGuide, guide),
                Font: selectNewestFile(newFont, font),
            }
            setCurrentData(basicAssetsNewData)
            setIsDisable(false)
        } else {
            setIsDisable(true)
        }
    }

    useEffect(() => {
        updateData()
    }, [name, newLogo, newGuide, newFont])

    useEffect(() => {
        setName(currentData?.Name ? currentData.Name : '')
        setLogo(currentData?.Logo ? currentData.Logo[0] : null)
        setGuide(currentData?.Guide ? currentData.Guide[0] : null)
        setFont(currentData?.Font ? currentData.Font[0] : null)
    }, [currentData])

    return (
        <div className="mx-6 mt-6 mb-2 font-subtitle">
            <div>
                Brand Name<span className="text-red-theme">*</span>
            </div>
            <textarea
                rows={1}
                id="brand_name"
                className="mt-1 border border-beige-outline font-body-text
                    py-3 px-4 w-full rounded-lg focus:outline-dark-blue mb-6"
                placeholder={'Sets the brand name'}
                value={name}
                onChange={(e) => {
                    setName(e.target.value)
                }}
            />
            <UploadFile
                typeOfFile={'JPG, PNG, GIF'}
                acceptsType={['image/jpeg', 'image/png', 'image/gif']}
                name={'Logo'}
                handleUpload={() => uploadClick(fileLogoRef)}
                data={newLogo === undefined ? logo : newLogo}
                setData={setNewLogo}
                isMust={true}
                imageData={
                    <img
                        alt="logo-brand"
                        className="w-12 aspect-square border border-beige-outline rounded-md"
                        src={
                            newLogo === undefined && logo
                                ? logo.url
                                : newLogo
                                  ? URL.createObjectURL(newLogo)
                                  : undefined
                        }
                    />
                }
            />
            <UploadFile
                typeOfFile={'PDF'}
                acceptsType={['application/pdf']}
                name={'Brand Guide'}
                handleUpload={() => uploadClick(fileGuideRef)}
                data={newGuide === undefined ? guide : newGuide}
                setData={setNewGuide}
                imageData={
                    <img
                        alt="logo-brand"
                        className="w-12 aspect-square"
                        src="/assets/icons/brand-guide-icon.svg"
                    />
                }
            />
            <UploadFile
                typeOfFile={'TTF, OTF'}
                acceptsType={['font/ttf', 'font/otf', 'ttf', 'otf']}
                name={'Custom font'}
                handleUpload={() => uploadClick(fileFontRef)}
                data={newFont === undefined ? font : newFont}
                setData={setNewFont}
                imageData={
                    <img
                        alt="logo-brand"
                        className="w-12 aspect-square"
                        src="/assets/icons/font-image.svg"
                    />
                }
            />
            <input
                type="file"
                name="myImage"
                ref={fileGuideRef}
                onChange={(event: any) => handleUpload(event, setNewGuide)}
                style={{ display: 'none' }}
                accept=".pdf"
            />
            <input
                type="file"
                name="myImage"
                ref={fileLogoRef}
                onChange={(event: any) => handleUpload(event, setNewLogo)}
                style={{ display: 'none' }}
                accept=".jpg, .png, .gif"
            />
            <input
                type="file"
                name="myImage"
                ref={fileFontRef}
                onChange={(event: any) => handleUpload(event, setNewFont)}
                style={{ display: 'none' }}
                accept=".ttf, .otf"
            />
        </div>
    )
}

export default CardTypeBrandAssets
