export const DEFAULT_ANIMATION = 'slide-down 600ms ease-out forwards'
export const STEP_DURATION = 1200
export const EffortData = {
    1: 'Less than 1 week',
    2: '1 to 2 weeks',
    3: 'More than 2 weeks',
    4: 'Undefine',
}
export interface ToolData {
    avatar: JSX.Element
    service: string
    id: string
    method?: string
    description: string
    isNoBorder?: boolean
    newId?: string
}
export const allTools: ToolData[] = [
    {
        avatar: (
            <img
                height={22}
                width={22}
                alt="shopify"
                src="/assets/svgs/shopify.svg"
            />
        ),
        service: 'Shopify',
        id: 'shopify',
        method: 'Shopify',
        description:
            'To give you new ideas, by analyzing your store’s performance. Required for Needle to run.',
    },
    {
        avatar: (
            <img
                height={22}
                width={22}
                alt="google analytics_4"
                src="/assets/svgs/google-analytic.svg"
            />
        ),
        id: 'google_analytics_4',
        service: 'Google Analytics 4',
        description:
            'To give you new ideas, by analyzing your store’s performance.',
    },
    {
        avatar: (
            <img
                height={22}
                width={22}
                alt="google analytics"
                src="/assets/svgs/google-analytic.svg"
            />
        ),
        id: 'google_analytics',
        service: 'Universal Analytics',
        description:
            'Connect to analyse website performance, and give you tactics to grow conversions.',
    },
    {
        avatar: (
            <img
                height={24}
                width={24}
                alt="meta"
                src="/assets/svgs/meta-ads-icon.svg"
            />
        ),
        service: 'Meta Ads',
        id: 'facebook_ads',
        newId: 'facebook',
        description:
            'To give you new Meta ad ideas, by analyzing your ad performance.',
    },
    {
        avatar: (
            <img
                height={22}
                width={22}
                alt="google ads"
                src="/assets/svgs/google-ads.svg"
            />
        ),
        service: 'Google Ads',
        id: 'google_ads',
        newId: 'google_ads',
        description:
            'To give you new Google ad ideas, by analyzing your ad performance.',
    },
    {
        avatar: (
            <img
                alt="google ads"
                width={22}
                height={22}
                src="/assets/svgs/xero.svg"
            />
        ),
        service: 'Xero',
        id: 'xero',
        description:
            'To analyse your expenses, and give you tactics on how to increase profits.',
    },
    {
        avatar: (
            <img
                alt="woocommerce"
                width={24}
                height={24}
                src="/assets/svgs/woocommerce.svg"
            />
        ),
        service: 'WooCommerce',
        id: 'woocommerce',
        description:
            'To give you new ideas, by analyzing your store’s performance. Required for Needle to run.',
    },
    {
        avatar: (
            <img
                alt="klaviyo"
                width={48}
                height={48}
                src="/assets/svgs/klaviyo.svg"
            />
        ),
        service: 'Klaviyo',
        id: 'klaviyo',
        newId: 'klaviyo',
        description:
            'To give you email ideas, by analyzing your email campaigns & flow performance.',
    },
    {
        avatar: (
            <img
                alt="tiktok_ads"
                width={22}
                height={22}
                src="/assets/svgs/tiktok_ads.svg"
            />
        ),
        service: 'Tiktok Ads',
        id: 'tiktok_ads',
        newId: 'tiktok',
        description:
            'To give you new TikTok ad ideas, by analyzing your ad performance.',
    },
    {
        avatar: (
            <img
                alt="leadsie"
                width={22}
                height={22}
                src="/assets/svgs/leadsie.svg"
            />
        ),
        service: 'Leadsie',
        id: 'leadsie',
        description:
            'Connect Facebook / Google to enhance ads insights / draft campaigns on your behalf.',
    },
    {
        avatar: (
            <img
                alt="email_assets"
                width={22}
                height={22}
                src="/assets/svgs/email_assets.svg"
            />
        ),
        service: 'Email Assets',
        id: 'email_assets',
        description:
            'Share samples, fonts and brand book to generate emails styled to your brand.',
    },
    {
        avatar: (
            <img
                alt="youtube_assets"
                width={22}
                height={22}
                src="/assets/svgs/youtube.svg"
            />
        ),
        service: 'youtube',
        id: 'youtube',
        newId: 'youtube',
        description:
            'Share samples, fonts and brand book to generate emails styled to your brand.',
    },
    {
        avatar: (
            <img
                alt="instagrams"
                width={36}
                height={36}
                src="/assets/svgs/instagrams.svg"
            />
        ),
        service: 'Instagrams',
        id: 'instagrams',
        newId: 'instagram',
        description: 'Instagrams post',
        isNoBorder: true,
    },
]
export const MAX_CONNECTED_TOOL = 3
export const MAX_BRAND_CUSTOMIZE = 7
export const MAX_ASSETS_AND_TEMPLATES = 3
export const DRAWER_WIDTH = 280
export const DISABLE_SEGMEMT_KEY = 'is-disable-segment'
export const CURRENT_USE_TACTIC_INFOR =
    'this-is-to-store-tactic-info-while-you-are-viewing'
export const SEGMENT_HEADER_KEY = 'X-Segment-status'
export const CURRENT_WORKSPACE = 'currentWorkspace'
export const USER_CURRENCY_KEY = 'currency'
export const TIKTOK_ADVERTISER_DATA_KEY = 'user-advertiser-data'
export const TACTICS_WITH_GENERATE_TEMPLATE = [19]
export const PROD_HOST = 'app.askneedle.com'
export const SETUP_GA_LINK =
    'https://help.shopify.com/en/manual/reports-and-analytics/google-analytics/google-analytics-setup'

export const acceptedUrls = [
    'app.askneedle.com',
    'dev-needle.web.app',
    'dev-needle.firebaseapp.com',
    'prod-needle.web.app',
    'prod-needle.firebaseapp.com',
]
