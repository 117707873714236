import React, { useEffect, useState } from 'react'
import { NeedleButton } from '../../../components/NeedleButton'
import {
    formatNumber,
    handleChange,
} from '../../../components/DetailCardView/component'
import { updateBrandProfile } from '../../../hooks/useEnhanceYourAI'
import { LAST_BUT_NOT_LEAST } from '../constants'
import useGrowthPlan from '../../../hooks/useGrowthPlan'

interface MarketingSpendProps {
    sendData: (progress: number) => void
}
export const MarketingSpend = ({ sendData }: MarketingSpendProps) => {
    const [marketSpend, setMarketSpend] = useState<number | undefined>(0)
    const [currency, setCurrency] = useState<string>('US$')
    const { data: growthPlan } = useGrowthPlan()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (growthPlan) {
            setCurrency(growthPlan?.currency ? growthPlan.currency : 'US$')
        }
    }, [growthPlan])

    const isDisable = (): boolean => {
        if (marketSpend) {
            return false
        }
        return true
    }

    const sendUpdateBrandMarketingSpend = async () => {
        setIsLoading(true)
        const data = await updateBrandProfile({
            brand_metrics: {
                marketing_spend: marketSpend,
            },
        })
        if (data) {
            sendData(LAST_BUT_NOT_LEAST)
        }
        setIsLoading(false)
    }

    return (
        <div className="w-500px text-dark-blue pt-4 items-center flex flex-col">
            <div className="flex flex-row pt-2 text-black font-normal leading-130 text-base item-center">
                <div className="bg-beige border-t border-l border-b border-beige-outline py-3 px-4 rounded-s-lg text-opacity-50 text-black">
                    {currency}
                </div>
                <input
                    type="text"
                    value={formatNumber(marketSpend)}
                    placeholder="8,000"
                    onChange={(e) => handleChange(e, setMarketSpend)}
                    className="py-3 px-4 border rounded-e-lg focus:ring-1 focus:ring-inset focus:ring-dark-blue w-60"
                />
                <div className="flex items-center pl-2 font-body-text">
                    per month
                </div>
            </div>
            <NeedleButton
                onClickFunction={sendUpdateBrandMarketingSpend}
                buttonCharacter={'Finish setup'}
                isFull={true}
                isLoading={isLoading}
                isDisable={isDisable()}
                border="py-4"
                className="pt-8"
            />
        </div>
    )
}

export default MarketingSpend
