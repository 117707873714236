import React from 'react'
import { Product } from '../../../utils/needleEnum'
import { NeedleImage } from '../../../components/NeedleImage'
interface ProductListProps {
    productData: Product[]
}

export const ProductList = ({ productData }: ProductListProps) => {
    return (
        <div className="flex gap-2 flex-wrap">
            {productData.map((productData: Product, index: any) => (
                <div className="w-120px" key={productData.id + index}>
                    <div className="text-xs font-sans text-dark-blue p-2 border rounded-lg shadow-un-click-card pt-32 relative h-full">
                        <div>{productData.title}</div>
                        <NeedleImage
                            src={productData.image}
                            alt={productData.title}
                            className="h-120px w-120px rounded-t-lg absolute top-0 left-0"
                            isNoBorder={true}
                        />
                    </div>
                </div>
            ))}
        </div>
    )
}

export default ProductList
