import React, { useState } from 'react'
import { KlaviyoTemplateGuide } from '../generate_template_screen/content/KlaviyoTemplateGuide'
import {
    Box,
    Button,
    CircularProgress,
    Container,
    TextField,
    Typography,
} from '@mui/material'
import axios from 'axios'
import useSWRMutation from 'swr/mutation'
import { Services } from '../../services'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../redux/store'
import { fetchUserConnector } from '../../redux/connectorReducer'
import { PATH } from '../../constants/path'
import { checkKlaviyoApiKey } from '../../services/generateTemplate/generateTemplate'

const ConnectToKlaviyoScreen = () => {
    const [apiKey, setApiKey] = useState('')
    const [message, setMessage] = useState<string>()
    const { trigger, isMutating } = useSWRMutation(apiKey, async () => {
        return await handleConnectKlaviyo()
    })
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const handleConnectKlaviyo = async () => {
        const check_KlaviyoApiKey = async () => {
            try {
                await checkKlaviyoApiKey({ apiKey: apiKey })
                return true
            } catch (e: any) {
                return false
            }
        }
        const validApiKey = await check_KlaviyoApiKey()
        if (!validApiKey) {
            setMessage('Klaviyo API key is invalid')
            return
        }
        await Services.createConnector({
            service: 'klaviyo',
            config: { api_key: apiKey },
            auth: {
                api_key: apiKey,
            },
        })
        await dispatch(fetchUserConnector())
        navigate(PATH.tools)
    }
    return (
        <Container sx={{ my: 8, bgcolor: 'white', p: 4, borderRadius: '16px' }}>
            <Box mb={3} display={'flex'} alignItems={'center'} gap={2}>
                <img
                    alt="klaviyo"
                    width={60}
                    src="	https://fivetran.com/integrations/klaviyo/resources/klaviyo.svg"
                />
                <Typography variant="h1">Klaviyo</Typography>
            </Box>
            <Box gap={2} alignItems={'start'} display={'flex'} mb={3}>
                <TextField
                    helperText={message}
                    error={!!message}
                    value={apiKey}
                    label="API key"
                    onChange={(e) => {
                        setMessage('')
                        setApiKey(e.target.value)
                    }}
                    name="api-key"
                    fullWidth
                    placeholder="Enter your Klaviyo API Key"
                />
                <Button
                    startIcon={
                        isMutating ? (
                            <CircularProgress color="info" size={16} />
                        ) : undefined
                    }
                    disabled={!apiKey || isMutating}
                    onClick={() => trigger()}
                    sx={{ width: '150px', marginTop: '7px' }}
                >
                    Save & Test
                </Button>
            </Box>
            <KlaviyoTemplateGuide />
        </Container>
    )
}

export default ConnectToKlaviyoScreen
