import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import React from 'react'

import { ReactComponent as CheckCircleIcon } from '../../assets/svgs/check-circle.svg'
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '467px',
    bgcolor: '#FFFFFF',
    padding: '32px 24px',
    borderRadius: '16px',
    outline: 'none',
    boerder: '',
}

function ConnectedSuccessModal(props: {
    open: boolean
    handleClose: () => void
}) {
    return (
        <Modal open={props.open} onClose={props.handleClose}>
            <Box sx={{ ...style, width: 400 }}>
                <Stack justifyContent={'center'} alignItems={'center'}>
                    <CheckCircleIcon />
                    <Typography
                        sx={{
                            fontWeight: '500',
                            fontFamily: 'Work Sans',
                            marginTop: '10px',
                            marginBottom: '6px',
                        }}
                        variant="subtitle1"
                    >
                        Shopify connected!
                    </Typography>
                    <Typography
                        lineHeight={1.2}
                        textAlign={'justify'}
                        sx={{ fontWeight: '400' }}
                        variant="subtitle2"
                    >
                        <strong>Needle</strong> will analyse your sales trends
                        and form insights from it. You’ll get notified when your
                        Growth plan is updated (1-2 working days).
                    </Typography>
                    <Button
                        sx={{ marginTop: '22px' }}
                        fullWidth
                        onClick={props.handleClose}
                    >
                        Done
                    </Button>
                </Stack>
            </Box>
        </Modal>
    )
}

export default ConnectedSuccessModal
