import { combineReducers } from '@reduxjs/toolkit'
import appReducer from './appReducer'
import authReducer from './authReducer'
import connectorReducer from './connectorReducer'

export const reducer = combineReducers({
    auth: authReducer,
    app: appReducer,
    connector: connectorReducer,
})
