import React from 'react'
import { Navigate } from 'react-router-dom'
import { PATH } from '../constants/path'
import useEnhanceYourAI from '../hooks/useEnhanceYourAI'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../firebase/client'
import LoadingScreen from '../views/LoadingScreen/LoadingScreen'

type Props = {
    children: JSX.Element
}
function UnAuthGuard(props: Props) {
    const [user] = useAuthState(auth)
    const { dataBrandProfile, isLoadingBrandProfile } = useEnhanceYourAI()
    if (dataBrandProfile?.brand_metrics?.marketing_spend) {
        return <Navigate to={PATH.homePage} />
    }
    if (isLoadingBrandProfile) {
        return (
            <div className="h-screen bg-red-500 absolute">
                <LoadingScreen />
            </div>
        )
    }
    return props.children
}

export default UnAuthGuard
