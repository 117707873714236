export interface FlagProps {
    flagNumber: number
    color?: FlagColor
}
export enum FlagColor {
    red = 'bg-red-theme',
    blue = 'bg-dark-blue',
}
export const Flag = ({ flagNumber, color = FlagColor.blue }: FlagProps) => {
    return (
        <div
            className={`w-6 h-22px rounded-xl ml-2 items-center text-center font-bold ${color} flex justify-center`}
        >
            <p className="text-white text-sm">{flagNumber}</p>
        </div>
    )
}
