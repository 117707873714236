import React, { useEffect, useState } from 'react'
import { CoverBox } from '../../components/CoverBox/CoverBox'
import { SmartAITool } from '../../components/CoverBox/SmartAITool'
import {
    EventType,
    IdeasList,
    LIST_EVENT_HOMEPAGE,
    LIST_SHOW_VIEW,
    ListEvent,
    NotDisplayPage,
} from './constants'
import { DropdownListView } from './DropdownListView'
import ideasStore from '../../storeZustand/ideasStore'
import { FlagColor } from '../../components/Flag'
import { BoxComplete, BoxCompleteType } from '../../components/BoxComplete'
import useUserProfile from '../../hooks/useUserProfile'
import { MAX_BRAND_CUSTOMIZE, MAX_CONNECTED_TOOL } from '../../constants'
import { useSelector } from 'react-redux'
import { AppState } from '../../redux/store'
import { ConnectorState } from '../../redux/connectorReducer'
import brandStore from '../../storeZustand/brandCustomizationStore'
import { formatDataIdeasList, smartHello, usePageTitle } from './component'
import NewUserCard from './NewUserCard'
import useEnhanceYourAI from '../../hooks/useEnhanceYourAI'

export const HomeScreenContent = () => {
    usePageTitle('Needle - Home Page')
    const { userProfile } = useUserProfile()
    const { dataBrandProfile, dataAssetsTemplate } = useEnhanceYourAI()
    const [listEvents, setListEvent] =
        useState<ListEvent[]>(LIST_EVENT_HOMEPAGE)
    const [isNotData, setIsNotData] = useState<boolean>(false)
    const [welcomeWords, setWelcomeWords] = useState<string>(
        smartHello(
            userProfile,
            isNotData,
            dataBrandProfile?.primary_owner?.first_name ??
                dataAssetsTemplate?.brand_name
        )
    )
    const [listShowState, setListShowState] =
        useState<boolean[]>(LIST_SHOW_VIEW)
    const { needleNewIdeas, needleDoing, needleAction } = ideasStore(
        (state) => ({
            needleNewIdeas: state.needleNewIdeas,
            needleDoing: state.needleDoing,
            needleAction: state.needleAction,
        })
    )
    const { emailConnected, marketConnected, shopAnalyticsConnected } =
        useSelector<AppState, ConnectorState>((state) => state.connector)

    const connectedTool =
        Number(emailConnected.length > 0) +
        Number(marketConnected.length > 0) +
        Number(shopAnalyticsConnected.length > 0)

    const { brandPCount, customerCount, brandMCount, productToCount } =
        brandStore((state) => ({
            brandPCount: state.brandPCount,
            customerCount: state.customerCount,
            brandMCount: state.brandMCount,
            productToCount: state.productToCount,
        }))

    const brandProfileCount =
        brandPCount + customerCount + brandMCount + productToCount

    useEffect(() => {
        const newListEvent = [...listEvents]
        newListEvent[IdeasList.ActionRequired].actions =
            formatDataIdeasList(needleAction)
        newListEvent[IdeasList.Doing].actions = formatDataIdeasList(needleDoing)
        newListEvent[IdeasList.NewIdeas].actions =
            formatDataIdeasList(needleNewIdeas)
        handleNoDataCase(listEvents)
        setListEvent(newListEvent)
    }, [needleAction, needleNewIdeas, needleDoing])

    const handleNoDataCase = (newListEvent: ListEvent[]): void => {
        if (
            newListEvent[IdeasList.ActionRequired].actions.length === 0 &&
            newListEvent[IdeasList.Doing].actions.length === 0 &&
            newListEvent[IdeasList.NewIdeas].actions.length === 0
        ) {
            setIsNotData(true)
        } else {
            setIsNotData(false)
        }
    }

    const setShowState = (isShow: boolean, index: number): void => {
        const newShowState = [...listShowState]
        newShowState[index] = isShow
        setListShowState(newShowState)
    }

    const headerDataRender = (ideasGroups: ListEvent): string => {
        return ideasGroups.actions &&
            ideasGroups.actions.length === 1 &&
            ideasGroups.smallName
            ? ideasGroups.smallName
            : ideasGroups.name
    }

    return (
        <div className="bg-beige h-full w-full transition-colors text-dark-blue">
            <SmartAITool pageWord={welcomeWords} />
            <div className="flex flex-col justify-center items-center w-full mb-14 pt-3">
                <div className="flex flex-col w-4/5 sm:w-5/6 max-w-728px items-center">
                    <CoverBox
                        name={
                            isNotData
                                ? 'Get started with these'
                                : 'Enhance your AI'
                        }
                    >
                        <div className="flex lg:flex-row flex-col">
                            <div className="lg:w-329px mr-6 w-full flex">
                                <BoxComplete
                                    value={connectedTool}
                                    max={MAX_CONNECTED_TOOL}
                                    mode={BoxCompleteType.ConnectTool}
                                />
                            </div>
                            <div className="lg:w-329px w-full flex">
                                <BoxComplete
                                    value={brandProfileCount}
                                    max={MAX_BRAND_CUSTOMIZE}
                                    mode={
                                        BoxCompleteType.BrandCustomizationHomePage
                                    }
                                />
                            </div>
                        </div>
                    </CoverBox>
                    {isNotData && <NewUserCard />}
                    <div className="w-full">
                        {listEvents.map((ideasGroups: ListEvent, index) => {
                            return (
                                ideasGroups.isNoDisplay !==
                                    NotDisplayPage.HOMEPAGE && (
                                    <CoverBox
                                        name={headerDataRender(ideasGroups)}
                                        flag={{
                                            flagNumber:
                                                ideasGroups.actions.length,
                                            color:
                                                ideasGroups.type ===
                                                EventType.Doing
                                                    ? FlagColor.blue
                                                    : FlagColor.red,
                                        }}
                                        key={ideasGroups.name}
                                        index={index}
                                        showClick={setShowState}
                                    >
                                        <DropdownListView
                                            ideasGroups={ideasGroups}
                                            isShowView={listShowState[index]}
                                            itemList={ideasGroups.actions}
                                        />
                                    </CoverBox>
                                )
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeScreenContent
