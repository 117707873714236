import React, { useEffect, useState } from 'react'
import { ButtonType, NeedleButton } from '../../../components/NeedleButton'
import { EventType } from '../../HomeScreen/constants'
import { handleDownloadAssets } from '../functions'
import { AssetsData, Concept, Idea } from '../../../utils/needleEnum'
import { NeedleImage } from '../../../components/NeedleImage'

interface ImagesAssetItemProps {
    currentIdeas: Concept
    ideasStatus: EventType
    assetsData: AssetsData
    deliveryText: string
}

export const ImagesAssetItem = ({
    currentIdeas,
    ideasStatus,
    assetsData,
    deliveryText,
}: ImagesAssetItemProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenImageNumber, setIsOpenImageNumber] = useState(1)
    const ImageListURL = assetsData.files ?? []
    const openModal = (imageNumber: number): void => {
        setIsOpen(true)
        setIsOpenImageNumber(imageNumber)
    }

    const closeModal = (): void => {
        setIsOpen(false)
    }
    const nextImage = (e: any): void => {
        e.stopPropagation()
        if (isOpenImageNumber < ImageListURL.length - 1) {
            setIsOpenImageNumber(isOpenImageNumber + 1)
        }
    }

    const previousImage = (e: any): void => {
        e.stopPropagation()
        if (isOpenImageNumber > 0) {
            setIsOpenImageNumber(isOpenImageNumber - 1)
        }
    }

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('overflow-hidden')
        } else {
            document.body.classList.remove('overflow-hidden')
        }
    }, [isOpen])

    return (
        <div>
            <div className="flex justify-center items-center pb-4">
                <div className="flex flex-row flex-wrap gap-4">
                    {ImageListURL.map((imageData, index) => {
                        return (
                            <div
                                key={imageData.url + index}
                                className="cursor-pointer"
                                onClick={() => openModal(index)}
                            >
                                <NeedleImage
                                    className="w-308px aspect-square rounded-md"
                                    alt={`asset-return`}
                                    src={imageData.url}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
            {isOpen && (
                <div
                    className="fixed inset-0 flex justify-center bg-black bg-opacity-80 z-50"
                    onClick={closeModal}
                >
                    <div className="w-full overflow-auto justify-center item-center flex h-full">
                        <div className="flex flex-col justify-center">
                            <div className="flex flex-row gap-4">
                                <div className="flex justify-center items-center">
                                    <div
                                        onClick={previousImage}
                                        className={`justify-center item-center flex  ${isOpenImageNumber === ImageListURL.length - 1 ? 'opacity-50' : 'cursor-pointer'}`}
                                    >
                                        <NeedleImage
                                            src="/assets/svgs/arrow-right.svg"
                                            alt="arrow-right"
                                            className={`rotate-180 h-12 aspect-square ${isOpenImageNumber === 0 ? 'opacity-50' : 'cursor-pointer'}`}
                                        />
                                    </div>
                                </div>
                                <NeedleImage
                                    className="w-600px pb-10px aspect-square"
                                    alt={`asset-return`}
                                    src={ImageListURL[isOpenImageNumber].url}
                                />
                                <div className="flex justify-center items-center">
                                    <div
                                        onClick={nextImage}
                                        className={`justify-center item-center flex  ${isOpenImageNumber === ImageListURL.length - 1 ? 'opacity-50' : 'cursor-pointer'}`}
                                    >
                                        <NeedleImage
                                            src="/assets/svgs/arrow-right.svg"
                                            alt="arrow-right"
                                            className={`h-12 aspect-square`}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-2 pl-16">
                                {ImageListURL.map((imageData, index) => {
                                    return (
                                        <div
                                            key={imageData.url + index}
                                            className={`cursor-pointer ${index !== isOpenImageNumber && 'opacity-50'}`}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                openModal(index)
                                            }}
                                        >
                                            <NeedleImage
                                                className="w-80px aspect-square rounded-md"
                                                alt={`asset-return`}
                                                src={imageData.url}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div
                className="font-body-text pb-4"
                dangerouslySetInnerHTML={{
                    __html: deliveryText,
                }}
            ></div>
        </div>
    )
}

export default ImagesAssetItem
