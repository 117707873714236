import React, { useEffect, useState } from 'react'
import { NeedleButton } from '../../components/NeedleButton'
import { NeedleTextInput } from '../../components/NeedleTextInput'
import { useNavigate } from 'react-router-dom'
import { PATH } from '../../constants/path'
import { LoginType, SignUpType } from '../../firebase/authentication'
import { AppState, useAppDispatch } from '../../redux/store'
import { AuthState, login, signUp } from '../../redux/authReducer'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import { useSelector } from 'react-redux'
import { checkEmailType, checkPassword } from './component'
import { isValidEmail } from '../../utils/utils'
import { SegmentTrackEmailFilled } from '../../services/segments'

interface GetUserNameAndEmailProps {
    isLoginGoogle?: boolean
    isLoginEmail?: boolean
    onClickFunction?: () => void
}
export const GetUserNameAndEmail = ({
    isLoginGoogle = false,
    isLoginEmail = false,
    onClickFunction = () => {},
}: GetUserNameAndEmailProps) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [emailError, setEmailError] = useState<any>('')
    const [password, setPassword] = useState<string>('')
    const [passwordError, setPasswordError] = useState('')
    const [inputPasswordType, setInputPasswordType] =
        useState<string>('password')
    const togglePasswordVisibility = () => {
        setInputPasswordType(
            inputPasswordType === 'password' ? 'text' : 'password'
        )
    }

    const [isDisableSignUp, setIsDisableSignUp] = useState<boolean>(true)
    const [errorMessageDisplay, setErrorMessageDisplay] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const { errorMessage } = useSelector<AppState, AuthState>(
        (state) => state.auth
    )

    useEffect(() => {
        cleanData()
    }, [isLoginEmail])

    const cleanData = (): void => {
        setFirstName('')
        setLastName('')
        setErrorMessageDisplay('')
        setEmailError('')
        setPassword('')
        setPasswordError('')
        setEmail('')
    }

    useEffect(() => {
        const isValid =
            checkPassword(password) &&
            email !== '' &&
            password !== '' &&
            (isLoginEmail || (firstName !== '' && lastName !== ''))
        setIsDisableSignUp(!isValid)
    }, [emailError, passwordError, email, password, firstName, lastName])

    useEffect(() => {
        if (errorMessage !== 'Close Popup') {
            if (
                errorMessage ===
                'Email already in use, please sign in or use a different email address'
            ) {
                handleAlreadyHaveAccount()
            } else {
                setErrorMessageDisplay(errorMessage)
            }
        }
        setIsLoading(false)
    }, [errorMessage])

    const handleAlreadyHaveAccount = (): void => {
        setEmailError(
            <div>
                You already have an account.{' '}
                <span
                    className="text-blue-theme underline cursor-pointer"
                    onClick={onClickFunction}
                >
                    Login instead
                </span>
            </div>
        )
    }

    const loginEmailAndPassword = async () => {
        try {
            const data = await dispatch(
                login({
                    loginType: LoginType.EmailAndPassword,
                    email: email,
                    password: password,
                })
            )
            const path = localStorage.getItem('desiredRoute')
            if (path) {
                localStorage.removeItem('desiredRoute')
                navigate(path!)
            } else if (data.type === 'auth/login/fulfilled')
                navigate(PATH.homePage)
            setIsLoading(false)
        } catch {
            setIsLoading(false)
        }
    }

    const signUpEmailAndPassword = async () => {
        try {
            const data = await dispatch(
                signUp({
                    signUpType: SignUpType.EmailAndPassword,
                    email: email,
                    password: password,
                    profile: {
                        first_name: firstName,
                        last_name: lastName,
                        timezone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                    },
                })
            )
            if (!data || !(data && data?.type === 'auth/signUp/fulfilled')) {
                setIsLoading(false)
                return
            }
            const path = localStorage.getItem('desiredRoute')
            if (path) {
                localStorage.removeItem('desiredRoute')
                navigate(path)
            } else {
                navigate(PATH.onBoardingFillData)
            }
            setIsLoading(false)
        } catch {
            setIsLoading(false)
        }
    }

    const checkingErrorData = (): boolean => {
        let isAcceptedData = true
        if (!checkEmailType(email)) {
            setEmailError('This doesn’t seem to be an email address')
            isAcceptedData = false
        }
        if (!checkPassword(password)) {
            setPasswordError(
                'The password needs to be at least 8 characters long'
            )
            isAcceptedData = false
        }
        return isAcceptedData
    }

    const handleClick = async (): Promise<void> => {
        setIsLoading(true)
        if (checkingErrorData()) {
            if (isLoginEmail) {
                await loginEmailAndPassword()
            } else {
                await signUpEmailAndPassword()
            }
        } else {
            setIsLoading(false)
        }
    }

    const handleEmailChange = (emailValue: string): void => {
        if (!isLoginEmail && isValidEmail(emailValue)) {
            SegmentTrackEmailFilled(emailValue)
        }
        setEmail(emailValue)
    }

    const handlePasswordChange = (passwordValue: string): void => {
        setPassword(passwordValue)
    }

    return (
        <div className="w-500px pt-8 pb-14">
            <div className="h-1px w-full bg-beige-outline"></div>
            <div className="font-subtitle pb-2 items-start flex w-full flex-col pt-8">
                {!isLoginEmail && (
                    <div className="w-full">
                        <div>First name</div>
                        <NeedleTextInput
                            className="mt-2 mb-6"
                            placeholder="First name"
                            value={firstName}
                            setValue={setFirstName}
                        />
                        <div>Last name</div>
                        <NeedleTextInput
                            className="mt-2 mb-6"
                            placeholder={'Last name'}
                            value={lastName}
                            setValue={setLastName}
                        />
                    </div>
                )}
                {!isLoginGoogle && (
                    <div className="w-full">
                        <div>Email</div>
                        <NeedleTextInput
                            className="mt-2 mb-6"
                            placeholder={
                                isLoginEmail
                                    ? 'youremail@address.com'
                                    : 'example@email.com'
                            }
                            value={email}
                            type={'email'}
                            setValue={handleEmailChange}
                            valueError={emailError}
                        />
                        <div>Password</div>
                        <div className="relative">
                            <NeedleTextInput
                                className="mt-2 mb-6"
                                placeholder={
                                    isLoginEmail
                                        ? 'Password'
                                        : 'somethingreallygood'
                                }
                                value={password}
                                type={inputPasswordType}
                                setValue={handlePasswordChange}
                                valueError={passwordError}
                                isPassword={true}
                            />
                            <div
                                onClick={() => togglePasswordVisibility()}
                                className="absolute top-1/2 -translate-y-1/2 right-3"
                            >
                                {inputPasswordType === 'password' ? (
                                    <VisibilityOffOutlinedIcon />
                                ) : (
                                    <RemoveRedEyeOutlinedIcon />
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {errorMessageDisplay && (
                    <div className="font-body-text font-bold text-red-theme pb-2 pl-2">
                        The email/password you entered is incorrect
                    </div>
                )}
            </div>
            <NeedleButton
                onClickFunction={handleClick}
                buttonCharacter={isLoginEmail ? 'Sign in' : 'Sign up'}
                isDisable={isDisableSignUp || isLoading}
                isFull={true}
                border="py-4"
            />
        </div>
    )
}
