import React from 'react'
import './TextSection.scss'
import ChatWithNeedleHelp from './ChatWithNeedleHelp'
interface CardTypeTextProps {
    placeHolder?: string
    setCurrentText: (value: React.SetStateAction<string | string[]>) => void
    currentText: string
    isDisplayNeedleHelp?: boolean
    setIsDisable: React.Dispatch<React.SetStateAction<boolean>>
}

function CardTypeText({
    placeHolder,
    setCurrentText,
    currentText,
    isDisplayNeedleHelp = false,
    setIsDisable,
}: CardTypeTextProps) {
    return (
        <div className="mx-6 mt-6 mb-2">
            <textarea
                rows={3}
                id="first_name"
                className="border border-beige-outline font-body-text
                py-3 px-4 w-full rounded-lg focus:outline-dark-blue"
                placeholder={placeHolder}
                value={currentText}
                onChange={(e) => {
                    if (e.target.value.trim() === '') {
                        setIsDisable(true)
                    } else {
                        setIsDisable(false)
                    }
                    setCurrentText(e.target.value)
                }}
            />
            {isDisplayNeedleHelp && <ChatWithNeedleHelp />}
        </div>
    )
}

export default CardTypeText
