import { create } from 'zustand'
export interface GlobalErrorDialogStoreProps {
    isOpenErrorDialog: boolean
    setIsOpenErrorDialog: (isOpen: boolean) => void
}

const globalErrorDialogStore = create<GlobalErrorDialogStoreProps>(
    (set, get) => ({
        isOpenErrorDialog: false,
        setIsOpenErrorDialog: (isOpen: boolean) =>
            set({
                isOpenErrorDialog: isOpen,
            }),
    })
)

export default globalErrorDialogStore
