import React from 'react'
import Lottie from 'react-lottie'
import { doneIconAnimation } from '../../components/LottieAnimation'
import ideasStore from '../../storeZustand/ideasStore'

function ReviewSubmitted() {
    const { needleNewIdeas } = ideasStore((state) => ({
        needleNewIdeas: state.needleNewIdeas,
    }))
    return (
        <div className="flex justify-center flex-col items-center">
            <div className="flex flex-row relative">
                <div className="font-subtitle text-black">
                    Review submitted
                    <div className="w-10 h-10 absolute -right-9 -top-[8px]">
                        <Lottie
                            options={doneIconAnimation}
                            isClickToPauseDisabled={true}
                        />
                    </div>
                </div>
            </div>
            <div className="font-body-text text-black pt-2">
                {needleNewIdeas.length > 0
                    ? 'A new idea has been added to the Ideas page!'
                    : 'A new idea will be added to the Ideas page soon!'}
            </div>
        </div>
    )
}

export default ReviewSubmitted
