import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { showToast } from '../../redux/appReducer'
import { ConnectorState } from '../../redux/connectorReducer'
import { AppState, useAppDispatch } from '../../redux/store'
import { Services } from '../../services'
import DisconnectToolModal from './DisconnectToolModal'
import GetInTouchModal from './GetInTouchModal'
import './ConnectToolPage.scss'
import { useSearchParams } from 'react-router-dom'
import ConnectToShopifyModal from './ConnectToShopifyModal'
import ConnectedSuccessModal from './ConnectShopifySuccessModal'
import { auth } from '../../firebase/client'
import { PATH } from '../../constants/path'
import {
    SmartAITool,
    SmartAIToolType,
} from '../../components/CoverBox/SmartAITool'
import { CoverBox } from '../../components/CoverBox/CoverBox'
import {
    currentEmailTools,
    currentMarket,
    connectToolShopAnalytics,
} from './component'
import { ConnectToolGroup } from './ConnectToolGroup'
import { usePageTitle } from '../HomeScreen/component'

const MAX_SHOP_CONNECTION = 2

function ConnectToolPage() {
    const {
        connectors,
        emailConnected,
        marketConnected,
        shopAnalyticsConnected,
    } = useSelector<AppState, ConnectorState>((state) => state.connector)
    const customServices = 'shopify,klaviyo,facebook_ads'
    usePageTitle('Needle - Connect tools')
    const [disconnectModal, setDisconnectModal] = useState<{
        serviceId: string
        connectorId: string
        open: boolean
    }>({
        serviceId: 'shopify',
        connectorId: '',
        open: false,
    })
    const dispatch = useAppDispatch()
    const [getInTouchModal, setGetInTouchModal] = useState<boolean>(false)
    const [connectToShopifyModal, setConnectToShopifyModal] =
        useState<boolean>(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [connectingService, setConnectingService] = useState<string>()
    const baseShopAnalytics = connectToolShopAnalytics(shopAnalyticsConnected)
    const handleConnectButtonOnClick = async (serviceId: string) => {
        if (serviceId === 'leadsie') {
            window.open(
                'https://app.leadsie.com/connect/b5938e52d587e3a3/manage',
                '_blank'
            )
            return
        }
        if (serviceId === 'email_assets') {
            window.open(
                'https://forms.askneedle.com/email-brand-assets',
                '_blank'
            )
            return
        }

        const isConnected =
            connectors?.some(
                (connector) =>
                    serviceId === connector.service &&
                    (connector.status.setup_state === 'connected' ||
                        customServices.includes(connector.service))
            ) ?? false
        if (isConnected) {
            setDisconnectModal({
                serviceId: serviceId,
                open: true,
                connectorId:
                    connectors?.find(
                        (connector) => serviceId === connector.service
                    )?.id ?? '',
            })
            return
        }

        // if (serviceId === 'tiktok_ads') {
        //     window.location.replace(
        //         `https://business-api.tiktok.com/portal/auth?app_id=7308905270179004417&state=${
        //             auth.currentUser?.uid
        //         }&redirect_uri=https%3A%2F%2Fasia-southeast1-${
        //             process.env.REACT_APP_ENVIRONMENT === 'production'
        //                 ? 'prod'
        //                 : 'dev'
        //         }-needle.cloudfunctions.net%2Fapp%2Ftiktok-integration%2Fcallback`
        //     )
        //     return
        // }

        // if (serviceId === "klaviyo") {
        //   navigate(PATH.klaviyoIntegrate);
        //   return;
        // }

        // if (serviceId === 'facebook_ads') {
        //   navigate(PATH.facebookIntegrate);
        //   return;
        // }

        if (connectingService) return
        if (serviceId === 'shopify') {
            setConnectToShopifyModal(true)
            return
        }

        setConnectingService(serviceId)
        let connector = connectors?.find((item) => item.service === serviceId)
        let connectorId = connector?.id ?? ''

        try {
            if (!connectorId) {
                const res = await Services.createConnector({
                    service: serviceId,
                    config: {},
                })
                connectorId = res.data.id
            }
            const card = await Services.getConnectorCard({
                connectorId: connectorId ?? '',
                redirectUri:
                    serviceId == 'facebook_ads'
                        ? `${window.location.origin}${PATH.facebookBusinessInstruction}`
                        : window.location.href,
            })
            window.location.href = card?.data?.connect_card?.uri
        } catch (e: any) {
            setConnectingService(undefined)
            dispatch(
                showToast({
                    type: 'error',
                    content: e.toString(),
                })
            )
        }
    }

    return (
        <div className="bg-beige h-full w-full transition-colors text-dark-blue">
            <div className="flex flex-col justify-center items-center w-full mb-14">
                <div className="flex flex-col w-4/5 sm:w-5/6 max-w-728px items-center">
                    <div className="flex w-full pb-2">
                        <SmartAITool
                            pageWord={`Connect tools`}
                            typePage={SmartAIToolType.detailPage}
                        />
                    </div>
                    <div className="font-body-text pb-2 text-black">
                        These provide critical data points for Needle to
                        generate better ideas
                    </div>
                    <CoverBox
                        name={'Email marketing'}
                        children={ConnectToolGroup(
                            currentEmailTools,
                            emailConnected,
                            handleConnectButtonOnClick,
                            connectingService
                        )}
                        connectStatus={{
                            currentConnect: emailConnected.length,
                            maxConnect: currentEmailTools.length,
                        }}
                    />
                    <CoverBox
                        name={'Paid marketing channels'}
                        children={ConnectToolGroup(
                            currentMarket,
                            marketConnected,
                            handleConnectButtonOnClick,
                            connectingService
                        )}
                        connectStatus={{
                            currentConnect: marketConnected.length,
                            maxConnect: currentMarket.length,
                        }}
                    />
                    <CoverBox
                        name={'Shop & analytics'}
                        children={ConnectToolGroup(
                            baseShopAnalytics,
                            shopAnalyticsConnected,
                            handleConnectButtonOnClick,
                            connectingService
                        )}
                        connectStatus={{
                            currentConnect: shopAnalyticsConnected.length,
                            maxConnect: MAX_SHOP_CONNECTION,
                        }}
                    />
                </div>
            </div>
            <DisconnectToolModal
                connectorId={disconnectModal.connectorId}
                open={disconnectModal.open}
                serviceId={disconnectModal.serviceId}
                handleClose={() =>
                    setDisconnectModal({
                        serviceId: '',
                        open: false,
                        connectorId: '',
                    })
                }
            />
            <GetInTouchModal
                open={getInTouchModal}
                handleClose={() => setGetInTouchModal(false)}
            />
            <ConnectToShopifyModal
                open={connectToShopifyModal}
                handleClose={() => setConnectToShopifyModal(false)}
            />
            <ConnectedSuccessModal
                open={searchParams.get('service') === 'shopify'}
                handleClose={() => {
                    setSearchParams({})
                }}
            />
        </div>
    )
}

export default ConnectToolPage
