import React from 'react'
interface ShowAndHideButtonProps {
    isShow: boolean
    setIsShow: React.Dispatch<React.SetStateAction<boolean>>
}
export const ShowAndHideButton = ({
    isShow,
    setIsShow,
}: ShowAndHideButtonProps) => {
    return (
        <div
            className="text-base flex flex-row items-center cursor-pointer justify-center pl-2"
            onClick={() => setIsShow(!isShow)}
        >
            <img
                src="/assets/svgs/arrow-pointer.svg"
                alt="arrow-pointer"
                className={`${
                    isShow ? 'rotate-180' : ''
                } transition-transform duration-300 h-18px w-18px p-1`}
            />
            <div className="font-link-small-text underline pl-1">
                {isShow ? 'Hide' : 'Show'}
            </div>
        </div>
    )
}

export default ShowAndHideButton
