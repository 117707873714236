import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Container,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Stack,
    Theme,
    Typography,
} from '@mui/material'
import React from 'react'
import useSWR from 'swr'
import { Services } from '../services'
import LoadingScreen from './LoadingScreen/LoadingScreen'
import useSWRMutation from 'swr/mutation'
import { useNavigate } from 'react-router-dom'
import { PATH } from '../constants/path'
import { fetchUserConnector } from '../redux/connectorReducer'
import { useAppDispatch } from '../redux/store'
import { TIKTOK_ADVERTISER_DATA_KEY } from '../constants'

const ConnectTiktokScreen = () => {
    const [advertiser, setAdvertiser] = React.useState<{
        asset_id: string
        business_id: string
        asset_name: string
    }>()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { data: assets, isLoading } = useSWR(
        `Get tiktok assets`,
        async () => {
            return await Services.getTiktokAssets()
        }
    )

    const { trigger, isMutating } = useSWRMutation(
        `Assing to Needle tiktok,${advertiser}`,
        async () => {
            const res = await Services.addTiktokAssetPartner({
                business_id: advertiser?.business_id ?? '',
                asset_id: advertiser?.asset_id ?? '',
            })
            localStorage.setItem(
                TIKTOK_ADVERTISER_DATA_KEY,
                JSON.stringify(advertiser)
            )
            if (res.message === 'OK') {
                navigate(PATH.tools)
            } else {
                navigate(PATH.tiktokPartnerGuide)
            }
            dispatch(fetchUserConnector())
        }
    )
    const handleChange = (event: SelectChangeEvent<typeof advertiser>) => {
        setAdvertiser(
            assets.find((item: any) => item.asset_id === event.target.value)
        )
    }

    const ITEM_HEIGHT = 48
    const ITEM_PADDING_TOP = 8
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    }

    if (isLoading) return <LoadingScreen />

    return (
        <Container
            sx={{
                mt: 10,
            }}
            maxWidth="xs"
        >
            <Stack spacing={2} alignItems={'center'}>
                <Typography variant="h3-bold">
                    Choose your advertiser account
                </Typography>
                <img
                    alt="tiktok_ads"
                    width={100}
                    height={100}
                    src="/assets/svgs/tiktok_ads.svg"
                />
                <Box>
                    <Select
                        sx={{ width: '400px' }}
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        value={advertiser}
                        onChange={handleChange}
                        MenuProps={MenuProps}
                    >
                        {assets?.map((business: any) => (
                            <MenuItem
                                key={business?.asset_id}
                                value={business?.asset_id}
                            >
                                {business?.asset_name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                <Button
                    startIcon={
                        isMutating ? (
                            <CircularProgress size={16} color="info" />
                        ) : undefined
                    }
                    onClick={() => {
                        trigger()
                    }}
                    fullWidth
                    disabled={advertiser === undefined || isMutating}
                >
                    Connect
                </Button>
            </Stack>
        </Container>
    )
}

export default ConnectTiktokScreen
