import React, { useEffect, useState } from 'react'
import useUserProfile, { updateUserProfile } from '../../hooks/useUserProfile'
import { ButtonType, NeedleButton } from '../../components/NeedleButton'
import { NeedleTextInput } from '../../components/NeedleTextInput'
import { checkPassword } from '../OnBoardingPage/component'
import { AuthService } from '../../firebase/authentication'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase/client'
import globalErrorDialogStore from '../../storeZustand/globalErrorDialogStore'

const AccountSettings = () => {
    const {
        userProfile: currentUser,
        isLoading: isLoadingCurrentUser,
        refetch,
    } = useUserProfile()
    const [user] = useAuthState(auth)
    const DEFAULT_PASSWORD = '***************'
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>(DEFAULT_PASSWORD)
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [disableSaveChanges, setDisableSaveChanges] = useState<boolean>(false)
    const [passwordError, setPasswordError] = useState('')
    // const [emailError, setEmailError] = useState<any>('')
    // const [errorMessageDisplay, setErrorMessageDisplay] = useState<string>('')
    // const { errorMessage } = useSelector<AppState, AuthState>(
    //     (state) => state.auth
    // )

    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        setCurrentUserData()
    }, [currentUser])

    useEffect(() => {
        cleanData()
    }, [isEdit])

    const setCurrentUserData = () => {
        if (currentUser) {
            if (currentUser.profile) {
                setFirstName(currentUser.profile.first_name)
                setLastName(currentUser.profile.last_name)
            } else {
                setFirstName('')
                setLastName('')
            }
            setEmail(currentUser.email)
            setPassword(DEFAULT_PASSWORD)
        }
    }

    useEffect(() => {
        if (
            password.trim() === '' ||
            firstName.trim() === '' ||
            lastName.trim() === ''
        ) {
            setDisableSaveChanges(true)
        } else {
            setDisableSaveChanges(false)
        }
    }, [password, firstName, lastName])

    const checkingErrorData = (): boolean => {
        let isAcceptedData = true
        // if (!checkEmailType(email)) {
        //     setEmailError('This doesn’t seem to be an email address')
        //     isAcceptedData = false
        // } else {
        //     setEmailError('')
        // }
        if (!checkPassword(password)) {
            setPasswordError(
                'The password needs to be at least 8 characters long'
            )
            isAcceptedData = false
        } else {
            setPasswordError('')
        }
        return isAcceptedData
    }

    // useEffect(() => {
    //     setErrorMessageDisplay(errorMessage)
    //     setIsLoading(false)
    // }, [errorMessage])

    const saveData = async () => {
        if (!isLoading && checkingErrorData() && user) {
            setIsLoading(true)
            const data = await updateUserProfile(
                {
                    profile: {
                        first_name: firstName,
                        last_name: lastName,
                    },
                },
                refetch
            )
            if (password !== DEFAULT_PASSWORD) {
                await AuthService.updatePassword(user, password)
            }
            if (data) {
                setIsEdit(false)
            }
            setIsLoading(false)
            // if (email !== user.email) {
            // const verifyEmail =
            //     await AuthService.verifyBeforeUpdateEmail(user, email)
            // const userUpdateEmail = await AuthService.updateEmail(
            //     user,
            //     email
            // )
            // }
        }
    }

    const cleanData = (): void => {
        // setErrorMessageDisplay('')
        // setEmailError('')
        setPasswordError('')
    }

    const cancelEdit = () => {
        setCurrentUserData()
        setIsEdit(false)
    }

    return (
        <div className="w-680px bg-white shadow-un-click-card rounded-lg relative">
            <div className="p-6 gap-4 flex flex-col font-subtitle">
                <div className="font-header-3">Account settings</div>
                <div>
                    <div className="pb-2">Login email:</div>
                    {user?.photoURL ? (
                        <div className="flex">
                            <div className="font-body-text p-2 pr-3 border border-beige-outline rounded-full flex flex-row items-center shadow-un-click-card">
                                <div className="h-6 aspect-square items-center flex justify-center border rounded-full border-beige-outline">
                                    <img
                                        src="/assets/svgs/google.svg"
                                        alt="Google icon"
                                    />
                                </div>
                                <div className="font-small-text-bold pl-1">
                                    {email}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="font-body-text">{email}</div>
                    )}
                    {isEdit && (
                        <div className="pt-2 font-body-text">
                            Need to change this? Ping us at{' '}
                            <a
                                href="mailto:support@askneedle.com"
                                className="font-bold underline cursor-pointer"
                            >
                                support@askneedle.com.
                            </a>
                        </div>
                    )}
                    {/* <NeedleTextInput
                        setValue={setEmail}
                        value={email}
                        placeholder={'youremail@address.com'}
                        isEdit={isEdit}
                        valueError={emailError}
                    /> */}
                </div>
                <div>
                    <div>Password:</div>
                    {isEdit ? (
                        <NeedleTextInput
                            setValue={setPassword}
                            value={password}
                            placeholder={'Password'}
                            isEdit={isEdit}
                            type={'password'}
                            valueError={passwordError}
                        />
                    ) : (
                        <div className="pt-2 font-body-text">
                            {DEFAULT_PASSWORD}
                        </div>
                    )}
                </div>
                <div>
                    <div className="pb-2">First name:</div>
                    <NeedleTextInput
                        setValue={setFirstName}
                        value={firstName}
                        placeholder={'First name'}
                        isEdit={isEdit}
                    />
                </div>
                <div>
                    <div className="pb-2">Last name:</div>
                    <NeedleTextInput
                        setValue={setLastName}
                        value={lastName}
                        placeholder={'First name'}
                        isEdit={isEdit}
                    />
                </div>
            </div>
            {isEdit ? (
                <div>
                    <div className="h-1px bg-beige-outline" />
                    <div className="py-4 pr-6 flex justify-end gap-2">
                        <div>
                            <NeedleButton
                                buttonCharacter="Cancel"
                                buttonType={ButtonType.White}
                                onClickFunction={cancelEdit}
                            />
                        </div>
                        <div>
                            <NeedleButton
                                buttonCharacter="Save changes"
                                onClickFunction={saveData}
                                isDisable={disableSaveChanges}
                                isLoading={isLoading || isLoadingCurrentUser}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="absolute top-6 right-6">
                    <NeedleButton
                        buttonCharacter="Edit"
                        buttonType={ButtonType.White}
                        onClickFunction={() => setIsEdit(true)}
                    />
                </div>
            )}
        </div>
    )
}
export default AccountSettings
