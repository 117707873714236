import { DISABLE_SEGMEMT_KEY, PROD_HOST } from '../../constants'

type SegmentTrackEventProps = {
    event_name: string
    data: any
}

export const EVENT_TRACK_SIGNED_IN = 'Signed In'
export const EVENT_TACTIC_CTA_CLICKED = 'Tactic CTA Clicked'
export const EVENT_TRACK_SIGNED_UP = 'Signed Up'
export const EVENT_TRACK_FEEDBACK_BUTTON_CLICKED = 'Feedback Button Clicked'
export const EVENT_TRACT_GENERATE_ASSET_REQUTESTED = 'Generate Asset Requested'
export const EVENT_TACTIC_DETAIL_VIEW = 'Tactic Detail Viewed'

export const segmentTrackEvent = ({
    event_name,
    data,
}: SegmentTrackEventProps) => {
    try {
        if (localStorage.getItem(DISABLE_SEGMEMT_KEY) !== null) {
            throw new Error('Disabled Segment by staff=true')
        }
        ;(window as any).analytics?.track(event_name, data)
    } catch (e: any) {
        if (!window.location.hostname.includes(PROD_HOST))
            console.log('Segment tracking failed! ', e.message)
    }
}

type SegmentTrackPageProps = {
    page_name: string
    data: any
}
export const segmentTrackPage = ({
    page_name,
    data,
}: SegmentTrackPageProps) => {
    try {
        if (localStorage.getItem(DISABLE_SEGMEMT_KEY) !== null) {
            throw new Error('Disabled Segment by staff=true')
        }

        ;(window as any).analytics?.page(page_name, data)
    } catch (e: any) {
        if (!window.location.hostname.includes(PROD_HOST))
            console.log('Segment tracking failed! ', e.message)
    }
}

type SegmentIdentifyProps = {
    user_id: string
    data: any
}
export const segmentIdentify = ({ user_id, data }: SegmentIdentifyProps) => {
    try {
        if (localStorage.getItem(DISABLE_SEGMEMT_KEY) !== null) {
            throw new Error('Disabled Segment by staff=true')
        }
        ;(window as any).analytics?.identify(user_id, data)
    } catch (e: any) {
        if (!window.location.hostname.includes(PROD_HOST))
            console.log('Segment tracking failed! ', e.message)
    }
}

export const SegmentTrackEmailFilled = async (email: string) => {
    segmentTrackEvent({
        event_name: 'Input Email During Signup',
        data: {
            email,
        },
    })
}
