import React from 'react'
import { usePageTitle } from '../HomeScreen/component'
import AccountSettings from './AccountSettings'
import UsersTeam from './UsersTeam'
import useWorkspace from '../../hooks/useWorkspace'
import { UserRoleInWorkspace } from '../../services/workspace/types'

const SettingsPage = () => {
    usePageTitle('Needle - Settings')
    const { data: workspaceSettings } = useWorkspace()
    return (
        <div className="text-dark-blue items-center flex justify-center flex-col">
            <div className="font-header-1 py-8">Settings</div>
            <AccountSettings />
            {workspaceSettings?.request_user_role ==
                UserRoleInWorkspace.owner && <UsersTeam />}
        </div>
    )
}
export default SettingsPage
