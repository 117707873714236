const resultRoute = '/result'
export const PATH = {
    login: '/login',
    logout: '/logout',
    signUp: '/sign-up',
    resetPassword: '/reset-password',
    forgotPassword: '/forgot-password',
    facebookBusinessInstruction: '/facebook-partner-guide',
    tiktokPartnerGuide: '/tiktok-partner-guide',
    getYourResult: '/get-your-result',
    faq: '/faq',
    onBoarding: '/',
    onBoardingFillData: '/get-to-know',
    notFound: '/404',
    notSupportedOnMobile: '/not-supported-on-mobile',
    askNeedle: '/ask-needle',
    generateTemplate: '/generate-template/:id/:mail',
    creatingYourConnector: `creating-your-connector/:tempCredId`,
    homePage: `${resultRoute}/home`,
    ideasPage: `${resultRoute}/ideas`,
    creditPage: `${resultRoute}/credits`,
    settingsPage: `${resultRoute}/settings`,
    actions: `${resultRoute}/actions`,
    addIdea: `${resultRoute}/add-an-idea`,
    tools: `${resultRoute}/enhance-your-ai/tools`,
    brandProfilePage: `${resultRoute}/enhance-your-ai/brand-profile`,
    assetsAndTemplates: `${resultRoute}/enhance-your-ai/assets-and-templates`,
    reviewPastIdeasPage: `${resultRoute}/enhance-your-ai/review-past-ideas`,
    enhanceYourAi: `${resultRoute}/enhance-your-ai`,
    tiktokIntegrate: `${resultRoute}tools/integrate-tiktok`,
    klaviyoIntegrate: `${resultRoute}/tools/integrate-klaviyo`,
    facebookIntegrate: `${resultRoute}/tools/integrate-facebook`,
    ideasDetail: `${resultRoute}/ideas/:id`,
    setting: `${resultRoute}/setting`,
}
