import React from 'react'
import './GettingResultLayout.scss'
import { Container, Box } from '@mui/material'

interface DetailScreenProps {
    children: React.ReactNode
}

export default function DetailScreen({ children }: DetailScreenProps) {
    const currentPath = window.location.pathname

    return (
        <Container
            sx={{
                p: '0 !important',
            }}
            maxWidth={currentPath.includes('business-overview') ? false : 'xl'}
        >
            <Box>{children}</Box>
        </Container>
    )
}
