import React, { useEffect, useState } from 'react'
import useCreditPlan from '../../hooks/useCreditPlan'
import useWorkspace from '../../hooks/useWorkspace'
import Lottie from 'react-lottie'
import { loadingIconAnimation } from '../../components/LottieAnimation'
import { CreditPlan } from '../../interfaces'
import { NeedleButton } from '../../components/NeedleButton'
import CreditsDialog from '../IdeasDetailPage/Dialog/CreditsDialog'
import { usePageTitle } from '../HomeScreen/component'
import CreditHistorySection from './CreditHistorySection'

const CreditsPage = () => {
    usePageTitle('Needle - Credits')
    const { isCreditPlansLoading, creditPlans, postCheckoutPlan, isCreditBuy } =
        useCreditPlan()
    const { data: workspace } = useWorkspace()
    const remaining_credits =
        (workspace?.paid_credit ?? 0) +
        (workspace?.free_credit ?? 0) -
        (workspace?.used_credit ?? 0)

    const onCheckout = async (credit_plan: CreditPlan) => {
        const redirectUrl = await postCheckoutPlan(credit_plan)
        window.location.href = redirectUrl
    }
    const [isOpenSuccessDialog, setIsOpenSuccessDialog] =
        useState<boolean>(false)

    const [creditBuyClick, setCreditBuyClick] = useState<string>('1')

    useEffect(() => {
        const query = new URLSearchParams(window.location.search)
        if (query.get('success') === 'true') {
            const creditsBuy = query.get('credits') ?? '1'
            setCreditBuyClick(creditsBuy)
            setIsOpenSuccessDialog(true)
        }
    }, [])

    return (
        <div className="text-dark-blue items-center flex justify-center flex-col gap-8 mt-8">
            <CreditsDialog
                isOpen={isOpenSuccessDialog}
                setIsOpen={setIsOpenSuccessDialog}
                creditBuy={creditBuyClick}
            ></CreditsDialog>
            <div className="font-header-1">Credits</div>
            <div className="w-680px bg-white shadow-un-click-card rounded-lg">
                <div className="p-6">
                    <div className="font-header-3">Credit balance</div>
                    <div className="font-large-body-text pt-4 flex flex-row items-center gap-1">
                        <div>{remaining_credits}</div>
                        <img
                            className="h-5 aspect-square"
                            alt={`credits-buy-icon`}
                            src="/assets/icons/credits-buy-icon.svg"
                        />
                    </div>
                </div>
                <div className="h-1px bg-beige-outline" />
                <div className="p-6">
                    <div className="font-header-3">Add Credits</div>
                    <div className="font-body-text pt-2 pb-4">
                        The Credits you buy never expire.
                    </div>
                    {isCreditPlansLoading ? (
                        <div className="h-7 aspect-square w-full flex justify-center">
                            <Lottie
                                options={loadingIconAnimation}
                                isClickToPauseDisabled={true}
                            />
                        </div>
                    ) : (
                        <div className="flex flex-row flex-wrap gap-4">
                            {creditPlans?.map((plan: CreditPlan) => (
                                <div
                                    key={plan.id}
                                    className="gradient-theme-background rounded-lg p-2px w-[200px]"
                                >
                                    <div className="bg-white rounded-md p-22px h-full justify-between flex flex-col">
                                        <div className="pb-4">
                                            <div className="flex flex-row items-center gap-1">
                                                <div>{plan.amount}</div>
                                                <img
                                                    className="h-6 aspect-square"
                                                    alt={`credits-buy-icon`}
                                                    src="/assets/icons/credits-buy-icon.svg"
                                                />
                                            </div>
                                            <div className="font-header-3 pt-2 flex flex-row">
                                                <div>US$</div>
                                                <div>{plan.price}</div>
                                            </div>
                                            {plan.discount !== 0 && (
                                                <div className="pt-1 flex flex-row items-center">
                                                    <div className="line-through flex flex-row font-body-text">
                                                        <div>US$</div>
                                                        <div>{plan.price}</div>
                                                    </div>
                                                    <div className="font-subtitle text-red-theme pl-2">
                                                        {plan.discount}% OFF
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <NeedleButton
                                            onClickFunction={() =>
                                                onCheckout(plan)
                                            }
                                            isFull={true}
                                            isDisable={isCreditBuy}
                                            border={'py-4'}
                                        >
                                            {isCreditBuy ? (
                                                <div className="h-4 aspect-square w-full flex justify-center">
                                                    <Lottie
                                                        options={
                                                            loadingIconAnimation
                                                        }
                                                        isClickToPauseDisabled={
                                                            true
                                                        }
                                                    />
                                                </div>
                                            ) : (
                                                <div>Buy Credits</div>
                                            )}
                                        </NeedleButton>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <CreditHistorySection />
        </div>
    )
}

export default CreditsPage
