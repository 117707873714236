import { ConceptType } from '../constants'

export const formatConceptsType = (conceptsType: ConceptType): string => {
    let keyword = ''
    switch (conceptsType) {
        case ConceptType.Email:
            keyword = 'an email template in'
            break
        case ConceptType.Video:
            keyword = 'a video around'
            break
        case ConceptType.Image:
            keyword = 'an image in around'
            break
    }
    return keyword
}
