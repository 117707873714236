import React, { useEffect, useState } from 'react'
import { Product } from '../../../utils/needleEnum'
import { ConceptType, MINIUM_PRODUCT_VIDEO } from '../constants'
import ProductList from './ProductList'
interface ProductConceptsDisplayProps {
    isShowFull: boolean
    conceptsType: ConceptType
    productData: Product[]
}

export const ProductConceptsDisplay = ({
    isShowFull,
    conceptsType,
    productData,
}: ProductConceptsDisplayProps) => {
    const moreProducts = productData.length - MINIUM_PRODUCT_VIDEO
    const [currentImageIndex, setCurrentImageIndex] = useState(0)

    useEffect(() => {
        if (conceptsType === ConceptType.Video) {
            const intervalId = setInterval(() => {
                setCurrentImageIndex(
                    (prevIndex) => (prevIndex + 1) % productData.length
                )
            }, 1000)

            return () => clearInterval(intervalId)
        }
    })

    // const renderTopFourImage = (): JSX.Element => {
    //     return (
    //         <div className="grid grid-cols-2">
    //             <img
    //                 className="w-120px aspect-square rounded-tl"
    //                 alt={`asset-return`}
    //                 src={productData[0].thumbnail}
    //             />
    //             <img
    //                 className="w-120px aspect-square rounded-tr"
    //                 alt={`asset-return`}
    //                 src={productData[1].thumbnail}
    //             />
    //             <img
    //                 className="w-120px aspect-square rounded-bl"
    //                 alt={`asset-return`}
    //                 src={productData[2].thumbnail}
    //             />
    //             <div className="relative">
    //                 <img
    //                     className="w-120px aspect-square rounded-br"
    //                     alt={`asset-return`}
    //                     src={productData[3].thumbnail}
    //                 />
    //                 {moreProducts > 0 && (
    //                     <div className="absolute h-full w-full top-0 justify-center items-center flex rounded-br bg-black bg-opacity-50">
    //                         <div className="text-white font-sans text-xl font-normal">
    //                             {moreProducts}+
    //                         </div>
    //                     </div>
    //                 )}
    //             </div>
    //         </div>
    //     )
    // }

    // if (conceptsType === ConceptType.Video) {
    //     return (
    //         <div
    //             className={`flex ${isShowFull ? 'flex-col' : 'flex-row'} pt-6`}
    //         >
    //             <div className="font-subtitle pb-4">
    //                 <div className="pb-2">Products</div>
    //                 {isShowFull ? (
    //                     <ProductList productData={productData} />
    //                 ) : (
    //                     <div className="mr-12">
    //                         {productData.length >= MINIUM_PRODUCT_VIDEO ? (
    //                             renderTopFourImage()
    //                         ) : (
    //                             <img
    //                                 className="w-60 aspect-square rounded-md"
    //                                 alt={`product`}
    //                                 src={productData[0]}
    //                             />
    //                         )}
    //                     </div>
    //                 )}
    //             </div>
    //             <div className="font-subtitle">
    //                 <div className="pb-2">Moodboard</div>
    //                 <div>
    //                     <img
    //                         className="w-60 aspect-square rounded-md"
    //                         alt={`asset-return`}
    //                         src={productData[currentImageIndex]}
    //                     />
    //                 </div>
    //                 <div className="italic font-normal text-xs pt-2">
    //                     For illustration purposes only
    //                 </div>
    //             </div>
    //             {isShowFull && (
    //                 <div>
    //                     <div className="font-subtitle pb-2 pt-6">
    //                         Assets needed from you
    //                     </div>
    //                     <div className="font-body-text">
    //                         Raw assets (videos & photos) of your products will
    //                         be required. I’ll provide you with a brief for
    //                         filming footage, and recommend content creation
    //                         companies. Once you upload the raw assets, I’ll edit
    //                         and create the video for you.
    //                     </div>
    //                 </div>
    //             )}
    //         </div>
    //     )
    // } else
    return productData.length > 0 ? (
        <div>
            <div className="font-subtitle pb-2 pt-6">Products</div>
            <div className="flex gap-2 flex-wrap">
                <ProductList productData={productData} />
            </div>
        </div>
    ) : (
        <div></div>
    )
}

export default ProductConceptsDisplay
