import React, { useEffect, useRef } from 'react'
import lottie from 'lottie-web'

interface LottieAnimationProps {
    animationUrl: string
    loop?: boolean
}
const LottieAnimation = ({
    animationUrl,
    loop = true,
}: LottieAnimationProps) => {
    const animationContainer = useRef(null)
    const animationInstance = useRef<any>(null)

    useEffect(() => {
        fetch(animationUrl)
            .then((response) => response.json())
            .then((data) => {
                if (animationContainer.current) {
                    animationInstance.current = lottie.loadAnimation({
                        container: animationContainer.current,
                        renderer: 'svg',
                        loop: false,
                        autoplay: true,
                        animationData: data,
                    })
                    if (loop) {
                        animationInstance.current.addEventListener(
                            'complete',
                            () => {
                                // Start looping from frame 40 to 180 after the first complete loop
                                animationInstance.current.playSegments(
                                    [40, 180],
                                    true
                                )
                            }
                        )
                    }
                }
            })

        return () => {
            if (animationInstance.current) {
                animationInstance.current.destroy() // Clean up on unmount
            }
        }
    }, [animationUrl])

    return <div ref={animationContainer}></div>
}

export default LottieAnimation

export const doneIconAnimation = {
    loop: false,
    autoplay: true,
    animationData: require('../assets/svgs/checked-icon-animation.json'),
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet',
    },
}
export const loadingIconAnimation = {
    loop: true,
    autoplay: true,
    animationData: require('../assets/svgs/loading-animation.json'),
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet',
    },
}

export const creditIconAnimation = {
    loop: false,
    autoplay: true,
    animationData: require('../assets/svgs/credit-animation.json'),
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet',
    },
}

export const logoIconAnimation = {
    loop: false,
    autoplay: true,
    animationData: require('../assets/svgs/needle-logo-animation.json'),
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet',
    },
}
export const onboardingIconAnimation = {
    loop: false,
    autoplay: true,
    animationData: require('../assets/svgs/onboarding-animation.json'),
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet',
    },
}
export const successOnboardingIconAnimation = {
    loop: false,
    autoplay: true,
    animationData: require('../assets/svgs/success-onboarding-icon-animation.json'),
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet',
    },
}

export const pageNotFoundIconAnimation = {
    loop: false,
    autoplay: true,
    animationData: require('../assets/svgs/page-not-found-animation.json'),
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet',
    },
}

export const loadingPageIconAnimation = {
    loop: false,
    autoplay: true,
    animationData: require('../assets/svgs/loading-icon-animation.json'),
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet',
    },
}
