import React from 'react'
import { ButtonType, NeedleButton } from '../../../components/NeedleButton'
import { PATH } from '../../../constants/path'
import { useNavigate } from 'react-router-dom'

export const EmailConnectBlocked = () => {
    const navigate = useNavigate()
    return (
        <div>
            <div className="font-header-3">Provide email templates</div>
            <div
                className="font-body-text pt-2 pb-6"
                dangerouslySetInnerHTML={{
                    __html: `
                    <div>
                            <p>You need to provide <strong>email templates</strong> in order 
                            for me to create a new email design.</p>
                    </div>
                    `,
                }}
            />
            <div className="flex justify-end">
                <div>
                    <NeedleButton
                        buttonCharacter="Provide email templates"
                        buttonType={ButtonType.Black}
                        onClickFunction={() =>
                            navigate(PATH.assetsAndTemplates)
                        }
                    />
                </div>
            </div>
        </div>
    )
}

export default EmailConnectBlocked
