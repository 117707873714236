import { CircularProgress } from '@mui/material'
import { ToolData } from '../../constants'
import { ButtonType, NeedleButton } from '../../components/NeedleButton'

export const ConnectToolGroup = (
    toolData: ToolData[],
    toolConnected: string[],
    handleConnectButtonOnClick: (serviceId: string) => Promise<void>,
    connectingService: string | undefined
): JSX.Element => {
    return (
        <div className="pt-4 grid grid-cols-1 xl:grid-cols-2 gap-4">
            {toolData.map((item: ToolData) => {
                const isDisabled = connectingService !== undefined
                const isLoading = connectingService === item.id
                const connector = toolConnected.find(
                    (shopId) => shopId === item.id
                )
                const isConnect = connector !== undefined
                const isEmail = item.service === 'Email Assets'
                const buttonCharacter = isConnect
                    ? 'Disconnect'
                    : isEmail
                      ? 'Upload'
                      : 'Connect'
                const buttonColor =
                    buttonCharacter === 'Disconnect'
                        ? ButtonType.White
                        : ButtonType.Black
                return (
                    <div
                        className="bg-white w-full xl:w-332px rounded-lg
                        shadow-un-click-card transition-all duration-300 p-4 px-6 pb-6 justify-between flex flex-col"
                        key={item.id}
                    >
                        <div className="flex flex-col">
                            <div>
                                <div className="w-9 h-9 rounded-lg border border-beige-outline  flex justify-center items-center">
                                    {item.avatar}
                                </div>
                            </div>
                            <div>
                                <div className="font-header-3 pt-3 pb-2">
                                    {item.service}
                                </div>
                                <div className="font-small-text pb-3">
                                    {item.description}
                                </div>
                            </div>
                        </div>
                        {isLoading ? (
                            <CircularProgress size={24} color="info" />
                        ) : (
                            <NeedleButton
                                buttonCharacter={buttonCharacter}
                                onClickFunction={() =>
                                    handleConnectButtonOnClick(item.id)
                                }
                                isDisable={isDisabled}
                                buttonType={buttonColor}
                            />
                        )}
                    </div>
                )
            })}
        </div>
    )
}
