import Dialog from '@mui/material/Dialog'
import React from 'react'
import { NeedleButton } from '../../../components/NeedleButton'
import { creditIconAnimation } from '../../../components/LottieAnimation'
import Lottie from 'react-lottie'

interface CreditsDialogProps {
    isOpen: boolean
    setIsOpen: any
    creditBuy: string
}

export const CreditsDialog = ({
    isOpen,
    setIsOpen,
    creditBuy,
}: CreditsDialogProps) => {
    return (
        <Dialog open={isOpen}>
            <div className="relative w-480px">
                <div className="text-dark-blue p-6">
                    <div className="justify-center items-center flex w-full relative pt-14 pb-10px">
                        <div className=" w-32 h-32 absolute -top-9">
                            <Lottie
                                options={creditIconAnimation}
                                isClickToPauseDisabled={true}
                            />
                        </div>
                    </div>
                    <div className="font-header-2">{`${creditBuy} Credit${creditBuy !== '1' ? 's' : ''} added!`}</div>
                    <div className="font-body-text pt-10px">
                        {
                            'Thanks for your purchase! You’ll receive an invoice via email.'
                        }
                    </div>
                </div>
                <div className="bg-beige-outline h-1px w-full absolute left-0" />
                <div className="flex flex-row items-end py-4 pr-6 justify-end">
                    <div className="pl-2">
                        <NeedleButton
                            buttonCharacter={'Nice!'}
                            onClickFunction={() => setIsOpen(false)}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default CreditsDialog
