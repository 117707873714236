import React, { ReactNode } from 'react'
import { Box, Modal, ModalProps } from '@mui/material'

interface NeedleModalProps extends Pick<ModalProps, 'open'> {
    children: ReactNode
    onClose: () => void
    size?: '400' | '480' | '560' | '640' | '720' | '780'
    blockOverlay?: boolean
    disableEscapeKeyDown?: boolean
    hideBackdrop?: boolean
    noPadding?: boolean
    closeAfterTransition?: boolean
}

export const NeedleModal = (props: NeedleModalProps) => {
    let widthStyle = `sm:w-[600px]`
    switch (props.size) {
        case '400':
            widthStyle = 'sm:w-[400px]'
            break
        case '480':
            widthStyle = 'sm:w-[480px]'
            break
        case '640':
            widthStyle = 'sm:w-[640px]'
            break
        case '720':
            widthStyle = 'sm:w-[720px]'
            break
        case '780':
            widthStyle = 'sm:w-[780px]'
            break
    }

    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            disableEscapeKeyDown={props.disableEscapeKeyDown}
            hideBackdrop={props.hideBackdrop}
            closeAfterTransition={props.closeAfterTransition}
            className="bg-black bg-opacity-90"
            slotProps={{
                backdrop: props.blockOverlay
                    ? {
                          onClick: undefined,
                      }
                    : {},
            }}
        >
            <Box
                sx={{
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                }}
                className={`w-[95%] ${
                    props.noPadding ? 'p-0' : 'p-8'
                } overflow-auto max-h-[95vh] ${widthStyle} grid gap-6 sm:gap-7 lg:gap-8  
        border-0 rounded-2xl top-[50%] left-[50%] absolute bg-white
         -translate-y-2/4 -translate-x-2/4 outline-0`}
            >
                {props.children}
            </Box>
        </Modal>
    )
}
