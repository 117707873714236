import {
    Box,
    Button,
    Container,
    List,
    ListItem,
    ListItemText,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { PATH } from '../../constants/path'
import { TIKTOK_ADVERTISER_DATA_KEY } from '../../constants'

const TiktokPartnerGuide = () => {
    const CopyButton = (props: { text: string }) => {
        const [isCopied, setIsCopied] = useState(false)
        return (
            <Button
                onClick={() => {
                    navigator.clipboard.writeText(props.text)
                    setIsCopied(true)
                }}
            >
                {isCopied ? `Copied` : `Copy`}
            </Button>
        )
    }
    const advertiser_account = localStorage.getItem(TIKTOK_ADVERTISER_DATA_KEY)
        ? JSON.parse(localStorage.getItem(TIKTOK_ADVERTISER_DATA_KEY) ?? '')
        : {
              asset_name: 'Your Advertiser account',
              business_id: ``,
          }
    const NEEDLE_BC_ID = `7307196520183414785`
    const user_bc_id = advertiser_account.business_id
    const advertiser_account_name = advertiser_account.asset_name
    return (
        <Container
            maxWidth={'md'}
            sx={{
                p: 4,
                pt: 2,
                my: { xs: 0, md: 8 },
                bgcolor: 'white',
                borderRadius: '16px',
            }}
        >
            <Stack>
                <Link to={PATH.tools}>
                    <Typography>{`< Go Back`}</Typography>
                </Link>
                <Box mt={1}>
                    <Typography mb={2} variant="h3-bold">
                        🥴 Oops! Your Tiktok account doesn’t seem to have the
                        right permissions to share access directly.
                    </Typography>
                    <Typography sx={{ opacity: 0.8 }} fontWeight={600} mt={2}>
                        {' '}
                        ✅ <strong>Don’t worry</strong>, you can still share
                        access to us by following these simple steps:
                    </Typography>
                </Box>
                <List component="ol" sx={{ listTyle: 'decimal' }}>
                    <ListItem component={'li'} className={'liStyle'}>
                        <Stack spacing={1}>
                            <ListItemText>
                                1. Copy Needle's Business Center ID
                            </ListItemText>
                            <Box>
                                <TextField value={NEEDLE_BC_ID} />
                            </Box>
                            <Box>
                                <CopyButton text={NEEDLE_BC_ID} />
                            </Box>
                        </Stack>
                    </ListItem>
                    <ListItem component={'li'} className={'liStyle'}>
                        <Stack spacing={1}>
                            <ListItemText>
                                2. Open your <strong>Business Manager</strong>.
                            </ListItemText>

                            <Box mt={2}>
                                <a
                                    target="_blank"
                                    href={`https://business.tiktok.com/manage/partner?org_id=${user_bc_id}`}
                                >
                                    <Button>OPEN BUSINESS MANAGER</Button>
                                </a>
                            </Box>
                        </Stack>
                    </ListItem>

                    <ListItem component={'li'} className={'liStyle'}>
                        <Stack spacing={1}>
                            <ListItemText>
                                3. In the <strong>Partner</strong> tab, clicking
                                on{' '}
                                <Box
                                    sx={{
                                        display: 'inline-block',
                                        bgcolor: 'yellow.100',
                                        padding: '2px',
                                        borderRadius: '4px',
                                    }}
                                >
                                    <Typography
                                        color={'red.600'}
                                        variant="code1"
                                    >
                                        <strong>Add partner</strong>
                                    </Typography>
                                </Box>{' '}
                                button.
                            </ListItemText>
                            <Container maxWidth="md">
                                <img
                                    width={'100%'}
                                    src="assets/imgs/add-tiktok-partner.png"
                                />
                            </Container>
                        </Stack>
                    </ListItem>

                    <ListItem component={'li'} className={'liStyle'}>
                        <Stack>
                            <ListItemText>
                                4. Paste in the <strong>copied</strong> Business
                                Center ID
                            </ListItemText>
                            <Box>
                                <img src="assets/imgs/paste-tiktok-bcid.png" />
                            </Box>
                        </Stack>
                    </ListItem>

                    <ListItem component={'li'} className={'liStyle'}>
                        <Stack>
                            <ListItemText>
                                5. Select "<strong>Advertiser accounts</strong>"
                                on the left. Choose{' '}
                                <Box
                                    sx={{
                                        display: 'inline-block',
                                        bgcolor: 'yellow.100',
                                        padding: '2px',
                                        borderRadius: '4px',
                                    }}
                                >
                                    <Typography
                                        color={'red.600'}
                                        variant="code1"
                                    >{`${advertiser_account_name}`}</Typography>
                                </Box>{' '}
                                and <strong>switch on</strong> "Operator"
                                permission.
                            </ListItemText>
                            <Box>
                                <img src="/assets/imgs/choose-assets-tiktok.png" />
                            </Box>
                        </Stack>
                    </ListItem>
                    <ListItem component={'li'} className={'liStyle'}>
                        <ListItemText>
                            6. Click <strong>Assign</strong>
                        </ListItemText>
                    </ListItem>
                </List>

                <Stack spacing={1} mt={1}>
                    <Typography>Done? Hit "Continue" 🙌</Typography>
                    <Box>
                        <Link to={PATH.tools}>
                            <Button> Continue</Button>
                        </Link>
                    </Box>
                </Stack>
            </Stack>
        </Container>
    )
}

export default TiktokPartnerGuide
