import { useEffect } from 'react'
import { Concept, Idea, IdeaStatus } from '../../utils/needleEnum'
import { ActionData, EventType } from './constants'
import { segmentTrackPage } from '../../services/segments'
import { auth } from '../../firebase/client'

export const formatDataIdeasList = (listData: Idea[]): ActionData[] => {
    const newListIdeas: ActionData[] = []
    listData.forEach((idea: Idea) => {
        const ideaType = formatIdeaToEventType(idea)
        newListIdeas.push({
            name: idea.idea_title,
            description: idea.idea_description,
            id: idea.id,
            metadata: idea.metadata,
            assets: idea.asset?.files.map((file) => file.url) ?? [],
            type: ideaType,
            dateEvent:
                ideaType === EventType.Done
                    ? idea.done_date
                    : idea.suggested_asset_delivery_date,
        })
    })
    return newListIdeas
}
export const smartHello = (
    userProfile: Needle.User | undefined,
    isNotData: boolean,
    fallbackName?: string
): string => {
    const userName = userProfile?.profile?.first_name
        ? userProfile.profile.first_name
        : fallbackName ?? 'my friend'
    if (isNotData) {
        return `Hi ${userName}, welcome!`
    } else {
        const helloStack = [
            `Hi [first_name], welcome back!`,
            `Hey [first_name], welcome back!`,
            `Hi [first_name], nice to see you again!`,
            `Great to have you, [first_name]!`,
            `Glad you’re here, [first_name]!`,
            `Let’s do this, [first_name]!`,
            `Hi [first_name], ready to go?`,
            `Hi [first_name] 👋`,
        ]
        return helloStack[
            Math.floor(Math.random() * helloStack.length)
        ].replace('[first_name]', userName)
    }
}
export const formatIdeaToEventType = (currentIdeas: Idea): EventType => {
    switch (currentIdeas.status) {
        case IdeaStatus.done:
            return EventType.Done
        case IdeaStatus.needle_is_working:
            return EventType.Doing
        case IdeaStatus.archived:
            return EventType.Archived
        case IdeaStatus.blocked:
            return EventType.Blocked
        case IdeaStatus.new_asset:
        case IdeaStatus.read_asset:
            return EventType.AssetReady
        case IdeaStatus.new:
        case IdeaStatus.read:
            return EventType.NewIdeas
        default:
            return EventType.Empty
    }
}

export function usePageTitle(title: string, enableChangeTitle = true) {
    useEffect(() => {
        if (enableChangeTitle) document.title = title
        segmentTrackPage({
            page_name: title.split(' - ').pop() || title,
            data: {
                title: window.document.title,
                url: window.location.href,
                userId: auth.currentUser?.uid,
            },
        })
    }, [title])
}
