import {
    Autocomplete,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogProps,
    ListItemText,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import React, { useEffect } from 'react'
import useSWRImmutable from 'swr/immutable'
import useWorkspace from '../../hooks/useWorkspace'
import { AdminPreviewServices } from '../../services/AdminPreview/AdminPreview.service'
import useSWRMutation from 'swr/mutation'

const SelectBrandModal = (props: DialogProps) => {
    const { data: brands } = useSWRImmutable(`/users?page=0&offset=0`, () => {
        return AdminPreviewServices.getAllBrands()
    })

    const {
        trigger: updatePreviewWorkspace,
        isMutating,
        error,
    } = useSWRMutation<void, Error, string, string>(
        `/admin-preview/:workspace_id`,
        (_key: string, options: Readonly<{ arg: string }>) => {
            return AdminPreviewServices.updatePreviewWorkspace(options.arg)
        },
        { throwOnError: false }
    )

    const { data: workspaceData, isLoading } = useWorkspace()
    const options =
        brands?.map((brand: any) => ({
            value: brand.id,
            label: `${brand.workspace_name} - ${brand.creator_email}`,
        })) ?? []
    const [selectedBrand, setSelectedBrand] = React.useState<{
        value: string
        label: string
    }>()
    useEffect(() => {
        if (workspaceData) {
            setSelectedBrand({
                value: workspaceData.workspace_id,
                label: workspaceData.workspace_name,
            })
        }
    }, [workspaceData])
    return (
        <Dialog {...props}>
            {!isLoading && brands ? (
                <DialogContent
                    sx={{
                        width: 600,
                    }}
                >
                    <Stack spacing={1}>
                        <Typography fontSize={22} fontWeight={600} variant="h2">
                            Select a brand
                        </Typography>
                        <Typography fontSize={18} fontWeight={600} variant="h3">
                            Current workspace:{' '}
                            <Typography
                                sx={{
                                    color: 'grey.600',
                                    display: 'inline',
                                }}
                            >
                                {workspaceData?.workspace_name}
                            </Typography>
                        </Typography>
                        <Typography fontSize={18} fontWeight={600} variant="h3">
                            Creator:{' '}
                            <Typography
                                sx={{
                                    color: 'grey.600',
                                    display: 'inline',
                                }}
                            >
                                {workspaceData?.creator_email}
                            </Typography>
                        </Typography>
                    </Stack>
                    <Typography sx={{ mt: 3, mb: 1 }}>
                        Type {`name, email, id`} here to search:
                    </Typography>
                    <Autocomplete
                        onChange={(_e, value) => {
                            setSelectedBrand(value as any)
                        }}
                        options={(options as any) ?? []}
                        value={selectedBrand}
                        getOptionLabel={(option) =>
                            (option as any).label + option.value
                        }
                        renderOption={(props, item) => (
                            <Typography {...props} key={item.value}>
                                <ListItemText>{item.label}</ListItemText>
                                <Typography sx={{ fontSize: 10 }}>
                                    {item.value}
                                </Typography>
                            </Typography>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select a website"
                                variant="outlined"
                            />
                        )}
                    />
                    <Stack
                        spacing={2}
                        marginTop={2}
                        direction="row"
                        justifyContent={'end'}
                    >
                        <Button
                            sx={{
                                marginTop: 2,
                            }}
                            variant="outlined"
                            onClick={() => {
                                props.onClose?.({}, 'backdropClick')
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            sx={{
                                marginTop: 2,
                            }}
                            disabled={isMutating}
                            onClick={async () => {
                                await updatePreviewWorkspace(
                                    selectedBrand?.value ?? ''
                                )
                                window.location.replace(window.location.origin)
                            }}
                        >
                            Update
                        </Button>
                    </Stack>
                </DialogContent>
            ) : (
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            )}
        </Dialog>
    )
}

export default SelectBrandModal
