import React, { useEffect, useRef, useState } from 'react'
import { IdeasDetail } from '../../components/DetailCardView/IdeasDetail'
import { TypeForm } from './type'
import ReviewGoodAndMehSelect from './ReviewGoodAndMehSelect'
import { ActionData, LIST_OLD_REVIEW } from '../HomeScreen/constants'
import ReviewUserInputData from './ReviewUserInputData'
import ReviewSubmitted from './ReviewSubmitted'
import { IdeaDetailServices } from '../../services/IdeaDetail/ideadetail.services'
import globalErrorDialogStore from '../../storeZustand/globalErrorDialogStore'
interface ReviewSectionProps {
    ideas: ActionData
    callRemove: (ideas: ActionData) => void
}
function ReviewSection({ ideas, callRemove }: ReviewSectionProps) {
    const [isOpenForm, setIsOpenForm] = useState<TypeForm>(TypeForm.NoSelected)
    const [isOpenCustomReview, setIsOpenCustomReview] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [progress, setProgress] = useState<number>(0)
    const reviewRef = useRef<HTMLDivElement>(null)
    const cardRef = useRef<HTMLDivElement>(null)
    const { setIsOpenErrorDialog } = globalErrorDialogStore.getState()

    const handleFlow = (typeForm: TypeForm) => () => {
        setIsOpenForm(typeForm)
        return true
    }

    const onSubmitEvent = async (dataFeedBack: any) => {
        if (ideas.id) {
            setIsLoading(true)
            try {
                await IdeaDetailServices.sendIdeaFeedBack(
                    ideas.id,
                    dataFeedBack
                )
                setIsOpenForm(TypeForm.Submitted)
            } catch (e) {
                setIsOpenErrorDialog(true)
            }
            setIsLoading(false)
        }
    }

    const renderReviewState = (): JSX.Element => {
        switch (isOpenForm) {
            case TypeForm.NoSelected:
                return <ReviewGoodAndMehSelect handleFlow={handleFlow} />
            case TypeForm.Submitted:
                return <ReviewSubmitted />
            default:
                return (
                    <ReviewUserInputData
                        ideasID={ideas.id ? ideas.id : 0}
                        isOpenForm={isOpenForm}
                        setIsOpenForm={setIsOpenForm}
                        onSubmit={onSubmitEvent}
                        isOpenCustomReview={isOpenCustomReview}
                        setIsOpenCustomReview={setIsOpenCustomReview}
                        isLoading={isLoading}
                    />
                )
        }
    }

    useEffect(() => {
        if (reviewRef.current) {
            switch (isOpenForm) {
                case TypeForm.NoSelected:
                    reviewRef.current.style.height = '115px'
                    break
                case TypeForm.Submitted:
                    reviewRef.current.style.height = '96px'
                    break
                default:
                    if (isOpenCustomReview) {
                        reviewRef.current.style.height = '295px'
                    } else {
                        reviewRef.current.style.height = '392px'
                    }
                    break
            }
        }
        if (isOpenForm === TypeForm.Submitted) {
            const interval = setInterval(() => {
                setProgress((prevProgress) => {
                    if (prevProgress === 80 && cardRef.current) {
                        const currentHeight =
                            cardRef.current.getBoundingClientRect().height
                        cardRef.current.style.height = `${currentHeight}px`
                    }
                    if (prevProgress === 100) {
                        if (cardRef.current) {
                            cardRef.current.style.height = '0px'
                            cardRef.current.style.marginTop = '0px'
                            cardRef.current.classList.add('scale-0')
                        }
                    }
                    if (prevProgress >= 100) {
                        clearInterval(interval)
                        callRemove(ideas)
                        return 108
                    }
                    return prevProgress + 1
                })
            }, 100)
            return () => {
                clearInterval(interval)
            }
        }
    }, [isOpenForm, isOpenCustomReview])

    return (
        <div
            className="rounded-lg mt-26px shadow-un-click-card max-w-728px flex flex-col justify-between w-full bg-white transition-all duration-300 ease-in-out"
            ref={cardRef}
        >
            <IdeasDetail
                action={ideas}
                ideasGroups={LIST_OLD_REVIEW}
                isSmallImage={true}
                isFullSize={true}
                isSubStyle={false}
                productData={ideas.metadata?.products}
                isHaveButton={true}
            />
            <div className="bg-beige-outline h-1px w-full" />
            <div
                className="flex justify-center flex-col items-center py-6 transition-all duration-300 ease-in-out"
                ref={reviewRef}
            >
                {renderReviewState()}
            </div>
            <div
                className="gradient-theme-background h-1 transition-width ease-linear duration-300 rounded-b"
                style={{ width: `${progress}%` }}
            />
        </div>
    )
}

export default ReviewSection
