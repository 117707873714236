import React, { useState } from 'react'
import { NeedleModal } from '../NeedleModal'
import { Box, Button, ButtonProps, Stack, Typography } from '@mui/material'
import { NeedleButton } from '../NeedleButton'

interface PromptModalConfig {
    icon: React.ReactNode
    title: string
    message: React.ReactNode | string
    buttons: {
        label: string
        buttonProps: ButtonProps
        onClickFunction?: any
    }[]
}
export const usePromptModal = () => {
    const [promptModalConfig, setPrompModalConfig] = useState<{
        open: boolean
        config: PromptModalConfig
    }>({
        open: false,
        config: {
            icon: <></>,
            title: '',
            message: <></>,
            buttons: [],
        },
    })
    const showModal = (props: PromptModalConfig) => {
        setPrompModalConfig({ open: true, config: props })
    }

    const hideModal = () => {
        setPrompModalConfig((val) => ({ ...val, open: false }))
    }
    return {
        promptModalConfig: { ...promptModalConfig, onClose: hideModal },
        showModal,
        hideModal,
    }
}

const PromptModal = ({
    open,
    onClose,
    config,
}: {
    open: boolean
    onClose: () => void
    config: {
        icon: React.ReactNode
        title: string
        message: React.ReactNode | string
        buttons: {
            label: string
            buttonProps: ButtonProps
            onClickFunction?: any
        }[]
    }
}) => {
    return (
        <NeedleModal noPadding open={open} onClose={onClose}>
            <Stack
                sx={{
                    p: 4,
                }}
            >
                <Box display={'flex'} justifyContent={'center'}>
                    {config.icon}
                </Box>
                <Typography
                    textAlign={'center'}
                    variant="h3-bold"
                    sx={{ mt: 1.5, mb: 2.5 }}
                >
                    {config.title}
                </Typography>
                <Typography textAlign={'center'} variant="body1">
                    {config.message}
                </Typography>
                <Box>
                    <Stack mt={3.5} direction={'row'} spacing={'20px'}>
                        {config.buttons.map((button, index) => {
                            return (
                                <NeedleButton
                                    isFull={true}
                                    key={index}
                                    onClickFunction={
                                        button.onClickFunction
                                            ? () => button.onClickFunction()
                                            : () => {}
                                    }
                                >
                                    <div>{button.label}</div>
                                </NeedleButton>
                            )
                        })}
                    </Stack>
                </Box>
            </Stack>
        </NeedleModal>
    )
}

export default PromptModal
