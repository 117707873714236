import { createTheme } from '@mui/material/styles'
import breakpoints from './breakpoints'
import { palette } from './colors'
import components from './components'
import { typography } from './typography'

const theme = createTheme({
    breakpoints: { values: breakpoints },
    typography,
    palette,
    components,
})

export default theme
export { fonts } from './typography'
export { default as breakpoints } from './breakpoints'
