import { useState } from 'react'
import useSWRImmutable from 'swr/immutable'
import ApiConfig from '../services/api/config'
import { axiosInstance, getOptions, handleResponse } from '../services/api'
import { CreditPlan } from '../interfaces'

const GENERATE_API_URL = `${process.env.REACT_APP_BASE_URL}${ApiConfig.CREDIT_PLANS}`

const useCreditPlan = () => {
    const [loading, setLoading] = useState(false)

    const {
        data: creditPlans,
        mutate: mutateCreditPlans,
        isLoading: isCreditPlansLoading,
        ...rest
    } = useSWRImmutable(ApiConfig.CREDIT_PLANS, async () => {
        const options = await getOptions()
        const data: CreditPlan[] = await handleResponse(
            axiosInstance.get(GENERATE_API_URL, options)
        )
        return data
    })

    const postCheckoutPlan = async (credit_plan: CreditPlan) => {
        setLoading(true)
        const options = await getOptions()
        const redirectUrl = await handleResponse(
            axiosInstance.post(
                GENERATE_API_URL + '/create-checkout-session',
                credit_plan,
                options
            )
        )
        setLoading(false)
        return redirectUrl
    }

    return {
        creditPlans,
        isCreditPlansLoading: isCreditPlansLoading,
        isCreditBuy: loading,
        mutateCreditPlans,
        postCheckoutPlan,
        ...rest,
    }
}

export default useCreditPlan
