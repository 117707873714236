import { ButtonType, NeedleButton } from '../../../components/NeedleButton'
import { updateAssetsAndTemplates } from '../../../hooks/useEnhanceYourAI'
import brandStore from '../../../storeZustand/brandCustomizationStore'
import { EITemplatesEmail } from '../../BrandProfilePage/type'
import DoneWithFlatForm from '../DoneWithFlatForm'
import { klaviyoEI } from '../constants'
import { connectToolType } from '../type'

function KlaviyoConnection() {
    const { aAndTEmail, setTemplateEmail } = brandStore((state) => ({
        aAndTEmail: state.aAndTEmail,
        setTemplateEmail: state.setTemplateEmail,
    }))

    const submitSharedAccess = (
        key: keyof EITemplatesEmail,
        value: any
    ): void => {
        setTemplateEmail(key, value)
    }
    return (
        <div>
            <div className="flex flex-row font-subtitle pt-6">
                <div>Share via Klaviyo</div>
                <span className="text-red-theme">*</span>
            </div>
            <div className="h-full w-full transition-colors text-dark-blue">
                {aAndTEmail.Klaviyo !== null ? (
                    <div className="pb-6 pt-2">
                        <DoneWithFlatForm
                            type={connectToolType.Klaviyo}
                            setIsOpen={() =>
                                submitSharedAccess('Klaviyo', null)
                            }
                        />
                    </div>
                ) : (
                    <div className="pb-6">
                        <div className="font-subtitle">Instructions:</div>
                        <div
                            className="font-body-text pt-2"
                            dangerouslySetInnerHTML={{
                                __html: klaviyoEI,
                            }}
                        />
                        <div className="font-link-small-text text-blue-theme underline ">
                            See video guide
                        </div>
                        <div className="flex justify-end pt-2">
                            <div>
                                <NeedleButton
                                    buttonCharacter="I've shared access"
                                    buttonType={ButtonType.Black}
                                    onClickFunction={() =>
                                        submitSharedAccess('Klaviyo', '')
                                    }
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default KlaviyoConnection
