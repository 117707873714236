import React, { useEffect, useState } from 'react'
import { EventType } from '../../HomeScreen/constants'
import { AssetsData, Concept } from '../../../utils/needleEnum'
import { NeedleImage } from '../../../components/NeedleImage'
interface EmailAssetItemProps {
    currentIdeas: Concept
    ideasStatus: EventType
    assetsData: AssetsData
    deliveryText: string
}
export const EmailAssetItem = ({
    currentIdeas,
    ideasStatus,
    assetsData,
    deliveryText,
}: EmailAssetItemProps) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = (): void => {
        setIsOpen(true)
    }

    const closeModal = (): void => {
        setIsOpen(false)
    }

    const EmailImageURL = assetsData.files[0].url ?? ''

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('overflow-hidden')
        } else {
            document.body.classList.remove('overflow-hidden')
        }
    }, [isOpen])

    return (
        <div>
            {isOpen && (
                <div
                    className="fixed inset-0 flex justify-center bg-black bg-opacity-80 z-50"
                    onClick={closeModal}
                >
                    <div className="w-full overflow-auto justify-center flex h-full">
                        <div onClick={(e) => e.stopPropagation()}>
                            <NeedleImage
                                className="w-600px py-60px"
                                alt={`asset-email-return`}
                                src={EmailImageURL}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div
                className="flex justify-center items-center pb-4 cursor-pointer"
                onClick={openModal}
            >
                <NeedleImage
                    className="w-480px"
                    alt={`asset-email-return`}
                    src={EmailImageURL}
                />
            </div>
            <div
                className="font-body-text pb-4"
                dangerouslySetInnerHTML={{
                    __html: deliveryText,
                }}
            ></div>
        </div>
    )
}

export default EmailAssetItem
