import { ConceptType } from '../views/IdeasDetailPage/constants'

export interface Product {
    id: string
    title: string
    image?: string
}
export interface ProductItemList {
    id: string
    title?: string
    image?: string
}

export interface Concept {
    title: string
    creative_type: ConceptType
    sub_type: string
    description: string
    products?: Product[]
    credits: number
    suggested_send_date?: string
    id: string
}

export const enum IdeaBlockerType {
    profile_essentials = 'profile_essentials',
    email_templates = 'email_templates',
    additional_assets = 'additional_assets',
}

export const enum IdeaStatus {
    draft_main = 'draft_main',
    draft_queue = 'draft_queue',
    queue = 'queue',
    new = 'new',
    read = 'read',
    blocked = 'blocked',
    archived = 'archived',
    needle_is_working = 'needle_is_working',
    new_asset = 'new_asset',
    read_asset = 'read_asset',
    done = 'done',
    new_learning_report = 'new_learning_report',
    read_learning_report = 'read_learning_report',
}

const enum FeedbackType {
    positive = 'positive',
    negative = 'negative',
}
export interface AssetsData {
    files: {
        url: string
        mimeType: string
    }[]
}
export interface Idea {
    id: string
    workspace_id: string
    idea_credit?: number
    idea_why: string
    system_state: string
    idea_next: string
    publishing_type: string
    idea_title: string
    idea_description: string
    brand_id: string
    idea_type: string
    idea_revenue: number
    idea_budget: number
    idea_supporting: string
    generated_by: string
    db_id: number
    concepts?: Concept[]
    sub_type: string
    generated_at: string
    nickname: string
    idea_skills: string
    idea_category: string
    status: IdeaStatus
    upload_date: string
    done_date: string
    metadata?: IdeaMetadata
    asset?: AssetsData
    delivery_text: string
    suggested_asset_delivery_date?: string
    skip_additional_assets?: boolean
    additional_assets?: string
    feedback?: {
        feedback_type: FeedbackType
        message: string
    }
    notion_page_id: string
    notion_page_url: string
}
export interface IdeaMetadata {
    blockers?: IdeaBlockerType[]
    products?: Product[]
    idea_apps?: string[]
}
interface BrandInputProfile {
    essentials_profile: {
        creation_purpose: string
        key_messages: string[]
        brand_personality: string
    }
    customer_profile: {
        who_they_are: string
        age_range: string
        gender: string
        family_status: string
        markets: string[]
    }
    brand_metrics: {
        marketing_spend: number
        gross_margin: number
        shiping_cost: number
    }
    preferred_products: Product[]
    brand_detail: {
        brand_name: string
        brand_logo: string
        brand_guide: string
        brand_font: string
    }
    assets: {
        type: 'google_drive' | 'dropbox'
        url: string
    }[]
    email_templates: {
        type: 'klaviyo' | 'figma' | 'canva'
        url?: string
    }[]
}
