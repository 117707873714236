import React from 'react'
import { Link } from 'react-router-dom'
import useWorkspaceCreditHistory from '../../hooks/useWorkspaceCreditHistory'
import { getInnerTextFromHTML } from '../../utils/utils'

const CreditHistoryRecord = (props: {
    action: 'ADD' | 'DEDUCT' | 'PURCHASE' | 'SPEND'
    timestamp: string
    description: string
    url?: string
    amount: number
}) => {
    const ACTION_MAPPING = {
        ADD: 'Credit Added',
        DEDUCT: 'Credit Deducted',
        PURCHASE: 'Credit Added',
        SPEND: 'Credit Spent',
    }

    const description = props.url ? (
        <Link
            to={props.url}
            className="text-sm font-normal underline text-blue-theme"
        >
            {props.description}
        </Link>
    ) : (
        <p className="text-sm font-normal">{props.description}</p>
    )
    return (
        <div className="flex justify-between items-start gap-4">
            <div className="flex flex-col gap-1  max-w-[90%]">
                <h4 className="font-header-4">
                    {ACTION_MAPPING[props.action]}
                </h4>
                {description}
                <p className="text-sm font-normal">
                    {new Date(props.timestamp).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                    })}
                </p>
            </div>
            <h3 className="font-header-4">
                {['ADD', 'PURCHASE'].includes(props.action) ? '+' : '-'}{' '}
                {props.amount}
            </h3>
        </div>
    )
}

const CreditHistorySection = () => {
    const [isShow, setIsShow] = React.useState(false)
    const { data } = useWorkspaceCreditHistory()
    return (
        <div className="w-680px bg-white shadow-un-click-card rounded-lg p-6 mb-10">
            <div className="flex justify-between">
                <h3 className="font-header-3">Credits history</h3>
                <div
                    onClick={() => setIsShow((val) => !val)}
                    className="select-none text-base flex flex-row items-center cursor-pointer justify-center"
                >
                    <img
                        src="/assets/svgs/arrow-pointer.svg"
                        alt="arrow-pointer"
                        className={`${
                            isShow ? 'rotate-180' : ''
                        } transition-transform duration-300`}
                    />
                    <div className="font-link-body-text underline pl-2">
                        {isShow ? 'Hide' : 'Show'}
                    </div>
                </div>
            </div>
            {isShow && (
                <div className="pt-4 flex flex-col gap-4">
                    {data?.map((record: any, index: number) => (
                        <CreditHistoryRecord
                            key={index}
                            action={record.action as any}
                            timestamp={record.timestamp}
                            description={getInnerTextFromHTML(record.reason)}
                            url={
                                record?.metadata?.idea_id &&
                                `/result/ideas/${record?.metadata?.idea_id}`
                            }
                            amount={record.amount}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

export default CreditHistorySection
