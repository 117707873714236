import Lottie from 'react-lottie'
import LottieAnimation, {
    doneIconAnimation,
    loadingIconAnimation,
} from '../../components/LottieAnimation'
import { EventType } from '../../views/HomeScreen/constants'
import { useEffect, useState } from 'react'
import { Idea, IdeaMetadata } from '../../utils/needleEnum'
import { getBlockedText } from '../../views/IdeasDetailPage/functions'
import { format, isToday, isTomorrow, isPast } from 'date-fns'
interface SubItemProps {
    event: EventType
    metadata?: IdeaMetadata
    dateSet?: string
}
export const formatDateGetAssets = (data: string): string => {
    const date = new Date(data)
    if (isPast(date) && !isToday(date)) {
        return 'Working on it'
    } else if (isToday(date)) {
        return `By today`
    } else if (isTomorrow(date)) {
        return `By tomorrow`
    } else {
        return `By ${format(data, 'MMMM dd')} `
    }
}

export const SubItem = ({
    event,
    metadata,
    dateSet,
}: SubItemProps): JSX.Element => {
    const [blockedDescription, setBlockedDescription] = useState<string>('')
    useEffect(() => {
        if (metadata?.blockers) {
            setBlockedDescription(getBlockedText(metadata.blockers))
        }
    }, [metadata])

    switch (event) {
        case EventType.NewIdeas:
            return (
                <div className="flex flex-row pl-3 justify-center items-center">
                    <div className="text-pink-theme text-sm font-black">
                        NEW
                    </div>
                    <div className="h-3 w-3 ml-1">
                        <LottieAnimation animationUrl="https://lottie.host/98a78630-8e5a-4074-b9fe-eb6890808a61/LfunoAZbKM.json" />
                    </div>
                </div>
            )
        case EventType.Doing:
            return (
                <div className="whitespace-nowrap p-2 px-3 text-xs text-dark-blue bg-beige rounded-lg font-bold leading-110 flex flex-row items-center">
                    <div className="w-13px h-13px">
                        <Lottie
                            options={loadingIconAnimation}
                            isClickToPauseDisabled={true}
                        />
                    </div>
                    <div className="pl-2">
                        <div>
                            {dateSet
                                ? formatDateGetAssets(dateSet)
                                : 'Working on it'}
                        </div>
                    </div>
                </div>
            )
        case EventType.Done:
            return (
                <div className="whitespace-nowrap p-2 px-3 text-xs bg-green-theme text-white rounded-lg font-bold leading-110">
                    Done {dateSet ? format(dateSet, '(MMMM dd)') : ''}
                </div>
            )
        case EventType.Archived:
            return (
                <div className="whitespace-nowrap p-2 px-3 text-xs text-dark-blue bg-beige-2 rounded-lg font-bold leading-110">
                    Archived
                </div>
            )
        case EventType.Blocked:
            return (
                <div className="whitespace-nowrap p-2 px-3 text-xs text-white bg-red-theme rounded-lg font-bold leading-110">
                    {blockedDescription}
                </div>
            )
        case EventType.AssetReady:
            return (
                <div className="whitespace-nowrap text-xs text-dark-blue rounded-lg font-bold leading-110 flex flex-row relative bg-gradient-to-r from-pink-ai-gradient to-blue-ai-gradient p-[1px]">
                    <div className="bg-white w-full flex pl-33px p-2 px-3 rounded-lg">
                        <div className="w-10 h-10 absolute left-0 -top-1">
                            <Lottie
                                options={doneIconAnimation}
                                isClickToPauseDisabled={true}
                            />
                        </div>
                        Asset ready
                    </div>
                </div>
            )
        default:
            return <></>
    }
}
