import React, { useEffect, useState } from 'react'
import { CoverBox } from '../../components/CoverBox/CoverBox'
import { SmartAITool } from '../../components/CoverBox/SmartAITool'
import ideasStore from '../../storeZustand/ideasStore'
import { FlagColor, FlagProps } from '../../components/Flag'
import {
    EventType,
    IdeasList,
    LIST_EVENT_HOMEPAGE,
    LIST_SHOW_VIEW,
    ListEvent,
    NotDisplayPage,
} from '../HomeScreen/constants'
import { DropdownListView } from '../HomeScreen/DropdownListView'
import { CoverBoxSize } from '../../components/CoverBox/CoverBoxType'
import { formatDataIdeasList, usePageTitle } from '../HomeScreen/component'

export const ActionsPage = () => {
    usePageTitle('Needle - Actions')
    const [listEvents, setListEvent] =
        useState<ListEvent[]>(LIST_EVENT_HOMEPAGE)
    const [listShowState, setListShowState] =
        useState<boolean[]>(LIST_SHOW_VIEW)
    const { needleDone, needleDoing, needleAction } = ideasStore((state) => ({
        needleDone: state.needleDone,
        needleDoing: state.needleDoing,
        needleAction: state.needleAction,
    }))

    useEffect(() => {
        const newListEvent = [...listEvents]
        newListEvent[IdeasList.ActionRequired].actions =
            formatDataIdeasList(needleAction)
        newListEvent[IdeasList.Doing].actions = formatDataIdeasList(needleDoing)
        newListEvent[IdeasList.Done].actions = formatDataIdeasList(needleDone)
        setListEvent(newListEvent)
    }, [needleAction, needleDoing, needleDone])

    const setShowState = (isShow: boolean, index: number): void => {
        const newShowState = [...listShowState]
        newShowState[index] = isShow
        setListShowState(newShowState)
    }

    const headerDataRender = (ideasGroups: ListEvent): string => {
        return ideasGroups.actions &&
            ideasGroups.actions.length === 1 &&
            ideasGroups.smallName
            ? ideasGroups.smallName
            : ideasGroups.name
    }

    const setupFlagData = (ideasGroups: ListEvent): FlagProps | undefined => {
        const flagColor =
            ideasGroups.type === EventType.Doing ||
            ideasGroups.type === EventType.Done
                ? FlagColor.blue
                : FlagColor.red

        const flagData =
            ideasGroups.actions.length === 0
                ? undefined
                : {
                      flagNumber: ideasGroups.actions.length,
                      color: flagColor,
                  }
        return flagData
    }

    const renderActionData = (
        ideasGroups: ListEvent,
        index: number
    ): JSX.Element => {
        if (ideasGroups.actions.length > 0) {
            return (
                <DropdownListView
                    itemList={ideasGroups.actions}
                    ideasGroups={ideasGroups}
                    isFullSize={ideasGroups.isDropDown === false}
                    isActionsPage={true}
                    isShowView={
                        ideasGroups.isDropDown === false
                            ? true
                            : listShowState[index]
                    }
                />
            )
        } else {
            return (
                <div className="flex flex-col relative font-small-text py-4 justify-center items-center bg-beige mt-4 rounded-lg">
                    Nothing here yet
                </div>
            )
        }
    }

    return (
        <div className="bg-beige h-full w-full transition-colors text-dark-blue">
            <SmartAITool pageWord={`Actions`} />
            <div className="font-body-text justify-center flex pt-2">
                These are all the Ideas you’ve taken action on
            </div>
            <div className="flex flex-col justify-center items-center w-full mb-14">
                <div className="flex flex-col items-center">
                    <div className="w-full flex flex-col xl:space-x-4 xl:flex-row">
                        {listEvents.map(
                            (ideasGroups: ListEvent, index: number) => {
                                return (
                                    ideasGroups.isNoDisplay !==
                                        NotDisplayPage.ACTIONS_PAGE && (
                                        <CoverBox
                                            name={headerDataRender(ideasGroups)}
                                            flag={setupFlagData(ideasGroups)}
                                            size={CoverBoxSize.small}
                                            isDropDown={ideasGroups.isDropDown}
                                            key={ideasGroups.name}
                                            index={index}
                                            showClick={setShowState}
                                            children={renderActionData(
                                                ideasGroups,
                                                index
                                            )}
                                        />
                                    )
                                )
                            }
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActionsPage
