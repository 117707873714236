import { useEffect, useRef } from 'react'
import Lottie from 'react-lottie'
import { loadingPageIconAnimation } from '../../components/LottieAnimation'

export default function LoadingScreen() {
    const lottieRef = useRef<any>()
    useEffect(() => {
        const anim = lottieRef.current?.anim
        if (anim) {
            const handleComplete = () => {
                anim.playSegments([98, 192], true)
            }
            anim.addEventListener('complete', handleComplete)
            anim.play()
            return () => {
                try {
                    anim.removeEventListener('complete', handleComplete)
                } catch {}
            }
        }
    }, [])

    return (
        <div className="text-dark-blue flex items-center text-center flex-col mb-96 absolute z-[99] bg-beige w-screen h-screen justify-center">
            <div className="h-113px aspect-square p-2">
                <Lottie
                    options={loadingPageIconAnimation}
                    isClickToPauseDisabled={true}
                    ref={lottieRef}
                />
            </div>
            <div className="font-header-2">Just a sec...</div>
        </div>
    )
}
