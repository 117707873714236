import useSWRImmutable from 'swr/immutable'
import { EnhanceYourAIServices } from '../services/EnhanceYourAI/enhanceyourai.services'
import { auth } from '../firebase/client'
import { useAuthState } from 'react-firebase-hooks/auth'
import { mutate } from 'swr'
import globalErrorDialogStore from '../storeZustand/globalErrorDialogStore'

const useEnhanceYourAI = () => {
    const [user] = useAuthState(auth)
    const {
        data: dataBrandProfile,
        isLoading: isLoadingBrandProfile,
        mutate: mutateBrandProfile,
        ...rest
    } = useSWRImmutable(
        user ? `brand-profile/${user.uid}` : null,
        async (key) => {
            const dataBrandProfile =
                await EnhanceYourAIServices.getBrandProfile()
            return dataBrandProfile as Needle.BrandProfile
        }
    )
    const {
        data: dataAssetsTemplate,
        isLoading: isLoadingAssetTemplates,
        mutate: mutateAssetsTemplate,
    } = useSWRImmutable('assets-and-templates', async () => {
        const dataAssetsTemplate =
            await EnhanceYourAIServices.getAssetsTemplates()
        return dataAssetsTemplate
    })

    return {
        dataBrandProfile,
        dataAssetsTemplate,
        isLoadingBrandProfile,
        mutateBrandProfile,
        mutateAssetsTemplate,
        ...rest,
    }
}
export const updateBrandProfile = async (data: any) => {
    try {
        const userTacticStatus =
            await EnhanceYourAIServices.updateBrandProfile(data)
        mutate(`/get-growth-plan`)
        return userTacticStatus ?? {}
    } catch (e) {
        const { setIsOpenErrorDialog } = globalErrorDialogStore.getState()
        setIsOpenErrorDialog(true)
        return undefined
    }
}

export const updateAssetsAndTemplates = async (
    data: Partial<Needle.BrandAssetAndTemplate>
) => {
    try {
        const userTacticStatus =
            await EnhanceYourAIServices.updateAssetsTemplates(data)
        mutate(`/get-growth-plan`)
        return userTacticStatus ?? {}
    } catch (e) {
        const { setIsOpenErrorDialog } = globalErrorDialogStore.getState()
        setIsOpenErrorDialog(true)
        return undefined
    }
}

export default useEnhanceYourAI
