import useWorkspace from './useWorkspace'

const useUserData = (workspace_id?: string) => {
    const { data: workspace, isLoading: isLoadingWorkspace } =
        useWorkspace(workspace_id)
    return {
        data: { workspace },
        isLoading: isLoadingWorkspace,
    }
}

export default useUserData
