import { auth } from '../firebase/client'
import { DISABLE_SEGMEMT_KEY, USER_CURRENCY_KEY } from '../constants'
import {
    Campaign,
    FakeGenAIConfig,
    Metric,
    Tactic,
    TacticsStatusData,
} from '../utils/enum'
import { setLocalStorageItem } from '../utils/utils'
import { axiosInstance, handleResponse, getOptions } from './api'
import config from './api/config'

export const Services = {
    getUserProfile: async (userId?: string) => {
        let id = userId !== undefined ? userId : auth.currentUser?.uid
        const options = await getOptions()
        const response = (await handleResponse(
            axiosInstance.get(`${config.USERS}/${id}`, options)
        )) as any
        const role = `${response.data?.role}`
        if (id === auth.currentUser?.uid && ['admin', 'staff'].includes(role)) {
            localStorage.setItem(DISABLE_SEGMEMT_KEY, 'true')
        }
        return response
    },

    updateUserProfile: async (props: { userProfile: any; userId?: string }) => {
        let id =
            props.userId !== undefined ? props.userId : auth.currentUser?.uid
        const options = await getOptions()
        return handleResponse(
            axiosInstance.patch(
                `${config.USERS}/${id}`,
                props.userProfile,
                options
            )
        )
    },
    getUserInfo: async () => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(`${config.OVERRIDEN_VALUES}`, options)
        )
    },

    updateUserInfo: async (props: { userInfo: any }) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.patch(
                `${config.OVERRIDEN_VALUES}/user_info`,
                props.userInfo,
                options
            )
        )
    },
    getConnectorConfig: async () => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(config.CONNECTORS_CONFIG, options)
        )
    },

    getConnectedConnector: async () => {
        const options = await getOptions()
        return handleResponse(axiosInstance.get(config.CONNECTORS, options))
    },

    createConnector: async (data: {
        service: string
        group_id?: string
        run_setup_tests?: boolean
        config: object
        auth?: any
    }) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.post(config.CONNECTORS, data, options)
        )
    },

    createShopifyConnector: async (tempCredId: string) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.post(
                config.SHOPIFY_CONNECTOR,
                { tempCredId },
                options
            )
        )
    },

    getConnectorCard: async (data: {
        connectorId: string
        redirectUri: string
    }) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(
                config.CONNECTORS +
                    `/${data.connectorId}` +
                    config.CONNECTOR_CARD +
                    `?redirectUri=${encodeURIComponent(data.redirectUri)}`,
                options
            )
        )
    },

    getConnectorById: async (data: { connectorId: string }) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(
                config.CONNECTORS + `/${data.connectorId}`,
                options
            )
        )
    },

    deleteConnectorById: async (data: {
        connectorId: string
        message?: string
    }) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.delete(
                config.CONNECTORS +
                    `/${data.connectorId}?message=${data.message}`,
                {
                    data: { message: data.message },
                    ...options,
                }
            )
        )
    },

    getUserResult: async (workspace_id?: string) => {
        let id = workspace_id ?? 'default'
        const options = await getOptions()
        const result = await handleResponse(
            axiosInstance.get(config.SUGGESTED_RESULTS + `/${id}`, options)
        )
        const currency = result?.currency
        setLocalStorageItem(USER_CURRENCY_KEY, currency ?? 'USD')
        return result
    },

    updateUserResult: async ({
        userId,
        data,
    }: {
        userId?: string
        data: any
    }) => {
        let id = userId !== undefined ? userId : auth.currentUser?.uid
        const options = await getOptions()
        return handleResponse(
            axiosInstance.patch(
                config.SUGGESTED_RESULTS + `/${id}`,
                data,
                options
            )
        )
    },

    getUserActions: async (userId?: string) => {
        let id = userId !== undefined ? userId : auth.currentUser?.uid
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(config.USER_ACTIONS + `/${id}`, options)
        )
    },

    speakToAdviser: async (content: string) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.post(
                config.USER_ACTIONS + `${config.SPEAK_TO_ADVISOR}`,
                {
                    request: content,
                },
                options
            )
        )
    },

    requestUnlistedItem: async (content: string) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.post(
                config.USER_ACTIONS + `${config.UNLISTED_TOOLS_REQUEST}`,
                {
                    request: content,
                },
                options
            )
        )
    },

    getAllTacticCommonSettings: async () => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(config.TACTIC_COMMON_SETTINGS, options)
        )
    },

    postTacticRequests: async (props: {
        tactic_id?: number
        requests: string[]
        form_text?: string
    }) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.post(
                `${config.USER_ACTIONS}/${props.tactic_id}${config.SELECTED_REQUEST}`,
                {
                    requests: props.requests,
                    form_text: props.form_text,
                },
                options
            )
        )
    },

    postTacticFeedback: async (props: {
        tactic_id: number
        feedback: string
    }) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.post(
                `${config.USER_ACTIONS}/${props.tactic_id}${config.FEEDBACK}`,
                {
                    feedback: props.feedback,
                },
                options
            )
        )
    },

    getSuggestedResultList: async (params?: {
        _start: number
        _end: number
    }) => {
        const options = await getOptions()
        const res = await axiosInstance.get(config.SUGGESTED_RESULTS, {
            params,
            ...options,
        })
        return {
            data: res.data,
            total: res.headers['x-total-count'],
        }
    },

    toogleReadyUserResult: async (userId?: string) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.put(
                `${config.RESULT}/${userId}${config.TOOGLE_READY_RESULT}`,
                {},
                options
            )
        )
    },

    getUserTacticsStatus: async (workspace_id?: string) => {
        let id = workspace_id ?? 'default'
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(config.TACTIC_STATUS + `/${id}`, options)
        ) as TacticsStatusData
    },

    toogleTacticBookmarkStatus: async (props: {
        workspace_id?: string
        data: { tactic_id: number }
    }) => {
        let id = props.workspace_id ?? 'default'
        const options = await getOptions()
        return handleResponse(
            axiosInstance.patch(
                `${config.TACTIC_STATUS}/${id}/bookmark`,
                props.data,
                options
            )
        ) as TacticsStatusData
    },

    updateTacticFeedback: async (props: {
        workspace_id?: string
        data: {
            tactic_id: number
            button_state: 'positive' | 'negative'
            feedback_value: string
        }
    }) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.post(
                `${config.TACTIC_STATUS}/${
                    props.workspace_id ?? 'default'
                }/feedback`,
                props.data,
                options
            )
        ) as TacticsStatusData
    },

    updateTacticStatus: async (props: {
        workspace_id?: string
        data: { tactic: Tactic; status: string }
    }) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.patch(
                `${config.TACTIC_STATUS}/${
                    props.workspace_id ?? 'default'
                }/status`,
                props.data,
                options
            )
        ) as TacticsStatusData
    },

    getAllTacticStatus: async (workspace_id?: string) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(
                `${config.TACTIC_STATUS}/${workspace_id ?? 'default'}`,
                options
            )
        ) as TacticsStatusData
    },

    getLinkPreviewData: async (url: string) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.post(`${config.LINK_PREVIEW}`, { url }, options)
        )
    },

    setHasViewResult: async () => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.post(`${config.HAS_VIEW_RESULT}`, {}, options)
        )
    },

    getAllTacticCommonConfig: async () => {
        const options = await getOptions()
        return handleResponse(axiosInstance.get(`${config.TACTICS}`, options))
    },

    getAllTacticFormOptions: async () => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(`${config.TACTIC_FORM_OPTIONS}`, options)
        )
    },

    getAllMetrics: async () => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(`${config.METRICS}`, options)
        ) as Metric[]
    },

    getAllFakeGenAIConfigs: async () => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(
                `${config.CTA_TRIGGER}${config.FAKE_GENAI_CONFIG}`,
                options
            )
        ) as FakeGenAIConfig
    },

    requestAssetGeneration: async (
        data: {
            tactic: Tactic
            dropDate: string | undefined
            campaign: Campaign | undefined
        },
        workspaceId: string | undefined = undefined
    ) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.post(
                `${config.CTA_TRIGGER}/${workspaceId}`,
                data,
                options
            )
        ) as FakeGenAIConfig
    },

    userUpdateTacticFromResult: async (currentTactic?: Tactic) => {
        let id = auth.currentUser?.uid
        const options = await getOptions()
        return handleResponse(
            axiosInstance.patch(
                `${config.SUGGESTED_RESULTS}/${id}${config.TACTICS}/${currentTactic?.tactic_id}`,
                currentTactic,
                options
            )
        )
    },

    createTacticListInResult: async (workspace_id: any, tactics: any) => {
        const options = await getOptions()
        return await handleResponse(
            axiosInstance.post(
                `${config.SUGGESTED_RESULTS}/${workspace_id}/tactics`,
                tactics,
                options
            )
        )
    },

    segmentTrack: async (props: {
        user_id?: string
        event_name: string
        data: Record<string, any>
    }) => {
        let id = props.user_id ?? auth.currentUser?.uid
        const options = await getOptions()
        return handleResponse(
            axiosInstance.post(
                `/segment-track`,
                { ...props, user_id: id },
                options
            )
        )
    },

    getFacebookLongLivedToken: async (access_token: string) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(
                `/facebook-integration/access_token?access_token=${access_token}`,
                options
            )
        )
    },

    getFacebookAssets: async () => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(`/facebook-integration/assets`, options)
        )
    },

    addFacebookAssetPartner: async (data: {
        ad_account_id: string
        business_id: string
    }) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.post(
                `/facebook-integration/assets/add-partner`,
                data,
                options
            )
        )
    },

    getTiktokAssets: async () => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(`/tiktok-integration/assets`, options)
        )
    },

    addTiktokAssetPartner: async (data: {
        asset_id: string
        business_id: string
    }) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.post(
                `/tiktok-integration/assets/add-partner`,
                data,
                options
            )
        )
    },
}
