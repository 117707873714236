import React from 'react'
import { CoverBox } from '../../components/CoverBox/CoverBox'
import { NeedleButton } from '../../components/NeedleButton'
import { useNavigate } from 'react-router-dom'
import { PATH } from '../../constants/path'
import useEnhanceYourAI from '../../hooks/useEnhanceYourAI'

export const NewUserCard = () => {
    const navigate = useNavigate()
    const { dataBrandProfile } = useEnhanceYourAI()
    return (
        <div className="w-full">
            <CoverBox
                name={"I'm working on this"}
                flag={{
                    flagNumber: 1,
                }}
            >
                <div className="bg-white shadow-un-click-card p-6 rounded-lg mt-4 mb-2">
                    <div className="font-header-3">
                        New marketing ideas{' '}
                        {dataBrandProfile?.brand_name
                            ? 'for ' + dataBrandProfile.brand_name
                            : ''}
                    </div>
                    <div className="font-body-text pt-2">
                        I’ll notify you when these ideas are ready!
                    </div>
                </div>
            </CoverBox>
            <CoverBox name={'Get to work immediately'}>
                <div className="bg-white shadow-un-click-card p-6 rounded-lg mt-4 mb-2">
                    <div className="font-header-3">Add your own idea</div>
                    <div className="font-body-text pt-2 pb-3">
                        Create an idea from scratch: fill in the details, and
                        I’ll work on it!
                    </div>
                    <NeedleButton
                        buttonCharacter={'Add an idea'}
                        onClickFunction={() => navigate(PATH.addIdea)}
                    />
                </div>
            </CoverBox>
        </div>
    )
}

export default NewUserCard
