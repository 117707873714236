export const checkEmailType = (email: string): boolean => {
    if (
        (email && !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) ||
        email.trim().length === 0
    ) {
        return false
    } else {
        return true
    }
}

export const checkPassword = (password: string): boolean => {
    if (password.length >= 8) {
        return true
    }
    return false
}
export const getFirstName = (nameData: string | undefined | null): string => {
    if (nameData) {
        return nameData.split(' ')[0]
    }
    return ''
}
export const getLastName = (nameData: string | undefined | null): string => {
    if (nameData) {
        return nameData.split(' ').slice(1).join(' ')
    }
    return ''
}

export function isWebView() {
    const userAgent = navigator.userAgent || navigator.vendor
    return /FBAN|FBAV|Instagram|Twitter/.test(userAgent)
}
