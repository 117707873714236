import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    Modal,
    Stack,
    Typography,
} from '@mui/material'
import React, { useState } from 'react'
import './ConnectToShopifyModal.scss'
import TextFieldDefault from '../../components/TextField'
import apiConfig, { BASE_URL } from '../../services/api/config'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase/client'
import { ButtonType, NeedleButton } from '../../components/NeedleButton'
import { NeedleTextInput } from '../../components/NeedleTextInput'
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '560px',
    bgcolor: '#FFFFFF',
    padding: '32px 32px 40px',
    borderRadius: '16px',
}

function ConnectToShopifyModal(props: {
    open: boolean
    handleClose: () => void
}) {
    const [user] = useAuthState(auth)
    const [isLoading, setIsLoading] = useState(false)
    const [shopName, setShopName] = useState<string>('')

    const handleConnectOnClick = async () => {
        setIsLoading(true)
        window.location.href = `${
            BASE_URL + apiConfig.SHOPIFY_OAUTH
        }?shop=${shopName.split('.').shift()}&userId=${
            user?.uid ?? ''
        }&location=${'Connect Tools'}`
    }

    return (
        <Modal
            open={props.open}
            onClose={isLoading ? () => {} : props.handleClose}
        >
            <Box sx={{ ...style }}>
                <Stack
                    className="modal-wrapper"
                    justifyContent={'center'}
                    spacing={0}
                >
                    <div className="font-header-2">Connect Shopify</div>
                    <div className="font-body-text pt-10px pb-6">
                        Shop name is part of the shop URL before .myshopify.com,
                        e.g. for my-shop.myshopify.com the shop name is{' '}
                        <strong>my-shop.</strong>
                    </div>
                    <FormControl
                        sx={{
                            width: '100%',
                            marginBottom: '24px',
                        }}
                    >
                        <Stack
                            direction={'row'}
                            spacing={2}
                            alignItems={'start'}
                        >
                            <Box className="platform-logo shopify">
                                <img
                                    alt="needle"
                                    src="/assets/svgs/shopify.svg"
                                />
                            </Box>
                            <Stack width={'100%'} justifyContent={'start'}>
                                <NeedleTextInput
                                    className="mt-2 mb-6"
                                    placeholder="Shop Name"
                                    value={shopName}
                                    setValue={setShopName}
                                />
                            </Stack>
                        </Stack>
                    </FormControl>
                    <div className="flex flex-row w-full items-center justify-end">
                        <div>
                            <NeedleButton
                                onClickFunction={
                                    isLoading ? undefined : props.handleClose
                                }
                                buttonCharacter="Cancel"
                                buttonType={ButtonType.White}
                            />
                        </div>
                        <div className="pl-2">
                            <NeedleButton
                                onClickFunction={handleConnectOnClick}
                                isDisable={shopName === ''}
                            >
                                {isLoading ? (
                                    <CircularProgress color="info" size={24} />
                                ) : (
                                    <div>Connect Shopify</div>
                                )}
                            </NeedleButton>
                        </div>
                    </div>
                </Stack>
            </Box>
        </Modal>
    )
}

export default ConnectToShopifyModal
