import type { Components, Theme } from '@mui/material'
import { colors } from './colors'

const components: Components<Omit<Theme, 'components'>> = {
    MuiAvatar: {
        styleOverrides: {
            root: {
                background: '#F5F5F5',
            },
        },
    },
    MuiCollapse: {
        defaultProps: {
            timeout: 600,
        },
    },
    MuiFormControlLabel: {
        styleOverrides: {
            root: {
                '& .MuiFormControlLabel-label ': {
                    fontFamily: 'Work Sans',
                    fontSize: '14px',
                    fontWeight: '400',
                },
                '& .MuiFormControlLabel-label.Mui-disabled ': {
                    color: 'rgba(0, 0, 0, 0.2)',
                },
                '&.Mui-focused ': {
                    color: 'rgba(0, 0, 0, 0.8)',
                },
            },
        },
    },
    MuiTooltip: {
        styleOverrides: {
            tooltip: {
                backgroundColor: '#262626',
                fontSize: '12px',
            },
            arrow: {
                '&:before': {
                    background: '#262626',
                },
            },
        },
    },
    MuiListItemButton: {
        styleOverrides: {
            root: {
                padding: '8px 24px',
            },
        },
    },
    MuiListItemIcon: {
        styleOverrides: {
            root: {
                minWidth: '33px',
            },
        },
    },
    MuiTextField: {
        defaultProps: {
            InputLabelProps: {
                required: false,
                shrink: true,
            },
        },
        styleOverrides: {
            root: {
                '& .MuiFormLabel-root': {},
            },
        },
    },

    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                notchedOutline: {
                    fontSize: '0.875rem',
                },
                fontSize: '16px',
                fontFamily: 'Work Sans',
                fontWeight: '400',
                lineHeight: '24px',
                color: 'rgba(0, 0, 0, 0.8)',
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.8)',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#F1AC34',
                },
                '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                    borderColor: '##C54812',
                    borderWidth: '2px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderRadius: '8px',
                },
            },
        },
    },
    MuiSelect: {
        defaultProps: {
            MenuProps: {
                sx: {
                    boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.15) !important',
                },
            },
        },
        styleOverrides: {
            select: {
                padding: '9px 16px',
                fontFamily: 'Work Sans',
                color: 'rgba(0, 0, 0, 0.8)',
                fontSize: '14px',
                fontWeight: '400',
                '& .MuiOutlinedInput-input': {
                    fontSize: '12px',
                    fontFamily: 'Work Sans',
                    fontWeight: '400',
                    padding: '6px 12px !important',
                },
                '& .MuiOutlinedInput-notchedOutline': {},
            },
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                fontFamily: 'Work Sans',
                fontSize: '12px',
                fontWeight: '400',
                padding: '8px',
                margin: '0 8px',
                '& + .MuiMenuItem-root': {
                    marginTop: '8px',
                },
                '&:hover': {
                    backgroundColor: '#FEFAF3 !important',
                },
                '&.Mui-selected': {
                    backgroundColor: 'transparent !important',
                },
            },
        },
    },
    MuiCheckbox: {
        styleOverrides: {
            root: {
                color: 'var(--primary-color)',
                '&.Mui-disabled svg': {
                    path: {
                        fill: 'rgba(0, 0, 0, 0.2)',
                    },
                },
                '&.Mui-disabled': {
                    color: 'rgba(0, 0, 0, 0.2)',
                },
            },
        },
    },
    MuiLink: {
        styleOverrides: {
            root: {
                textDecoration: 'none',
                fontWeight: 500,
                color: 'var(--primary-color)',
                fontFamily: 'Work Sans',
            },
        },
    },
    MuiButton: {
        defaultProps: {
            variant: 'contained',
            color: 'primary',
            size: 'large',
        },
        styleOverrides: {
            root: {
                minWidth: 'unset !important',
                boxShadow: 'none',
                paddingLeft: '16px',
                paddingRight: '16px',
                fontSize: '1rem',
                fontWeight: 600,
                lineHeight: '120%',
                borderRadius: '8px',
                textTransform: 'none',
                '@media screen and (max-width: 1024px)': {
                    fontSize: '0.8125rem',
                    borderRadius: '4px',
                },
                '&.MuiButton-sizeSmall': {
                    minHeight: '32px',
                    // '@media screen and (max-width: 1024px)': {
                    //   padding: '4px 8px',
                    // },
                },
                '&.Mui-disabled': {
                    backgroundColor: colors.yellow[100],
                    color: colors.black[100],
                },
                '&.MuiButton-sizeMedium': {
                    height: '36px',
                },
                '&.MuiButton-sizeLarge': {
                    height: '40px',
                    // '@media screen and (max-width: 1024px)': {
                    //   padding: '8px 16px',
                    // },
                },
                '& .MuiButton-startIcon': {
                    margin: 0,
                    marginRight: '4px',
                },
                '& .MuiButton-endIcon': {
                    margin: 0,
                    marginLeft: '4px',
                },
            },

            contained: {
                backgroundColor: colors.yellow[500],
                boxShadow: 'none !important',
                '&:hover': {
                    backgroundColor: colors.yellow[600],
                },
                '&:active': {
                    backgroundColor: colors.yellow[700],
                },
            },
            outlined: {
                backgroundColor: 'white',
                boxShadow: 'none !important',
                color: colors.yellow[700],
                borderWidth: '1.5px !important',
                borderColor: colors.yellow[700],
                '&:hover': {
                    backgroundColor: colors.yellow[100],
                },
                '&:active': {
                    backgroundColor: colors.yellow[200],
                },
            },
            text: {
                backgroundColor: 'transparent !important',
                '&.Mui-disabled': {
                    color: colors.black[100],
                },
            },
        },
    },
    MuiTypography: {
        styleOverrides: {
            root: {
                padding: 0,
            },
        },
    },
}

export default components
