import React, { useState } from 'react'
import { EventType } from '../../HomeScreen/constants'
import { AssetsData, Concept } from '../../../utils/needleEnum'
import { CardMedia } from '@mui/material'

interface VideoAssetItemProps {
    currentIdeas: Concept
    ideasStatus: EventType
    assetsData: AssetsData
    deliveryText: string
}

export const VideoAssetItem = ({
    currentIdeas,
    ideasStatus,
    assetsData,
    deliveryText,
}: VideoAssetItemProps) => {
    const VideoURL = assetsData.files[0].url
    return (
        <div>
            <div className="flex justify-center items-center pb-4">
                <div className="flex flex-row flex-wrap gap-4">
                    <CardMedia
                        component="video"
                        sx={{ width: 632 }}
                        controls
                        src={VideoURL}
                        title="assets-video"
                    />
                </div>
            </div>
            <div
                className="font-body-text pb-4"
                dangerouslySetInnerHTML={{
                    __html: deliveryText,
                }}
            ></div>
        </div>
    )
}

export default VideoAssetItem
