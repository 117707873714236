import globalErrorDialogStore from '../storeZustand/globalErrorDialogStore'
import { Dialog } from '@mui/material'
import { NeedleButton } from '../components/NeedleButton'
const GlobalErrorDialog = () => {
    const { isOpenErrorDialog, setIsOpenErrorDialog } = globalErrorDialogStore(
        (state) => ({
            isOpenErrorDialog: state.isOpenErrorDialog,
            setIsOpenErrorDialog: state.setIsOpenErrorDialog,
        })
    )

    return (
        <Dialog open={isOpenErrorDialog}>
            <div className="relative w-480px">
                <div className="text-dark-blue p-6">
                    <div className="font-header-2">Something went wrong</div>
                    <div className="font-body-text pt-10px">
                        We’re having trouble completing your request right now.
                        Please try again.
                    </div>
                </div>
                <div className="bg-beige-outline h-1px w-full absolute left-0" />
                <div className="flex flex-row items-end py-4 pr-6 justify-end">
                    <div className="pl-2">
                        <NeedleButton
                            buttonCharacter={'Okay'}
                            onClickFunction={() => setIsOpenErrorDialog(false)}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default GlobalErrorDialog
