import React, { useEffect, useState } from 'react'
import { AppState, useAppDispatch } from '../../../redux/store'
import { clearStatus } from '../../../redux/authReducer'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../../firebase/client'
import OnBoardingProgress from './OnBoardingProgress'
import GetFirstAndLastName from './GetFirstAndLastName'
import ConnectStore from './ConnectStore'
import YourCustomersData from './YourCustomersData'
import MarketingSpend from './MarketingSpend'
import LastButNotLeastView from './LastButNotLeastView'
import useUserProfile, {
    updateUserProfile,
} from '../../../hooks/useUserProfile'
import { useSelector } from 'react-redux'
import { ConnectorState } from '../../../redux/connectorReducer'
import { useSearchParams } from 'react-router-dom'
import useEnhanceYourAI from '../../../hooks/useEnhanceYourAI'
import {
    DATA_CUSTOMERS,
    DATA_CUSTOMERS_DETAILS,
    FINISH_STORE_SETTING,
    FIRST_AND_LAST_NAME,
    LAST_BUT_NOT_LEAST,
    STORE_SETTING,
} from '../constants'
import { usePageTitle } from '../../HomeScreen/component'
import { getFirstName } from '../component'
import Lottie from 'react-lottie'
import {
    onboardingIconAnimation,
    successOnboardingIconAnimation,
} from '../../../components/LottieAnimation'

export const OnBoardingFillYourData = () => {
    usePageTitle('Needle - Onboard')
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()
    const [user] = useAuthState(auth)
    const { userProfile } = useUserProfile()
    const [currentProgress, setCurrentProgress] = useState<number>(
        user?.displayName ? FIRST_AND_LAST_NAME : STORE_SETTING
    )
    const [header, setHeader] = useState<string>(`Nice to have you !`)
    const [firstName, setFirstName] = useState<string>(
        user?.displayName ? `${getFirstName(user.displayName)}` : ``
    )
    const [description, setDescription] = useState<string>(
        'Did I get your name right?'
    )
    const { connectors } = useSelector<AppState, ConnectorState>(
        (state) => state.connector
    )
    const { dataBrandProfile } = useEnhanceYourAI()

    useEffect(() => {
        dispatch(clearStatus())
    }, [])

    useEffect(() => {
        if (searchParams.get('services') !== null) {
            setCurrentProgress(FINISH_STORE_SETTING)
        } else if (
            connectors &&
            connectors.length > 0 &&
            connectors[0].status?.setup_state === 'connected'
        ) {
            setCurrentProgress(DATA_CUSTOMERS)
        } else if (userProfile?.profile?.first_name) {
            setCurrentProgress(STORE_SETTING)
        }
    }, [userProfile, connectors, dataBrandProfile])

    useEffect(() => {
        if (currentProgress === FIRST_AND_LAST_NAME) {
            setHeader(`Nice to have you, ${firstName}!`)
            setDescription('Did I get your name right?')
        } else if (currentProgress <= STORE_SETTING) {
            setHeader(`Connect your store`)
            setDescription(
                'This allows me to create marketing ideas for your store. Your data won’t be shared or sold.'
            )
        } else if (currentProgress < DATA_CUSTOMERS) {
            setHeader(`Store connected!`)
            setDescription('')
            return
        } else if (currentProgress < DATA_CUSTOMERS_DETAILS) {
            setHeader(`Tell me about your customers`)
            setDescription(
                'Describe them as if you’re talking to a friend. Data alone never tells the full story, so this helps me create better ideas for your brand.'
            )
        } else if (currentProgress < LAST_BUT_NOT_LEAST) {
            setHeader(`One last question!`)
            setDescription(
                'On average, how much do you spend in total per month on all online marketing? This helps me suggest budget-appropriate ideas!'
            )
        } else if (currentProgress >= LAST_BUT_NOT_LEAST) {
            setHeader(
                `You’re all set ${userProfile?.profile && `, ${userProfile.profile.first_name}`}!`
            )
            setDescription('')
        }
    }, [currentProgress])

    const handleSendStore = (progress: number): void => {
        setCurrentProgress(progress)
    }

    const renderGetData = (): JSX.Element => {
        if (user) {
            if (currentProgress < STORE_SETTING) {
                return (
                    <GetFirstAndLastName
                        sendData={handleSendStore}
                        user={user}
                        firstName={firstName}
                        setFirstName={(firstName: string) => {
                            setHeader(`Nice to have you, ${firstName}!`)
                            setFirstName(firstName)
                        }}
                    />
                )
            } else if (currentProgress < DATA_CUSTOMERS) {
                return (
                    <ConnectStore
                        sendData={handleSendStore}
                        connectors={connectors}
                    />
                )
            } else if (currentProgress < DATA_CUSTOMERS_DETAILS) {
                return <YourCustomersData sendData={handleSendStore} />
            } else if (currentProgress < LAST_BUT_NOT_LEAST) {
                return <MarketingSpend sendData={handleSendStore} />
            } else {
                return <LastButNotLeastView />
            }
        }
        return <div></div>
    }

    return (
        <div
            className={`bg-beige h-full w-full transition-colors text-dark-blue items-center flex flex-col min-h-screen ${currentProgress >= 100 && 'justify-center'}`}
        >
            {currentProgress < LAST_BUT_NOT_LEAST && (
                <OnBoardingProgress currentProgress={currentProgress} />
            )}
            <div
                className={`flex flex-col items-center justify-center w-500px ${currentProgress < LAST_BUT_NOT_LEAST && 'pt-14'} pb-60px`}
            >
                <div className="h-20 w-20 aspect-square relative">
                    {currentProgress >= LAST_BUT_NOT_LEAST ? (
                        <div className="h-[134px] aspect-square top-1/2 left-1/2 absolute -translate-y-1/2 -translate-x-1/2">
                            <Lottie
                                options={successOnboardingIconAnimation}
                                isClickToPauseDisabled={true}
                            />
                        </div>
                    ) : (
                        <div className="h-[200px] aspect-square top-1/2 left-1/2 absolute -translate-y-1/2 -translate-x-1/2">
                            <Lottie
                                options={onboardingIconAnimation}
                                isClickToPauseDisabled={true}
                            />
                        </div>
                    )}
                </div>
                <div className="font-header-1 text-center pt-4">{header}</div>
                {description !== '' && (
                    <div className="font-large-body-text pt-2 text-center">
                        {description}
                    </div>
                )}
                {renderGetData()}
            </div>
        </div>
    )
}

export default OnBoardingFillYourData
