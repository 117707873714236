import { useNavigate } from 'react-router-dom'
import LottieAnimation from '../LottieAnimation'
import { PATH } from '../../constants/path'
interface ISmartAIToolProps {
    pageWord: string
    sparkle?: boolean
    typePage?: SmartAIToolType
}
export enum SmartAIToolType {
    mainPage = 'Main page',
    detailPage = 'Detail Page',
}

export const SmartAITool = ({
    pageWord = '',
    sparkle = false,
    typePage = SmartAIToolType.mainPage,
}: ISmartAIToolProps) => {
    const navigate = useNavigate()

    return (
        <div className="pt-8 w-full">
            {typePage === SmartAIToolType.mainPage ? (
                <div className="flex flex-col items-center pt-7">
                    <img
                        src="/assets/svgs/smart-ai-needle.svg"
                        alt="tools-logo"
                        className="h-60px w-60px"
                    />
                    <div className="flex flex-row justify-center mt-4 relative">
                        <div className="font-header-1">{pageWord}</div>
                        {sparkle && (
                            <div className="h-35px w-35px absolute -right-10">
                                <LottieAnimation
                                    animationUrl="https://lottie.host/0559f2b3-6420-462a-a9c6-2678082b5dbf/rLI8WycGVx.json"
                                    loop={false}
                                />
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className="flex flex-row justify-center relative">
                    <div
                        className="text-center items-center absolute left-0 flex top-2 cursor-pointer"
                        onClick={() => navigate(PATH.enhanceYourAi)}
                    >
                        <img
                            src="/assets/icons/back-icon.svg"
                            alt="back-icon"
                        />
                        <div className="pl-2 font-subtitle">Back</div>
                    </div>
                    <div className="font-header-1 justify-center items-center flex">
                        {pageWord}
                    </div>
                </div>
            )}
        </div>
    )
}
