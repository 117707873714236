import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { PATH } from '../../constants/path'
import { NeedleButton } from '../../components/NeedleButton'
import Lottie from 'react-lottie'
import { pageNotFoundIconAnimation } from '../../components/LottieAnimation'
function PageNotFound() {
    const navigate = useNavigate()
    const lottieRef = useRef<any>()

    useEffect(() => {
        const anim = lottieRef.current?.anim
        if (anim) {
            const handleComplete = () => {
                anim.playSegments([87, 182], true)
            }

            anim.addEventListener('complete', handleComplete)
            anim.play()

            return () => {
                try {
                    anim.removeEventListener('complete', handleComplete)
                } catch {}
            }
        }
    }, [])

    return (
        <div className="text-dark-blue flex items-center text-center flex-col mb-96">
            <div className="h-113px aspect-square p-2">
                <Lottie
                    options={pageNotFoundIconAnimation}
                    isClickToPauseDisabled={true}
                    ref={lottieRef}
                />
            </div>
            <div className="font-header-1">Page not found</div>
            <div className="font-large-body-text pt-2 pb-6">
                The page you're looking for doesn't exist or has been moved.
            </div>
            <div>
                <NeedleButton
                    buttonCharacter={'Back to home'}
                    onClickFunction={() => navigate(PATH.homePage)}
                />
            </div>
        </div>
    )
}

export default PageNotFound
