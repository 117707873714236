import React, { useState } from 'react'
import { ProgressBar } from '../../../components/ProgressBar'
import Lottie from 'react-lottie'
import { doneIconAnimation } from '../../../components/LottieAnimation'
import {
    BRAND_MARKETING,
    DATA_CUSTOMERS,
    FIRST_AND_LAST_NAME,
    LAST_BUT_NOT_LEAST,
    STORE_SETTING,
} from '../constants'
interface OnBoardingProgressProps {
    currentProgress: number
}
export const OnBoardingProgress = ({
    currentProgress,
}: OnBoardingProgressProps) => {
    const [isHovered, setIsHovered] = useState<boolean>(false)
    const displayWordAnimation = `absolute -bottom-3 min-w-56 flex items-center justify-center transition-all duration-300 
                                    ${isHovered ? 'translate-y-3 opacity-100' : 'opacity-0'}`
    const circleImage = `h-8 aspect-square flex rounded-full items-center justify-center relative`
    const doneIcon = 'h-20 aspect-square absolute'
    return (
        <div
            className={`flex flex-row justify-between w-728px relative mt-52px font-small-text text-dark-blue`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="w-728px absolute top-1/2 -translate-y-1/2">
                <ProgressBar
                    value={currentProgress}
                    max={100}
                    backgroundColor="bg-beige-outline"
                    backgroundFill="gradient-theme-background"
                    height="h-1"
                />
            </div>
            <div className="w-full justify-between flex flex-row z-10">
                <div className={`gradient-theme-background ${circleImage}`}>
                    <div className={displayWordAnimation}>
                        <div>Sign up</div>
                    </div>
                    <div
                        className={`bg-beige h-6 aspect-square rounded-full`}
                    />
                    {currentProgress > FIRST_AND_LAST_NAME && (
                        <div className={doneIcon}>
                            <Lottie
                                options={doneIconAnimation}
                                isClickToPauseDisabled={true}
                            />
                        </div>
                    )}
                </div>
                <div
                    className={`${currentProgress >= STORE_SETTING ? 'gradient-theme-background' : 'bg-beige-outline'} 
                     ${circleImage}`}
                >
                    <div className={displayWordAnimation}>
                        <div>Connect Store</div>
                    </div>
                    <div
                        className={`bg-beige h-6 aspect-square rounded-full`}
                    />
                    {currentProgress > STORE_SETTING && (
                        <div className={doneIcon}>
                            <Lottie
                                options={doneIconAnimation}
                                isClickToPauseDisabled={true}
                            />
                        </div>
                    )}
                </div>
                <div
                    className={`${currentProgress >= DATA_CUSTOMERS ? 'gradient-theme-background' : 'bg-beige-outline'} 
                     ${circleImage}`}
                >
                    <div className={displayWordAnimation}>
                        <div>Your customers</div>
                    </div>
                    <div
                        className={`bg-beige h-6 aspect-square rounded-full`}
                    />
                    {currentProgress > DATA_CUSTOMERS && (
                        <div className={doneIcon}>
                            <Lottie
                                options={doneIconAnimation}
                                isClickToPauseDisabled={true}
                            />
                        </div>
                    )}
                </div>
                <div
                    className={`${currentProgress >= BRAND_MARKETING ? 'gradient-theme-background' : 'bg-beige-outline'} 
                     ${circleImage}`}
                >
                    <div className={displayWordAnimation}>
                        <div>Marketing spend</div>
                    </div>
                    <div
                        className={`bg-beige h-6 aspect-square rounded-full`}
                    />
                    {currentProgress >= LAST_BUT_NOT_LEAST && (
                        <div className={doneIcon}>
                            <Lottie
                                options={doneIconAnimation}
                                isClickToPauseDisabled={true}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default OnBoardingProgress
