export const colorList = [
    'bg-[#FF457D]',
    'bg-[#FF7245]',
    'bg-[#FFB31E]',
    'bg-[#41CE71]',
    'bg-[#3698C2]',
    'bg-[#8653D9]',
]
export const userBackground = (userLetter: string | undefined): string => {
    const FAVORITE_COLOR = 3
    const NUMBER_COLOR = 6
    if (userLetter) {
        const colorPosition = Math.ceil(
            (userLetter.toLowerCase().charCodeAt(0) - 'a'.charCodeAt(0) + 1) /
                NUMBER_COLOR
        )
        if (colorPosition > NUMBER_COLOR || colorPosition < 0)
            return colorList[FAVORITE_COLOR]
        return colorList[colorPosition]
    }
    return colorList[FAVORITE_COLOR]
}
