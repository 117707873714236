import Dialog from '@mui/material/Dialog'
import React from 'react'
import { ButtonType, NeedleButton } from '../../../components/NeedleButton'

interface AssetsDialogProps {
    isOpen: boolean
    setIsOpen: any
    confirmFunction: any
    isVideo?: boolean
    isSubmit?: boolean
}

export const AssetsDialog = ({
    isOpen,
    setIsOpen,
    confirmFunction,
    isVideo = false,
    isSubmit = false,
}: AssetsDialogProps) => {
    const keyword = isVideo ? 'assets' : 'images'
    return (
        <Dialog open={isOpen}>
            <div className="relative w-480px">
                <div className="text-dark-blue p-6">
                    <div className="font-header-2">
                        {isSubmit
                            ? `Submit ${keyword}?`
                            : `Proceed without additional ${keyword}?`}
                    </div>
                    <div className="font-body-text pt-10px">
                        {isSubmit
                            ? `You can’t edit this afterwards. `
                            : `The more relevant ${keyword} you add now, the better the
                        quality of my output.`}
                    </div>
                </div>
                <div className="bg-beige-outline h-1px w-full absolute left-0" />
                <div className="flex flex-row items-end py-4 pr-6 justify-end">
                    <div>
                        <NeedleButton
                            buttonCharacter="Cancel"
                            onClickFunction={() => setIsOpen(false)}
                            buttonType={ButtonType.White}
                        />
                    </div>
                    <div className="pl-2">
                        <NeedleButton
                            buttonCharacter={isSubmit ? 'Submit' : 'Confirm'}
                            onClickFunction={() => confirmFunction()}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default AssetsDialog
