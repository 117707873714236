import React, { useEffect, useState } from 'react'
import './TextSection.scss'
import { LinkType } from '../../views/BrandProfilePage/type'
import CheckBoxNeedle from '../CheckBoxNeedle'
import { EMAIL_DATA } from '../../views/BrandProfilePage/constants'
interface CardTypeLinkProps {
    description?: string
    setCurrentData: (value: React.SetStateAction<LinkType>) => void
    currentData: LinkType
    setIsDisable: React.Dispatch<React.SetStateAction<boolean>>
}

function CardTypeLink({
    description,
    setCurrentData,
    currentData,
    setIsDisable,
}: CardTypeLinkProps) {
    const [link, setLink] = useState<string>(currentData.link)
    const [accessMail, setAccessMail] = useState<string[]>(
        currentData.accessMail
    )

    useEffect(() => {
        if (link.trim() !== '' || accessMail.length > 0) {
            const dataLink: LinkType = {
                link: link,
                accessMail: accessMail,
            }
            setCurrentData(dataLink)
            setIsDisable(false)
        } else {
            setIsDisable(true)
        }
    }, [link, accessMail])

    return (
        <div className="mx-6 mt-6 mb-2">
            <textarea
                rows={3}
                id="first_name"
                className="border border-beige-outline font-body-text
                py-3 px-4 w-full rounded-lg focus:outline-dark-blue"
                placeholder={description}
                value={link}
                onChange={(e) => {
                    setLink(e.target.value)
                }}
            />
            {EMAIL_DATA.map((emailType) => {
                return (
                    <CheckBoxNeedle
                        key={emailType}
                        keyName={emailType}
                        selectedData={accessMail}
                        setData={setAccessMail}
                    />
                )
            })}
        </div>
    )
}

export default CardTypeLink
