import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { User, sendEmailVerification } from 'firebase/auth'
import {
    AuthService,
    firebaseAuthErrorCodes,
    LoginType,
    SignUpType,
} from '../firebase/authentication'
import { auth } from '../firebase/client'
import { Services } from '../services'
import {
    EVENT_TRACK_SIGNED_IN,
    EVENT_TRACK_SIGNED_UP,
    segmentIdentify,
    segmentTrackEvent,
} from '../services/segments'
import { DISABLE_SEGMEMT_KEY } from '../constants'
import { WorkspaceServices } from '../services/workspace/workspace.services'
import { updateUserProfile } from '../hooks/useUserProfile'
import { mutate } from 'swr'

export interface UserInfo {
    uid: string
    email: string
    fivetranGroupId: string
    role?: string
    shopify_name?: string
}

export interface AuthState {
    user: User | null
    userInfo: UserInfo | null
    authStatus: 'idle' | 'loggedin' | 'loading' | 'error' | 'first-login'
    authAction?: 'login' | 'sign-up' | 'forgot-password'
    updateUserInfoStatus: 'idle' | 'loading' | 'error' | 'success'
    errorMessage: string
}
const initialState = {
    user: auth.currentUser,
    userInfo: null,
    authStatus: 'idle',
    updateUserInfoStatus: 'idle',
    errorMessage: '',
} as unknown as AuthState

const DEFAULT_DELAY_TIME = 1000
const delayLoading = (ms: number = DEFAULT_DELAY_TIME) =>
    new Promise((resolve) => setTimeout(resolve, ms))

export const setUserInfo = createAction<UserInfo>('auth/setUserInfo')
export const clearStatus = createAction('auth/clearStatus')
export const logOut = createAction('auth/logout')

export const getUserInfo = createAsyncThunk(
    'auth/getUserInfo',
    async (thunkAPI) => {
        const userInfo = (await Services.getUserProfile()).data as UserInfo
        return { userInfo }
    }
)

export const verifyEmail = createAsyncThunk(
    'auth/verifyEmail',
    async (data: any, thunkAPI) => {
        try {
            await sendEmailVerification(data['user'])
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const login = createAsyncThunk<any, {}, any>(
    'auth/login',
    async (data: any, thunkAPI) => {
        let user: User | null = null
        switch (data['loginType']) {
            case LoginType.Google:
                user = await AuthService.loginWithGoogle(data['loginType'])
                try {
                    const added = await WorkspaceServices.acceptAllInvitation()
                    if (added) {
                        window.location.href = '/'
                    }
                } catch (e) {
                    console.error(e)
                }
                const isNewUser =
                    user.metadata.creationTime === user.metadata.lastSignInTime

                if (isNewUser) {
                    try {
                        segmentIdentify({
                            user_id: user?.uid ?? '',
                            data: {
                                email: user?.email,
                            },
                        })
                        segmentTrackEvent({
                            event_name: EVENT_TRACK_SIGNED_UP,
                            data: {
                                user_id: user?.uid,
                                email: user?.email,
                            },
                        })
                    } catch (e) {}
                }
                break
            case LoginType.EmailAndPassword:
                user = await AuthService.loginWithEmailAndPassword(
                    data['email'],
                    data['password']
                )
                break
        }
        let userProfile
        try {
            userProfile = (await Services.getUserProfile()).data
        } catch (e) {
            userProfile = {}
        }
        let workspace = await WorkspaceServices.getWorkspaceSetting()
        return {
            user: { uid: user?.uid, email: user?.email, ...userProfile },
            workspace,
        }
    }
)

export const signUp = createAsyncThunk(
    'auth/signUp',
    async (
        data: {
            signUpType: SignUpType
            email: string
            password: string
            profile?: any
        },
        thunkAPI
    ) => {
        let user
        try {
            switch (data['signUpType']) {
                case SignUpType.EmailAndPassword:
                    user = await AuthService.signUpWithEmailAndPassword(
                        data['email'],
                        data['password']
                    )
                    try {
                        const [added, _] = await Promise.all([
                            WorkspaceServices.acceptAllInvitation(),
                            updateUserProfile({
                                profile: data['profile'],
                            }),
                        ])
                        mutate(`/use-user-profile/${user?.uid}`)
                        if (added) {
                            window.location.href = '/'
                        }
                    } catch (e) {
                        console.error(e)
                    }
                    break
            }
            return { user: { uid: user?.uid, email: user?.email } }
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

const authReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(getUserInfo.fulfilled, (state, action) => {
            state.userInfo = action.payload.userInfo
        })
        .addCase(verifyEmail.fulfilled, (state) => {
            state.errorMessage = ''
        })
        .addCase(verifyEmail.rejected, (state) => {
            state.errorMessage = 'Fail to send email verification'
        })
        .addCase(setUserInfo, (state, action) => {
            state.userInfo = action.payload
        })
        .addCase(login.pending, (state) => {
            state.authStatus = 'loading'
            state.authAction = 'login'
        })
        .addCase(login.rejected, (state, action) => {
            const error: any = action.error
            state.authStatus = 'error'
            state.errorMessage =
                firebaseAuthErrorCodes[error.code] ??
                'An error occured. Please try again.'
        })
        .addCase(login.fulfilled, (state, action) => {
            const user = action.payload.user
            const workspace = action.payload.workspace
            state.userInfo = user
            state.authStatus = 'first-login'
            try {
                segmentIdentify({
                    user_id: user?.uid ?? '',
                    data: {
                        email: user?.email,
                        timezone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                    },
                })
                segmentTrackEvent({
                    event_name: EVENT_TRACK_SIGNED_IN,
                    data: {
                        user_id: user?.uid,
                        email: user?.email,
                        shopify_name: workspace?.shopify_name,
                        workspace_id: user?.default_workspace,
                        workspace_creator_email: workspace?.creator_email,
                    },
                })
            } catch (e) {
                console.log('Segment tracking failed', e)
            }
        })
        .addCase(signUp.pending, (state) => {
            state.authStatus = 'loading'
            state.authAction = 'sign-up'
        })
        .addCase(signUp.rejected, (state, action) => {
            const error: any = action.payload
            if (
                error !== null &&
                !error.code.includes('cancel') &&
                !error.code.includes('by-user')
            ) {
                state.authStatus = 'error'
                state.errorMessage =
                    firebaseAuthErrorCodes[error.code] ??
                    'An error occured. Please try again.'
            } else {
                state.authStatus = 'idle'
            }
        })
        .addCase(signUp.fulfilled, (state, action) => {
            state.authStatus = 'loggedin'
            const user = action?.payload?.user

            try {
                segmentIdentify({
                    user_id: user?.uid ?? '',
                    data: {
                        email: user?.email,
                    },
                })
                segmentTrackEvent({
                    event_name: EVENT_TRACK_SIGNED_UP,
                    data: {
                        user_id: user?.uid,
                        email: user?.email,
                    },
                })
            } catch (e) {
                console.log('Segment tracking failed', e)
            }
        })
        .addCase(clearStatus, (state) => {
            state.errorMessage = ''
        })
        .addCase(logOut, (state) => {
            AuthService.logout()
            localStorage.clear()
            window.location.href = '/'
            setTimeout(() => {
                const staffMode = localStorage.getItem(DISABLE_SEGMEMT_KEY)
                localStorage.clear()
                if (staffMode)
                    localStorage.setItem(DISABLE_SEGMEMT_KEY, staffMode)
            }, 1000)
        })
})

export default authReducer
