import { useEffect, useRef, useState } from 'react'
import {
    ActionData,
    GRID,
    ListEvent,
    OPACITY_LOST,
    SUB_CARD_HEIGHT,
    SUB_CARD_SHIFT,
    SUB_CARD_WIDTH_LOST,
} from './constants'
import { IdeasDetail } from '../../components/DetailCardView/IdeasDetail'
import './BrainstormBox.scss'
import GetDataCard from '../../components/DetailCardView/GetDataCard'
import { Sections_Data } from '../BrandProfilePage/type'
interface DropdownListViewProps {
    ideasGroups?: ListEvent
    itemList: Sections_Data[] | ActionData[]
    isShowView: boolean
    isFullSize?: boolean
    isActionsPage?: boolean
    isHoverAnimated?: boolean
}
export const DropdownListView = ({
    ideasGroups,
    itemList,
    isShowView,
    isHoverAnimated = true,
    isFullSize = false,
    isActionsPage = false,
}: DropdownListViewProps) => {
    const MAX_ANIMATION_CARD = 3
    const addSpan =
        itemList.length > MAX_ANIMATION_CARD
            ? MAX_ANIMATION_CARD
            : itemList.length
    let pageDisplay = 'flex flex-col'
    const [firstCardHeight, setFirstCardHeight] = useState(0)
    const [isStartAnimated, setIsStartAnimated] = useState<boolean>(false)

    const ref = useRef<HTMLDivElement>(null)

    if (!isFullSize) {
        pageDisplay = 'absolute pt-6'
    }

    useEffect(() => {
        setIsStartAnimated(isShowView)
    }, [isShowView])

    const resizeObserver = new ResizeObserver(() => {
        if (ref.current) {
            setFirstCardHeight(ref.current.clientHeight)
        }
    })

    useEffect(() => {
        if (ref.current) {
            resizeObserver.observe(ref.current)
            return () => resizeObserver.disconnect()
        }
    }, [])

    const cardStyle = (index: any): React.CSSProperties | undefined => {
        let opacityNumber
        if (isShowView || index === 0) {
            opacityNumber = 0
        } else {
            opacityNumber = 1 - index * OPACITY_LOST
        }
        return {
            height: `${firstCardHeight ? firstCardHeight : undefined}px`,
            top: isShowView
                ? `${index * firstCardHeight + GRID}px`
                : `${index * SUB_CARD_HEIGHT}px`,
            opacity: opacityNumber,
            left: isShowView ? '0' : `${SUB_CARD_SHIFT * index}px`,
            width: isShowView
                ? '100%'
                : `calc(100% - ${index * SUB_CARD_WIDTH_LOST}px)`,
            zIndex: isStartAnimated ? '-1' : itemList.length - index,
        }
    }

    const GroupItem = () => {
        return itemList.map(
            (action, index) =>
                (isShowView || index === 0) && (
                    <div
                        key={action.id ? action.id : action.name}
                        className={`rounded-lg mt-4 shadow-click-card max-w-728px 
                            transition-all ease-in-out flex flex-col justify-between bg-white w-full duration-300
                            ${isStartAnimated || index === 0 ? 'opacity-100' : 'opacity-0'}
                            ${isHoverAnimated && 'hover:shadow-click-card-hover hover:-translate-y-1 cursor-pointer'}
                            ${index === 0 && 'z-40'}`}
                        ref={index === 0 ? ref : undefined}
                    >
                        {detailListView(action)}
                    </div>
                )
        )
    }

    const detailListView = (action: any) => {
        if (ideasGroups) {
            return (
                <IdeasDetail
                    action={action}
                    ideasGroups={ideasGroups}
                    isActionsPage={isActionsPage}
                    isFullSize={isFullSize}
                    productData={
                        action.metadata?.products
                            ? action.metadata.products
                            : []
                    }
                />
            )
        } else {
            return (
                <GetDataCard
                    card={action.name}
                    description={action.description}
                    placeHolder={action.placeHolder}
                    stateKey={action.stateKey}
                    type={action.type}
                />
            )
        }
    }

    const AnimationLayer = () => {
        return itemList.map(
            (action, index) =>
                index < MAX_ANIMATION_CARD && (
                    <div
                        key={action.id ? action.id : action.name}
                        className={`rounded-lg pt-4 pb-6 px-6 mt-4 shadow-click-card max-w-728px
                        transition-all duration-300 flex flex-col justify-between bg-white w-full cursor-default
                        ${pageDisplay}`}
                        style={cardStyle(index)}
                    />
                )
        )
    }

    return (
        <div className="flex flex-col relative">
            {GroupItem()}
            {!isFullSize && (
                <div>
                    {AnimationLayer()}
                    {!isShowView && (
                        <div
                            style={{
                                height: `${(addSpan - 1) * SUB_CARD_HEIGHT}px`,
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    )
}
