import { create } from 'zustand'
import { Idea, IdeaStatus } from '../utils/needleEnum'
export interface IdeasStoreProps {
    currency: string
    needleNewIdeas: Idea[]
    needleIdeas: Idea[]
    needleDoing: Idea[]
    needleDone: Idea[]
    needleArchive: Idea[]
    needleAction: Idea[]
    needleReview: Idea[]
    needleIsRead: Idea[]
    needleAllIdea: number
    isShouldReload: boolean
    isLoaded: boolean
    setShouldReload: (isReload: boolean) => void
    setupDataIdeas: (newListIdea: Idea[], currency: string) => void
}

const ideasStore = create<IdeasStoreProps>((set, get) => ({
    currency: 'US$',
    needleNewIdeas: [],
    needleIdeas: [],
    needleDoing: [],
    needleDone: [],
    needleArchive: [],
    needleAction: [],
    needleReview: [],
    needleIsRead: [],
    needleAllIdea: 0,
    isShouldReload: false,
    isLoaded: false,
    setShouldReload: (isReload: boolean) => {
        set({ isShouldReload: isReload })
    },
    setupDataIdeas: (newListIdea: Idea[], currency: string) => {
        const newIdeas: Idea[] = []
        const doneIdeas: Idea[] = []
        const doingIdeas: Idea[] = []
        const actionIdeas: Idea[] = []
        const archiveIdeas: Idea[] = []
        const reviewIdeas: Idea[] = []
        const readIdeas: Idea[] = []
        const ideas: Idea[] = []
        const sortedIdea = sortIdeasByRevenue(newListIdea)
        sortedIdea.forEach((idea: Idea) => {
            if (!idea.feedback && idea.status === IdeaStatus.read) {
                reviewIdeas.push(idea)
            }
            switch (idea.status) {
                case IdeaStatus.read:
                    readIdeas.push(idea)
                    break
                case IdeaStatus.new:
                    newIdeas.push(idea)
                    break
                case IdeaStatus.archived:
                    archiveIdeas.push(idea)
                    break
                case IdeaStatus.blocked:
                case IdeaStatus.new_asset:
                case IdeaStatus.read_asset:
                    actionIdeas.push(idea)
                    break
                case IdeaStatus.needle_is_working:
                    doingIdeas.push(idea)
                    break
                case IdeaStatus.done:
                    doneIdeas.push(idea)
                    break
                default:
                    ideas.push(idea)
                    break
            }
        })
        set({
            needleNewIdeas: newIdeas,
            needleIdeas: ideas,
            needleDoing: doingIdeas,
            needleDone: doneIdeas,
            needleArchive: archiveIdeas,
            needleAction: actionIdeas,
            needleReview: reviewIdeas,
            needleIsRead: readIdeas,
            needleAllIdea:
                newIdeas.length +
                readIdeas.length +
                archiveIdeas.length +
                actionIdeas.length +
                doneIdeas.length +
                doingIdeas.length,
            currency: currency ? currency : 'US$',
            isLoaded: true,
        })
    },
}))

function sortIdeasByRevenue(ideas: Idea[]): Idea[] {
    return ideas.sort((a, b) => {
        if (a.idea_revenue == null && b.idea_revenue == null) {
            return 0
        }
        if (a.idea_revenue == null) {
            return -1
        }
        if (b.idea_revenue == null) {
            return 1
        }
        return b.idea_revenue - a.idea_revenue
    })
}
export default ideasStore
