import { useState } from 'react'
interface NeedleImageProps {
    src: string | undefined
    alt: string | undefined
    className?: string
    isNoBorder?: boolean
}
export const NeedleImage = ({
    src,
    alt,
    className,
    isNoBorder = false,
}: NeedleImageProps) => {
    const [imgSrc, setImgSrc] = useState<string | undefined | null>(src)

    const handleError = () => {
        setImgSrc(null)
    }

    return imgSrc ? (
        <img
            src={imgSrc}
            alt={alt}
            className={`${className} object-cover`}
            onError={handleError}
        />
    ) : (
        <div
            className={`${className} bg-white flex items-center justify-center ${!isNoBorder && 'border border-beige-outline'}`}
        />
    )
}
