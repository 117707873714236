import React, { useState } from 'react'
import { ButtonType, NeedleButton } from '../../../components/NeedleButton'
import Select from 'react-select'
import {
    CustomOptionSmall,
    SingleValue,
    customStylesDropDownAssets,
    dataOptions,
    getLinkDropbox,
    getLinkGoogleDrive,
} from '../constants'
import AssetsDialog from '../Dialog/AssetsDialog'
interface UploadAssetBlockedProps {
    skipBlockedList: (isSubmit: boolean, driveLink: string) => void
    isVideo?: boolean
}

export const UploadAssetBlocked = ({
    skipBlockedList,
    isVideo,
}: UploadAssetBlockedProps) => {
    const [selectedOptions, setSelectedOptions] = useState<any>()
    const [driveLink, setDriveLink] = useState<string>('')
    const [isDisable, setIsDisable] = useState<boolean>(true)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isSubmit, setIsSubmit] = useState<boolean>(false)

    const handleChange = (selected: any): void => {
        setSelectedOptions(selected)
    }

    return (
        <div className="relative font-body-text text-dark-blue">
            <AssetsDialog
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                confirmFunction={() => skipBlockedList(isSubmit, driveLink)}
                isVideo={isVideo}
                isSubmit={isSubmit}
            />
            <div className="flex flex-row justify-between">
                <div className="pr-6 ">
                    <div className="font-header-3">
                        Upload additional assets
                    </div>
                    {isVideo && (
                        <div className="pt-2">
                            <div className="font-subtitle">
                                Free text header
                            </div>
                        </div>
                    )}
                </div>
                <div>
                    <NeedleButton
                        buttonCharacter="Skip"
                        buttonType={ButtonType.White}
                        onClickFunction={() => {
                            setIsSubmit(false)
                            setIsOpen(true)
                        }}
                    />
                </div>
            </div>
            {isVideo && (
                <div>
                    <div className="font-body-text pt-2">
                        Free text that Needle admin can enter goes here. Free
                        text that Needle admin can enter goes here. Free text
                        that Needle admin can enter goes here.
                    </div>
                    <div className="mt-2 border border-blue-theme rounded-lg py-2 px-4 bg-blue-theme bg-opacity-10 flex flex-wrap flex-row">
                        <div>Need help with this? &nbsp;</div>
                        <div className="underline text-blue-theme cursor-pointer">
                            Send us a request
                        </div>
                        <div>
                            &nbsp; and we’ll reach out with additional services
                        </div>
                        <div>that can help you get the footage you need.</div>
                    </div>
                    <div className="font-subtitle pt-6">Instructions:</div>
                </div>
            )}
            <div
                className="font-body-text pt-2 pb-6"
                dangerouslySetInnerHTML={{
                    __html: `
                            <div>
                                    <p>Share additional images or videos you think will be needed for this concept via
                                    <strong>Google Drive </strong> or <strong>Dropbox</strong>.
                                    <br><br>
                                    Click “Submit” once you’re ready, and I’ll begin working on this. </p>
                            </div>
                        `,
                }}
            />
            <Select
                isClearable={false}
                options={dataOptions}
                isSearchable={false}
                value={selectedOptions}
                onChange={handleChange}
                placeholder="Select app"
                components={{
                    SingleValue,
                    IndicatorSeparator: () => null,
                    Option: CustomOptionSmall,
                }}
                styles={customStylesDropDownAssets}
                backspaceRemovesValue={false}
            />
            {selectedOptions && (
                <div className="py-6">
                    <div className="font-subtitle">Instructions:</div>
                    <div
                        className="font-body-text pt-2"
                        dangerouslySetInnerHTML={{
                            __html:
                                selectedOptions.value === 'Google Drive'
                                    ? getLinkGoogleDrive
                                    : getLinkDropbox,
                        }}
                    />
                    <textarea
                        rows={1}
                        id="first_name"
                        className="border border-beige-outline font-body-text
                                py-3 px-4 w-full rounded-lg focus:outline-dark-blue mt-2"
                        placeholder={`Paste ${selectedOptions.value} folder link`}
                        value={driveLink}
                        onChange={(e) => {
                            if (e.target.value.trim() === '') {
                                setIsDisable(true)
                            } else {
                                setIsDisable(false)
                            }
                            setDriveLink(e.target.value)
                        }}
                    />
                    <div className="font-link-small-text text-blue-theme underline ">
                        See video guide
                    </div>
                    <div className="flex justify-end">
                        <div>
                            <NeedleButton
                                buttonCharacter="Submit"
                                buttonType={ButtonType.Black}
                                onClickFunction={() => {
                                    setIsSubmit(true)
                                    setIsOpen(true)
                                }}
                                isDisable={isDisable}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default UploadAssetBlocked
