import { IdeaMetadata } from '../../utils/needleEnum'

export enum EventType {
    NewIdeas = 'newIdeas',
    Doing = 'doing',
    ActionRequired = 'actionRQ',
    Ideas = 'idea',
    Done = 'done',
    Empty = 'empty',
    Archived = 'archived',
    Blocked = 'blocked',
    AssetReady = 'assetReady',
}
export const enum NotDisplayPage {
    HOMEPAGE = 'homepage',
    ACTIONS_PAGE = 'action page',
}
export const LIST_OLD_REVIEW: ListEvent = {
    name: 'Done',
    smallName: 'Done',
    type: EventType.Done,
    isShow: false,
    isDropDown: true,
    isNoDisplay: NotDisplayPage.HOMEPAGE,
    link: '',
    actions: [],
}

export enum IdeasList {
    ActionRequired = 0,
    Doing = 1,
    NewIdeas = 2,
    Done = 3,
}

export const LIST_EVENT_HOMEPAGE: ListEvent[] = [
    {
        name: 'Your action required',
        smallName: 'Your action required',
        isShow: false,
        isDropDown: false,
        link: '/result/ideas',
        type: EventType.ActionRequired,
        actions: [],
    },
    {
        name: 'I’m working on these',
        smallName: 'I’m working on this',
        isShow: false,
        isDropDown: false,
        type: EventType.Doing,
        link: '/result/ideas',
        actions: [],
    },
    {
        name: 'Check out these new ideas',
        smallName: 'Check out this new idea',
        type: EventType.NewIdeas,
        isShow: false,
        isNoDisplay: NotDisplayPage.ACTIONS_PAGE,
        link: '/result/ideas',
        actions: [],
    },
    {
        name: 'Done',
        smallName: 'Done',
        type: EventType.Done,
        isShow: false,
        isDropDown: true,
        isNoDisplay: NotDisplayPage.HOMEPAGE,
        link: '/result/ideas',
        actions: [],
    },
]
export const LIST_SHOW_VIEW = [false, false, false]
export type ListEvent = {
    name: string
    smallName?: string
    isNoDisplay?: NotDisplayPage
    isShow: boolean
    isDropDown?: boolean
    link: string
    type: EventType
    actions: ActionData[]
}

export type ActionData = {
    name: string
    description: string
    type: EventType
    assets: string[]
    dateEvent?: string
    id?: any
    metadata?: IdeaMetadata
}

export const BOX_HEIGHT = 154
export const CARD_HEIGHT = 140
export const OPACITY_LOST = 0.2
export const SUB_CARD_WIDTH_LOST = 20
export const SUB_CARD_HEIGHT = 8
export const SUB_CARD_SHIFT = SUB_CARD_WIDTH_LOST / 2
export const GRID = 10
