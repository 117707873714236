import React from 'react'
import { ButtonType, NeedleButton } from '../../../components/NeedleButton'
import { PATH } from '../../../constants/path'
import { useNavigate } from 'react-router-dom'

export const BrandProfileBlocked = () => {
    const navigate = useNavigate()
    return (
        <div>
            <div className="font-header-3">
                Fill in brand profile essentials
            </div>
            <div
                className="font-body-text pt-2 pb-6"
                dangerouslySetInnerHTML={{
                    __html: `
                    <div>
                            <p>Please make sure that the 
                            <strong>“Brand profile essentials”</strong> 
                            section of the Brand Profile page are filled up.</p>
                    </div>
                    `,
                }}
            />
            <div className="flex justify-end">
                <div>
                    <NeedleButton
                        buttonCharacter="Fill in brand profile essentials"
                        buttonType={ButtonType.Black}
                        onClickFunction={() => navigate(PATH.brandProfilePage)}
                    />
                </div>
            </div>
        </div>
    )
}

export default BrandProfileBlocked
