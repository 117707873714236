import { axiosInstance, handleResponse, getOptions } from '../api'
import config from '../api/config'
import {
    Invitation,
    Workspace,
    WorkspaceRawUserInput,
    WorkspaceSettings,
} from './types'

export const WorkspaceServices = {
    createNewWorkspace: async (id?: string) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.post(config.WORKSPACE, {}, options)
        ) as WorkspaceSettings | undefined
    },

    updateWorkspace: async (id: any, data: any) => {
        const workspace_id = id ?? 'default'
        const options = await getOptions()
        return handleResponse(
            axiosInstance.patch(
                config.WORKSPACE + `/${workspace_id}`,
                data,
                options
            )
        ) as Workspace | undefined
    },

    getWorkspaceSetting: async (id?: string) => {
        const workspace_id = id ?? 'default'
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(
                config.WORKSPACE + `/${workspace_id}/settings`,
                options
            )
        ) as WorkspaceSettings | undefined
    },

    getEmailWorkspaces: async (email: string) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(
                config.WORKSPACE + `/check-user-workspace/${email}`,
                options
            )
        ) as any[]
    },

    removeUserFromWorkspace: async ({
        user_email,
        workspace_id,
    }: {
        user_email: string
        workspace_id?: string
    }) => {
        const workspaceId = workspace_id ?? 'default'
        const options = await getOptions()
        const res = (await handleResponse(
            axiosInstance.delete(
                config.WORKSPACE + `/${workspaceId}/${user_email}`,
                options
            )
        )) as WorkspaceSettings
        return res
    },

    changeUserRoleFromWorkspace: async ({
        user_email,
        workspace_id,
        role,
    }: {
        user_email: string
        workspace_id?: string
        role: string
    }) => {
        const workspaceId = workspace_id ?? 'default'
        const options = await getOptions()
        // TODO implement the api call here
        const res = (await handleResponse(
            axiosInstance.patch(
                config.WORKSPACE + `/${workspaceId}/role`,
                { user_email, role },
                options
            )
        )) as WorkspaceSettings
        return res
    },

    addUsersToWorkspace: async (data: {
        user_emails: string[]
        workspace_id?: string
        role: string
    }) => {
        const { workspace_id } = data
        const workspaceId = workspace_id ?? 'default'
        const options = await getOptions()
        return handleResponse(
            axiosInstance.post(
                config.WORKSPACE + `/${workspaceId}/add_users`,
                data,
                options
            )
        ) as WorkspaceSettings
    },

    getUserInvitations: async () => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(config.INVITATIONS, options)
        ) as Invitation[]
    },

    acceptInvitation: async (workspace_id: string) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.post(
                config.INVITATIONS + `/${workspace_id}/accept`,
                {},
                options
            )
        ) as Invitation[]
    },

    acceptAllInvitation: async () => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.post(config.INVITATIONS + `/accept/all`, {}, options)
        ) as Invitation[]
    },

    updateWorkspaceSettings: async ({
        workspace_id,
        data,
    }: {
        workspace_id?: string
        data: any
    }) => {
        const workspaceId = workspace_id ?? 'default'
        const options = await getOptions()
        const res = (await handleResponse(
            axiosInstance.patch(
                config.WORKSPACE + `/${workspaceId}/settings`,
                data,
                options
            )
        )) as WorkspaceSettings
        return res
    },

    createWorkspaceRawUserInput: async ({
        workspace_id,
        data,
    }: {
        workspace_id?: string | undefined
        data: any
    }) => {
        const workspaceId = workspace_id ?? 'default'
        const options = await getOptions()
        ;(await handleResponse(
            axiosInstance.post(
                config.WORKSPACE + `/${workspaceId}/raw_user_input`,
                data,
                options
            )
        )) as WorkspaceRawUserInput
    },

    getWorkspaceCreditHistory: async () => {
        const options = await getOptions()
        return await handleResponse(
            axiosInstance.get(config.WORKSPACE + `/credit-history`, options)
        )
    },
}
