import React, { useCallback, useEffect, useState } from 'react'
import './TextSection.scss'
import ChatWithNeedleHelp from './ChatWithNeedleHelp'
import { ButtonType, NeedleButton } from '../NeedleButton'
interface CardTypeMultipleTextProps {
    placeHolder?: string
    setCurrentListText: (value: React.SetStateAction<string | string[]>) => void
    currentListText: string[]
    isDisplayNeedleHelp?: boolean
    setIsDisable: React.Dispatch<React.SetStateAction<boolean>>
}

function CardTypeMultipleText({
    placeHolder,
    setCurrentListText,
    currentListText,
    isDisplayNeedleHelp = false,
    setIsDisable,
}: CardTypeMultipleTextProps) {
    const [editedTextList, setEditedTextList] =
        useState<string[]>(currentListText)
    const [isCloseAble, setIsCloseAble] = useState<boolean>(
        currentListText.length > 2
    )

    const editText = useCallback(
        (index: number, data: string): void => {
            const currentTextList = [...editedTextList]
            currentTextList[index] = data
            setCurrentListText(currentTextList)
            setEditedTextList(currentTextList)
        },
        [editedTextList]
    )

    const addNewItem = useCallback((): void => {
        const newList = [...editedTextList, '']
        setEditedTextList(newList)
        setIsDisable(true)
    }, [editedTextList])

    const removeText = (index: number): void => {
        const newList = [...editedTextList]
        newList.splice(index, 1)
        setCurrentListText(newList)
        setEditedTextList(newList)
    }

    useEffect(() => {
        if (editedTextList.length === 1) {
            setIsCloseAble(false)
        } else {
            setIsCloseAble(true)
        }
        let isDisable = false
        editedTextList.forEach((stringData: string) => {
            if (stringData.trim() === '') {
                isDisable = true
            }
        })
        setIsDisable(isDisable)
    }, [editedTextList])

    return (
        <div className="mx-6 mt-4 mb-2">
            {editedTextList.map((text, index) => {
                return (
                    <div
                        className="flex flex-row items-center pb-4"
                        key={index}
                    >
                        <textarea
                            rows={1}
                            id="first_name"
                            className="border border-beige-outline font-body-text 
                            py-3 px-4 w-full rounded-lg focus:outline-dark-blue"
                            placeholder={placeHolder}
                            value={text}
                            onChange={(e) => editText(index, e.target.value)}
                        />
                        {isCloseAble && (
                            <div onClick={() => removeText(index)}>
                                <img
                                    src="/assets/icons/close-icon.svg"
                                    alt="close-icon"
                                    className="ml-2 h-4 w-4 cursor-pointer"
                                />
                            </div>
                        )}
                    </div>
                )
            })}
            {isDisplayNeedleHelp && <ChatWithNeedleHelp />}
            <div className="flex justify-center">
                <div>
                    <NeedleButton
                        onClickFunction={addNewItem}
                        buttonCharacter="Add"
                        buttonType={ButtonType.White}
                        border="py-10px px-3"
                    />
                </div>
            </div>
        </div>
    )
}

export default CardTypeMultipleText
