import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    Modal,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { showToast } from '../../redux/appReducer'
import { useAppDispatch } from '../../redux/store'
import { Services } from '../../services'
import './GetInTouchModal.scss'
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '467px',
    bgcolor: '#FFFFFF',
    padding: '32px 24px',
    borderRadius: '16px',
}

function GetInTouchModal(props: { open: boolean; handleClose: () => void }) {
    const [step, setStep] = useState<number>(0)
    const [isLoading, setIsLoading] = useState(false)
    const [feedback, setFeedback] = useState<string>('')
    useEffect(() => {
        setFeedback('')
    }, [props.open])
    const dispatch = useAppDispatch()
    const sendRequestOnClick = async () => {
        try {
            setIsLoading(true)
            await Services.requestUnlistedItem(feedback)
            setIsLoading(false)
            setStep(1)
        } catch (e: any) {
            setIsLoading(false)
            dispatch(showToast({ type: 'error', content: e.toString() }))
        }
    }

    const SuccessModal = () => {
        return (
            <Stack
                className="modal-wrapper"
                alignItems={'center'}
                justifyContent={'center'}
                spacing={3}
            >
                <Typography className="modal-title" textAlign={'center'}>
                    Thanks for your feedback!
                </Typography>
                <Typography
                    variant="subtitle2"
                    textAlign={'center'}
                    sx={{ color: 'color: rgba(0, 0, 0, 0.6)' }}
                >
                    Your feedback helps us improve Needle.
                </Typography>

                <Box>
                    <img
                        className="success-img"
                        src="/assets/imgs/High-Five.svg"
                        alt="success"
                    />
                </Box>

                <Stack
                    spacing={3}
                    width={'100%'}
                    direction={'row'}
                    justifyContent="space-between"
                >
                    <Button
                        onClick={() => {
                            props.handleClose()
                            setStep(0)
                        }}
                        fullWidth
                        variant={'outlined'}
                    >
                        Close
                    </Button>
                </Stack>
            </Stack>
        )
    }

    return (
        <Modal
            open={props.open}
            onClose={isLoading ? () => {} : props.handleClose}
        >
            <Box sx={{ ...style, width: 400 }}>
                {step === 0 && (
                    <Stack
                        className="modal-wrapper"
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={3}
                    >
                        <Typography
                            className="modal-title"
                            textAlign={'center'}
                        >
                            Tools not listed? Let us know!
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            textAlign={'center'}
                            sx={{ color: 'color: rgba(0, 0, 0, 0.6)' }}
                        >
                            Let us know what tools you’re using so we can start
                            working on a solution for you.
                        </Typography>

                        <FormControl
                            sx={{
                                width: '100%',
                            }}
                        >
                            <TextField
                                disabled={isLoading}
                                value={feedback}
                                onChange={(e) => setFeedback(e.target.value)}
                                sx={{
                                    marginTop: '24px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderRadius: '16px',
                                    },
                                }}
                                rows={3}
                                placeholder={'Your tools here'}
                                multiline
                                variant="outlined"
                            />
                        </FormControl>

                        <Stack
                            spacing={3}
                            width={'100%'}
                            direction={'row'}
                            justifyContent="space-between"
                        >
                            <Button
                                onClick={
                                    isLoading ? undefined : props.handleClose
                                }
                                fullWidth
                                variant={'outlined'}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={feedback === ''}
                                onClick={sendRequestOnClick}
                                fullWidth
                                variant={'contained'}
                            >
                                {isLoading ? (
                                    <CircularProgress color="info" size={24} />
                                ) : (
                                    'Send'
                                )}
                            </Button>
                        </Stack>
                    </Stack>
                )}
                {step === 1 && <SuccessModal />}
            </Box>
        </Modal>
    )
}

export default GetInTouchModal
