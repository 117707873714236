import { WorkspaceServices } from '../services/workspace/workspace.services'
import useSWRImmutable from 'swr/immutable'

const useWorkspace = (workspaceId?: string) => {
    const { data, mutate, ...rest } = useSWRImmutable(
        `/workspace/${workspaceId}/settings`,
        async (key) => {
            const data =
                await WorkspaceServices.getWorkspaceSetting(workspaceId)
            return data
        },
        { errorRetryCount: 0, shouldRetryOnError: false }
    )

    const handleChangeWorkspace = (id: string) => {
        // setWorkspaceId(id);
    }
    const reloadUserData = () => {
        mutate()
    }

    const removeUserFromWorkspace = (user_email: string) => {
        const users = data?.users?.filter(
            (item) => item.user_email !== user_email
        )
        const invitations = data?.invitations?.filter(
            (item) => item.user_email !== user_email
        )
        mutate(
            WorkspaceServices.removeUserFromWorkspace({
                user_email,
                workspace_id: workspaceId,
            }),
            {
                revalidate: false,
                optimisticData: {
                    ...data,
                    users: users,
                    invitations,
                } as any,
                rollbackOnError: true,
            }
        )
    }

    const changeUserRole = (user_email: string, value: string) => {
        const users = data?.users.map((item) =>
            item.user_email !== user_email ? item : { ...item, role: value }
        )
        const invitations = data?.invitations.map((item) =>
            item.user_email !== user_email ? item : { ...item, role: value }
        )
        mutate(
            WorkspaceServices.changeUserRoleFromWorkspace({
                user_email,
                role: value,
                workspace_id: workspaceId,
            }),
            {
                revalidate: false,
                optimisticData: {
                    ...data,
                    users: users ?? [],
                    invitations: invitations ?? [],
                } as any,
                rollbackOnError: true,
            }
        )
    }

    const inviteUsersToWorkspace = async ({
        user_emails,
        role,
    }: {
        user_emails: string[]
        role: string
    }) => {
        return WorkspaceServices.addUsersToWorkspace({
            user_emails,
            role,
            workspace_id: workspaceId,
        })
    }

    return {
        data,
        currentWorkspace: workspaceId,
        handleChangeWorkspace,
        changeUserRole,
        reloadUserData,
        removeUserFromWorkspace,
        inviteUsersToWorkspace,
        mutate,
        ...rest,
    }
}

export default useWorkspace
