import { Dialog } from '@mui/material'
import { useState } from 'react'
import { ButtonType, NeedleButton } from '../../../components/NeedleButton'
import brandStore from '../../../storeZustand/brandCustomizationStore'
function CreateFromScratch() {
    const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false)
    const { aAndTEmail, setTemplateEmail } = brandStore((state) => ({
        aAndTEmail: state.aAndTEmail,
        setTemplateEmail: state.setTemplateEmail,
    }))
    const setIsStartFromScratch = (type: boolean): void => {
        setTemplateEmail('FromScratch', type)
    }
    return (
        <div className="pt-6 flex">
            <div
                className="flex flex-row cursor-pointer pr-2 pt-1"
                onClick={() => {
                    if (aAndTEmail.FromScratch) {
                        setIsStartFromScratch(false)
                    } else {
                        setIsOpenDialog(true)
                    }
                }}
            >
                <div
                    className={`h-4 aspect-square rounded border border-beige-outline ${aAndTEmail.FromScratch && 'bg-black'}`}
                >
                    <img
                        src="/assets/icons/checked-icon.svg"
                        alt="checked-icon"
                        className="ml-3px absolute pt-4px w-2"
                    />
                </div>
            </div>
            <div>
                <div>I want Needle to create email templates from scratch.</div>
                <div
                    className="font-body-text pt-1"
                    dangerouslySetInnerHTML={{
                        __html: `
                                <div>
                                    <p>If you select this, the first email generated by Needle will
                                        cost <strong>2 extra Credits.</strong></p>
                                </div>`,
                    }}
                ></div>
            </div>
            <Dialog open={isOpenDialog}>
                <div className="relative w-480px">
                    <div className="text-dark-blue p-6">
                        <div className="font-header-2">
                            Let Needle design your email template?
                        </div>
                        <div className="font-body-text pt-10px">
                            The first email generated will cost{' '}
                            <strong>2 extra Credits</strong> if you select this
                            option
                        </div>
                    </div>
                    <div className="bg-beige-outline h-1px w-full absolute left-0" />
                    <div className="flex flex-row items-end py-4 pr-6 justify-end">
                        <div>
                            <NeedleButton
                                buttonCharacter="Cancel"
                                onClickFunction={() => setIsOpenDialog(false)}
                                buttonType={ButtonType.White}
                            />
                        </div>

                        <div className="pl-2">
                            <NeedleButton
                                buttonCharacter={'Confirm'}
                                onClickFunction={() => {
                                    setIsStartFromScratch(true)
                                    setIsOpenDialog(false)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default CreateFromScratch
