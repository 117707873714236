import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { NeedleButton } from '../../../components/NeedleButton'
import useUserProfile from '../../../hooks/useUserProfile'
import { Services } from '../../../services'
import { WorkspaceServices } from '../../../services/workspace/workspace.services'
import { Connector } from '../../../utils/enum'
import ConnectToShopifyModal from '../../ConnectToolsPage/ConnectToShopifyModal'
import { CHECK_SHOPIFY_CONNECTOR_PATH } from './ConnectingYourShopifyConnector'
import useEnhanceYourAI from '../../../hooks/useEnhanceYourAI'
interface ConnectStoreProps {
    sendData: (progress: number) => void
    connectors: Connector[] | undefined
}
export const ConnectStore = ({ sendData, connectors }: ConnectStoreProps) => {
    const [searchParams] = useSearchParams()
    const { userProfile } = useUserProfile()
    const { dataBrandProfile } = useEnhanceYourAI()

    const [storeConnected, setStoreConnected] = useState<
        string | undefined | null
    >(
        searchParams.get('services') === null
            ? undefined
            : searchParams.get('services')
    )
    const [isError, setIsError] = useState<string | null>(
        searchParams.get('error') ? 'shopify' : null
    )
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [connectToShopifyModal, setConnectToShopifyModal] =
        useState<boolean>(false)

    useEffect(() => {
        const desiredDestination = localStorage.getItem(
            CHECK_SHOPIFY_CONNECTOR_PATH
        )
        if (desiredDestination) {
            localStorage.removeItem(CHECK_SHOPIFY_CONNECTOR_PATH)
            navigate(desiredDestination)
        }
    }, [])

    const connectWoo = async () => {
        if (!Boolean(userProfile?.default_workspace)) {
            await WorkspaceServices.createNewWorkspace()
        }
        let connectorId = connectors?.find(
            (item) => item.service === 'woocommerce'
        )?.id
        try {
            if (!connectorId) {
                const res = await Services.createConnector({
                    service: 'woocommerce',
                    config: {},
                })
                connectorId = res.data.id
            }
            const card = await Services.getConnectorCard({
                connectorId: connectorId ?? '',
                redirectUri: `${window.location.host}/get-to-know?services=woocommerce`,
            })
            window.location.href = card?.data?.connect_card?.uri
        } catch (e: any) {
            setIsError('WooComerce')
            setIsLoading(false)
        }
    }

    const handleConnected = (store: string) => (): void => {
        if (isLoading) return
        setIsLoading(true)
        if (store === 'shopify') {
            setIsLoading(false)
            window.location.href = `https://apps.shopify.com/needle`
        } else {
            connectWoo()
        }
    }

    return (
        <div className="w-500px text-dark-blue">
            <ConnectToShopifyModal
                open={connectToShopifyModal}
                handleClose={() => {
                    setIsLoading(false)
                    setConnectToShopifyModal(false)
                }}
            />
            {storeConnected ? (
                <div className="flex flex-col pt-8">
                    <div className="flex justify-center items-center">
                        <div
                            className="flex border border-beige-outline rounded-full p-4 flex-row shadow-un-click-card
                            justify-center items-center bg-white"
                        >
                            <div className="border border-beige-outline rounded-full h-10 aspect-square items-center justify-center flex">
                                <img
                                    className="h-6 aspect-square"
                                    alt={`shop-icon`}
                                    src={`${storeConnected === 'shopify' ? '/assets/svgs/shopify.svg' : '/assets/svgs/woocommerce.svg'}`}
                                />
                            </div>
                            <div className="font-subtitle px-4">
                                {dataBrandProfile?.brand_name
                                    ? dataBrandProfile.brand_name
                                    : 'Store'}
                            </div>
                            <div className=" bg-green-theme text-white font-sans text-xs font-bold rounded-full px-3 py-6px flex flex-row items-center justify-center text-center">
                                <div>Done</div>
                            </div>
                        </div>
                    </div>
                    <NeedleButton
                        onClickFunction={() => sendData(66)}
                        buttonCharacter="Next"
                        isFull={true}
                        border="py-4"
                        className="pt-8"
                    />
                </div>
            ) : (
                <div>
                    {isError && (
                        <div className="font-body-text border border-red-theme py-4 px-6 rounded-lg bg-red-theme bg-opacity-10 mt-4">
                            Hmm, strange. I couldn’t connect to your {isError}
                            store. Can you try again? If this persists, please{' '}
                            <span className="text-blue-theme cursor-pointer underline">
                                reach out to my team
                            </span>{' '}
                            and we’ll sort it out.
                        </div>
                    )}
                    <div
                        className={`bg-white border-beige-outline shadow-click-card
                            duration-300 w-full flex flex-row items-center ${
                                isLoading
                                    ? 'cursor-wait opacity-50'
                                    : 'cursor-pointer hover:shadow-click-card-hover hover:-translate-y-2'
                            } 
                            rounded-lg mt-8`}
                        onClick={handleConnected('shopify')}
                    >
                        <div className="ml-6 my-6">
                            <div className="w-12 aspect-square rounded-lg border border-beige-outline flex justify-center items-center">
                                <img
                                    height={26}
                                    width={26}
                                    alt="shopify"
                                    src="/assets/svgs/shopify.svg"
                                />
                            </div>
                        </div>
                        <div className="font-header-2 pl-4">Shopify</div>
                    </div>
                    <div
                        className={`bg-white border-beige-outline shadow-click-card
                            duration-300 w-full flex flex-row items-center ${
                                isLoading
                                    ? 'cursor-wait opacity-50'
                                    : 'cursor-pointer hover:shadow-click-card-hover hover:-translate-y-2 '
                            } 
                            mt-6 rounded-lg`}
                        onClick={handleConnected('woocommerce')}
                    >
                        <div className="ml-6 my-6">
                            <div className="w-12 aspect-square rounded-lg border border-beige-outline flex justify-center items-center">
                                <img
                                    alt="woocommerce"
                                    width={29}
                                    height={29}
                                    src="/assets/svgs/woocommerce.svg"
                                />
                            </div>
                        </div>
                        <div className="font-header-2 pl-4">WooCommerce</div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ConnectStore
