import React, { useState } from 'react'
import { NeedleButton } from '../../../components/NeedleButton'
import { OptionType } from '../../BrandProfilePage/type'
import {
    AGE_DATA,
    FAMILY_DATA,
    GENDER_DATA,
} from '../../BrandProfilePage/constants'
import CheckBoxNeedle from '../../../components/CheckBoxNeedle'
import CardTypeSelectDropDown from '../../../components/DetailCardView/CardTypeSelectDropDown'
import { updateBrandProfile } from '../../../hooks/useEnhanceYourAI'
import { formatToCountryFromOption } from '../../../storeZustand/brandCustomizationStore'
import { BRAND_MARKETING } from '../constants'
interface YourCustomersDataProps {
    sendData: (progress: number) => void
}
export const YourCustomersData = ({ sendData }: YourCustomersDataProps) => {
    const [describe, setDescribe] = useState<string>('')
    const [gender, setGender] = useState<string[]>([])
    const [ageGroup, setAgeGroup] = useState<string[]>([])
    const [family, setFamily] = useState<string[]>([])
    const [openMoreDetail, setOpenMoreDetail] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [marketStore, setMarketStore] = useState<OptionType[]>([])

    const isDisable = (): boolean => {
        if (describe.trim().length === 0) {
            return true
        }
        if (
            openMoreDetail &&
            (gender.length === 0 ||
                ageGroup.length === 0 ||
                family.length === 0 ||
                marketStore.length === 0)
        ) {
            return true
        }
        return false
    }
    const sendUpdateBrandProfile = async () => {
        setIsLoading(true)
        const data = await updateBrandProfile({
            brand_input: {
                brand_customer_description: describe,
                brand_customers_age: ageGroup.join(', '),
                brand_customers_gender: gender.join(', '),
                brand_customers_status: family.join(', '),
                brand_markets: formatToCountryFromOption(marketStore),
            },
        })
        if (data) {
            sendData(BRAND_MARKETING)
        }
        setIsLoading(false)
    }

    const onClickNext = (): void => {
        if (openMoreDetail) {
            sendUpdateBrandProfile()
        } else {
            setOpenMoreDetail(true)
        }
    }

    return (
        <div className="w-500px text-dark-blue pt-4">
            <textarea
                rows={5}
                id="first_name"
                className="textarea-box-needle"
                placeholder={
                    'e.g. Our customers are dog parents who want the best for their fur babies. They don’t want to feed their dogs food they wouldn’t eat themselves...'
                }
                value={describe}
                onChange={(e) => {
                    setDescribe(e.target.value)
                }}
            />
            {openMoreDetail && (
                <div className="pt-8">
                    <div className="bg-beige-outline h-1px w-full"></div>
                    <div className="font-header-2 py-8 text-center">
                        These additional info help me craft better marketing
                        materials:
                    </div>
                    <div>
                        <div className="font-subtitle">Age group:</div>
                        <div className="flex flex-col py-4">
                            {AGE_DATA.map((ageType) => {
                                return (
                                    <CheckBoxNeedle
                                        key={ageType}
                                        keyName={ageType}
                                        selectedData={ageGroup}
                                        setData={setAgeGroup}
                                    />
                                )
                            })}
                        </div>
                        <div className="font-subtitle">Gender:</div>
                        <div className="flex flex-row py-4 flex-wrap">
                            {GENDER_DATA.map((genderType) => {
                                return (
                                    <CheckBoxNeedle
                                        key={genderType}
                                        keyName={genderType}
                                        selectedData={gender}
                                        setData={setGender}
                                    />
                                )
                            })}
                        </div>
                        <div className="font-subtitle pt-2">Family status:</div>
                        <div className="flex flex-col py-4">
                            {FAMILY_DATA.map((familyType) => {
                                return (
                                    <CheckBoxNeedle
                                        key={familyType}
                                        keyName={familyType}
                                        selectedData={family}
                                        setData={setFamily}
                                    />
                                )
                            })}
                        </div>
                    </div>
                    <div className="font-header-3">
                        What markets should we generate ideas for?
                    </div>
                    <div className="font-body-text pt-2">
                        Choose up to 3 markets
                    </div>
                    <CardTypeSelectDropDown
                        setCurrentData={setMarketStore}
                        currentData={marketStore}
                        setIsDisable={() => {}}
                        isOnboarding={true}
                    />
                </div>
            )}
            <NeedleButton
                onClickFunction={onClickNext}
                buttonCharacter="Next"
                isFull={true}
                isLoading={isLoading}
                isDisable={isDisable()}
                border="py-4"
                className="pt-8"
            />
        </div>
    )
}

export default YourCustomersData
