import React from 'react'
import { TypeForm } from './type'
import { NeedleButton } from '../../components/NeedleButton'
interface ReviewPastIdeasPageProps {
    handleFlow: (typeForm: TypeForm) => () => boolean
}

function ReviewGoodAndMehSelect({ handleFlow }: ReviewPastIdeasPageProps) {
    return (
        <div className="flex flex-col justify-center items-center">
            <div className="font-subtitle">Review:</div>
            <div className="flex flex-row gap-2 pt-2">
                <NeedleButton
                    onClickFunction={handleFlow(TypeForm.Good)}
                    children={
                        <div className="text-white font-button-text w-28 flex justify-center h-full items-center py-2px">
                            {TypeForm.Good}
                        </div>
                    }
                />
                <NeedleButton
                    onClickFunction={handleFlow(TypeForm.Meh)}
                    children={
                        <div className="text-white font-button-text w-28 flex justify-center h-full items-center">
                            {TypeForm.Meh}
                        </div>
                    }
                />
            </div>
        </div>
    )
}

export default ReviewGoodAndMehSelect
