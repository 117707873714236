import React, { useEffect, useState } from 'react'
import { ButtonType, NeedleButton } from '../../components/NeedleButton'
import { LoginType } from '../../firebase/authentication'
import { AppState, useAppDispatch } from '../../redux/store'
import { AuthState, clearStatus, login } from '../../redux/authReducer'
import { useNavigate } from 'react-router'
import { PATH } from '../../constants/path'
import { logoIconAnimation } from '../../components/LottieAnimation'
import Lottie from 'react-lottie'
import { useSelector } from 'react-redux'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase/client'
import { Navigate, useSearchParams } from 'react-router-dom'
import { usePageTitle } from '../HomeScreen/component'
import { isWebView } from './component'
import { GetUserNameAndEmail } from './GetUserNameAndEmail'

export const OnBoardingPage = () => {
    usePageTitle('Needle - Welcome')
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [isLoadingGoogle, setIsLoadingGoogle] = useState<boolean>(false)
    const { errorMessage } = useSelector<AppState, AuthState>(
        (state) => state.auth
    )
    const [user] = useAuthState(auth)
    const [loginType, setLoginType] = useState<LoginType>(LoginType.Google)
    const [isWebViewUser, setIsWebViewUser] = useState(false)
    if (user !== null) return <Navigate to={PATH.onBoardingFillData} />
    const [searchParams] = useSearchParams()

    useEffect(() => {
        if (errorMessage !== 'Close Popup' && isLoadingGoogle) {
            const path = localStorage.getItem('desiredRoute')
            if (path) {
                localStorage.removeItem('desiredRoute')
                navigate(path!)
            } else {
                navigate(PATH.onBoardingFillData)
            }
        }
        setIsLoadingGoogle(false)
    }, [errorMessage])

    useEffect(() => {
        dispatch(clearStatus())
    }, [dispatch])

    const loginWithGoogle = async () => {
        if (!isLoadingGoogle) {
            setIsLoadingGoogle(true)
            try {
                const data = await dispatch(
                    login({
                        loginType: LoginType.Google,
                        email: '',
                        password: '',
                    })
                )
                if (data && data.type === 'auth/login/fulfilled') {
                    const path = localStorage.getItem('desiredRoute')
                    if (path) {
                        localStorage.removeItem('desiredRoute')
                        navigate(path!)
                    } else {
                        navigate(PATH.onBoardingFillData)
                    }
                }
                setIsLoadingGoogle(false)
            } catch {}
        }
    }

    useEffect(() => {
        const type = searchParams.get('type')
        if (type === 'login') {
            setLoginType(LoginType.EmailAndPassword)
        }
        if (type === 'signup') {
            setLoginType(LoginType.Google)
        }
    }, [searchParams])

    useEffect(() => {
        setIsWebViewUser(isWebView())
    }, [])

    return (
        <div className="bg-beige h-full w-full text-dark-blue items-center flex flex-col justify-between min-h-screen">
            <div
                className={`flex flex-col items-center justify-center w-500px transition-all duration-300  ${loginType === LoginType.Google ? 'pt-36' : 'pt-10'}`}
            >
                <div className="h-24 aspect-square">
                    <Lottie
                        options={logoIconAnimation}
                        isClickToPauseDisabled={true}
                    />
                </div>
                <div className="font-header-1 text-center pt-3">
                    Hi, I’m Needle.<br></br>Let’s get started.
                </div>
                {loginType !== LoginType.EmailAndPassword && (
                    <div className="font-body-text pt-2">
                        Sign-ups are free.
                    </div>
                )}
                {!isWebViewUser && (
                    <div className={`w-full pt-12`}>
                        <NeedleButton
                            onClickFunction={loginWithGoogle}
                            buttonType={ButtonType.White}
                            isFull={true}
                            border="py-4"
                            isDisable={isLoadingGoogle}
                        >
                            <div className="relative flex flex-row justify-center items-center">
                                <img
                                    src="./assets/svgs/google.svg"
                                    alt="Google icon"
                                    className="h-4 aspect-square"
                                />
                                <div className="pl-2">
                                    Sign{' '}
                                    {loginType !== LoginType.EmailAndPassword
                                        ? 'up'
                                        : 'in'}{' '}
                                    with Google
                                </div>
                            </div>
                        </NeedleButton>
                    </div>
                )}
                {loginType === LoginType.Google ? (
                    <div
                        className="font-small-text opacity-60 underline cursor-pointer pt-6"
                        onClick={() => setLoginType(LoginType.SignUp)}
                    >
                        Use email instead
                    </div>
                ) : (
                    <GetUserNameAndEmail
                        isLoginEmail={loginType === LoginType.EmailAndPassword}
                        onClickFunction={() =>
                            setLoginType(LoginType.EmailAndPassword)
                        }
                    />
                )}
            </div>
            <div className="flex flex-col items-center justify-center pt-4 transition-all duration-300">
                {loginType === LoginType.EmailAndPassword ? (
                    <div className="font-body-text pb-38px">
                        Don't have account?{' '}
                        <span
                            className="underline font-bold cursor-pointer"
                            onClick={() => setLoginType(LoginType.SignUp)}
                        >
                            Create account
                        </span>
                    </div>
                ) : (
                    <div className="font-body-text pb-38px">
                        Already have an account?{' '}
                        <span
                            className="underline font-bold cursor-pointer"
                            onClick={() =>
                                setLoginType(LoginType.EmailAndPassword)
                            }
                        >
                            Log in.
                        </span>
                    </div>
                )}
                <div className="font-small-text opacity-60 pb-10">
                    By signing up, I agree to the{' '}
                    <span
                        className="cursor-pointer underline"
                        onClick={() =>
                            window.open(
                                'https://www.askneedle.com/terms',
                                '_blank'
                            )
                        }
                    >
                        Terms of Use
                    </span>{' '}
                    &{' '}
                    <span
                        className="cursor-pointer underline"
                        onClick={() =>
                            window.open(
                                'https://www.askneedle.com/privacy-policy',
                                '_blank'
                            )
                        }
                    >
                        Privacy Policy.
                    </span>
                </div>
            </div>
        </div>
    )
}

export default OnBoardingPage
