import React, { useEffect, useRef, useState } from 'react'
import {
    SmartAITool,
    SmartAIToolType,
} from '../../components/CoverBox/SmartAITool'
import { ProgressBar } from '../../components/ProgressBar'
import ideasStore from '../../storeZustand/ideasStore'
import { ActionData, LIST_OLD_REVIEW } from '../HomeScreen/constants'
import ReviewSection from './ReviewSection'
import { doneIconAnimation } from '../../components/LottieAnimation'
import Lottie from 'react-lottie'
import { formatDataIdeasList, usePageTitle } from '../HomeScreen/component'
import useGrowthPlan from '../../hooks/useGrowthPlan'

function ReviewPastIdeasPage() {
    usePageTitle('Needle - Review Past Ideas')
    const { needleReview, needleIsRead } = ideasStore((state) => ({
        needleReview: state.needleReview,
        needleIsRead: state.needleIsRead,
    }))

    const [listReviews, setListReviews] = useState<ActionData[]>([])
    const [currentReview, setCurrentReview] = useState<number>(
        needleIsRead.length - needleReview.length
    )

    const currentReviewRef = useRef<number>(currentReview)
    const listReviewsRef = useRef<ActionData[]>(LIST_OLD_REVIEW.actions)
    const [maxReviewed, setMaxReviewd] = useState<number>(needleIsRead.length)

    const [isDisplayProgressBar, setIsDisplayProgressBar] = useState<boolean>(
        currentReview !== maxReviewed
    )
    const { reloadGrowthPlan } = useGrowthPlan()

    const removeIdeaReview = (ideas: ActionData) => {
        listReviewsRef.current = listReviewsRef.current.filter(
            (i) => i.id !== ideas.id
        )
        setListReviews(listReviewsRef.current)
        setCurrentReview(needleIsRead.length - listReviewsRef.current.length)
    }

    useEffect(() => {
        const needleReviewList = formatDataIdeasList(needleReview)
        setListReviews(needleReviewList)
        listReviewsRef.current = needleReviewList
        setMaxReviewd(needleIsRead.length)
        setIsDisplayProgressBar(needleReview.length > 0)
        setCurrentReview(needleIsRead.length - needleReview.length)
        currentReviewRef.current = needleIsRead.length - needleReview.length
        return () => {
            reloadGrowthPlan()
        }
    }, [needleReview, needleIsRead])

    return (
        <div className="bg-beige h-full w-full transition-colors text-dark-blue">
            <div className="flex flex-col justify-center items-center w-full mb-14">
                <div className="flex flex-col w-4/5 sm:w-5/6 max-w-728px items-center">
                    <div className="flex w-full pb-2">
                        <SmartAITool
                            pageWord={`Review Past Ideas`}
                            typePage={SmartAIToolType.detailPage}
                        />
                    </div>
                    <div className="font-body-text pb-6">
                        Get a new idea for each one you review and make Needle
                        smarter!
                    </div>
                    {isDisplayProgressBar && (
                        <div className="flex w-full flex-col">
                            <ProgressBar
                                value={currentReview}
                                max={maxReviewed}
                                backgroundColor="bg-white"
                            />
                            <div className="font-small-text pt-2 justify-start flex w-full pb-6px">
                                {currentReview}/{maxReviewed} Reviewed
                            </div>
                        </div>
                    )}
                    <div className="w-full transition-all duration-300">
                        {listReviews.length !== 0 ? (
                            listReviews.map((ideas: ActionData) => (
                                <ReviewSection
                                    ideas={ideas}
                                    key={ideas.id}
                                    callRemove={removeIdeaReview}
                                />
                            ))
                        ) : (
                            <div className="bg-white rounded-lg mt-2 border border-beige-outline py-6 justify-center flex flex-col items-center">
                                <div className="relative">
                                    <div className="font-header-3 text-dark-blue">
                                        You're all Done
                                    </div>
                                    <div className="w-12 h-12 -right-42px absolute -top-3">
                                        <Lottie
                                            options={doneIconAnimation}
                                            isClickToPauseDisabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="font-body-text text-dark-blue pt-2">
                                    Come back again when there are more ideas to
                                    review.
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReviewPastIdeasPage
