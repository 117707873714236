export type Workspace = {
    workspace_id: string
    workspace_name: string
    name: string
    creator_id: string
    creator_email?: string
    result_ready?: boolean
    fivetran_group_id?: string
    current_plan?: string
    klaviyo_key?: string
    shopify_access_token?: string
    brand_elements?: any
    shopify_name?: string
    featured_products?: any
    paid_credit?: number
    free_credit?: number
    used_credit?: number
    connections?: any
}

export type WorkspaceSettings = Workspace & {
    invitations: Invitation[]
    users: WorkspaceUser[]
    request_user_role?: UserRoleInWorkspace
}

export type WorkspaceRawUserInput = Workspace & {
    userInfo?: any
    your_customers?: any
}

export interface WorkspaceUser {
    invited_by: string
    workspace_id: string
    user_id: string
    user_email: string
    role: UserRoleInWorkspace
    created_at: number
}

export interface Invitation {
    invited_by: string
    workspace_id: string
    user_email: string
    workspace_name: string
    role: UserRoleInWorkspace
    created_at: number
}

export enum UserRoleInWorkspace {
    owner = 'owner',
    member = 'member',
}
