import axios, { AxiosError, AxiosResponse } from 'axios'
import UseSWR, { SWRConfiguration } from 'swr'
import UseSWRMutation from 'swr/mutation'
import { auth } from '../firebase/client'
import { DISABLE_SEGMEMT_KEY, SEGMENT_HEADER_KEY } from '../constants'

/** call this after login and refresh page */
export const setDefaultToken = async (token: string) => {
    if (!token) {
        delete axios.defaults.headers.common['Authorization']
    }
    axios.defaults.headers[SEGMENT_HEADER_KEY] =
        localStorage.getItem(DISABLE_SEGMEMT_KEY)
    axios.defaults.headers.common['Authorization'] = `${token}`
}

/**TODO: remove this when we already set default token */
const getDefaultHeader = async () => {
    return {
        headers: {
            Authorization: await auth.currentUser?.getIdToken(),
            [SEGMENT_HEADER_KEY]: localStorage.getItem(DISABLE_SEGMEMT_KEY),
        },
    }
}

const fetcher = async <T>(url: string): Promise<T> =>
    axios.get<T>(url, await getDefaultHeader()).then((res) => res.data)

export type ApiError<T> = AxiosError<T>

export interface ApiStateOption
    extends Pick<Partial<SWRConfiguration>, 'refreshInterval'> {
    shouldFetch?: boolean
}

export const get = <T, E = unknown>(url: string, opts?: ApiStateOption) => {
    return UseSWR<T, AxiosError<E>>(
        () => (opts?.shouldFetch !== false ? url : null),
        fetcher,
        {
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            ...opts,
        }
    )
}

export const mutation = (url: string) => {
    return UseSWRMutation(url, fetcher)
}

export const post = async <T, D = unknown>(url: string, body: D) => {
    return axios.post<T, AxiosResponse<T>, D>(
        url,
        body,
        await getDefaultHeader()
    )
}

export const patch = async <T, D = unknown>(url: string, body?: D) => {
    return axios.patch<T, AxiosResponse<T>, D>(
        url,
        body,
        await getDefaultHeader()
    )
}
