import klaviyo from '../assets/svgs/klaviyo.svg'
import mailchimp from '../assets/svgs/mailchimp.svg'
import sendgrid from '../assets/svgs/sendgrid.svg'

export enum MailPlatform {
    Klaviyo = 'klaviyo',
    Mailchimp = 'mailchimp',
    Sendgrid = 'sendgrid',
}

export interface MailPlatformData {
    name: string
    icon: string
    enable?: boolean
}

export const MAIL_SERVICE_DATA: Record<MailPlatform, MailPlatformData> = {
    [MailPlatform.Klaviyo]: {
        name: 'Klaviyo',
        icon: klaviyo,
        enable: true,
    },
    [MailPlatform.Mailchimp]: {
        name: 'Mailchimp',
        icon: mailchimp,
    },
    [MailPlatform.Sendgrid]: {
        name: 'Sendgrid',
        icon: sendgrid,
    },
}

export const klavioCopyTemplateGuide = [
    'Go to <a target="_blank" href="https://www.klaviyo.com/campaigns/list">Klaviyo campaign</a>, click Create Campaign',
    'Fill the necessary fields',
    'On the Campaign content choose HTML (Custom code your email for complete control)',
    'Paste HTML, click Save then click Back to Content',
    'Proceed the rest of the flow as usual',
]

export const GEN_KEY_STORAGE_NAME = 'needle_gtak'
