import React from 'react'
import { SmartAITool } from '../../components/CoverBox/SmartAITool'
import { BoxComplete, BoxCompleteType } from '../../components/BoxComplete'
import { useSelector } from 'react-redux'
import { AppState } from '../../redux/store'
import { ConnectorState } from '../../redux/connectorReducer'
import {
    MAX_ASSETS_AND_TEMPLATES,
    MAX_BRAND_CUSTOMIZE,
    MAX_CONNECTED_TOOL,
} from '../../constants'
import ideasStore from '../../storeZustand/ideasStore'
import brandStore from '../../storeZustand/brandCustomizationStore'
import { usePageTitle } from '../HomeScreen/component'

const EnhanceYourAiPage = () => {
    usePageTitle('Needle - Enhance Your AI')
    const { emailConnected, marketConnected, shopAnalyticsConnected } =
        useSelector<AppState, ConnectorState>((state) => state.connector)
    const { needleReview, needleIsRead } = ideasStore((state) => ({
        needleReview: state.needleReview,
        needleIsRead: state.needleIsRead,
    }))

    const connectedTool =
        Number(emailConnected.length > 0) +
        Number(marketConnected.length > 0) +
        Number(shopAnalyticsConnected.length > 0)

    const {
        brandPCount,
        customerCount,
        brandMCount,
        productToCount,
        aAndTBasicAssets,
        aAndTEmail,
        aAndTAssets,
    } = brandStore((state) => ({
        brandPCount: state.brandPCount,
        customerCount: state.customerCount,
        brandMCount: state.brandMCount,
        productToCount: state.productToCount,
        aAndTBasicAssets: state.aAndTBasicAssets,
        aAndTEmail: state.aAndTEmail,
        aAndTAssets: state.aAndTAssets,
    }))

    const brandProfileCount =
        brandPCount + customerCount + brandMCount + productToCount
    const assetsConnectCount =
        Number(aAndTBasicAssets.Logo.length > 0) +
        Number(aAndTAssets[0].url !== '') +
        Number(aAndTEmail.FromScratch || aAndTEmail.Klaviyo !== null)

    return (
        <div className="bg-beige h-full w-full transition-colors text-dark-blue">
            <SmartAITool pageWord={'Enhance Your AI'} />
            <div className="flex flex-col items-center">
                <div className="pt-2 xl:grid xl:grid-cols-3 mb-14 flex flex-col px-4 sm:px-0 gap-5">
                    <div className="xl:w-329px sm:w-560px w-full">
                        <BoxComplete
                            value={connectedTool}
                            max={MAX_CONNECTED_TOOL}
                            mode={BoxCompleteType.ConnectTool}
                        />
                    </div>
                    <div className="xl:w-329px sm:w-560px w-full">
                        <BoxComplete
                            value={brandProfileCount}
                            max={MAX_BRAND_CUSTOMIZE}
                            mode={BoxCompleteType.BrandCustomization}
                        />
                    </div>
                    <div className="xl:w-329px sm:w-560px w-full">
                        <BoxComplete
                            value={assetsConnectCount}
                            max={MAX_ASSETS_AND_TEMPLATES}
                            mode={BoxCompleteType.AssetsTemplates}
                        />
                    </div>
                    <div className="xl:w-329px sm:w-560px w-full">
                        <BoxComplete
                            value={needleIsRead.length - needleReview.length}
                            max={needleIsRead.length}
                            mode={BoxCompleteType.ReviewPastIdeas}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EnhanceYourAiPage
