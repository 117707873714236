import React from 'react'
interface CheckBoxNeedleProps {
    keyName: string
    selectedData: string[]
    setData: React.Dispatch<React.SetStateAction<string[]>>
}

function CheckBoxNeedle({
    keyName,
    selectedData,
    setData,
}: CheckBoxNeedleProps) {
    return (
        <div
            className="flex font-body-text pr-6 items-center mb-4 cursor-pointer"
            onClick={() => {
                if (!selectedData.includes(keyName)) {
                    const newData = [...selectedData]
                    newData.push(keyName)
                    setData(newData)
                } else {
                    setData((prev) =>
                        prev.filter((gender) => gender !== keyName)
                    )
                }
            }}
        >
            <div
                className={`h-6 w-6 rounded-md border border-beige-outline ${selectedData.includes(keyName) ? 'bg-dark-blue' : 'bg-white'} items-center flex justify-center`}
            >
                <img src="/assets/icons/checked-icon.svg" alt="checked-icon" />
            </div>
            <div className="pl-2">{keyName}</div>
        </div>
    )
}

export default CheckBoxNeedle
