import { axiosInstance, handleResponse, getOptions } from '../api'
import { needdleConfig } from '../api/config'

export const GrowthPlanServices = {
    getGrowthPlan: async () => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(needdleConfig.GROWTH_PLAN, options)
        )
    },
}
