import useSWRImmutable from 'swr/immutable'
import { auth } from '../firebase/client'
import { WorkspaceServices } from '../services/workspace/workspace.services'

const useWorkspaceCreditHistory = () => {
    const { data, mutate, ...rest } = useSWRImmutable(
        `/use-user-profile/${auth.currentUser?.uid}`,
        async () => {
            const creditHistory =
                await WorkspaceServices.getWorkspaceCreditHistory()
            console.log('creditHistory', creditHistory)
            return creditHistory
        }
    )

    return { data, mutate, ...rest }
}

export default useWorkspaceCreditHistory
