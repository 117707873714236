import { connectToolType } from './type'

interface AssetsLinkProps {
    type: connectToolType
    setIsOpen: any
}

function DoneWithFlatForm({ type, setIsOpen }: AssetsLinkProps) {
    let image = '/assets/svgs/google-drive-icon.svg'
    let linkData = 'Google Drive'
    switch (type) {
        case connectToolType.Google:
            image = '/assets/icons/google-icon-circle.png'
            linkData = 'Google'
            break
        case connectToolType.Dropbox:
            image = '/assets/icons/dropbox-icon-circle.png'
            linkData = 'Dropbox'
            break
        case connectToolType.Klaviyo:
            image = '/assets/svgs/klaviyo-icon.svg'
            linkData = 'Klaviyo'
            break
        case connectToolType.Figma:
            image = '/assets/icons/figma-icon-circle.png'
            linkData = 'Figma'
            break
        case connectToolType.Canva:
            image = '/assets/icons/canva-icon-circle.png'
            linkData = 'Canva'
            break
        default:
            break
    }
    return (
        <div className="flex">
            <div
                className="flex border border-beige-outline rounded-full p-2 flex-row
                justify-center items-center cursor-pointer hover:shadow-button-secondary 
                transition-all duration-300 ease-in-out"
                onClick={() => setIsOpen(true)}
            >
                <div className="flex flex-row">
                    <img
                        className="h-6 w-6"
                        alt={`${linkData}-icon`}
                        src={image}
                    />
                    <div className="font-subtitle pl-1 pr-2">{linkData}</div>
                </div>
                <div className=" bg-green-theme text-white font-sans text-xs font-bold rounded-full px-3 py-6px flex flex-row items-center justify-center mr-1 text-center">
                    <div>Done</div>
                </div>
            </div>
        </div>
    )
}

export default DoneWithFlatForm
