import React, { useState } from 'react'
import { NeedleButton } from '../../components/NeedleButton'
import useUserProfile from '../../hooks/useUserProfile'
import useWorkspace from '../../hooks/useWorkspace'
import { ReactComponent as ErrorIcon } from '../../assets/svgs/error.svg'
import PromptModal, { usePromptModal } from '../../components/PromptModal'
import Select, { components } from 'react-select'
import { customStylesDropDownAAndT } from '../AssetsAndTemplates/constants'
import InviteUsersModal from '../settings/components/InviteUsersModal'

export const ValueUser = (props: any): JSX.Element => {
    return (
        <components.SingleValue {...props}>
            <div className="flex flex-row items-center">
                <div className="flex flex-col justify-center font-body-text">
                    {props.data.label}
                </div>
            </div>
        </components.SingleValue>
    )
}

export const CustomUserRole = (props: any): JSX.Element => {
    return (
        <components.Option {...props}>
            <div className="flex flex-row items-center justify-between">
                <div className="flex flex-col justify-center font-body-text">
                    {props.data.label}
                </div>
            </div>
        </components.Option>
    )
}
export const optionsUserRole: any[] = [
    {
        label: 'Owner',
        value: 'owner',
    },
    {
        label: 'Member',
        value: 'member',
    },
]

const UsersTeam = () => {
    const { userProfile: currentUser } = useUserProfile()
    const { data: workspaceSettings, changeUserRole } = useWorkspace(
        currentUser?.default_workspace
    )
    const { promptModalConfig, showModal, hideModal } = usePromptModal()
    const [showInviteModal, setShowInviteModal] = useState(false)

    const activated_users =
        workspaceSettings?.users?.map((item) => ({
            user_email: item.user_email,
            role: item.role,
            status: 'active',
            created_at: item.created_at ?? 0,
        })) ?? []

    const pending_users =
        workspaceSettings?.invitations?.map((item) => ({
            user_email: item.user_email,
            role: item.role,
            status: 'pending',
            created_at: item.created_at ?? 0,
        })) ?? []
    const users = [...activated_users, ...pending_users].sort(
        (a, b) => a.created_at - b.created_at
    )

    const handleChangeRole = ({
        email,
        val,
    }: {
        email: string
        val: string
    }) => {
        if (
            val == 'member' &&
            users.filter((user) => user.role === 'owner').length === 1
        ) {
            showModal({
                title: 'Your brand must have at least 1 Owner',
                message:
                    'Assign another member as an Owner before changing your role.',
                icon: (
                    <>
                        <ErrorIcon />
                    </>
                ),
                buttons: [
                    {
                        label: 'Got it',
                        onClickFunction: () => hideModal(),
                        buttonProps: {
                            onClick: hideModal,
                        },
                    },
                ],
            })
            return
        }
        changeUserRole(email, val)
    }

    return (
        <div className="w-680px bg-white shadow-un-click-card rounded-lg relative mt-6 text-dark-blue">
            <PromptModal {...promptModalConfig} />
            {showInviteModal && (
                <InviteUsersModal
                    open={showInviteModal}
                    onClose={() => setShowInviteModal(false)}
                />
            )}
            <div className="p-6 gap-4 flex flex-col font-body-text">
                <div className="font-header-3">Users</div>
                <div className="grid grid-cols-4 gap-4 font-subtitle">
                    <div className="col-span-2">Email</div>
                    <div>Role</div>
                    <div>Status</div>
                    {users.map((user, index) => (
                        <React.Fragment key={index}>
                            <div className="col-span-2 font-body-text flex items-center">
                                {user.user_email}
                            </div>
                            <div>
                                <Select
                                    isClearable={false}
                                    options={optionsUserRole}
                                    isSearchable={false}
                                    value={optionsUserRole.find(
                                        (option) => option.value === user.role
                                    )}
                                    onChange={(e: any) =>
                                        handleChangeRole({
                                            email: user.user_email,
                                            val: e.value as string,
                                        })
                                    }
                                    loadingMessage={() => 'Fetching options...'}
                                    components={{
                                        SingleValue: ValueUser,
                                        IndicatorSeparator: () => null,
                                        Option: CustomUserRole,
                                    }}
                                    styles={customStylesDropDownAAndT}
                                    backspaceRemovesValue={false}
                                />
                            </div>
                            <div className="flex">
                                <div className="flex items-center">
                                    {user.status === 'active' ? (
                                        <div className="bg-green-theme rounded-lg flex px-3 py-2 text-white">
                                            Joined
                                        </div>
                                    ) : (
                                        <div className="bg-beige-2 rounded-lg flex px-3 py-2">
                                            Invited
                                        </div>
                                    )}
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
                <NeedleButton onClickFunction={() => setShowInviteModal(true)}>
                    <div className="flex flex-row">
                        <img
                            className="h-4 aspect-square mr-2"
                            alt={`open-drive-icon`}
                            src="/assets/icons/invite-user-icon.png"
                        />
                        <div>Invite user</div>
                    </div>
                </NeedleButton>
            </div>
        </div>
    )
}
export default UsersTeam
