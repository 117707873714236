import axios from 'axios'
import { auth } from '../../firebase/client'
import { BASE_URL } from './config'
import { DISABLE_SEGMEMT_KEY, SEGMENT_HEADER_KEY } from '../../constants'
import { resultRoutes, routes } from '../../routes'
import { LOCAL_NEEDLE_ROLE } from '../../hooks/useUserProfile'
import { checkIfCurrentUserIsAdmin, pathToRegex } from '../../utils/utils'

const PREVIEW_EXEPTIONAL_ENDPOINTS = {
    UpdateIdeaStatus: `/growth-plan/ideas/*/status`,
}

export const getAuthHeader = async () => {
    const currentPage = routes
        .concat(resultRoutes)
        .filter((item) => item.name)
        .find((item) => item.path === window.location.pathname)
    let accessToken = await auth.currentUser?.getIdToken()
    if (accessToken) {
        return {
            Authorization: accessToken,
            [SEGMENT_HEADER_KEY]: localStorage.getItem(DISABLE_SEGMEMT_KEY),
            'current-path-name': currentPage?.name ?? undefined,
        }
    }
    return { 'current-path-name': currentPage?.name ?? undefined }
}

type Options = {
    headers?: object
}

export const getOptions = async () => {
    let options: Options = {}
    options['headers'] = await getAuthHeader()
    return options
}

export const axiosInstance = axios.create({
    baseURL: BASE_URL,
})

axiosInstance.interceptors.request.use(
    (request) => {
        if (['post', 'patch'].includes(request.method?.toLowerCase() ?? '')) {
            if (
                localStorage.getItem(LOCAL_NEEDLE_ROLE) === 'admin' &&
                pathToRegex(PREVIEW_EXEPTIONAL_ENDPOINTS.UpdateIdeaStatus).test(
                    request.url ?? ''
                ) &&
                JSON.stringify(request.data).includes('needle_is_working')
            ) {
                if (
                    window.confirm(
                        'Do you really want to request concept inside Admin Preview?'
                    )
                ) {
                    return request
                } else {
                    return Promise.reject(
                        new Error(
                            'Post and Patch request is disable with admin role'
                        )
                    )
                }
            }
            if (
                checkIfCurrentUserIsAdmin() &&
                !request.url?.includes('admin-preview')
            ) {
                console.log(
                    `YOU ARE LOGIN AS ADMIN ACCOUNT: ${request.method?.toUpperCase()} request is disable with endpoint: ${request.url}`
                )
                return Promise.reject(
                    new Error(
                        'Post and Patch request is disable with admin role'
                    )
                )
            }
        }
        return request
    },
    (error) => {
        // Handle the error
        return Promise.reject(error)
    }
)

export function handleResponse(response: any) {
    return response.then(
        (text: any) => {
            return text.data
        },
        (error: any) => {
            return Promise.reject(error)
        }
    )
}
