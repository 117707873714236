import React from 'react'
import { ExpandableSection } from '../../../components/ExpandableSection'
import { Box, Typography } from '@mui/material'
export const KlaviyoTemplateGuide = () => {
    const liStyle = 'text-sm ml-5 font-normal'
    const imgStyle = 'mt-3 mb-5'
    return (
        <div className="p-3 sm:p-4 lg:p-6 border border-[#D4D4D4] rounded-lg text-sm flex flex-col">
            <ExpandableSection
                maxHeight={244}
                canHide={true}
                hideLabel="See less"
                viewLabel="See all"
            >
                <div>
                    {' '}
                    <div className="mb-4 font-semibold text-base">
                        How to get Klaviyo API Key
                    </div>
                    <ul className="list-decimal list-outside">
                        <li className={liStyle}>
                            Log in to your Klaviyo account on
                            <a
                                href="https://www.klaviyo.com/settings/account/api-keys"
                                target="_blank"
                                className="text=[#E3960F]"
                            >
                                &nbsp;this link
                            </a>
                        </li>
                        <li className={liStyle}>
                            On the API key menu, click “Create Private API Key”
                        </li>
                        <img
                            className={imgStyle}
                            src="/assets/imgs/klaviyo-api-key.png"
                            alt="private key"
                        />
                        <li className={liStyle}>Name your Key e.g. Needle</li>
                        <li className={liStyle}>
                            Provide the required Access Level and API Scopes. We
                            recommend you to provide the Read-Only Key access
                            level for all the endpoints if you want to sync all
                            tables. You can also provide the access and scopes
                            based on the endpoints you want to sync.
                            <br />
                            <i>
                                {' '}
                                In the future, Needle can help you on Flow and
                                Template, would be nice if you can enable{' '}
                                <strong>Full Access</strong> to these service.
                            </i>
                        </li>
                        <Box
                            sx={{
                                my: 1,
                                p: 1,
                                bgcolor: 'yellow.100',
                            }}
                        >
                            <Typography variant="code2">
                                IMPORTANT: The API scope to the Events endpoint
                                is necessary for the connector setup tests to
                                pass.
                            </Typography>
                        </Box>
                        <img
                            className={imgStyle}
                            src="https://fivetran.com/static-assets-docs/_next/static/media/Step-5.347a2f60.png"
                            alt="copy"
                        />

                        <li className={liStyle}>Click “Create”</li>
                        <li className={liStyle}>
                            Click on the copy icon, paste it here in Needle, and
                            you’re done!
                        </li>
                        <img
                            className={imgStyle}
                            src="/assets/imgs/klaviyo-copy-key.png"
                            alt="copy"
                        />
                    </ul>
                </div>
            </ExpandableSection>
        </div>
    )
}
