import type { TypographyOptions } from '@mui/material/styles/createTypography'
import { colors } from './colors'

declare module '@mui/material/styles' {
    interface TypographyVariants {
        'h3-bold'?: React.CSSProperties
        'h4-bold': React.CSSProperties
        'body1-bold': React.CSSProperties
        'body2-bold': React.CSSProperties
        link1: React.CSSProperties
        link2: React.CSSProperties
        code1: React.CSSProperties
        code2: React.CSSProperties
        eyebrow: React.CSSProperties
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        'h3-bold'?: React.CSSProperties
        'h4-bold': React.CSSProperties
        'body1-bold': React.CSSProperties
        'body2-bold': React.CSSProperties
        'body3-bold': React.CSSProperties
        body3: React.CSSProperties
        link1: React.CSSProperties
        link2: React.CSSProperties
        code1: React.CSSProperties
        code2: React.CSSProperties
        eyebrow: React.CSSProperties
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        'h3-bold': true
        'h4-bold': true
        'body1-bold': true
        'body2-bold': true
        'body3-bold': true
        body3: true
        link1: true
        link2: true
        code1: true
        code2: true
        eyebrow: true
    }
}
export const fonts = {
    sans: "'Work Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;",
    code: "'Trispace', 'monospace'",
}

export const variants = {
    h1: {
        fontWeight: 700,
        fontSize: '2rem',
        lineHeight: '120%',
        '@media screen and (max-width: 1024px)': {
            fontSize: '1.5rem',
        },
    },
    h2: {
        fontWeight: 400,
        fontSize: '2rem',
        lineHeight: '120%',
        '@media screen and (max-width: 1024px)': {
            fontSize: '1.25rem',
        },
    },
    h3: {
        fontWeight: 400,
        fontSize: '1.5rem',
        lineHeight: '120%',
        '@media screen and (max-width: 1024px)': {
            fontSize: '1.125rem',
        },
    },
    'h3-bold': {
        fontWeight: 600,
        fontSize: '1.5rem',
        lineHeight: '120%',
        '@media screen and (max-width: 1024px)': {
            fontSize: '1.125rem',
        },
    },
    h4: {
        fontWeight: 400,
        fontSize: '1.125rem',
        lineHeight: '120%',
        '@media screen and (max-width: 1024px)': {
            fontSize: '1rem',
        },
    },
    'h4-bold': {
        fontWeight: 600,
        fontSize: '1.125rem',
        lineHeight: '120%',
        '@media screen and (max-width: 1024px)': {
            fontSize: '1rem',
        },
    },
    body1: {
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '150%',
        '@media screen and (max-width: 1024px)': {
            fontSize: '0.875rem',
        },
    },
    'body1-bold': {
        fontWeight: 600,
        fontSize: '1rem',
        lineHeight: '150%',
        '@media screen and (max-width: 1024px)': {
            fontSize: '0.875rem',
        },
    },
    body2: {
        color: 'black-80',
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: '150%',
        '@media screen and (max-width: 1024px)': {
            fontSize: '0.875rem',
        },
    },
    'body2-bold': {
        color: 'black-80',
        fontWeight: 600,
        fontSize: '0.875rem',
        lineHeight: '150%',
        '@media screen and (max-width: 1024px)': {
            fontSize: '0.875rem',
        },
    },
    body3: {
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: '150%',
        '@media screen and (max-width: 1024px)': {
            fontSize: '0.625rem',
        },
    },
    'body3-bold': {
        fontWeight: 600,
        fontSize: '0.75rem',
        lineHeight: '150%',
        '@media screen and (max-width: 1024px)': {
            fontSize: '0.625rem',
        },
    },
    link1: {
        fontWeight: 500,
        fontSize: '1rem',
        lineHeight: '120%',
        color: colors.yellow[800],
        '@media screen and (max-width: 1024px)': {
            fontSize: '0.875rem',
        },
    },
    link2: {
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: '120%',
        color: colors.yellow[800],
        '@media screen and (max-width: 1024px)': {
            fontSize: '0.75rem',
        },
    },
    eyebrow: {
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: '120%',
        '@media screen and (max-width: 1024px)': {
            fontSize: '0.75rem',
        },
    },
    code1: {
        fontWeight: 600,
        fontFamily: fonts.code,
        fontSize: '1.0625rem',
        lineHeight: '170%',
        letterSpacing: '-0.025rem',
        '@media screen and (max-width: 1024px)': {
            fontSize: '0.9375rem',
        },
    },
    code2: {
        fontWeight: 600,
        fontFamily: fonts.code,
        fontSize: '0.9375rem',
        lineHeight: '170%',
        letterSpacing: '-0.025rem',
        '@media screen and (max-width: 1024px)': {
            fontSize: '0.8125rem',
        },
    },
}

export const typography: TypographyOptions = {
    fontFamily: fonts.sans,
    htmlFontSize: 16,
    ...variants,
}
