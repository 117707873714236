import useSWRImmutable from 'swr/immutable'
import { UserServices } from '../services/User/user.services'
import globalErrorDialogStore from '../storeZustand/globalErrorDialogStore'

export const LOCAL_NEEDLE_ROLE = 'needle-role'
const useUserProfile = (user_id?: string) => {
    const {
        data: userProfile,
        mutate,
        ...rest
    } = useSWRImmutable(`/use-user-profile/${user_id}`, async () => {
        const userProfile = await UserServices.getUserProfile()
        localStorage.setItem(LOCAL_NEEDLE_ROLE, userProfile.data?.role)
        return userProfile.data as Needle.User
    })
    const refetch = async (mutateData?: any) => {
        await mutate(mutateData)
    }
    return { userProfile, mutate, refetch, ...rest }
}

export const updateUserProfile = async (
    data: Partial<Needle.User>,
    mutateData?: any
) => {
    try {
        const userServicesStatus = await UserServices.updateUserProfile(data)
        if (mutateData) {
            await mutateData(userServicesStatus)
        }
        return userServicesStatus ?? {}
    } catch (e) {
        const { setIsOpenErrorDialog } = globalErrorDialogStore.getState()
        setIsOpenErrorDialog(true)
        return undefined
    }
}
export default useUserProfile
