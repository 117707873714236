import { GrowthPlanServices } from '../services/GrowthPlan/growthplan.services'
import useSWR from 'swr'

const useGrowthPlan = () => {
    const { data, mutate, ...rest } = useSWR(
        `/get-growth-plan`,
        async (id) => {
            const useGrowthPlan = await GrowthPlanServices.getGrowthPlan()
            return useGrowthPlan
        }
    )
    const reloadGrowthPlan = () => {
        mutate()
    }
    return { data, mutate, reloadGrowthPlan, ...rest }
}

export default useGrowthPlan
