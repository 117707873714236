import { ToolData, allTools } from '../../constants'
import { Connector } from '../../utils/enum'

const customServices = 'shopify'
const emailMarketing = ['klaviyo']
const marketingTools = ['facebook_ads', 'google_ads', 'tiktok_ads']
const essentialTools = ['shopify', 'google_analytics_4', 'woocommerce']
const ideasTools = [
    'facebook',
    'klaviyo',
    'tiktok',
    'instagram',
    'google_ads',
    'youtube',
]

const brandingTools = ['email_assets']

export const currentEmailTools: ToolData[] = allTools.filter((tool) =>
    emailMarketing.includes(tool.id)
)
export const currentMarket: ToolData[] = allTools.filter((tool) =>
    marketingTools.includes(tool.id)
)
export const currentShopAnalytics: ToolData[] = allTools.filter((tool) =>
    essentialTools.includes(tool.id)
)
export const currentIdeasTools: ToolData[] = allTools.filter((tool) => {
    if (tool.newId) {
        return ideasTools.includes(tool.newId)
    }
})
export const connectToolShopAnalytics = (
    shopAnalyticsConnected: string[]
): ToolData[] => {
    let currentTool = essentialTools
    if (
        shopAnalyticsConnected.find((shopId) => shopId === 'shopify') !==
        undefined
    ) {
        currentTool = currentTool.filter((shopId) => shopId !== 'woocommerce')
    }
    if (
        shopAnalyticsConnected.find((shopId) => shopId === 'woocommerce') !==
        undefined
    ) {
        currentTool = currentTool.filter((shopId) => shopId !== 'shopify')
    }
    return allTools.filter((tool) => currentTool.includes(tool.id))
}

export function getConnectedList(
    connectors: Connector[],
    dataTool: ToolData[]
): string[] {
    let dataConnected: string[] = []
    if (connectors) {
        dataTool.forEach((tool: ToolData) => {
            const isConnectToThis =
                connectors.find(
                    (connector) =>
                        tool.id === connector.service &&
                        (connector.status.setup_state === 'connected' ||
                            customServices.includes(connector.service))
                ) !== undefined
            if (isConnectToThis) dataConnected.push(tool.id)
        })
    }
    return dataConnected
}
