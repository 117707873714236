import React, { useState } from 'react'
import { CircularProgress, Dialog, Typography } from '@mui/material'
import Select, { components } from 'react-select'
import { ReactComponent as ErrorIcon } from '../../../assets/svgs/error.svg'
import { ReactComponent as CheckCircleIcon } from '../../../assets/svgs/check-circle.svg'
import useSWRMutation from 'swr/mutation'
import useWorkspace from '../../../hooks/useWorkspace'
import PromptModal from '../../../components/PromptModal'
import useUserProfile from '../../../hooks/useUserProfile'
import { WorkspaceServices } from '../../../services/workspace/workspace.services'
import { ButtonType, NeedleButton } from '../../../components/NeedleButton'
import { NeedleTextInput } from '../../../components/NeedleTextInput'
import {
    CustomUserRole,
    optionsUserRole,
    ValueUser,
} from '../../SettingsPage/UsersTeam'
import { customStylesDropDownAAndT } from '../../AssetsAndTemplates/constants'
const InviteUsersModal = ({
    open,
    onClose,
}: {
    open: boolean
    onClose: () => void
}) => {
    const { userProfile } = useUserProfile()
    const { inviteUsersToWorkspace, mutate } = useWorkspace(
        userProfile?.default_workspace
    )
    const [email, setEmail] = useState<string>('')
    const [isError, setIsError] = useState<boolean>(false)
    const { trigger, isMutating, data, error } = useSWRMutation(
        `/api/workspace/users/add/${email}`,
        async () => {
            const validEmails = [email]
            const workspaces = await WorkspaceServices.getEmailWorkspaces(email)
            if (workspaces.length > 0) {
                setIsError(true)
                return false
            }
            const res = await inviteUsersToWorkspace({
                user_emails: validEmails,
                role,
            })
            mutate()
            return res
        },
        { throwOnError: true }
    )

    const handleAddUsersToWorkspace = () => {
        trigger()
    }

    const [role, setRole] = useState('member')
    const handleInputEmailChange = (value: string) => {
        setEmail(value)
    }

    if (isError)
        return (
            <PromptModal
                config={{
                    icon: (
                        <>
                            <ErrorIcon width={64} height={64} />
                        </>
                    ),
                    title: `Oops! Something went wrong.`,
                    message: (
                        <>This user is already part of another workspace</>
                    ),
                    buttons: [
                        {
                            label: 'Got it',
                            buttonProps: { onClick: onClose },
                        },
                    ],
                }}
                open={open}
                onClose={onClose}
            ></PromptModal>
        )

    if (data)
        return (
            <PromptModal
                config={{
                    icon: (
                        <>
                            <CheckCircleIcon width={64} height={64} />
                        </>
                    ),
                    title: `User invited!`,
                    message: (
                        <>
                            We have invited <strong>{email}</strong>. <br />{' '}
                            <Typography sx={{ marginTop: '12px' }}>
                                {' '}
                                Please inform them to sign up. They will be
                                automatically added to your brand after signing
                                up.{' '}
                            </Typography>
                        </>
                    ),
                    buttons: [
                        {
                            label: 'Got it',
                            onClickFunction: onClose,
                            buttonProps: { onClick: onClose },
                        },
                    ],
                }}
                open={open}
                onClose={onClose}
            ></PromptModal>
        )

    return (
        <Dialog
            open={open}
            onClose={isMutating ? () => {} : onClose}
            PaperProps={{
                style: {
                    borderRadius: 8,
                },
            }}
        >
            <div className="p-6 w-[480px] font-body-text">
                <div className="font-header-2 pb-10px">Invite users</div>
                <div className="flex flex-row gap-10px">
                    <NeedleTextInput
                        value={email}
                        setValue={handleInputEmailChange}
                        placeholder={'Email address'}
                    />
                    <Select
                        className="w-[190px]"
                        isClearable={false}
                        options={optionsUserRole}
                        isSearchable={false}
                        value={optionsUserRole.find(
                            (option) => option.value === role
                        )}
                        onChange={(e: any) => setRole(e.value as string)}
                        loadingMessage={() => 'Fetching options...'}
                        components={{
                            SingleValue: ValueUser,
                            IndicatorSeparator: () => null,
                            Option: CustomUserRole,
                        }}
                        styles={customStylesDropDownAAndT}
                        backspaceRemovesValue={false}
                    />
                </div>
                <div className="font-body-text pt-10px">
                    {role !== 'member' ? (
                        <div>
                            <strong>Owners</strong> have access to all actions.
                        </div>
                    ) : (
                        <div>
                            <strong>Members</strong> have access to all actions
                            but cannot invite new users.
                        </div>
                    )}
                </div>
            </div>
            <div className="bg-beige-outline h-1px w-full"></div>
            <div className="flex flex-row justify-end items-end py-4 pr-6">
                <div>
                    <NeedleButton
                        buttonCharacter={'Cancel'}
                        buttonType={ButtonType.White}
                        onClickFunction={onClose}
                    />
                </div>
                <div className="pl-2">
                    <NeedleButton
                        onClickFunction={handleAddUsersToWorkspace}
                        isDisable={isMutating || email.length === 0}
                    >
                        {isMutating ? (
                            <CircularProgress color="info" size={16} />
                        ) : (
                            <div>Invite</div>
                        )}
                    </NeedleButton>
                </div>
            </div>
        </Dialog>
    )
}

export default InviteUsersModal
