import { BrandStoreProps } from '../../storeZustand/brandCustomizationStore'

export interface OptionType {
    value: string
    label: string
    image: string
    description?: string
}
export interface LinkType {
    link: string
    accessMail: string[]
}
export interface LinkAndMediaType {
    link: string
    media: File[]
}

export interface BrandMetricsType {
    marketSpend: number | undefined
    grossMargin: number | undefined
    shippingCost: number | undefined
}

export type OurCustomers = {
    Describe: string
    Gender: string[]
    AgeGroup: string[]
    Family: string[]
}
export type EIBasicAssets = {
    Name: string
    Logo: Needle.metaDataAssets[]
    Guide: Needle.metaDataAssets[]
    Font: Needle.metaDataAssets[]
}
export type EIAssets = {
    platform: string
    url: string
}
export type EITemplatesEmail = {
    Figma: string
    Canva: string
    Klaviyo: string | null
    FromScratch: boolean
}

export type Sections_Data = {
    name: string
    value: any
    stateKey: keyof BrandStoreProps
    description?: string
    placeHolder?: string
    id?: number
    type: BrandsCardType
}

export type BrandCustomizationSection = {
    name: string
    detailSection: Sections_Data[]
}

export enum BrandsCardType {
    Text = 'Text',
    MultipleText = 'Multiple Text',
    EIBasicAssets = 'EIBasicAssets',
    EIAssets = 'EIAssets',
    EITemplatesEmail = 'EITemplatesEmail',
    Customers = 'Customers',
    Link = 'Link',
    Metrics = 'Metrics',
    LinkAndMedia = 'LinkAndMedia',
    Number = 'Number',
    MultipleSelectDropdown = 'Multiple Select Dropdown',
    Percentage = 'Percentage',
    ProductSelect = 'Multiple Select Dropdown Product Select',
}
