import React, { useEffect, useRef, useState } from 'react'
import { NeedleButton } from '../../../components/NeedleButton'
import { useNavigate } from 'react-router-dom'
import { PATH } from '../../../constants/path'
import { BoxComplete, BoxCompleteType } from '../../../components/BoxComplete'
import useEnhanceYourAI from '../../../hooks/useEnhanceYourAI'

export const LastButNotLeastView = () => {
    const navigate = useNavigate()
    const { dataBrandProfile, mutateBrandProfile } = useEnhanceYourAI()
    const [message, setMessage] = useState<number>(0)
    const [buttonCharacter, setButtonCharacter] = useState<string>('Got it')
    const [description, setDescription] = useState<string>(
        'It can take me up to 2 days to sync the numbers and familiarize myself with your brand, customers & data.'
    )
    const [listHeight, setListHeight] = useState<number>(0)
    const divRef = useRef<HTMLDivElement>(null)

    const nextMessage = () => {
        if (message === 0) {
            setListHeight(listHeight + 64)
            setDescription(`It can take me up to 2 days to sync the numbers and
                    familiarize myself with your brand, customers & data.`)
            setButtonCharacter('Sounds good')
            setMessage(1)
        } else if (message === 1) {
            setListHeight(listHeight + 200)
            setDescription(
                `Meanwhile, you can help me get smarter in the dashboard: `
            )
            setButtonCharacter('Go to dashboard')
            setMessage(2)
        } else if (message === 2) {
            window.location.href = PATH.homePage
        }
    }

    useEffect(() => {
        if (divRef.current) {
            const currentHeight = divRef.current.scrollHeight
            setListHeight(currentHeight)
        }
    }, [])

    return (
        <div className="bg-beige h-full w-full transition-colors text-dark-blue items-center flex flex-col">
            <div
                ref={divRef}
                className="flex flex-col items-center justify-center w-500px pt-4 transition-all duration-300 ease-in-out fade-in"
                style={{ height: listHeight ? listHeight : '' + 'px' }}
            >
                <div
                    className={`font-large-body-text text-center ${message === 2 ? 'w-600px' : 'w-full  fade-in'}`}
                >
                    {description}
                </div>
                {message === 1 && (
                    <div className="font-large-body-text text-center pt-3 fade-in">
                        Once I’m done, I’ll suggest 7+ marketing ideas that’ll
                        help move the needle{' '}
                        {dataBrandProfile?.brand_name
                            ? 'for ' + dataBrandProfile.brand_name
                            : ''}
                        .
                    </div>
                )}
                {message === 2 && (
                    <div className="font-body-text flex flex-row w-[890px] gap-6 fade-in">
                        <div className="flex-1">
                            <BoxComplete
                                value={0}
                                max={10}
                                mode={BoxCompleteType.ConnectTool}
                                isOnboarding={true}
                            />
                        </div>
                        <div className="flex-1">
                            <BoxComplete
                                value={0}
                                max={10}
                                mode={
                                    BoxCompleteType.BrandCustomizationOnBoarding
                                }
                                isOnboarding={true}
                            />
                        </div>
                        <div className="flex-1">
                            <BoxComplete
                                value={0}
                                max={10}
                                mode={BoxCompleteType.AddOldIdea}
                                isOnboarding={true}
                            />
                        </div>
                    </div>
                )}
                <NeedleButton
                    onClickFunction={nextMessage}
                    buttonCharacter={buttonCharacter}
                    isFull={true}
                    border="py-4"
                    className="pt-8"
                />
            </div>
        </div>
    )
}

export default LastButNotLeastView
