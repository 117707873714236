import type { PaletteOptions } from '@mui/material'
declare module '@mui/material/styles' {
    interface Palette {
        yellow: Palette['grey']
        black: Palette['grey']
        green: Palette['grey']
        blue: Palette['grey']
        orange: Palette['grey']
        red: Palette['grey']
    }

    interface PaletteOptions {
        yellow: PaletteOptions['grey']
        black: PaletteOptions['grey']
        green: PaletteOptions['grey']
        blue: PaletteOptions['grey']
        orange: PaletteOptions['grey']
        red: PaletteOptions['grey']
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        yellow: true
        black: true
        green: true
        blue: true
        orange: true
        red: true
    }
}
export const colors = {
    dark_blue: '#002D43',
    grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#E5E5E5',
        300: '#D4D4D4',
        400: '#A3A3A3',
        500: '#737373',
        600: '#525252',
        700: '#404040',
        800: '#262626',
        900: '#171717',
    },
    yellow: {
        50: '#FEFAF3',
        100: '#FDF0DB',
        200: '#FBE7C3',
        300: '#F9DDAB',
        400: '#F8D393',
        500: '#F6C878',
        600: '#F3B64C',
        700: '#F1AC34',
        800: '#E3960F',
        900: '#9B660A',
    },
    green: {
        50: '#EBF6EF',
        100: '#D7EDDE',
        200: '#C3E5CE',
        300: '#AFDCBD',
        400: '#87CA9D',
        500: '#5FB97C',
        600: '#37A75B',
        700: '#2C8649',
        800: '#216437',
        900: '#164324',
    },
    blue: {
        50: '#EEF3F9',
        100: '#DDE6F3',
        200: '#CDDAEE',
        300: '#BCCEE8',
        400: '#9AB5DC',
        500: '#799DD1',
        600: '#5784C5',
        700: '#466A9E',
        800: '#344F76',
        900: '#23354F',
    },
    orange: {
        50: '#FDF3EB',
        100: '#FCE8D8',
        200: '#FADCC4',
        300: '#F8D1B0',
        400: '#F5B989',
        500: '#F1A261',
        600: '#EE8B3A',
        700: '#BE6F2E',
        800: '#8F5323',
        900: '#5F3817',
    },
    red: {
        50: '#F8EAEB',
        100: '#F1D5D7',
        200: '#EBC1C4',
        300: '#E4ACB0',
        400: '#D68288',
        500: '#C95961',
        600: '#BB2F39',
        700: '#96262E',
        800: '#701C22',
        900: '#4B1317',
    },
    black: {
        50: 'rgba(0, 0, 0, 0.1)',
        100: 'rgba(0, 0, 0, 0.2)',
        200: 'rgba(0, 0, 0, 0.3)',
        300: 'rgba(0, 0, 0, 0.4)',
        400: 'rgba(0, 0, 0, 0.5)',
        500: 'rgba(0, 0, 0, 0.6)',
        600: 'rgba(0, 0, 0, 0.7)',
        700: 'rgba(0, 0, 0, 0.8)',
        800: 'rgba(0, 0, 0, 0.9)',
        900: 'rgba(0, 0, 0, 1)',
    },
    success: {
        100: '#D7EDDE',
        500: '#5FB97C',
        900: '#164324',
    },
    info: {
        100: '#DDE6F3',
        500: '#799DD1',
        900: '#23354F',
    },
    warning: {
        100: '#FCE8D8',
        500: '#F1A261',
        900: '#5F3817',
    },
    error: {
        100: '#F1D5D7',
        500: '#C95961',
        900: '#4B1317',
    },
}
export const palette: PaletteOptions = {
    primary: {
        main: colors.yellow[500],
        contrastText: colors.grey[900],
    },

    secondary: {
        main: colors.yellow[500],
        contrastText: colors.grey[900],
    },
    grey: colors.grey,
    yellow: colors.yellow,
    green: colors.green,
    blue: colors.blue,
    orange: colors.orange,
    red: colors.red,
    black: colors.black,
    success: {
        100: colors.green[100],
        500: colors.green[500],
        900: colors.green[900],
    },
    info: {
        100: colors.blue[100],
        500: colors.blue[500],
        900: colors.blue[900],
    },
    warning: {
        100: colors.orange[100],
        500: colors.orange[500],
        900: colors.orange[900],
    },
    error: {
        100: colors.red[100],
        500: colors.red[500],
        900: colors.red[900],
    },
    action: {
        disabledBackground: colors.yellow[100],
        disabled: colors.black[200],
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
}
