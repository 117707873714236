export const ALL_COUNTRIES = [
    { name: 'Afghanistan', code: 'AF' },
    { name: 'Åland Islands', code: 'AX' },
    { name: 'Albania', code: 'AL' },
    { name: 'Algeria', code: 'DZ' },
    { name: 'American Samoa', code: 'AS' },
    { name: 'AndorrA', code: 'AD' },
    { name: 'Angola', code: 'AO' },
    { name: 'Anguilla', code: 'AI' },
    { name: 'Antarctica', code: 'AQ' },
    { name: 'Antigua and Barbuda', code: 'AG' },
    { name: 'Argentina', code: 'AR' },
    { name: 'Armenia', code: 'AM' },
    { name: 'Aruba', code: 'AW' },
    { name: 'Australia', code: 'AU' },
    { name: 'Austria', code: 'AT' },
    { name: 'Azerbaijan', code: 'AZ' },
    { name: 'Bahamas', code: 'BS' },
    { name: 'Bahrain', code: 'BH' },
    { name: 'Bangladesh', code: 'BD' },
    { name: 'Barbados', code: 'BB' },
    { name: 'Belarus', code: 'BY' },
    { name: 'Belgium', code: 'BE' },
    { name: 'Belize', code: 'BZ' },
    { name: 'Benin', code: 'BJ' },
    { name: 'Bermuda', code: 'BM' },
    { name: 'Bhutan', code: 'BT' },
    { name: 'Bolivia', code: 'BO' },
    { name: 'Bosnia and Herzegovina', code: 'BA' },
    { name: 'Botswana', code: 'BW' },
    { name: 'Bouvet Island', code: 'BV' },
    { name: 'Brazil', code: 'BR' },
    { name: 'British Indian Ocean Territory', code: 'IO' },
    { name: 'Brunei Darussalam', code: 'BN' },
    { name: 'Bulgaria', code: 'BG' },
    { name: 'Burkina Faso', code: 'BF' },
    { name: 'Burundi', code: 'BI' },
    { name: 'Cambodia', code: 'KH' },
    { name: 'Cameroon', code: 'CM' },
    { name: 'Canada', code: 'CA' },
    { name: 'Cape Verde', code: 'CV' },
    { name: 'Cayman Islands', code: 'KY' },
    { name: 'Central African Republic', code: 'CF' },
    { name: 'Chad', code: 'TD' },
    { name: 'Chile', code: 'CL' },
    { name: 'China', code: 'CN' },
    { name: 'Christmas Island', code: 'CX' },
    { name: 'Cocos (Keeling) Islands', code: 'CC' },
    { name: 'Colombia', code: 'CO' },
    { name: 'Comoros', code: 'KM' },
    { name: 'Congo', code: 'CG' },
    { name: 'Congo, The Democratic Republic of the', code: 'CD' },
    { name: 'Cook Islands', code: 'CK' },
    { name: 'Costa Rica', code: 'CR' },
    { name: "Cote D'Ivoire", code: 'CI' },
    { name: 'Croatia', code: 'HR' },
    { name: 'Cuba', code: 'CU' },
    { name: 'Cyprus', code: 'CY' },
    { name: 'Czech Republic', code: 'CZ' },
    { name: 'Denmark', code: 'DK' },
    { name: 'Djibouti', code: 'DJ' },
    { name: 'Dominica', code: 'DM' },
    { name: 'Dominican Republic', code: 'DO' },
    { name: 'Ecuador', code: 'EC' },
    { name: 'Egypt', code: 'EG' },
    { name: 'El Salvador', code: 'SV' },
    { name: 'Equatorial Guinea', code: 'GQ' },
    { name: 'Eritrea', code: 'ER' },
    { name: 'Estonia', code: 'EE' },
    { name: 'Ethiopia', code: 'ET' },
    { name: 'Falkland Islands (Malvinas)', code: 'FK' },
    { name: 'Faroe Islands', code: 'FO' },
    { name: 'Fiji', code: 'FJ' },
    { name: 'Finland', code: 'FI' },
    { name: 'France', code: 'FR' },
    { name: 'French Guiana', code: 'GF' },
    { name: 'French Polynesia', code: 'PF' },
    { name: 'French Southern Territories', code: 'TF' },
    { name: 'Gabon', code: 'GA' },
    { name: 'Gambia', code: 'GM' },
    { name: 'Georgia', code: 'GE' },
    { name: 'Germany', code: 'DE' },
    { name: 'Ghana', code: 'GH' },
    { name: 'Gibraltar', code: 'GI' },
    { name: 'Greece', code: 'GR' },
    { name: 'Greenland', code: 'GL' },
    { name: 'Grenada', code: 'GD' },
    { name: 'Guadeloupe', code: 'GP' },
    { name: 'Guam', code: 'GU' },
    { name: 'Guatemala', code: 'GT' },
    { name: 'Guernsey', code: 'GG' },
    { name: 'Guinea', code: 'GN' },
    { name: 'Guinea-Bissau', code: 'GW' },
    { name: 'Guyana', code: 'GY' },
    { name: 'Haiti', code: 'HT' },
    { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
    { name: 'Holy See (Vatican City State)', code: 'VA' },
    { name: 'Honduras', code: 'HN' },
    { name: 'Hong Kong', code: 'HK' },
    { name: 'Hungary', code: 'HU' },
    { name: 'Iceland', code: 'IS' },
    { name: 'India', code: 'IN' },
    { name: 'Indonesia', code: 'ID' },
    { name: 'Iran, Islamic Republic Of', code: 'IR' },
    { name: 'Iraq', code: 'IQ' },
    { name: 'Ireland', code: 'IE' },
    { name: 'Isle of Man', code: 'IM' },
    { name: 'Israel', code: 'IL' },
    { name: 'Italy', code: 'IT' },
    { name: 'Jamaica', code: 'JM' },
    { name: 'Japan', code: 'JP' },
    { name: 'Jersey', code: 'JE' },
    { name: 'Jordan', code: 'JO' },
    { name: 'Kazakhstan', code: 'KZ' },
    { name: 'Kenya', code: 'KE' },
    { name: 'Kiribati', code: 'KI' },
    { name: "Korea, Democratic People'S Republic of", code: 'KP' },
    { name: 'Korea, Republic of', code: 'KR' },
    { name: 'Kuwait', code: 'KW' },
    { name: 'Kyrgyzstan', code: 'KG' },
    { name: "Lao People'S Democratic Republic", code: 'LA' },
    { name: 'Latvia', code: 'LV' },
    { name: 'Lebanon', code: 'LB' },
    { name: 'Lesotho', code: 'LS' },
    { name: 'Liberia', code: 'LR' },
    { name: 'Libyan Arab Jamahiriya', code: 'LY' },
    { name: 'Liechtenstein', code: 'LI' },
    { name: 'Lithuania', code: 'LT' },
    { name: 'Luxembourg', code: 'LU' },
    { name: 'Macao', code: 'MO' },
    { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
    { name: 'Madagascar', code: 'MG' },
    { name: 'Malawi', code: 'MW' },
    { name: 'Malaysia', code: 'MY' },
    { name: 'Maldives', code: 'MV' },
    { name: 'Mali', code: 'ML' },
    { name: 'Malta', code: 'MT' },
    { name: 'Marshall Islands', code: 'MH' },
    { name: 'Martinique', code: 'MQ' },
    { name: 'Mauritania', code: 'MR' },
    { name: 'Mauritius', code: 'MU' },
    { name: 'Mayotte', code: 'YT' },
    { name: 'Mexico', code: 'MX' },
    { name: 'Micronesia, Federated States of', code: 'FM' },
    { name: 'Moldova, Republic of', code: 'MD' },
    { name: 'Monaco', code: 'MC' },
    { name: 'Mongolia', code: 'MN' },
    { name: 'Montserrat', code: 'MS' },
    { name: 'Morocco', code: 'MA' },
    { name: 'Mozambique', code: 'MZ' },
    { name: 'Myanmar', code: 'MM' },
    { name: 'Namibia', code: 'NA' },
    { name: 'Nauru', code: 'NR' },
    { name: 'Nepal', code: 'NP' },
    { name: 'Netherlands', code: 'NL' },
    { name: 'Netherlands Antilles', code: 'AN' },
    { name: 'New Caledonia', code: 'NC' },
    { name: 'New Zealand', code: 'NZ' },
    { name: 'Nicaragua', code: 'NI' },
    { name: 'Niger', code: 'NE' },
    { name: 'Nigeria', code: 'NG' },
    { name: 'Niue', code: 'NU' },
    { name: 'Norfolk Island', code: 'NF' },
    { name: 'Northern Mariana Islands', code: 'MP' },
    { name: 'Norway', code: 'NO' },
    { name: 'Oman', code: 'OM' },
    { name: 'Pakistan', code: 'PK' },
    { name: 'Palau', code: 'PW' },
    { name: 'Palestinian Territory, Occupied', code: 'PS' },
    { name: 'Panama', code: 'PA' },
    { name: 'Papua New Guinea', code: 'PG' },
    { name: 'Paraguay', code: 'PY' },
    { name: 'Peru', code: 'PE' },
    { name: 'Philippines', code: 'PH' },
    { name: 'Pitcairn', code: 'PN' },
    { name: 'Poland', code: 'PL' },
    { name: 'Portugal', code: 'PT' },
    { name: 'Puerto Rico', code: 'PR' },
    { name: 'Qatar', code: 'QA' },
    { name: 'Reunion', code: 'RE' },
    { name: 'Romania', code: 'RO' },
    { name: 'Russian Federation', code: 'RU' },
    { name: 'RWANDA', code: 'RW' },
    { name: 'Saint Helena', code: 'SH' },
    { name: 'Saint Kitts and Nevis', code: 'KN' },
    { name: 'Saint Lucia', code: 'LC' },
    { name: 'Saint Pierre and Miquelon', code: 'PM' },
    { name: 'Saint Vincent and the Grenadines', code: 'VC' },
    { name: 'Samoa', code: 'WS' },
    { name: 'San Marino', code: 'SM' },
    { name: 'Sao Tome and Principe', code: 'ST' },
    { name: 'Saudi Arabia', code: 'SA' },
    { name: 'Senegal', code: 'SN' },
    { name: 'Serbia and Montenegro', code: 'CS' },
    { name: 'Seychelles', code: 'SC' },
    { name: 'Sierra Leone', code: 'SL' },
    { name: 'Singapore', code: 'SG' },
    { name: 'Slovakia', code: 'SK' },
    { name: 'Slovenia', code: 'SI' },
    { name: 'Solomon Islands', code: 'SB' },
    { name: 'Somalia', code: 'SO' },
    { name: 'South Africa', code: 'ZA' },
    { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
    { name: 'Spain', code: 'ES' },
    { name: 'Sri Lanka', code: 'LK' },
    { name: 'Sudan', code: 'SD' },
    { name: 'Suriname', code: 'SR' },
    { name: 'Svalbard and Jan Mayen', code: 'SJ' },
    { name: 'Swaziland', code: 'SZ' },
    { name: 'Sweden', code: 'SE' },
    { name: 'Switzerland', code: 'CH' },
    { name: 'Syrian Arab Republic', code: 'SY' },
    { name: 'Taiwan, Province of China', code: 'TW' },
    { name: 'Tajikistan', code: 'TJ' },
    { name: 'Tanzania, United Republic of', code: 'TZ' },
    { name: 'Thailand', code: 'TH' },
    { name: 'Timor-Leste', code: 'TL' },
    { name: 'Togo', code: 'TG' },
    { name: 'Tokelau', code: 'TK' },
    { name: 'Tonga', code: 'TO' },
    { name: 'Trinidad and Tobago', code: 'TT' },
    { name: 'Tunisia', code: 'TN' },
    { name: 'Turkey', code: 'TR' },
    { name: 'Turkmenistan', code: 'TM' },
    { name: 'Turks and Caicos Islands', code: 'TC' },
    { name: 'Tuvalu', code: 'TV' },
    { name: 'Uganda', code: 'UG' },
    { name: 'Ukraine', code: 'UA' },
    { name: 'United Arab Emirates', code: 'AE' },
    { name: 'United Kingdom', code: 'GB' },
    { name: 'United States', code: 'US' },
    { name: 'United States Minor Outlying Islands', code: 'UM' },
    { name: 'Uruguay', code: 'UY' },
    { name: 'Uzbekistan', code: 'UZ' },
    { name: 'Vanuatu', code: 'VU' },
    { name: 'Venezuela', code: 'VE' },
    { name: 'Viet Nam', code: 'VN' },
    { name: 'Virgin Islands, British', code: 'VG' },
    { name: 'Virgin Islands, U.S.', code: 'VI' },
    { name: 'Wallis and Futuna', code: 'WF' },
    { name: 'Western Sahara', code: 'EH' },
    { name: 'Yemen', code: 'YE' },
    { name: 'Zambia', code: 'ZM' },
    { name: 'Zimbabwe', code: 'ZW' },
]

export const MARKET_OPTIONS = [
    {
        label: 'Afghanistan',
        value: 'AF',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Åland Islands',
        value: 'AX',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Albania',
        value: 'AL',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Algeria',
        value: 'DZ',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'American Samoa',
        value: 'AS',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'AndorrA',
        value: 'AD',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Angola',
        value: 'AO',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Anguilla',
        value: 'AI',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Antarctica',
        value: 'AQ',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Antigua and Barbuda',
        value: 'AG',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Argentina',
        value: 'AR',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Armenia',
        value: 'AM',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Aruba',
        value: 'AW',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Australia',
        value: 'AU',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Austria',
        value: 'AT',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Azerbaijan',
        value: 'AZ',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Bahamas',
        value: 'BS',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Bahrain',
        value: 'BH',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Bangladesh',
        value: 'BD',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Barbados',
        value: 'BB',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Belarus',
        value: 'BY',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Belgium',
        value: 'BE',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Belize',
        value: 'BZ',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Benin',
        value: 'BJ',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Bermuda',
        value: 'BM',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Bhutan',
        value: 'BT',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Bolivia',
        value: 'BO',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Bosnia and Herzegovina',
        value: 'BA',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Botswana',
        value: 'BW',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Bouvet Island',
        value: 'BV',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Brazil',
        value: 'BR',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'British Indian Ocean Territory',
        value: 'IO',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Brunei Darussalam',
        value: 'BN',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Bulgaria',
        value: 'BG',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Burkina Faso',
        value: 'BF',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Burundi',
        value: 'BI',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Cambodia',
        value: 'KH',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Cameroon',
        value: 'CM',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Canada',
        value: 'CA',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Cape Verde',
        value: 'CV',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Cayman Islands',
        value: 'KY',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Central African Republic',
        value: 'CF',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Chad',
        value: 'TD',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Chile',
        value: 'CL',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'China',
        value: 'CN',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Christmas Island',
        value: 'CX',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Cocos (Keeling) Islands',
        value: 'CC',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Colombia',
        value: 'CO',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Comoros',
        value: 'KM',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Congo',
        value: 'CG',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Congo, The Democratic Republic of the',
        value: 'CD',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Cook Islands',
        value: 'CK',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Costa Rica',
        value: 'CR',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: "Cote D'Ivoire",
        value: 'CI',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Croatia',
        value: 'HR',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Cuba',
        value: 'CU',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Cyprus',
        value: 'CY',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Czech Republic',
        value: 'CZ',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Denmark',
        value: 'DK',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Djibouti',
        value: 'DJ',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Dominica',
        value: 'DM',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Dominican Republic',
        value: 'DO',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Ecuador',
        value: 'EC',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Egypt',
        value: 'EG',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'El Salvador',
        value: 'SV',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Equatorial Guinea',
        value: 'GQ',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Eritrea',
        value: 'ER',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Estonia',
        value: 'EE',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Ethiopia',
        value: 'ET',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Falkland Islands (Malvinas)',
        value: 'FK',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Faroe Islands',
        value: 'FO',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Fiji',
        value: 'FJ',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Finland',
        value: 'FI',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'France',
        value: 'FR',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'French Guiana',
        value: 'GF',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'French Polynesia',
        value: 'PF',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'French Southern Territories',
        value: 'TF',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Gabon',
        value: 'GA',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Gambia',
        value: 'GM',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Georgia',
        value: 'GE',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Germany',
        value: 'DE',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Ghana',
        value: 'GH',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Gibraltar',
        value: 'GI',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Greece',
        value: 'GR',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Greenland',
        value: 'GL',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Grenada',
        value: 'GD',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Guadeloupe',
        value: 'GP',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Guam',
        value: 'GU',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Guatemala',
        value: 'GT',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Guernsey',
        value: 'GG',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Guinea',
        value: 'GN',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Guinea-Bissau',
        value: 'GW',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Guyana',
        value: 'GY',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Haiti',
        value: 'HT',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Heard Island and Mcdonald Islands',
        value: 'HM',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Holy See (Vatican City State)',
        value: 'VA',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Honduras',
        value: 'HN',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Hong Kong',
        value: 'HK',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Hungary',
        value: 'HU',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Iceland',
        value: 'IS',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'India',
        value: 'IN',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Indonesia',
        value: 'ID',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Iran, Islamic Republic Of',
        value: 'IR',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Iraq',
        value: 'IQ',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Ireland',
        value: 'IE',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Isle of Man',
        value: 'IM',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Israel',
        value: 'IL',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Italy',
        value: 'IT',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Jamaica',
        value: 'JM',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Japan',
        value: 'JP',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Jersey',
        value: 'JE',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Jordan',
        value: 'JO',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Kazakhstan',
        value: 'KZ',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Kenya',
        value: 'KE',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Kiribati',
        value: 'KI',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: "Korea, Democratic People'S Republic of",
        value: 'KP',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Korea, Republic of',
        value: 'KR',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Kuwait',
        value: 'KW',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Kyrgyzstan',
        value: 'KG',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: "Lao People'S Democratic Republic",
        value: 'LA',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Latvia',
        value: 'LV',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Lebanon',
        value: 'LB',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Lesotho',
        value: 'LS',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Liberia',
        value: 'LR',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Libyan Arab Jamahiriya',
        value: 'LY',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Liechtenstein',
        value: 'LI',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Lithuania',
        value: 'LT',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Luxembourg',
        value: 'LU',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Macao',
        value: 'MO',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Macedonia, The Former Yugoslav Republic of',
        value: 'MK',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Madagascar',
        value: 'MG',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Malawi',
        value: 'MW',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Malaysia',
        value: 'MY',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Maldives',
        value: 'MV',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Mali',
        value: 'ML',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Malta',
        value: 'MT',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Marshall Islands',
        value: 'MH',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Martinique',
        value: 'MQ',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Mauritania',
        value: 'MR',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Mauritius',
        value: 'MU',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Mayotte',
        value: 'YT',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Mexico',
        value: 'MX',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Micronesia, Federated States of',
        value: 'FM',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Moldova, Republic of',
        value: 'MD',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Monaco',
        value: 'MC',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Mongolia',
        value: 'MN',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Montserrat',
        value: 'MS',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Morocco',
        value: 'MA',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Mozambique',
        value: 'MZ',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Myanmar',
        value: 'MM',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Namibia',
        value: 'NA',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Nauru',
        value: 'NR',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Nepal',
        value: 'NP',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Netherlands',
        value: 'NL',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Netherlands Antilles',
        value: 'AN',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'New Caledonia',
        value: 'NC',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'New Zealand',
        value: 'NZ',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Nicaragua',
        value: 'NI',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Niger',
        value: 'NE',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Nigeria',
        value: 'NG',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Niue',
        value: 'NU',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Norfolk Island',
        value: 'NF',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Northern Mariana Islands',
        value: 'MP',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Norway',
        value: 'NO',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Oman',
        value: 'OM',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Pakistan',
        value: 'PK',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Palau',
        value: 'PW',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Palestinian Territory, Occupied',
        value: 'PS',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Panama',
        value: 'PA',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Papua New Guinea',
        value: 'PG',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Paraguay',
        value: 'PY',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Peru',
        value: 'PE',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Philippines',
        value: 'PH',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Pitcairn',
        value: 'PN',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Poland',
        value: 'PL',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Portugal',
        value: 'PT',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Puerto Rico',
        value: 'PR',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Qatar',
        value: 'QA',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Reunion',
        value: 'RE',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Romania',
        value: 'RO',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Russian Federation',
        value: 'RU',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'RWANDA',
        value: 'RW',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Saint Helena',
        value: 'SH',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Saint Kitts and Nevis',
        value: 'KN',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Saint Lucia',
        value: 'LC',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Saint Pierre and Miquelon',
        value: 'PM',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Saint Vincent and the Grenadines',
        value: 'VC',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Samoa',
        value: 'WS',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'San Marino',
        value: 'SM',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Sao Tome and Principe',
        value: 'ST',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Saudi Arabia',
        value: 'SA',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Senegal',
        value: 'SN',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Serbia and Montenegro',
        value: 'CS',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Seychelles',
        value: 'SC',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Sierra Leone',
        value: 'SL',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Singapore',
        value: 'SG',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Slovakia',
        value: 'SK',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Slovenia',
        value: 'SI',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Solomon Islands',
        value: 'SB',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Somalia',
        value: 'SO',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'South Africa',
        value: 'ZA',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'South Georgia and the South Sandwich Islands',
        value: 'GS',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Spain',
        value: 'ES',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Sri Lanka',
        value: 'LK',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Sudan',
        value: 'SD',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Suriname',
        value: 'SR',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Svalbard and Jan Mayen',
        value: 'SJ',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Swaziland',
        value: 'SZ',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Sweden',
        value: 'SE',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Switzerland',
        value: 'CH',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Syrian Arab Republic',
        value: 'SY',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Taiwan, Province of China',
        value: 'TW',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Tajikistan',
        value: 'TJ',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Tanzania, United Republic of',
        value: 'TZ',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Thailand',
        value: 'TH',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Timor-Leste',
        value: 'TL',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Togo',
        value: 'TG',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Tokelau',
        value: 'TK',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Tonga',
        value: 'TO',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Trinidad and Tobago',
        value: 'TT',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Tunisia',
        value: 'TN',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Turkey',
        value: 'TR',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Turkmenistan',
        value: 'TM',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Turks and Caicos Islands',
        value: 'TC',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Tuvalu',
        value: 'TV',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Uganda',
        value: 'UG',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Ukraine',
        value: 'UA',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'United Arab Emirates',
        value: 'AE',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'United Kingdom',
        value: 'GB',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'United States',
        value: 'US',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'United States Minor Outlying Islands',
        value: 'UM',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Uruguay',
        value: 'UY',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Uzbekistan',
        value: 'UZ',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Vanuatu',
        value: 'VU',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Venezuela',
        value: 'VE',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Viet Nam',
        value: 'VN',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Virgin Islands, British',
        value: 'VG',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Virgin Islands, U.S.',
        value: 'VI',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Wallis and Futuna',
        value: 'WF',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Western Sahara',
        value: 'EH',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Yemen',
        value: 'YE',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Zambia',
        value: 'ZM',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
    {
        label: 'Zimbabwe',
        value: 'ZW',
        image: '/assets/icons/vietnam-flag-icon.svg',
    },
]
