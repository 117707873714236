import React, { useEffect, useState } from 'react'
import { SmartAITool } from '../../components/CoverBox/SmartAITool'
import { ActionData } from '../HomeScreen/constants'
import IdeasItem from './IdeasItem'
import { BoxComplete, BoxCompleteType } from '../../components/BoxComplete'
import ideasStore from '../../storeZustand/ideasStore'
import './IdeasPage.scss'
import { formatDataIdeasList, usePageTitle } from '../HomeScreen/component'
import { useNavigate } from 'react-router-dom'
import { PATH } from '../../constants/path'

export const IdeasPage = () => {
    usePageTitle('Needle - Ideas')
    const [listNewIdeas, setListNewIdeas] = useState<ActionData[]>([])
    const [listArchiveIdeas, setListArchiveIdeas] = useState<ActionData[]>([])
    const [isShowArchive, setIsShowArchive] = useState<boolean>(false)
    const navigate = useNavigate()
    const {
        needleNewIdeas,
        needleArchive,
        needleReview,
        needleIsRead,
        needleAllIdea,
        isLoaded,
    } = ideasStore((state) => ({
        needleNewIdeas: state.needleNewIdeas,
        needleArchive: state.needleArchive,
        needleReview: state.needleReview,
        needleIsRead: state.needleIsRead,
        needleAllIdea: state.needleAllIdea,
        isLoaded: state.isLoaded,
    }))

    useEffect(() => {
        if (isLoaded && needleAllIdea === 0) {
            navigate(PATH.homePage)
        }
    }, [isLoaded, needleAllIdea])

    const handleClickShowArchive = (): void => {
        setIsShowArchive(!isShowArchive)
    }

    useEffect(() => {
        setListNewIdeas(
            formatDataIdeasList([...needleNewIdeas, ...needleIsRead])
        )
        setListArchiveIdeas(formatDataIdeasList(needleArchive))
    }, [needleNewIdeas, needleArchive])

    return (
        <div className="bg-beige h-full w-full transition-colors text-dark-blue flex flex-col items-center">
            <SmartAITool pageWord={'Ideas'} sparkle={true} />
            <div className="font-body-text text-dark-blue pt-2">
                New ways to help your business grow, based on your data and
                brand
            </div>
            <div className="flex flex-col justify-center items-center w-full mb-14">
                <div className="flex flex-col w-4/5 sm:w-5/6 max-w-680px animated-list">
                    {listNewIdeas.map((idea: ActionData, index: number) => (
                        <IdeasItem idea={idea} key={idea.id} index={index} />
                    ))}
                    {needleReview.length > 0 ? (
                        <BoxComplete
                            value={needleIsRead.length - needleReview.length}
                            max={needleIsRead.length}
                            mode={BoxCompleteType.ReviewIdeas}
                        />
                    ) : (
                        <div
                            className="bg-beige-2 rounded-lg p-6 py-16 mt-6 mb-2 text-dark-blue 
                            justify-center items-center flex flex-col border-custom-dashed"
                        >
                            <div className="font-header-3">
                                More ideas coming up soon!
                            </div>
                            <div className="font-body-text pt-2">
                                I’ll notify you as soon as new ideas are
                                available
                            </div>
                        </div>
                    )}
                    {listArchiveIdeas.length > 0 && (
                        <div
                            className="font-link-body-text text-dark-blue cursor-pointer flex pt-14 justify-center"
                            onClick={handleClickShowArchive}
                        >
                            <img
                                src="/assets/svgs/arrow-pointer.svg"
                                alt="arrow-pointer"
                                className={`${
                                    isShowArchive ? 'rotate-180' : ''
                                } transition-transform duration-300`}
                            />
                            <div className="pl-1">{`${isShowArchive ? 'Hide' : 'Show'} archived ideas`}</div>
                        </div>
                    )}
                    {isShowArchive &&
                        listArchiveIdeas.map(
                            (idea: ActionData, index: number) => (
                                <IdeasItem
                                    idea={idea}
                                    key={idea.id}
                                    index={index}
                                    isArchive={true}
                                />
                            )
                        )}
                </div>
            </div>
        </div>
    )
}

export default IdeasPage
