import { NeedleImage } from '../../components/NeedleImage'
import { Product } from '../../utils/needleEnum'
import {
    GRID,
    IMAGE_CARD_HEIGHT,
    OPACITY_LOST,
    SUB_CARD_HEIGHT,
    SUB_CARD_SHIFT,
    SUB_CARD_WIDTH_LOST,
} from './constants'

interface ImageListDropDownProps {
    groupImage: Product[][]
    isDropdown: boolean
}
export const ImageListDropDown = ({
    groupImage,
    isDropdown,
}: ImageListDropDownProps) => {
    return (
        <div className="relative mt-3">
            <div
                className={`flex flex-row rounded-2xl h-${IMAGE_CARD_HEIGHT}px invisible`}
            />
            <div className="flex flex-row rounded-2xl absolute top-0">
                <div className={`flex flex-col w-560px`}>
                    {groupImage.map((imageList, indexLine) => (
                        <div
                            key={imageList[0] + indexLine.toString()}
                            className="flex flex-row absolute transition-all duration-300"
                            style={{
                                top: isDropdown
                                    ? `${
                                          indexLine * (IMAGE_CARD_HEIGHT + GRID)
                                      }px`
                                    : `${indexLine * SUB_CARD_HEIGHT}px`,
                                opacity: isDropdown
                                    ? 1
                                    : indexLine > 1
                                      ? '0'
                                      : 1 - indexLine * OPACITY_LOST,
                                left: isDropdown
                                    ? '0'
                                    : `${SUB_CARD_SHIFT * indexLine}px`,
                                width: isDropdown
                                    ? '100%'
                                    : `calc(100% - ${
                                          indexLine * SUB_CARD_WIDTH_LOST
                                      }px)`,
                                zIndex: -indexLine,
                            }}
                        >
                            {groupImage[indexLine].map((data, index) => (
                                <div key={data.id + index}>
                                    <NeedleImage
                                        src={data.image}
                                        alt={data.title}
                                        className={`h-140px w-140px  ${
                                            index === 0 && 'rounded-s-md'
                                        } ${
                                            index ===
                                                groupImage[indexLine].length -
                                                    1 && 'rounded-e-md'
                                        }`}
                                    />
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ImageListDropDown
