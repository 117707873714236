import { axiosInstance, handleResponse, getOptions } from '../api'
import { needdleConfig } from '../api/config'

export const AdminPreviewServices = {
    getAllBrands: async () => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(needdleConfig.ADMIN_PREVIEW, options)
        )
    },
    updatePreviewWorkspace: async (workspace_id: string) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.patch(
                needdleConfig.ADMIN_PREVIEW + `/${workspace_id}`,
                { workspace_id },
                options
            )
        )
    },
}
