import React, { useEffect, useState } from 'react'
import './GettingResultLayout.scss'
import { resultRoutes, RouteNavigation, RouteType } from '../../routes'
import { useNavigate } from 'react-router-dom'
import { ListItemIcon } from '@mui/material'
import { Flag, FlagColor } from '../../components/Flag'
import ideasStore from '../../storeZustand/ideasStore'
import useGrowthPlan from '../../hooks/useGrowthPlan'

interface NavigationListProps {
    currentUser: Needle.User
}

export default function NavigationList({ currentUser }: NavigationListProps) {
    const navigate = useNavigate()
    const { isLoading: isLoadingdGrowthPlan } = useGrowthPlan()
    const [isDisplayIdeasPage, setIsDisplayIdeasPage] = useState<boolean>(true)
    const currentPath = window.location.pathname
    const {
        needleNewIdeas,
        needleReview,
        needleIsRead,
        needleAction,
        needleAllIdea,
    } = ideasStore((state) => ({
        needleNewIdeas: state.needleNewIdeas,
        needleArchive: state.needleArchive,
        needleReview: state.needleReview,
        needleIsRead: state.needleIsRead,
        needleAction: state.needleAction,
        needleDone: state.needleDone,
        needleAllIdea: state.needleAllIdea,
    }))

    useEffect(() => {
        if (!isLoadingdGrowthPlan) {
            if (needleAllIdea === 0) {
                setIsDisplayIdeasPage(false)
            } else {
                setIsDisplayIdeasPage(true)
            }
        }
    }, [needleAllIdea, isLoadingdGrowthPlan])

    const numIdeas = needleNewIdeas.length + needleIsRead.length
    const mainRoutes = resultRoutes.filter(
        (item) => item.routeType === RouteType.MainFunction
    )
    const adminRoutes = resultRoutes.filter(
        (item) => item.routeType === RouteType.AdminFunction
    )
    const finalRoutes =
        currentUser.role === 'admin'
            ? [...mainRoutes, ...adminRoutes]
            : [...mainRoutes]

    const renderDetailList = (
        item: RouteNavigation
    ): JSX.Element[] | undefined => {
        if (currentPath.includes(item.path) && item.routeSubList) {
            return item.routeSubList.map((route) => (
                <div
                    className={`cursor-pointer flex flex-row items-center hover:opacity-100 hover:bg-transparent pt-4 pl-8 text-base leading-110 font-sans
                    ${currentPath.includes(route.path) ? 'font-bold' : 'opacity-50'}
                    `}
                    key={route.name ? route.name : route.path}
                    onClick={() => navigate(route.path)}
                >
                    <div>{route.name}</div>
                    {route.name == 'Review Past Ideas' &&
                        needleReview.length > 0 && (
                            <Flag
                                flagNumber={needleReview.length}
                                color={FlagColor.blue}
                            />
                        )}
                </div>
            ))
        }
    }

    return (
        <div className="gap-6 flex flex-col text-dark-blue">
            {finalRoutes.map((item) => {
                if (!isDisplayIdeasPage && item.name === 'Ideas') {
                    return null
                }
                return (
                    <div className="" key={item.name ? item.name : item.path}>
                        <div
                            className={`cursor-pointer flex flex-row items-center hover:opacity-100 hover:bg-transparent
                            ${currentPath.includes(item.path) ? '' : 'opacity-50'}
                        `}
                            onClick={() => navigate(item.path)}
                        >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <div className="font-header-3">{item.name}</div>
                            {item.name === 'Ideas' && numIdeas > 0 && (
                                <Flag
                                    flagNumber={numIdeas}
                                    color={FlagColor.blue}
                                />
                            )}
                            {item.name === 'Actions' &&
                                needleAction.length > 0 && (
                                    <Flag
                                        flagNumber={needleAction.length}
                                        color={FlagColor.blue}
                                    />
                                )}
                        </div>
                        {renderDetailList(item)}
                    </div>
                )
            })}
        </div>
    )
}
