import styled from '@emotion/styled'
import { CheckboxProps, Checkbox } from '@mui/material'
import { ReactComponent as CheckboxIconFilled } from '../assets/svgs/checkbox-filled-uncheck.svg'
import { ReactComponent as CheckboxIconCheckedFilled } from '../assets/svgs/checkbox-filled.svg'
import { ReactComponent as CheckboxIcon } from '../assets/svgs/checkbox.svg'
import { ReactComponent as CheckboxCheckedIcon } from '../assets/svgs/checkbox-checked.svg'

export const StyleCheckbox = styled((props: CheckboxProps) => (
    <Checkbox
        checkedIcon={<CheckboxCheckedIcon />}
        icon={<CheckboxIcon />}
        {...props}
    />
))(({ theme }) => ({
    textTransform: 'none',
    fontWeight: '500',
    fontSize: '16px',
    fontFamily: 'Work Sans',
    color: 'rgba(0, 0, 0, 0.3)',
    '&.Mui-selected': {
        color: 'var(--primary-color)',
    },
}))

export const StyleCheckboxFill = styled((props: CheckboxProps) => (
    <Checkbox
        checkedIcon={<CheckboxIconCheckedFilled />}
        icon={<CheckboxIconFilled />}
        {...props}
    />
))(({ theme }) => ({
    textTransform: 'none',
    fontWeight: '500',
    fontSize: '16px',
    fontFamily: 'Work Sans',
    color: 'rgba(0, 0, 0, 0.3)',
    '&.Mui-selected': {
        color: 'var(--primary-color)',
    },
}))
