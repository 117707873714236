import React, { useEffect, useRef, useState } from 'react'
import './TextSection.scss'
import { LinkAndMediaType } from '../../views/BrandProfilePage/type'
import { NeedleButton } from '../NeedleButton'
interface CardTypeLinkAndMediaProps {
    description?: string
    setCurrentData: (value: React.SetStateAction<LinkAndMediaType>) => void
    currentData: LinkAndMediaType
    setIsDisable: React.Dispatch<React.SetStateAction<boolean>>
}

function CardTypeLinkAndMedia({
    description,
    setCurrentData,
    currentData,
    setIsDisable,
}: CardTypeLinkAndMediaProps) {
    const [link, setLink] = useState<string>(currentData.link)
    const [media, setMedia] = useState<File[]>(currentData.media)
    const fileMediaRef = useRef<HTMLInputElement>(null)

    const handleClickMedia = (): void => {
        if (fileMediaRef.current) {
            fileMediaRef.current.click()
        }
    }

    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const files = Array.from(event.target.files as FileList)
        if (files.length > 0) {
            setMedia((prevMedia) => [...prevMedia, ...files])
            event.target.value = ''
        }
    }

    const removeItem = (index: number): void => {
        setMedia((prevMedia) => prevMedia.filter((_, i) => i !== index))
    }

    useEffect(() => {
        if (link.trim() !== '' || media.length > 0) {
            const dataLinkAndMedia: LinkAndMediaType = {
                link: link,
                media: media,
            }
            setCurrentData(dataLinkAndMedia)
            setIsDisable(false)
        } else {
            setIsDisable(true)
        }
    }, [link, media])

    return (
        <div className="mx-6 mt-6 mb-2 font-subtitle">
            <div className="pb-3">Media link</div>
            <textarea
                rows={3}
                id="first_name"
                className="border border-beige-outline font-body-text
                py-3 px-4 w-full rounded-lg focus:outline-dark-blue"
                placeholder={description}
                value={link}
                onChange={(e) => {
                    setLink(e.target.value)
                }}
            />
            <div className="pb-3 pt-2">Media upload</div>
            <div className="rounded-lg py-6 border-dashed border-2 outline-beige-outline flex justify-center flex-col items-center">
                {media.length > 0 && (
                    <div className="pb-6 flex flex-row flex-wrap gap-4 pl-8 w-full">
                        {media.map((mediaItem, index) => (
                            <div
                                className="border outline-beige-outline truncate rounded-lg w-[30%] shadow-un-click-card"
                                key={mediaItem.lastModified}
                            >
                                <div className="flex justify-center py-10">
                                    MEDIA FILE
                                </div>
                                <div className="h-0.5 w-full bg-beige-outline" />
                                <div className="flex flex-row justify-center items-center px-3 my-10px">
                                    <div className="truncate flex-1">
                                        {mediaItem.name}
                                    </div>
                                    <div
                                        className="flex cursor-pointer"
                                        onClick={() => {
                                            removeItem(index)
                                        }}
                                    >
                                        <img
                                            src="/assets/icons/trash-icon.svg"
                                            alt="trash-icon"
                                            className="h-4 w-4"
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                <div className="flex justify-center items-center">
                    <NeedleButton
                        onClickFunction={handleClickMedia}
                        buttonCharacter={
                            media.length > 0 ? 'Upload another file' : 'Upload'
                        }
                    />
                </div>
            </div>
            <input
                type="file"
                name="myMedia"
                ref={fileMediaRef}
                onChange={handleUpload}
                style={{ display: 'none' }}
                accept=".jpeg, .jpg, .png, .gif, .bmp, .svg .mp4, .mov, .wmv, .avi, .mkv"
                multiple
            />
        </div>
    )
}

export default CardTypeLinkAndMedia
