import React, { useEffect, useState } from 'react'
import { BrandMetricsType } from '../../views/BrandProfilePage/type'
import { formatNumber, handleChange } from './component'
import ideasStore from '../../storeZustand/ideasStore'

interface CardTypeMetricsProps {
    setCurrentData?: any
    currentData: any
    setIsDisable: any
}
function CardTypeMetrics({
    setCurrentData,
    currentData,
    setIsDisable,
}: CardTypeMetricsProps) {
    const [marketSpend, setMarketSpend] = useState<number | undefined>(
        currentData.marketSpend
    )
    const [grossMargin, setGrossMargin] = useState<number | undefined>(
        currentData.grossMargin
    )
    const [shippingCost, setShippingCost] = useState<number | undefined>(
        currentData.shippingCost
    )
    const { currency } = ideasStore((state) => ({
        currency: state.currency,
    }))
    useEffect(() => {
        if (marketSpend && grossMargin && shippingCost) {
            const DataMetrics: BrandMetricsType = {
                marketSpend: marketSpend,
                grossMargin: grossMargin,
                shippingCost: shippingCost,
            }
            setCurrentData(DataMetrics)
            setIsDisable(false)
        } else {
            setIsDisable(true)
        }
    }, [marketSpend, grossMargin, shippingCost])
    return (
        <div className="mx-6 mb-2">
            <div className="font-subtitle text-dark-blue mt-6">
                <div>
                    Marketing spend per month
                    <span className="text-red-theme">*</span>
                </div>
                <div className="font-small-text pt-2">
                    This helps me suggest budgets to test ideas
                </div>
                <div className="flex flex-row pt-2 text-black font-normal leading-130 text-base item-center">
                    <div className="bg-beige border-t border-l border-b border-beige-outline py-3 px-4 rounded-s-lg text-opacity-50 text-black">
                        {currency}
                    </div>
                    <input
                        type="text"
                        value={formatNumber(marketSpend)}
                        placeholder="8,000"
                        onChange={(e) => handleChange(e, setMarketSpend)}
                        className="py-3 px-4 border rounded-e-lg w-140px focus:ring-1 focus:ring-inset focus:ring-dark-blue"
                    />
                    <div className="flex items-center pl-2 font-body-text">
                        per month
                    </div>
                </div>
            </div>
            <div className="font-subtitle text-dark-blue mt-6">
                <div>
                    Gross margin
                    <span className="text-red-theme">*</span>
                </div>
                <div className="font-small-text pt-2">
                    This helps me understand where to focus, and how much
                    discount you can potentially offer
                </div>
                <div className="flex flex-row pt-2 text-black font-normal leading-130 text-base item-center">
                    <input
                        type="text"
                        value={grossMargin}
                        placeholder="75"
                        onChange={(e) => handleChange(e, setGrossMargin)}
                        className="py-3 px-4 border rounded-s-lg w-60px focus:ring-1 focus:ring-inset focus:ring-dark-blue"
                    />
                    <div className="bg-beige border-t border-r border-b border-beige-outline py-3 px-4 rounded-e-lg text-opacity-50 text-black">
                        %
                    </div>
                </div>
            </div>
            <div className="font-subtitle text-dark-blue mt-6 pb-5">
                <div>
                    Shipping cost per order
                    <span className="text-red-theme">*</span>
                </div>
                <div className="font-small-text pt-2">
                    This helps me understand where to focus, and how much
                    discount you can potentially offer
                </div>
                <div className="flex flex-row pt-2 text-black font-normal leading-130 text-base item-center">
                    <div className="bg-beige border-t border-l border-b border-beige-outline py-3 px-4 rounded-s-lg text-opacity-50 text-black">
                        {currency}
                    </div>
                    <input
                        type="text"
                        value={formatNumber(shippingCost)}
                        placeholder="12"
                        onChange={(e) => handleChange(e, setShippingCost)}
                        className="py-3 px-4 border rounded-e-lg w-70px focus:ring-1 focus:ring-inset focus:ring-dark-blue"
                    />
                    <div className="flex items-center pl-2 font-body-text">
                        per order
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardTypeMetrics
