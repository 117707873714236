import brandStore from '../../../storeZustand/brandCustomizationStore'
import OptionalCheckBox from './OptionalCheckBox'
import { connectToolType } from '../type'
import CreateFromScratch from './CreateFromScratch'
import KlaviyoConnection from './KlaviyoConnection'
function TemplatesEmail() {
    const { aAndTEmail } = brandStore((state) => ({
        aAndTEmail: state.aAndTEmail,
    }))

    return (
        <div className="bg-white rounded-lg mt-4 p-6 shadow-un-click-card font-subtitle relative">
            <div className="font-header-3">Share access to email templates</div>
            <div className="font-body-text pt-2">
                Needle needs access to your email templates to generate new
                emails based on your existing style and designs.
            </div>
            {!aAndTEmail.FromScratch && (
                <div>
                    <KlaviyoConnection />
                    <div className="h-1px bg-beige-outline absolute w-full left-0" />
                    <div className="pb-6">
                        <OptionalCheckBox type={connectToolType.Figma} />
                        <OptionalCheckBox type={connectToolType.Canva} />
                    </div>
                    <div className="h-1px bg-beige-outline absolute w-full left-0" />
                </div>
            )}
            <CreateFromScratch />
        </div>
    )
}

export default TemplatesEmail
