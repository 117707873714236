import {
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    TextFieldProps,
    Typography,
} from '@mui/material'
import React from 'react'
import './TextField.scss'
import { ReactComponent as OpenEyeIcon } from '../assets/svgs/open-eye.svg'
import { ReactComponent as CloseEyeIcon } from '../assets/svgs/close-eye.svg'
function TextFieldDefault(props: TextFieldProps) {
    const [showPassword, setShowPassword] = React.useState(false)
    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault()
    }
    return (
        <Stack className={'text-field-default ' + props.className}>
            <TextField
                {...props}
                type={showPassword ? 'text' : props.type}
                error={!!props.error}
                InputLabelProps={{
                    required: false,
                    shrink: true,
                }}
                InputProps={{
                    endAdornment:
                        props.type === 'password' ? (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    sx={{
                                        '& path': {
                                            opacity: 0.3,
                                        },
                                        '&:hover path': {
                                            opacity: 0.7,
                                        },
                                    }}
                                >
                                    {!showPassword ? (
                                        <OpenEyeIcon />
                                    ) : (
                                        <CloseEyeIcon />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ) : undefined,
                }}
                required={false}
                helperText={
                    props.helperText && (
                        <Typography
                            color={props.error ? 'error' : 'blue'}
                            variant="subtitle2"
                            className="error-message"
                        >
                            {props.helperText}
                        </Typography>
                    )
                }
            ></TextField>
        </Stack>
    )
}

export default TextFieldDefault
