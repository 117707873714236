import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Stack,
    Typography,
} from '@mui/material'
import { Formik, FormikProps } from 'formik'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import TextFieldDefault from '../../components/TextField'
import { LoginType } from '../../firebase/authentication'
import { AuthState, clearStatus, login } from '../../redux/authReducer'
import { AppState, useAppDispatch } from '../../redux/store'
import { useSelector } from 'react-redux'
import './index.scss'
import { AuthService } from '../../firebase/authentication'

export const ERROR_MESSAGES = {
    required: 'Field is required.',
    invalid: 'Field is invalid.',
    min: 'At least 8 characters',
    passwordMustMatch: 'Password must match',
    requiredCapital: 'Must include at least a capital letter',
}

function LoginScreen() {
    const dispatch = useAppDispatch()
    const { authStatus, errorMessage } = useSelector<AppState, AuthState>(
        (state) => state.auth
    )

    interface FormValues {
        email: string
        password: string
    }

    const initialValues: FormValues = {
        email: '',
        password: '',
    }

    const authWithUsernameAndPasswordSchema = Yup.object().shape({
        email: Yup.string().email(ERROR_MESSAGES.invalid),
        password: Yup.string()
            .matches(/(?=.*[A-Z])/, ERROR_MESSAGES.requiredCapital)
            .min(8, ERROR_MESSAGES.min),
    })

    useEffect(() => {
        dispatch(clearStatus())
    }, [dispatch])

    return (
        <Box className="sign-in-wrapper">
            <Box className={`background-img`}></Box>
            <Box className="sign-in-modal">
                <Formik
                    initialValues={initialValues}
                    validationSchema={authWithUsernameAndPasswordSchema}
                    onSubmit={(values, actions) => {
                        dispatch(
                            login({
                                loginType: LoginType.EmailAndPassword,
                                email: values.email,
                                password: values.password,
                            })
                        )
                    }}
                >
                    {(props: FormikProps<FormValues>) => {
                        const { values, errors, handleSubmit, setFieldValue } =
                            props
                        return (
                            <form onSubmit={handleSubmit}>
                                <Stack>
                                    <Typography
                                        className="page-title"
                                        variant="h2"
                                    >
                                        Welcome to Needle
                                    </Typography>
                                    <p className="page-description">
                                        Welcome back to Needle, your AI
                                        marketing assistant.
                                    </p>
                                    <Button
                                        variant="outlined"
                                        className="login-with-google"
                                        onClick={() => {
                                            dispatch(
                                                login({
                                                    loginType: LoginType.Google,
                                                    email: '',
                                                    password: '',
                                                })
                                            )
                                        }}
                                    >
                                        <Stack
                                            className="login-with-google"
                                            direction={'row'}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                            sx={{
                                                minHeight: '53.5px',
                                                color: 'rgba(0, 0, 0, 0.8)',
                                            }}
                                        >
                                            <img
                                                src="./assets/svgs/google.svg"
                                                alt="Google icon"
                                            />
                                            <Typography
                                                fontSize={'1rem'}
                                                fontWeight={500}
                                                lineHeight={'100%'}
                                                letterSpacing={'0.0125rem'}
                                            >
                                                Sign in with Google
                                            </Typography>
                                        </Stack>
                                    </Button>
                                    <Stack
                                        className="divider"
                                        direction={'row'}
                                        spacing={1}
                                        alignItems={'center'}
                                    >
                                        <Box sx={{ width: '50%' }}>
                                            <Divider />
                                        </Box>

                                        <span>Or</span>
                                        <Box sx={{ width: '50%' }}>
                                            <Divider />
                                        </Box>
                                    </Stack>
                                    <Stack>
                                        <TextFieldDefault
                                            className="email-intput"
                                            label="Email"
                                            placeholder="youremail@address.com"
                                            value={values.email}
                                            onChange={(e) => {
                                                setFieldValue(
                                                    e.target.name,
                                                    e.target.value
                                                )
                                            }}
                                            name="email"
                                            error={!!errors.email}
                                            helperText={errors.email}
                                        />
                                        <TextFieldDefault
                                            className="password-input"
                                            placeholder="Password"
                                            label="Password"
                                            type="password"
                                            value={values.password}
                                            onChange={(e) => {
                                                setFieldValue(
                                                    e.target.name,
                                                    e.target.value
                                                )
                                            }}
                                            name="password"
                                            error={!!errors.password}
                                            helperText={errors.password}
                                        />
                                    </Stack>

                                    {authStatus === 'error' && (
                                        <Stack
                                            className="forget-password-wrapper"
                                            direction={'row'}
                                        >
                                            <Typography
                                                sx={{ color: 'red' }}
                                                variant="subtitle2"
                                            >
                                                {errorMessage}
                                            </Typography>
                                        </Stack>
                                    )}

                                    <Stack
                                        className="forget-password-wrapper"
                                        direction={'row'}
                                    >
                                        <Link
                                            className="forget-password"
                                            to={'/forgot-password'}
                                        >
                                            Forgot Password
                                        </Link>
                                    </Stack>
                                    <Stack>
                                        <Button
                                            className="login-button"
                                            type="submit"
                                            variant="contained"
                                            color="warning"
                                        >
                                            {authStatus === 'loading' ? (
                                                <CircularProgress
                                                    color="info"
                                                    size={18}
                                                />
                                            ) : (
                                                'Sign in'
                                            )}
                                        </Button>
                                        <Stack
                                            className="other-option"
                                            justifyContent={'center'}
                                            direction={'row'}
                                            spacing={1}
                                        >
                                            <span>Don't have account?</span>
                                            <Link to={'/sign-up'}>
                                                Create account
                                            </Link>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </form>
                        )
                    }}
                </Formik>
            </Box>
        </Box>
    )
}

export function LogoutScreen() {
    return (
        <Button
            onClick={() => {
                AuthService.logout()
                window.location.href = '/'
                setTimeout(() => {
                    const staffMode = localStorage.getItem('is-disable-segment')
                    localStorage.clear()
                    if (staffMode)
                        localStorage.setItem('is-disable-segment', staffMode)
                }, 1000)
            }}
        >
            Logout
        </Button>
    )
}
export default LoginScreen
