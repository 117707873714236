import React from 'react'
import LottieAnimation from '../LottieAnimation'

function ChatWithNeedleHelp() {
    return (
        <div className="flex flex-row pb-4">
            <div className="font-small-text">{`Need help writing this?`}</div>
            <div className="font-dm font-bold text-sm leading-130 bg-gradient-to-r from-pink-ai-gradient to-blue-ai-gradient text-transparent bg-clip-text cursor-pointer pl-6px">
                <div>Chat with Needle</div>
                <div className="bg-gradient-to-r from-pink-ai-gradient to-blue-ai-gradient h-1px -mt-2px" />
            </div>
            <div className="h-4 w-4 ml-1">
                <LottieAnimation animationUrl="https://lottie.host/98a78630-8e5a-4074-b9fe-eb6890808a61/LfunoAZbKM.json" />
            </div>
        </div>
    )
}

export default ChatWithNeedleHelp
