import {
    BrandCustomizationSection,
    BrandsCardType,
    OptionType,
    Sections_Data,
} from './type'

const BRAND_POSITIONING_SECTIONS: Sections_Data[] = [
    {
        name: 'Why did you start your brand?',
        description:
            'Write your personal story. Tell us the soul and meaning behind your brand',
        placeHolder:
            'e.g. I started this brand because I wanted to empower moms to be comfortable in their skin',
        value: '',
        stateKey: 'brandPStart',
        type: BrandsCardType.Text,
    },
    {
        name: 'What are 2-3 key messages that you want to tell the world?',
        description: `Avoid copying from your website. Write from your heart, like how you would talk to a friend over coffee.`,
        value: '',
        placeHolder: 'e.g. Everyone deserves love and respect in this world',
        stateKey: 'brandPTell',
        type: BrandsCardType.MultipleText,
    },
    {
        name: 'Describe your brand personality, like how you would tell a friend over dinner.',
        description:
            'A good description helps us understand how to write like you!',
        value: '',
        placeHolder:
            'e.g. witty, modern, and empowering. My brand projects a minimalist and chic aesthetic. We talk to our audience like a friend who lives next door who has great taste in life.',
        stateKey: 'brandPDescribe',
        type: BrandsCardType.Text,
    },
]
const YOUR_CUSTOMER_SECTIONS: Sections_Data[] = [
    {
        name: 'About your customers',
        placeHolder:
            'e.g. Our customers are dog parents who want the best for their fur babies. They don’t want to feed their dogs food they wouldn’t eat themselves...',
        value: { Describe: '', Gender: [], AgeGroup: [], Family: [] },
        stateKey: 'customersWho',
        type: BrandsCardType.Customers,
    },
    {
        name: 'Main markets served',
        value: [],
        stateKey: 'customerServed',
        type: BrandsCardType.MultipleSelectDropdown,
    },
]
const BRAND_METRICS_SECTIONS: Sections_Data[] = [
    {
        name: 'Metrics',
        value: {
            marketSpend: undefined,
            grossMargin: undefined,
            shippingCost: undefined,
        },
        stateKey: 'brandMetrics',
        type: BrandsCardType.Metrics,
    },
]
const P_TO_F_SECTIONS: Sections_Data[] = [
    {
        name: 'Preferred products',
        value: [],
        description:
            'Needle will focus on creating Ideas that feature these products.',
        stateKey: 'productToFeature',
        type: BrandsCardType.ProductSelect,
    },
]

export const BRAND_CUSTOMIZATION_SECTIONS: BrandCustomizationSection[] = [
    {
        name: 'Brand profile essentials',
        detailSection: BRAND_POSITIONING_SECTIONS,
    },
    { name: 'Your customers', detailSection: YOUR_CUSTOMER_SECTIONS },
    { name: 'Brand metrics', detailSection: BRAND_METRICS_SECTIONS },
    {
        name: 'Preferred products',
        detailSection: P_TO_F_SECTIONS,
    },
]
export const LIST_SHOW_BRAND_CUSTOMIZATION: boolean[] = [
    false,
    false,
    false,
    false,
    false,
    false,
]

export const GENDER_DATA = ['Male', 'Female', 'Non-binary']
export const EMAIL_DATA = ['Klaviyo', 'Shopify Email', 'Omnisend']
export const AGE_DATA = [
    'Below 10 years old',
    'Gen Z (10-20 years old)',
    'Gen Y / Millennials (20-40 years old)',
    'Gen X (40-60 years old)',
    'Above 60 years old',
]
export const FAMILY_DATA = [
    'Single',
    'Partnered / Married',
    'Parents with young kids (below 5 years old)',
    'Parents with older kids (5 years old and above)',
]

export const PRODUCT_OPTIONS: OptionType[] = [
    {
        value: 'Infinity Rainbow — enamel pin',
        label: 'Infinity Rainbow',
        description: 'enamel pin',
        image: '/assets/imgs/sample-product.png',
    },
    {
        value: 'Love is love — enamel pin',
        label: 'Love is love',
        description: 'enamel pin',
        image: '/assets/imgs/sample-product.png',
    },
    {
        value: 'Baby in the car -- enamel pin',
        label: 'Baby in the car',
        description: 'enamel pin',
        image: '/assets/imgs/sample-product.png',
    },
    {
        value: 'Sword and shields',
        label: 'Sword and shields',
        image: '/assets/imgs/sample-product.png',
    },
    {
        value: 'Fast food may feel good',
        label: 'Fast food may feel good',
        image: '/assets/imgs/sample-product.png',
    },
]
