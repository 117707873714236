import React, { useEffect, useState } from 'react'
import { NeedleButton } from '../../../components/NeedleButton'
import { NeedleTextInput } from '../../../components/NeedleTextInput'
import { User } from 'firebase/auth'
import { getFirstName, getLastName } from '../component'
import { updateUserProfile } from '../../../hooks/useUserProfile'
import globalErrorDialogStore from '../../../storeZustand/globalErrorDialogStore'
import { STORE_SETTING } from '../constants'
interface GetFirstAndLastNameProps {
    sendData: (progress: number) => void
    user: User
    setFirstName: any
    firstName: string
}
export const GetFirstAndLastName = ({
    sendData,
    user,
    setFirstName,
    firstName,
}: GetFirstAndLastNameProps) => {
    const [isSendName, setIsSendName] = useState<boolean>(false)
    const [lastName, setLastName] = useState<string>(
        getLastName(user.displayName)
    )
    const [isDisableSetName, setIsDisableSetName] = useState<boolean>(true)

    useEffect(() => {
        if (firstName.trim() !== '' && lastName.trim() !== '') {
            setIsDisableSetName(false)
        } else {
            setIsDisableSetName(true)
        }
    }, [firstName, lastName])

    const handleSendName = async () => {
        setIsSendName(true)
        const data = await updateUserProfile({
            profile: {
                first_name: firstName,
                last_name: lastName,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                email: user?.email ?? '',
            },
        })
        setIsSendName(false)
        if (data) {
            sendData(STORE_SETTING)
        }
    }

    return (
        <div className="font-subtitle pb-8 w-full">
            <div>First name</div>
            <NeedleTextInput
                className="mt-2 mb-6"
                placeholder="First name"
                value={firstName}
                setValue={setFirstName}
            />
            <div>Last name</div>
            <NeedleTextInput
                className="mt-2 mb-6"
                placeholder={'Last name'}
                value={lastName}
                setValue={setLastName}
            />
            <NeedleButton
                onClickFunction={() => handleSendName()}
                buttonCharacter="Looks good!"
                isDisable={isDisableSetName}
                isFull={true}
                isLoading={isSendName}
                border="py-4"
            />
        </div>
    )
}

export default GetFirstAndLastName
