declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        mobile: true
        tablet: true
        laptop: true
        desktop: true
    }
}

const breakpoints = {
    xs: 0,
    sm: 600,
    md: 800,
    lg: 1160,
    xl: 1800,
    mobile: 360,
    tablet: 640,
    laptop: 1024,
    desktop: 1200,
}

export default breakpoints
