import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import useUserProfile from '../../hooks/useUserProfile'
import SelectBrandModal from './SelectBrandModal'
import useWorkspace from '../../hooks/useWorkspace'

const AdminPreviewFloating = () => {
    const { userProfile } = useUserProfile()
    const { data: workspaceData } = useWorkspace()

    const [open, setOpen] = useState(false)
    if (userProfile?.role !== 'admin') return null
    return (
        <>
            <Box
                onClick={() => {
                    setOpen(true)
                }}
                sx={{
                    position: 'fixed',
                    bottom: '100px',
                    right: '20px',
                    padding: '8px',
                    zIndex: 1000,
                    background: '#777',
                    color: 'white',
                    opacity: 0.5,
                    borderRadius: '4px',
                    cursor: 'pointer',
                    '&:hover': {
                        opacity: 1,
                    },
                }}
            >
                <Typography fontWeight={600}>
                    Admin Preview under workspace
                </Typography>
                <Typography>Name: {workspaceData?.workspace_name}</Typography>
                <Typography>Creator: {workspaceData?.creator_email}</Typography>
                <Typography>
                    <u>
                        <i>Click here to change</i>
                    </u>
                </Typography>
            </Box>
            <SelectBrandModal open={open} onClose={() => setOpen(false)} />
        </>
    )
}

export default AdminPreviewFloating
