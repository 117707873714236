import React, { useEffect, useState } from 'react'
import * as opentype from 'opentype.js'
import { formatFileSize, formatFileType } from './component'
import { handleDragOver } from '../../views/AddAnIdeaPage/function'

interface UploadFileProps {
    typeOfFile: string
    name: string
    acceptsType: string[]
    handleUpload: () => void
    data: File | Needle.metaDataAssets | null
    setData: React.Dispatch<React.SetStateAction<any>>
    isMust?: boolean
    imageData?: any
}

function UploadFile({
    typeOfFile,
    name,
    data,
    acceptsType,
    setData,
    handleUpload,
    isMust = false,
    imageData,
}: UploadFileProps) {
    // const [fontName, setFontName] = useState('')

    // useEffect(() => {
    //     if (data && typeOfFile === 'TTF, OTF') {
    //         if (data instanceof File) {
    //             const reader = new FileReader()
    //             reader.onload = (event: any) => {
    //                 const arrayBuffer = event.target.result
    //                 try {
    //                     const font = opentype.parse(arrayBuffer)
    //                     setFontName(font.names.fullName.en + ' ')
    //                     return font.names.fullName.en + ' '
    //                 } catch (error) {
    //                     setFontName('Unable name')
    //                 }
    //             }
    //             reader.readAsArrayBuffer(data)
    //         } else if (data) {
    //             // setFontName(data.metadata.name )
    //         }
    //     }
    // }, [data])

    const displayData = {
        name: data instanceof File ? data.name : data?.label,
    }
    const onDropEvent = (event: any): void => {
        event.preventDefault()
        event.stopPropagation()
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const file = event.dataTransfer.files[0]
            const fileExtension = file.name.split('.').pop().toLowerCase()
            if (
                acceptsType.includes(file.type) ||
                acceptsType.includes(fileExtension)
            ) {
                setData(file)
            }
        }
    }
    return (
        <div>
            <div>
                {name}
                {isMust && <span className="text-red-theme">*</span>}
            </div>
            <div className="mt-2 mb-6">
                {data ? (
                    <div className="flex flex-row items-center justify-between border border-beige-outline rounded-lg">
                        <div className="flex flex-row font-body-text py-2 items-center">
                            <div className="ml-2 w-12">{imageData}</div>
                            <div className=" flex flex-col pl-4">
                                <div className="truncate lg:max-w-md max-w-sm">
                                    {displayData.name}
                                </div>
                                <div className="font-small-text opacity-50 pt-1">
                                    {/* {formatFileSize(displayData.size) +
                                        ` | ` +
                                        fontName +
                                        formatFileType(typeOfFile)} */}
                                </div>
                            </div>
                        </div>
                        <div
                            className="flex flex-row justify-center items-center cursor-pointer px-4"
                            onClick={() => setData(null)}
                        >
                            <img
                                alt="close-icon"
                                className="w-6 aspect-square"
                                src="/assets/icons/close-icon.svg"
                            />
                        </div>
                    </div>
                ) : (
                    <div>
                        <div
                            className="rounded-lg border-dashed border-2 outline-beige-outline flex justify-center w-full items-center"
                            onClick={handleUpload}
                            onDrop={onDropEvent}
                            onDragOver={handleDragOver}
                        >
                            <div className="text-dark-blue items-center justify-center flex flex-col py-8">
                                <img
                                    alt="logo-brand"
                                    className="w-12"
                                    src="/assets/icons/add-file-icon.svg"
                                />
                                <div className="font-body-text pt-2">
                                    Drag and drop or click to upload files
                                </div>
                                <div className="font-small-text pt-1">
                                    Accepted file types: {typeOfFile}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default UploadFile
