import { ButtonType, NeedleButton } from '../../../components/NeedleButton'
import brandStore from '../../../storeZustand/brandCustomizationStore'
import { EITemplatesEmail } from '../../BrandProfilePage/type'
import DoneWithFlatForm from '../DoneWithFlatForm'
import { useEffect, useState } from 'react'
import { canvaTutorial, figmaTutorial } from '../constants'
import { connectToolType } from '../type'

interface OptionalCheckBoxProps {
    type: connectToolType
}
function OptionalCheckBox({ type }: OptionalCheckBoxProps) {
    const { aAndTEmail, setTemplateEmail } = brandStore((state) => ({
        aAndTEmail: state.aAndTEmail,
        setTemplateEmail: state.setTemplateEmail,
    }))
    let tutorialType = figmaTutorial
    let keyName: keyof EITemplatesEmail = 'Figma'
    let dataHook = aAndTEmail.Figma

    if (type === connectToolType.Canva) {
        tutorialType = canvaTutorial
        keyName = 'Canva'
        dataHook = aAndTEmail.Canva
    }

    const submitSharedAccess = async (
        key: keyof EITemplatesEmail,
        value: any
    ) => {
        return await setTemplateEmail(key, value)
    }
    const [checkbox, setCheckbox] = useState<boolean>(dataHook !== '')
    const [isDisable, setIsDisable] = useState<boolean>(dataHook === '')
    const [link, setLink] = useState<string>(dataHook)
    const [isOpen, setIsOpen] = useState<boolean>(false)

    useEffect(() => {
        if (link.trim() === '') {
            setIsDisable(true)
        } else {
            setIsDisable(false)
        }
    }, [link])

    useEffect(() => {
        setCheckbox(dataHook !== '')
        setIsDisable(dataHook === '')
        setLink(dataHook)
    }, [dataHook])

    return (
        <div>
            <div className="flex flex-col pt-6">
                <div
                    className="flex flex-row items-center cursor-pointer"
                    onClick={async () => {
                        if (dataHook !== '') {
                            setLink('')
                            const data = await submitSharedAccess(keyName, '')
                            if (data) {
                                setIsOpen(!checkbox)
                                setCheckbox(!checkbox)
                            }
                        } else {
                            setIsOpen(!checkbox)
                            setCheckbox(!checkbox)
                        }
                    }}
                >
                    <div
                        className={`h-4 w-4 rounded border border-beige-outline ${checkbox && 'bg-black'}`}
                    >
                        <img
                            src="/assets/icons/checked-icon.svg"
                            alt="checked-icon"
                            className="ml-3px absolute pt-4px w-2"
                        />
                    </div>
                    <div className="pl-2">Optional: Share via {type}</div>
                </div>
                {isOpen ? (
                    <div className="pt-4">
                        <div>Instructions:</div>
                        <div
                            className="font-body-text pt-2"
                            dangerouslySetInnerHTML={{
                                __html: tutorialType,
                            }}
                        />
                        <textarea
                            rows={1}
                            id="first_name"
                            className="border border-beige-outline font-body-text
                             py-3 px-4 w-full rounded-lg focus:outline-dark-blue mt-2"
                            placeholder={`Paste ${type} links, separated by commas`}
                            value={link}
                            onChange={(e) => setLink(e.target.value)}
                        />
                        <div className="font-link-small-text text-blue-theme underline ">
                            See video guide
                        </div>
                        <div className="flex justify-end pt-2">
                            <div>
                                <NeedleButton
                                    buttonCharacter="I've shared access"
                                    buttonType={ButtonType.Black}
                                    onClickFunction={async () => {
                                        const data = await submitSharedAccess(
                                            keyName,
                                            link
                                        )
                                        if (data) {
                                            setIsOpen(false)
                                        }
                                    }}
                                    isDisable={isDisable}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    dataHook !== '' && (
                        <div className="pt-4">
                            <DoneWithFlatForm
                                type={type}
                                setIsOpen={() => setIsOpen(true)}
                            />
                        </div>
                    )
                )}
            </div>
        </div>
    )
}

export default OptionalCheckBox
