import axios from 'axios'
import {
    GoogleAuthProvider,
    signInWithPopup,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    User,
    updateEmail,
    updatePassword,
    confirmPasswordReset,
    sendPasswordResetEmail,
    verifyBeforeUpdateEmail,
} from 'firebase/auth'
import { doc, getDoc, setDoc } from 'firebase/firestore/lite'
import { auth, firestore } from './client'

export enum LoginType {
    Google,
    Facebook,
    SignUp,
    EmailAndPassword,
    PhoneAndPassword,
}

export enum SignUpType {
    EmailAndPassword,
    PhoneAndPassword,
}

interface UserInfo {
    uid: string
    email: string
    fivetranGroupId: string
}

export const AuthService = {
    loginWithGoogle: async (type: LoginType) => {
        let provider = new GoogleAuthProvider()
        const result = await signInWithPopup(auth, provider)
        return result.user
    },
    signUpWithEmailAndPassword: async (email: string, password: string) => {
        const result = await createUserWithEmailAndPassword(
            auth,
            email,
            password
        )
        return result.user
    },
    loginWithEmailAndPassword: async (email: string, password: string) => {
        const result = await signInWithEmailAndPassword(auth, email, password)
        return result.user
    },
    verifyBeforeUpdateEmail: async (user: User, newEmail: string) => {
        return await verifyBeforeUpdateEmail(user, newEmail)
    },
    updateEmail: async (user: User, newEmail: string) => {
        return await updateEmail(user, newEmail)
    },
    updatePhone: async (user: User, newPhone: string) => {
        const email = newPhone + '@dummy.vn'
        return await updateEmail(user, email)
    },
    updatePassword: async (user: User, newPassword: string) => {
        return await updatePassword(user, newPassword)
    },
    resetPassword: async (email: string) => {
        try {
            await sendPasswordResetEmail(auth, email)
            return {
                success: true,
                message: 'The link has been sent to your email.',
            }
        } catch (e: any) {
            return {
                success: false,
                message: e.message,
            }
        }
    },
    resetPhonePassword: async (token: string, new_password: string) => {
        return axios.post(
            process.env.NEXT_PUBLIC_FORGET_PASSWORD_ENDPOINT ?? '',
            {
                token,
                new_password,
            }
        )
    },
    confirmResetPassword: async (code: string, newPassword: string) => {
        return await confirmPasswordReset(auth, code, newPassword)
    },

    logout: async () => {
        await auth.signOut()
    },
    isUserExisted: async (user: User) => {
        if (!user) return null
        const userRef = doc(firestore, 'users', user.uid)
        const userDoc = await getDoc(userRef)
        return userDoc.exists()
    },
    updatePhoneVerificationInfo: async (user: User, phoneVerified: boolean) => {
        if (!user) return null
        await auth.updateCurrentUser(user)
        const userRef = doc(firestore, 'users', user.uid)
        await setDoc(
            userRef,
            { phone_verified: phoneVerified },
            { merge: true }
        )
    },
    getUserInfo: async (user: User) => {
        if (!user) return null
        const userRef = doc(firestore, 'users', user.uid)
        const userDoc = await getDoc(userRef)
        if (!userDoc.exists()) {
            const initialData = {
                uid: user.uid,
                email: user.email,
                fivetranGroupdId: '',
            }
            await setDoc(userRef, initialData)
            return initialData as unknown as UserInfo
        } else {
            const result = userDoc.data() as UserInfo
            return result
        }
    },
}

export const firebaseAuthErrorCodes: { [key: string]: string } = {
    'auth/popup-closed-by-user': 'Close Popup',
    'auth/app-deleted': 'The Firebase app has been deleted.',
    'auth/app-not-authorized':
        'The Firebase app is not authorized to use Firebase Authentication.',
    'auth/argument-error':
        'An invalid argument was provided to a Firebase Authentication method.',
    'auth/invalid-api-key': 'The provided API key is invalid.',
    'auth/invalid-user-token':
        "The user's token is invalid, expired or revoked.",
    'auth/network-request-failed':
        'A network error occurred while attempting to reach the Firebase Authentication servers.',
    'auth/operation-not-allowed':
        'The requested Firebase Authentication operation is not allowed.',
    'auth/requires-recent-login':
        "The user's last authentication was too long ago.",
    'auth/too-many-requests':
        'Firebase Authentication has blocked all requests from this device due to unusual activity.',
    'auth/unauthorized-domain':
        'The Firebase Authentication domain is not authorized for the project.',
    'auth/user-disabled':
        "The user's account has been disabled by an administrator.",
    'auth/user-not-found': 'User does not exist.',
    'auth/wrong-password': 'The password you entered is incorrect .',
    'auth/email-already-in-use':
        'Email already in use, please sign in or use a different email address',
}
