import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import { Formik, FormikProps } from 'formik'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'
import TextFieldDefault from '../../components/TextField'
import { useAppDispatch } from '../../redux/store'
import './index.scss'
import { checkActionCode, confirmPasswordReset } from 'firebase/auth'
import { auth } from '../../firebase/client'
import { ERROR_MESSAGES } from '../login_screen'
import { showToast } from '../../redux/appReducer'
import LoadingScreen from '../LoadingScreen/LoadingScreen'
import NeedleTopLogo from '../../components/NeedleTopLogo'

function ResetPasswordScreen() {
    const [searchParams] = useSearchParams()
    const [status, setStatus] = useState<
        'idle' | 'success' | 'error' | 'loading' | 'invalid' | 'isChecking'
    >('idle')
    const [email, setEmail] = useState<string | undefined | null>()

    useEffect(() => {
        const checkAccess = async () => {
            try {
                setStatus('isChecking')
                const oobCode = searchParams.get('oobCode') ?? ''
                const res = await checkActionCode(auth, oobCode)
                setStatus(res.data.email ? 'idle' : 'invalid')
                setEmail(res.data.email)
            } catch (e) {
                setStatus('invalid')
            }
        }
        checkAccess()
    }, [searchParams])
    const navigate = useNavigate()
    const dispath = useAppDispatch()

    interface FormValues {
        retypePassword: string
        password: string
    }

    const initialValues: FormValues = {
        retypePassword: '',
        password: '',
    }

    const authWithUsernameAndPasswordSchema = Yup.object().shape({
        password: Yup.string()
            .matches(/(?=.*[A-Z])/, ERROR_MESSAGES.requiredCapital)
            .min(8, ERROR_MESSAGES.min),
        retypePassword: Yup.string().oneOf(
            [Yup.ref('password')],
            ERROR_MESSAGES.passwordMustMatch
        ),
    })

    const handleResetPassword = async (password: string) => {
        try {
            setStatus('loading')
            const oobCode = searchParams.get('oobCode') ?? ''
            await confirmPasswordReset(auth, oobCode, password)
            setStatus('success')
            dispath(
                showToast({
                    type: 'success',
                    content: 'Update password successfully!',
                })
            )
        } catch (e: any) {
            setStatus('invalid')
            dispath(
                showToast({
                    type: 'error',
                    content: e.message,
                })
            )
        }
    }

    if (status === 'isChecking') return <LoadingScreen />

    if (status === 'success' || status === 'invalid')
        return (
            <Box className="reset-password-wrapper">
                <NeedleTopLogo />
                <Box className={`background-img`}></Box>
                <Box className="sign-in-modal">
                    <Stack>
                        <Typography className="page-title" variant="h2">
                            {status === 'success'
                                ? 'Your password has been changed!'
                                : 'Try resetting your password again'}
                        </Typography>
                        <p className="page-description">
                            {status === 'success'
                                ? 'You can now log in with your new password.'
                                : 'Your password reset request has expired or the link is already in use'}
                        </p>

                        <Stack direction={'row'} justifyContent={'center'}>
                            <img
                                width={280}
                                height={280}
                                object-fit={'contain'}
                                src={
                                    status === 'invalid'
                                        ? './assets/svgs/getting-started.svg'
                                        : './assets/imgs/High-Five.svg'
                                }
                                alt="Needle getting started"
                            />
                        </Stack>

                        <Stack sx={{ mt: 4 }}>
                            <Button
                                onClick={() => navigate('/login')}
                                variant="contained"
                                color="warning"
                            >
                                Go back to Needle
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Box>
        )

    return (
        <Box className="reset-password-wrapper">
            <NeedleTopLogo />
            <Box className={`background-img`}></Box>
            <Box className="sign-in-modal">
                <Formik
                    initialValues={initialValues}
                    validationSchema={authWithUsernameAndPasswordSchema}
                    onSubmit={(values, actions) => {
                        handleResetPassword(values.password)
                    }}
                >
                    {(props: FormikProps<FormValues>) => {
                        const { values, errors, handleSubmit, setFieldValue } =
                            props
                        return (
                            <form onSubmit={handleSubmit}>
                                <Stack>
                                    <Typography
                                        className="page-title"
                                        variant="h2"
                                    >
                                        Create new password
                                    </Typography>
                                    <p className="page-description">
                                        for {email}
                                    </p>

                                    <Stack>
                                        <TextFieldDefault
                                            className="password-input"
                                            placeholder="Choose a strong password"
                                            label="New Password"
                                            type="password"
                                            value={values.password}
                                            onChange={(e) => {
                                                setFieldValue(
                                                    e.target.name,
                                                    e.target.value
                                                )
                                            }}
                                            name="password"
                                            error={!!errors.password}
                                            helperText={errors.password}
                                        />

                                        <TextFieldDefault
                                            className="password-input"
                                            placeholder="Confirm new password"
                                            label="Confirm new password"
                                            type="password"
                                            value={values.retypePassword}
                                            onChange={(e) => {
                                                setFieldValue(
                                                    e.target.name,
                                                    e.target.value
                                                )
                                            }}
                                            name="retypePassword"
                                            error={!!errors.retypePassword}
                                            helperText={errors.retypePassword}
                                        />
                                        <Box>
                                            <ul className="password-format-list">
                                                <li className="password-format-tile">
                                                    Must be 8 characters
                                                </li>
                                                <li className="password-format-tile">
                                                    Must include a capital
                                                    letter
                                                </li>
                                            </ul>
                                        </Box>
                                    </Stack>

                                    <Stack>
                                        <Button
                                            className="action-button"
                                            type="submit"
                                            variant="contained"
                                            color="warning"
                                        >
                                            {status === 'loading' ? (
                                                <CircularProgress
                                                    color="info"
                                                    size={16}
                                                />
                                            ) : (
                                                'Submit'
                                            )}
                                        </Button>
                                    </Stack>
                                </Stack>
                            </form>
                        )
                    }}
                </Formik>
            </Box>
        </Box>
    )
}

export default ResetPasswordScreen
