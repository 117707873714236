import { HTMLInputTypeAttribute } from 'react'

interface NeedleTextInputProps {
    setValue: any
    value: string
    placeholder: string
    className?: string
    valueError?: any
    type?: HTMLInputTypeAttribute
    isEdit?: boolean
    isPassword?: boolean
}

export const NeedleTextInput = ({
    setValue,
    value,
    placeholder,
    className = '',
    valueError,
    type = 'text',
    isEdit = true,
    isPassword = false,
}: NeedleTextInputProps) => {
    return isEdit ? (
        <div className={`w-full ${className}`}>
            <input
                id="needle-input"
                className={`${valueError ? 'text-area-needle-error' : 'text-area-needle'}`}
                style={isPassword ? { paddingRight: '43px' } : undefined}
                placeholder={placeholder}
                value={value}
                onChange={(e) => {
                    setValue(e.target.value)
                }}
                type={type}
            />
            {valueError && (
                <div className="text-red-theme font-small-text pl-4 pt-2">
                    {valueError}
                </div>
            )}
        </div>
    ) : (
        <div className="font-body-text">{value}</div>
    )
}
