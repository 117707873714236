import * as api from '../request'
import { ResponseData } from '../types'
import {
    EmailTemplateData,
    GenerateTemplateData,
    GenerateTemplateResponse,
} from './types'
import ApiConfig from '../api/config'
import { axiosInstance, getOptions, handleResponse } from '../api'
import { auth } from '../../firebase/client'

const GENERATE_API_URL = `${process.env.REACT_APP_BASE_URL}${ApiConfig.GENERATE_TEMPLATE}`

export const generateTemplate = (data: GenerateTemplateData) => {
    const mailPlatform = data.mailPlatform
    return api.post<ResponseData<unknown>, GenerateTemplateData>(
        `${GENERATE_API_URL}/${mailPlatform}/templates`,
        data
    )
}

export const updateTemplate = ({
    data,
    id,
}: {
    data: GenerateTemplateData
    id: string
}) => {
    const mailPlatform = data.mailPlatform
    return api.patch<ResponseData<unknown>, GenerateTemplateData>(
        `${GENERATE_API_URL}/${mailPlatform}/templates/${id}`,
        data
    )
}

export const getGenerateTemplate = (options?: api.ApiStateOption) => {
    return api.get<GenerateTemplateResponse>(GENERATE_API_URL, options)
}

export const getEmailTemplateData = async (
    workspace_id: string,
    tacticId: string | number
) => {
    const options = await getOptions()
    return handleResponse(
        axiosInstance.get(
            `${GENERATE_API_URL}/${tacticId}${
                workspace_id && `/${workspace_id}`
            }`,
            options
        )
    ) as EmailTemplateData & {
        status?: 'init' | 'loading' | 'error' | 'success'
    }
}

export const getClientSite = async (workspace_id: string, tacticId: string) => {
    const workspaceId = workspace_id ? workspace_id : 'default'
    const options = await getOptions()
    return handleResponse(
        axiosInstance.get(
            `${GENERATE_API_URL}/${tacticId}/${workspaceId}/getClientSite`,
            options
        )
    )
}

export const postEmailTemplateData = async (
    workspace_id: string,
    tacticId: string,
    data?: EmailTemplateData
) => {
    const options = await getOptions()
    return handleResponse(
        axiosInstance.post(
            `${GENERATE_API_URL}/${tacticId}${
                workspace_id && `/${workspace_id}`
            }`,
            data,
            options
        )
    ) as EmailTemplateData
}

export const saveEmailTemplate = async (
    workspace_id: string,
    tacticId: string
) => {
    const options = await getOptions()
    const workspaceId = workspace_id ? workspace_id : 'default'
    return handleResponse(
        axiosInstance.post(
            `${GENERATE_API_URL}/${tacticId}/${workspaceId}/generateEmailTemplate`,
            {},
            options
        )
    )
}

export const setUpKlaviyoCampaign = (
    workspace_id: string,
    tacticId: string
) => {
    const workspaceId = workspace_id ? workspace_id : 'default'
    return `${GENERATE_API_URL}/hook`
}

export const checkKlaviyoApiKey = (data: { apiKey: string }) => {
    return api.post<ResponseData<unknown>, { apiKey: string }>(
        `${GENERATE_API_URL}/klaviyo/check_key`,
        data
    )
}
