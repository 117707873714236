import { axiosInstance, handleResponse, getOptions } from '../api'
import { needdleConfig } from '../api/config'

export const IdeaDetailServices = {
    getIdeaDetail: async (ideaId: string) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(needdleConfig.GROWTH_PLAN_ID + ideaId, options)
        )
    },
    updateIdeaStatus: async (ideaId: string, data: any) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.patch(
                needdleConfig.GROWTH_PLAN_ID +
                    ideaId +
                    needdleConfig.UPDATE_STATUS,
                data,
                options
            )
        )
    },
    reportSomethingWrong: async (ideaId: string, data: any) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.post(
                needdleConfig.GROWTH_PLAN_ID +
                    ideaId +
                    needdleConfig.REPORT_SOMETHING_WRONG,
                data,
                options
            )
        )
    },
    addAnIdea: async (data: any) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.post(needdleConfig.GROWTH_PLAN_ID, data, options)
        )
    },
    sendIdeaFeedBack: async (ideaId: string, data: any) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.post(
                needdleConfig.GROWTH_PLAN_ID + ideaId + needdleConfig.FEED_BACK,
                data,
                options
            )
        )
    },
    skipIdeaBlock: async (ideaId: string) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.post(
                needdleConfig.GROWTH_PLAN_ID +
                    ideaId +
                    needdleConfig.SKIP_ASSETS_BLOCKER,
                {},
                options
            )
        )
    },
    updateAssets: async (ideaId: string, data: any) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.post(
                needdleConfig.GROWTH_PLAN_ID +
                    ideaId +
                    needdleConfig.UPDATE_ADDITIONAL_IDEA_ASSETS,
                data,
                options
            )
        )
    },
    updateConcepts: async (ideaId: string, conceptsId: string, data: any) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.patch(
                needdleConfig.GROWTH_PLAN_ID +
                    ideaId +
                    needdleConfig.CONCEPTS +
                    conceptsId,
                data,
                options
            )
        )
    },
    getCreativeType: async () => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(needdleConfig.CREATIVE_TYPE, options)
        )
    },
}
