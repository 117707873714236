import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Snackbar,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { Services } from '../../../services'
import useSWRImmutable from 'swr/immutable'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../../redux/store'
import { addNewConnectorToList } from '../../../redux/connectorReducer'
import { Connector } from '../../../utils/enum'
import { PATH } from '../../../constants/path'
import { usePageTitle } from '../../HomeScreen/component'
export const CHECK_SHOPIFY_CONNECTOR_PATH = 'creating-your-connector'
const ConnectingYourShopifyConnector = () => {
    const { tempCredId } = useParams()
    usePageTitle('Connecting Your Shopify Connector')
    const navigate = useNavigate()

    const { data, error } = useSWRImmutable(
        tempCredId ? `/connectors/${tempCredId}` : null,
        async () => {
            const res = await Services.createShopifyConnector(tempCredId!)
            return res
        }
    )
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (error) {
            console.log(
                'Error creating connector, maybe the token has been expired',
                error
            )
            setTimeout(() => navigate('/'), 3000)
        }
        if (data) {
            dispatch(addNewConnectorToList(data.connector as Connector))
            let path = `${PATH.onBoardingFillData}?services=shopify`
            navigate(path, {
                replace: true,
            })
        }
    }, [data, error])

    return (
        <Box
            width={'100vw'}
            height={'100vh'}
            display={'flex'}
            flexDirection={'column'}
            gap={2}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <CircularProgress />
            <Typography variant={'h6'}>
                {error
                    ? 'Oops! Something went wrong with the connection. Please try again or contact support@askneedle.com'
                    : ' Creating your Shopify connector...'}
            </Typography>
        </Box>
    )
}

export default ConnectingYourShopifyConnector
