import AuthGuard from './components/AuthGuard'
import { LogoutScreen } from './views/login_screen'
import ForgotPasswordScreen from './views/forgot_password'
import UnAuthGuard from './components/UnAuthGuard'
import NotFoundScreen from './views/404/404Screen'
import ResetPasswordScreen from './views/reset_password_screen'
import ToolsScreen from './views/ConnectToolsPage/ConnectToolPage'
import AskNeedleAI from './views/ask_needle_ai'
import { PATH } from './constants/path'
import NotSupportOnMobileScreen from './views/not_support_on_mobile'
import ConnectToFacebookScreen from './views/connect_facebook'
import ConnectToTiktokScreen from './views/connect_tiktok'
import ConnectToKlaviyoScreen from './views/connect_klaviyo'
import FacebookBusinessInstructionScreen from './views/facebook_business_instruction'
import TiktokPartnerGuide from './views/tiktok_partner_guide'
import HomeScreen from './views/HomeScreen/HomeScreen'
import IdeasScreen from './views/IdeasPage/IdeasPage'
import EnhanceYourAIPage from './views/EnhanceYourAiPage/EnhanceYourAiPage'
import ActionsPage from './views/ActionsPage/ActionsPage'
import BrandProfilePage from './views/BrandProfilePage/BrandProfilePage'
import ReviewPastIdeasPage from './views/ReviewPastIdeas/ReviewPastIdeasPage'
import IdeasDetailPage from './views/IdeasDetailPage/IdeasDetailPage'
import AssetsAndTemplatesPage from './views/AssetsAndTemplates/AssetsAndTemplates'
import AddAnIdeaPage from './views/AddAnIdeaPage/AddAnIdeaPage'
import OnBoardingPage from './views/OnBoardingPage/OnBoardingPage'
import OnBoardingFillYourData from './views/OnBoardingPage/OnBoardingFillYourData/OnBoardingFillYourData'
import ConnectingYourShopifyConnector from './views/OnBoardingPage/OnBoardingFillYourData/ConnectingYourShopifyConnector'
import CreditsPage from './views/CreditsPage/CreditsPage'
import SettingsPage from './views/SettingsPage/SettingsPage'
import { Navigate } from 'react-router-dom'

export enum RouteType {
    MainFunction = 'main',
    SubFunction = 'sub',
    AdminFunction = 'admin',
}
export type Route = {
    path: string
    element: React.ReactNode
    routeType?: RouteType
    routeSubList?: Route[]
    icon?: React.ReactNode
    name?: string
}

export interface RouteNavigation extends Route {
    routeType: RouteType
    routeSubList?: Route[]
}

const resultRoute = '/result'

export const routes: Route[] = [
    {
        path: PATH.onBoarding,
        element: (
            <UnAuthGuard>
                <OnBoardingPage />
            </UnAuthGuard>
        ),
    },
    {
        path: PATH.onBoardingFillData,
        element: (
            <AuthGuard>
                <OnBoardingFillYourData />
            </AuthGuard>
        ),
    },
    {
        path: PATH.login,
        element: <Navigate to={'/'} />,
    },
    {
        path: PATH.logout,
        element: <LogoutScreen />,
    },
    {
        path: PATH.signUp,
        element: <Navigate to={'/'} />,
        name: 'Sign Up',
    },
    {
        path: PATH.resetPassword,
        element: <ResetPasswordScreen />,
    },

    {
        path: PATH.tiktokPartnerGuide,
        element: <TiktokPartnerGuide />,
    },
    {
        path: PATH.forgotPassword,
        element: (
            <UnAuthGuard>
                <ForgotPasswordScreen />
            </UnAuthGuard>
        ),
    },
    {
        path: PATH.notFound,
        element: <NotFoundScreen />,
    },
    {
        path: PATH.notSupportedOnMobile,
        element: <NotSupportOnMobileScreen />,
    },
    {
        path: PATH.askNeedle,
        element: (
            <AuthGuard>
                <AskNeedleAI />
            </AuthGuard>
        ),
    },
    {
        path: PATH.creatingYourConnector,
        element: (
            <AuthGuard>
                <ConnectingYourShopifyConnector />
            </AuthGuard>
        ),
    },
]

export const resultRoutes: RouteNavigation[] = [
    {
        path: PATH.homePage,
        element: <HomeScreen />,
        icon: <img src="/assets/svgs/home.svg" alt="home-icon" />,
        name: 'Home',
        routeType: RouteType.MainFunction,
    },
    {
        path: PATH.ideasPage,
        element: <IdeasScreen />,
        icon: <img src="/assets/svgs/ideas.svg" alt="ideas-icon" />,
        name: 'Ideas',
        routeType: RouteType.MainFunction,
    },
    {
        path: PATH.actions,
        element: <ActionsPage />,
        icon: <img src="/assets/svgs/actions.svg" alt="actions-icon" />,
        name: 'Actions',
        routeType: RouteType.MainFunction,
    },
    {
        path: PATH.addIdea,
        element: <AddAnIdeaPage />,
        icon: <img src="/assets/svgs/add-idea-icon.svg" alt="add-idea-icon" />,
        name: 'Add An Idea',
        routeType: RouteType.MainFunction,
    },
    {
        path: PATH.enhanceYourAi,
        element: <EnhanceYourAIPage />,
        icon: (
            <img src="/assets/svgs/personalize-ai.svg" alt="Enhance-ai-icon" />
        ),
        name: 'Enhance Your AI',
        routeType: RouteType.MainFunction,
        routeSubList: [
            {
                path: PATH.tools,
                element: <ToolsScreen />,
                name: 'Connect Tools',
                routeType: RouteType.SubFunction,
            },
            {
                path: PATH.brandProfilePage,
                element: <BrandProfilePage />,
                name: 'Brand Profile',
                routeType: RouteType.SubFunction,
            },
            {
                path: PATH.assetsAndTemplates,
                element: <AssetsAndTemplatesPage />,
                name: 'Assets and Templates',
                routeType: RouteType.SubFunction,
            },
            {
                path: PATH.reviewPastIdeasPage,
                element: <ReviewPastIdeasPage />,
                name: 'Review Past Ideas',
                routeType: RouteType.SubFunction,
            },
        ],
    },
    {
        path: PATH.tools,
        element: <ToolsScreen />,
        name: 'Connect Tools',
        routeType: RouteType.SubFunction,
    },
    {
        path: PATH.brandProfilePage,
        element: <BrandProfilePage />,
        name: 'Assets and Templates',
        routeType: RouteType.SubFunction,
    },
    {
        path: PATH.assetsAndTemplates,
        element: <AssetsAndTemplatesPage />,
        name: 'Connect Tools',
        routeType: RouteType.SubFunction,
    },
    {
        path: PATH.reviewPastIdeasPage,
        element: <ReviewPastIdeasPage />,
        name: 'Review Past Ideas',
        routeType: RouteType.SubFunction,
    },
    {
        path: PATH.creditPage,
        name: 'Credits',
        element: <CreditsPage />,
        routeType: RouteType.SubFunction,
    },
    {
        path: PATH.settingsPage,
        name: 'Settings',
        element: <SettingsPage />,
        routeType: RouteType.SubFunction,
    },
    {
        path: PATH.tiktokIntegrate,
        element: <ConnectToTiktokScreen />,
        routeType: RouteType.SubFunction,
    },
    {
        path: PATH.klaviyoIntegrate,
        element: <ConnectToKlaviyoScreen />,
        routeType: RouteType.SubFunction,
    },
    {
        path: PATH.facebookIntegrate,
        element: <ConnectToFacebookScreen />,
        routeType: RouteType.SubFunction,
    },
    {
        name: PATH.ideasDetail,
        path: `${resultRoute}/ideas/:id`,
        element: <IdeasDetailPage />,
        routeType: RouteType.SubFunction,
    },
    {
        path: PATH.facebookBusinessInstruction,
        element: <FacebookBusinessInstructionScreen />,
        routeType: RouteType.SubFunction,
    },
]
