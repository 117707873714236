import { axiosInstance, handleResponse, getOptions } from '../api'
import { needdleConfig } from '../api/config'

export const EnhanceYourAIServices = {
    getBrandProfile: async () => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(needdleConfig.BRAND_PROFILE, options)
        )
    },
    getAssetsTemplates: async () => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(needdleConfig.ASSETS_TEMPLATES, options)
        )
    },

    getBrandProducts: async () => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(needdleConfig.BRAND_PRODUCTS, options)
        )
    },

    updateBrandProfile: async (data: Partial<Needle.BrandProfile>) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.patch(needdleConfig.BRAND_PROFILE, data, options)
        )
    },

    updateAssetsTemplates: async (
        data: Partial<Needle.BrandAssetAndTemplate>
    ) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.patch(needdleConfig.ASSETS_TEMPLATES, data, options)
        )
    },
}
